import { FileImageOutlined, FileTextOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import { FC } from 'react';
import { typeFileMsg } from '../../utils/messages/typeFile';
import CarouselItem from './CarouselItem';
import './style.scss';

interface CarouselCustomProps {
  data: any[];
  slidesQuantity: number;
}

const handleFile = (isImg: any) => {
  if (isImg) return <FileImageOutlined />;
  return <FileTextOutlined />;
};
const CarouselCustom: FC<CarouselCustomProps> = ({ data, slidesQuantity }) => {
  return (
    <Carousel
      arrows={true}
      dots={false}
      slidesToScroll={1}
      rows={1}
      infinite={false}
      nextArrow={<RightOutlined />}
      prevArrow={<LeftOutlined />}
      slidesToShow={data?.length > slidesQuantity ? slidesQuantity : data?.length}
      className={`carousel ${data?.length >= slidesQuantity ? 'over_than_quantity' : 'less_than_quantity'}`}>
      {data?.reverse().map((file: any, index: number) => {
        const isImg = file.type.includes('image');
        let overallType = isImg ? typeFileMsg.image : typeFileMsg.other;
        return (
          <CarouselItem
            preview={file?.url_preview}
            url={file?.url_download}
            filename={file?.filename}
            isImg={isImg}
            uuid={file?.uuid}
            overallType={overallType}
            key={index}
            handleFile={handleFile}
            typeFile={file?.type}
          />
        );
      })}
    </Carousel>
  );
};

export default CarouselCustom;
