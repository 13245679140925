import { FC, useEffect, useRef, useState } from 'react';
import { Chart, Chart as ChartJS, registerables } from 'chart.js';
import { DownloadOutlined } from '@ant-design/icons';
import { DateTimePicker, ErrorMessage, TitleContent } from '../../../components/commons';
import { analysisMessages } from '../../../utils/messages';
import { Col, Row } from 'antd';
import { Doughnut } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import analysisApi from '../../../api/analysisApi';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import EmptyWrap from '../../../components/commons/EmptyWrap';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import { constant } from '../../../utils/constants';
import { truncateString } from '../../../utils/helper';

ChartJS.register(...registerables);

interface DoughnutProps {}

function randomColor() {
  const color1 = Math.floor(Math.random() * (235 - 52 + 1) + 52);
  const color2 = Math.floor(Math.random() * (235 - 52 + 1) + 52);
  const color3 = Math.floor(Math.random() * (235 - 52 + 1) + 52);
  return `rgb(${color1}, ${color2}, ${color3})`;
}

const DoughnutChart: FC<DoughnutProps> = (props) => {
  const myRef = useRef<Chart<'doughnut'>>(null);
  const ref = useRef<any>(null);
  const { control } = useForm();
  const [isDownload, setDownload] = useState(false);
  const [isEnable, setEnable] = useState(true);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const diffMonth = Math.round(endDate.diff(startDate, 'months', true));
  const { isLoading, data, isFetching }: any = useQuery(
    ['analysis-building', startDate.format('YYYY-MM'), endDate.format('YYYY-MM')],
    () => analysisApi.building(startDate.format('YYYY-MM'), endDate.format('YYYY-MM')),
    {
      enabled: startDate <= endDate && diffMonth <= 12 && diffMonth >= 0,
    }
  );
  const dataDaily = data?.map((i: any) => i.countDaily);
  const dataRegular = data?.map((i: any) => i.countRegular);
  const dataAnalysis = data?.map((i: any) => i.total);
  const nameBuildings = data?.map(
    (i: any) => `${truncateString(i.building_name, 20)} ${i.status ? `(${analysisMessages.deleted})` : ''} `
  );

  const colors = Array.from(Array(data?.length).keys()).map((e: any) => randomColor());
  const [backgroundColor, setBackgroundColor] = useState(colors);

  const options: any = {
    plugins: {
      tooltip: {
        boxPadding: 10,
        displayColors: false,
        borderColor: analysisMessages.tooltipBorderColor,
        backgroundColor: analysisMessages.tooltipBackgroundColor,
        borderWidth: 1,
        borderRadius: 0,
        bodyColor: analysisMessages.tooltipTexColor,
        titleColor: analysisMessages.tooltipTexColor,
        titleFont: {
          size: constant.tooltipFontSize,
          weight: constant.tooltipFontWeight,
          style: analysisMessages.tooltipFontStyle,
          family: analysisMessages.tooltipFontFamily,
        },
        bodyFont: {
          size: constant.tooltipFontSize,
          weight: constant.tooltipFontWeight,
          style: analysisMessages.tooltipFontStyle,
          family: analysisMessages.tooltipFontFamily,
        },
        xAlign: 'left',
        padding: 9,
        callbacks: {
          title: (data: any) => `${analysisMessages.total}: ${dataAnalysis[data[0].dataIndex]}`,
          label: (data: any) => `${analysisMessages.labelperiodical}: ${dataRegular[data.dataIndex]}`,
          afterLabel: (data: any) => `${analysisMessages.labelDaily}: ${dataDaily[data.dataIndex]}`,
        },
      },
      legend: {
        display: false,
        padding: 8,
        // position: analysisMessages.positionLegend,
        labels: {
          color: analysisMessages.colorLegend,
          padding: 30,
          boxHeight: 12,
          boxWidth: 70,
        },
        onHover: function handleHover(event: any, legendItem: any, legend: any) {
          const myChart: any = document.getElementById('doughnut-chart');
          myChart.style.cursor = 'pointer';
          const chart = legend.chart;
          const tooltip = chart.tooltip;
          const chartArea = chart.chartArea;
          tooltip.setActiveElements(
            [
              {
                datasetIndex: 0,
                index: legendItem.index,
              },
            ],
            {
              x: (chartArea.left + chartArea.right) / 2,
              y: (chartArea.top + chartArea.bottom) / 2,
            }
          );
          chart.update();
        },
        onLeave: function handleHover(event: any, legendItem: any, legend: any) {
          const myChart: any = document.getElementById('doughnut-chart');
          myChart.style.cursor = 'default';
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const state = {
    labels: nameBuildings,
    datasets: [
      {
        data: dataAnalysis,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        hoverOffset: 4,
        responsive: true,
        maintainAspectRatio: false,
      },
    ],
  };
  const handleDownload = () => {
    setDownload(true);
  };
  const checkDate =
    startDate > endDate && (startDate.month() !== endDate.month() || startDate.year() !== endDate.year());
  const download: any = useQuery(
    ['download-building', startDate?.format('YYYY-MM'), endDate?.format('YYYY-MM')],
    () => analysisApi.downloadBuilding(startDate.format('YYYY-MM'), endDate.format('YYYY-MM')),
    {
      enabled: isDownload && diffMonth <= 12 && diffMonth >= 0,
      onSuccess: () => setDownload(false),
    }
  );
  useEffect(() => {
    setEnable(data?.length > 0);
    setBackgroundColor(colors);
  }, [data]);
  useEffect(() => {
    setDownload(false);
  }, [startDate, endDate]);
  const className = `${
    !checkDate && data && data?.length > 0
      ? 'analysis-content__plan__title__icon'
      : 'analysis-content__plan__title__icon__disabled'
  } `;
  let msg: string | undefined;
  if (checkDate) msg = analysisMessages.errMonthMsg;
  else if (!(diffMonth <= 12 && diffMonth >= 0)) msg = analysisMessages.limitMonthMsg;

  const handleActive = (index: number) => {
    const legend = document.getElementById('legend-id');
    const tooltip = myRef.current?.tooltip;

    if (legend) {
      legend.addEventListener('mousemove', function () {
        const numberOfElements = myRef.current?.data.datasets[0].data.length;
        if (numberOfElements) {
          myRef.current?.setActiveElements([{ datasetIndex: 0, index: index }]);
          const chartArea = myRef.current?.chartArea;
          tooltip?.setActiveElements(
            [
              {
                datasetIndex: 0,
                index: index,
              },
            ],
            { x: (chartArea?.left + chartArea?.right) / 2, y: (chartArea?.top + chartArea?.bottom) / 2 }
          );
        }
      });
      myRef.current?.update();
    }
  };
  const handleClick = (index: number, color: string) => {
    const legend = document.getElementById(`legend-${index}`);
    const element: any = myRef.current?.getActiveElements();
    if (legend && element) {
      if (element[0].element.hidden === false || element[0].element.hidden === undefined) {
        myRef.current?.hide(0, index);
        legend.style.textDecoration = 'line-through';
        let i: any = document.getElementsByClassName('content');
        i[index].style.background = 'grey';
        return;
      }
      if (element[0].element.hidden) {
        legend.style.textDecoration = 'none';
        let i: any = document.getElementsByClassName('content');
        i[index].style.background = color;
        myRef.current?.show(0, index);
      }
    }
  };

  return (
    <>
      <section className="analysis-content__plan">
        <div className="analysis-content__plan__title">
          <TitleContent
            leftIcon={false}
            title={analysisMessages.titleBuilding}
            rightIcon={{
              front: (
                <>
                  <div className={className} onClick={!checkDate && isEnable ? handleDownload : undefined}>
                    <DownloadOutlined />
                  </div>
                </>
              ),
            }}
            side={1}
          />
        </div>

        <Row className="analysis-content__plan__filter filter-doughnut" gutter={[30, 0]}>
          <Col xs={8} lg={5} className="analysis-content__plan__filter__item">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  props={{
                    ...field,
                    onBlur: (e: any) => {
                      setStartDate(e.target.value ? moment(e.target.value, 'YYYY年MM月') : moment());
                    },
                    value: moment(startDate, 'YYYY年MM月'),
                    onChange: (e: any) => {
                      field.onChange(e);
                      setStartDate(e ? e : moment());
                    },
                  }}
                  label={analysisMessages.time}
                  picker="month"
                  dateFormat="YYYY年MM月"
                  allowClear={false}
                  isError={checkDate || !(diffMonth <= 12 && diffMonth >= 0)}
                  help={<ErrorMessage message={msg} />}
                />
              )}
            />
          </Col>
          <Col xs={8} lg={5} className="analysis-content__plan__filter__item">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  label=""
                  picker="month"
                  dateFormat="YYYY年MM月"
                  allowClear={false}
                  props={{
                    ...field,
                    onBlur: (e: any) => {
                      setEndDate(e.target.value ? moment(e.target.value, 'YYYY年MM月') : moment());
                    },
                    value: moment(endDate, 'YYYY年MM月'),
                    onChange: (e: any) => {
                      field.onChange(e);
                      setEndDate(e ? e : moment());
                    },
                  }}
                  isError={checkDate || !(diffMonth <= 12 && diffMonth >= 0)}
                  help={<ErrorMessage message="" />}
                />
              )}
            />
          </Col>
        </Row>
        <div className="analysis-content__plan__pie-chart">
          {isLoading || isFetching || download.isLoading || download.isFetching ? (
            <Loading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  <div className="analysis-content__plan__pie-chart__wrapper">
                    <Doughnut
                      ref={myRef}
                      data={state}
                      options={options}
                      id="doughnut-chart"
                      plugins={[
                        {
                          id: 'custom_canvas',
                          beforeInit: function (chart: any, args: any, options: any) {
                            const legend = document.getElementById('legend-id') as HTMLElement;
                            if (chart.canvas.id === 'doughnut-chart') {
                              chart.data.labels.forEach((label: string, i: number) => {
                                var div = document.createElement('div');
                                div.innerHTML = `<div id="legend-${i}"><span class="content" style="background-color: ${colors[i]}"> </span>${label}</div>`;
                                div.onmousemove = () => {
                                  handleActive(i);
                                };
                                div.onclick = () => {
                                  handleClick(i, colors[i]);
                                };
                                legend.appendChild(div);
                              });
                            }
                            return;
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="legend__wrap">
                    <div id="legend-id" ref={ref}></div>
                  </div>
                </>
              ) : (
                <EmptyWrap />
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default DoughnutChart;
