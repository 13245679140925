import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { notification, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MasterTable from '..';
import buildingApi from '../../../api/buildingApi';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import Popup from '../../../components/commons/Popup';
import { configs } from '../../../configs';
import { Building } from '../../../models/building';
import { appRoutes } from '../../../router/routes';
import { buildingMasterMessages, generalMessages, masterMessages } from '../../../utils/messages';
import './style.scss';

interface MasterBuildingProps {}

const MasterBuilding: FC<MasterBuildingProps> = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iD, setId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort]: any = useState({});
  const [filter, setFilter]: any = useState();
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isFetching, isError, data }: any = useQuery(
    ['building', currentPage, filter, sort],
    () => buildingApi.getPage(currentPage, filter, type ? sort[type] : undefined, type),
    { staleTime: filter ? 0 : Infinity }
  );
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const mutation = useMutation(buildingApi.delete, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['building', currentPage]).then(() => {
          const dat: any = queryClient.getQueryData(['building', currentPage]);
          if (dat?.data?.length === 0) {
            setCurrentPage((preState: any) => preState - 1);
          }
          if (!isFetching) setLoading(false);
          setVisible(false);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'building' && query.queryKey[1] !== currentPage,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      if (error && error.response.status === 404) {
        queryClient.clear();
      }
    },
  });
  const handleOpen = () => {
    navigate(`${appRoutes.MASTER.BUILDING.ADD}`);
  };

  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
    queryClient.invalidateQueries(['facility']);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  const handleEdit = (e: any, id: string) => {
    e.stopPropagation();
    navigate(`${appRoutes.MASTER.BUILDING.ROOT}/details/${id}`);
  };
  const columns: ColumnsType<Building> = [
    {
      title: (
        <div className="equipment-management__table__title__wrapper">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_name: 'asc' })}
              style={{ color: sort.sort_by_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_name: 'desc' })}
              style={{ color: sort.sort_by_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{buildingMasterMessages.masterColumn}</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'nameBuilding',
      width: '30%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <div className="equipment-management__table__container__building">
          <Tooltip title={text} placement="topLeft">
            <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {text}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <div className="equipment-management__table__title">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_postal_code: 'asc' })}
              style={{ color: sort.sort_by_postal_code == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_postal_code: 'desc' })}
              style={{ color: sort.sort_by_postal_code == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{buildingMasterMessages.postalCode}</span>
        </div>
      ),
      dataIndex: 'postal_code',
      key: 'postalCode',
      width: '20%',
    },
    {
      title: (
        <div className="equipment-management__table__title">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_address_1: 'asc' })}
              style={{ color: sort.sort_by_address_1 == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_address_1: 'desc' })}
              style={{ color: sort.sort_by_address_1 == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{buildingMasterMessages.address1}</span>
        </div>
      ),
      dataIndex: 'address_1',
      key: 'address1',
      width: '32%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <div className="note" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {text}
        </div>
      ),
    },
    {
      title: (
        <div className="equipment-management__table__title">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_address_2: 'asc' })}
              style={{ color: sort.sort_by_address_2 == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_address_2: 'desc' })}
              style={{ color: sort.sort_by_address_2 == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{buildingMasterMessages.address2}</span>
        </div>
      ),
      dataIndex: 'address_2',
      key: 'address2',
      width: '32%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <div className="note" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {text}
        </div>
      ),
    },
    {
      title: <></>,
      key: 'action',
      align: 'right',
      width: '6%',

      render: (_: any, record: Building) => (
        <Space className="action">
          <div className="btn-action" onClick={(e) => handleEdit(e, record.id)}>
            <EditOutlined />
          </div>
          <div
            className="btn-action"
            onClick={(e) => {
              e.stopPropagation();
              setId(record.id);
              setVisible(true);
            }}>
            <CloseOutlined />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <>
      {loading || isFetching ? (
        <Loading />
      ) : (
        <div className="master-table">
          <MasterTable
            route={`${appRoutes.MASTER.BUILDING.ROOT}/details/`}
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            handleFilter={handleFilter}
            title={buildingMasterMessages.title}
            handleOpen={handleOpen}
            columns={columns}
            data={data?.data}
            total={data?.total}
            pageSize={generalMessages.page_size}
            currentPage={currentPage}
            setCurrent={(page: number) => setCurrentPage(page)}
          />
        </div>
      )}

      {visible && (
        <Popup
          unVisible={() => setVisible(false)}
          title={masterMessages.titleConfirm}
          actions={() => {
            setLoading(true);
            if (iD) mutation.mutate(iD);
            setVisible(false);
          }}
          width="415px"
          text={masterMessages.textConfirm}
          btn1={masterMessages.yes}
          btn2={masterMessages.no}
        />
      )}
    </>
  );
};

export default MasterBuilding;
