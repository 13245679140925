import { Button, Form } from 'antd';

interface ButtonWrapProps {
  type?: 'primary' | 'default' | 'dashed' | 'text' | 'link' | 'ghost';
  htmlType?: 'button' | 'submit' | 'reset';
  text?: React.ReactNode;
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  width?: string | number;
  height?: string | number;
  loading?: boolean;
  onClick?: () => void;
  letterSpacing?: boolean;
  danger?: boolean;
  backgroundColor?: string;
  display?: string;
  ghost?: boolean;
  border?: string;
  padding?: string;
  autoFocus?: boolean;
  ref?: any;
  props?: any;
}

const ButtonWrap: React.FC<ButtonWrapProps> = ({
  type = 'default',
  htmlType = 'button',
  text,
  icon,
  className = '',
  color,
  disabled,
  width,
  height,
  loading,
  onClick,
  letterSpacing,
  danger,
  iconLeft,
  iconRight,
  backgroundColor,
  display,
  ghost,
  border,
  padding,
  autoFocus,
  ref,
  props,
}) => {
  return (
    <Form.Item>
      <Button
        type={type}
        danger={danger}
        className={`c-button ${className} ${type}`}
        style={{
          width: width,
          height: height,
          letterSpacing: letterSpacing ? '-2px' : 'unset',
          color: color,
          backgroundColor: backgroundColor,
          border: border,
          display: display,
          padding: padding,
        }}
        icon={icon}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        autoFocus={autoFocus ?? undefined}
        htmlType={htmlType}
        ghost={ghost}
        ref={ref}
        {...props}>
        {iconLeft}
        {text}
        {iconRight}
      </Button>
    </Form.Item>
  );
};

export default ButtonWrap;
