export enum PLANTYPE {
  PERIORD = 1,
  EVERYDAY = 2,
}

export enum FREQUENCY {
  MONTH = 1,
  YEAR = 2,
}

export const FILEVALIDATION = 'image/png application/pdf image/jpeg text/plain';

export const FILELIMIT: number = 52428800;
export const FILELIMITONEFILE: number = 20971520;
