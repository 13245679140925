import { analysisMessages } from '../utils/messages';
import axiosClient from './axiosClient';
import { download } from './common/download';

const analytics = 'analytics/';
const base64 = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
const headers: any = { responseType: 'arraybuffer' };

const analysisApi = {
  plan(
    start_month: string,
    end_month: string,
    user_id: string | undefined,
    building_id: string | undefined
  ): Promise<any> {
    const url = analytics;
    return axiosClient.get(
      `${url}plan?start_month=${start_month}&end_month=${end_month}${building_id ? `&building_id=${building_id}` : ''}${
        user_id ? `&user_id=${user_id}` : ''
      }`
    );
  },
  building(start_month: string, end_month: string): Promise<any> {
    const url = analytics;
    return axiosClient.get(`${url}building?start_month=${start_month}&end_month=${end_month}`);
  },
  async downloadPlan(
    start_month: string,
    end_month: string,
    user_id: string | undefined,
    building_id: string | undefined
  ): Promise<any> {
    const url = analytics;
    const response: any = await axiosClient.get(
      `${url}plan?start_month=${start_month}&end_month=${end_month}${building_id ? `&building_id=${building_id}` : ''}${
        user_id ? `&user_id=${user_id}` : ''
      }&download=true`,
      headers
    );
    const result = base64 + Buffer.from(response, 'binary').toString('base64');
    download(result, `${analysisMessages.titlePlan}_${start_month}_${end_month}.xlsx`);
    return result;
  },
  async downloadBuilding(start_month: string, end_month: string): Promise<any> {
    const url = analytics;
    const response: any = await axiosClient.get(
      `${url}building?start_month=${start_month}&end_month=${end_month}&download=true`,
      headers
    );
    const result = base64 + Buffer.from(response, 'binary').toString('base64');
    download(result, `${analysisMessages.titleBuilding}_${start_month}_${end_month}.xlsx`);
    return result;
  },
};

export default analysisApi;
