import { Form, notification } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import profileApi from '../../../api/profileApi';
import { ButtonWrap, ErrorMessage, InputPassword } from '../../../components/commons';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputPasswordCustom from '../../../components/InputPasswordCustom';
import { addUserMessages, generalMessages } from '../../../utils/messages';
import { regex } from '../../../utils/regex';
interface ChangePasswordFormProps {
  isVisible: () => void;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ isVisible }) => {
  const [err, setError]: any = useState({});
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const mutation = useMutation(profileApi.changePassword, {
    onSuccess: (data: any) => {
      if (data.success) {
        setLoading(false);
        notification.success({
          message: generalMessages.successMsg,
        });
        isVisible();
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const onSubmit = (data: any) => {
    setError({});
    setLoading(true);
    if (data.confirmPassword) delete data.confirmPassword;
    mutation.mutate(data);
  };

  const handleCancel = () => {
    if (!loading) {
      isVisible();
      setError({});
    }
  };
  useEffect(() => {
    reset();
    setError({});
  }, [isVisible]);

  return (
    <>
      {loading && <Loading />}
      <div className="popup__form">
        <Form className="popup__form__wrapper">
          <div className="popup__form__input">
            <div className="popup__form__item">
              <Controller
                name="current_password"
                rules={{
                  required: addUserMessages.requireCurrentPwd,
                  pattern: {
                    value: regex.validateCurrentPassword,
                    message: addUserMessages.validateCurrentPassword,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <InputPasswordCustom
                    props={field}
                    msgServer={err['current_password']}
                    msgValidate={errors?.current_password?.message}
                    label={
                      <>
                        {addUserMessages.currentPwd}
                        <RequiredLabel />
                      </>
                    }
                    placeholder={addUserMessages.placeholder}
                    autoFocus={true}
                  />
                )}
              />
            </div>
            <div className="popup__form__item">
              <Controller
                name="password"
                rules={{
                  required: addUserMessages.requiredPassword,
                  pattern: {
                    value: regex.validatePassword,
                    message: addUserMessages.validatePassword,
                  },
                  validate: (val: string) => {
                    if (watch('current_password') === val) {
                      return addUserMessages.existPassword;
                    }
                  },
                }}
                control={control}
                render={({ field }) => (
                  <InputPassword
                    help={
                      <ErrorMessage
                        message={
                          err['password'] ?? (
                            <>
                              {errors.password && (
                                <>
                                  {errors.password?.message} <br />
                                  {addUserMessages.rulePassword1} <br />
                                  {addUserMessages.rulePassword2} <br />
                                </>
                              )}
                            </>
                          )
                        }
                      />
                    }
                    props={field}
                    state={err['password'] || errors.password ? 'error' : ''}
                    label={
                      <>
                        {addUserMessages.password}
                        <RequiredLabel />
                      </>
                    }
                    placeholder={addUserMessages.placeholder}
                  />
                )}
              />
            </div>
            <div className="popup__form__item" style={err['password'] ? { marginTop: '30px' } : {}}>
              <Controller
                name="confirmPassword"
                rules={{
                  required: addUserMessages.requiredConfirmPassword,
                  pattern: {
                    value: regex.validatePassword,
                    message: addUserMessages.validatePassword,
                  },
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return addUserMessages.validateConfirmPassword;
                    }
                  },
                }}
                control={control}
                render={({ field }) => (
                  <InputPassword
                    help={<ErrorMessage message={errors.confirmPassword?.message} />}
                    props={field}
                    state={errors.confirmPassword ? 'error' : ''}
                    label={
                      <>
                        {addUserMessages.confirmPassword}
                        <RequiredLabel />
                      </>
                    }
                    placeholder={addUserMessages.placeholder}
                  />
                )}
              />
            </div>
          </div>

          <div className="popup__form__button">
            <div className="button-wrapper">
              <ButtonWrap
                type="default"
                color="#49ABB1"
                backgroundColor="#1C282F"
                border="1px solid #247E84"
                text={addUserMessages.cancel}
                width="100%"
                height="56px"
                onClick={handleCancel}
              />
              <ButtonWrap
                type="primary"
                htmlType="submit"
                text={addUserMessages.popupTitle}
                width="100%"
                height="56px"
                backgroundColor="#247E84"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ChangePasswordForm;
