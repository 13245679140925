interface AppRoute {
  AUTH: {
    REGISTER: string;
    LOGIN: string;
    PROFILE: string;
    FORGOT_PASSWORD: string;
  };
  USER: {
    ROOT: string;
    LIST: string;
    ADD: string;
    EDIT: string;
    PROFILE: string;
  };
  HOME: {
    ROOT: string;
  };
  MASTER: {
    ROOT: string;
    FACILITY: {
      ROOT: string;
      ADD: string;
      EDIT: string;
    };
    BUILDING: {
      ROOT: string;
      ADD: string;
      EDIT: string;
    };
    DEVICE: {
      ROOT: string;
      ADD: string;
      EDIT: string;
    };
  };
  DEVICE: {
    ROOT: string;
  };
  MAIN: {
    ROOT: string;
    DETAIL: string;
    LIST_DETAIL: string;
    LIST_STATUS: string;
  };
  FORM_DEMO: {
    ROOT: string;
  };
  PLAN: {
    ROOT: string;
    LIST: string;
    CALENDAR: string;
    CREATE: string;
    REVIEW: string;
    HISTORY: string;
    MAP: string;
  };
  ANALYSIS: {
    ROOT: string;
  };
  NOT_FOUND: string;
  ADMIN: {
    ROOT: string;
    LIST: string;
    ADD: string;
    EDIT: string;
    PROFILE: string;
  };
}

export const appRoutes: AppRoute = {
  AUTH: {
    REGISTER: '/register',
    LOGIN: '/login',
    PROFILE: '/profile',
    FORGOT_PASSWORD: '/forgot-password',
  },
  USER: {
    ROOT: '/user',
    LIST: '/user/list',
    ADD: '/user/add',
    EDIT: '/user/details/:id',
    PROFILE: '/profile',
  },
  HOME: {
    ROOT: '/home',
  },
  MASTER: {
    ROOT: '/master',
    FACILITY: {
      ROOT: '/master/facility',
      ADD: '/master/facility/add',
      EDIT: '/master/facility/details/:id',
    },
    BUILDING: {
      ROOT: '/master/building',
      ADD: '/master/building/add',
      EDIT: '/master/building/details/:id',
    },
    DEVICE: {
      ROOT: '/master/device',
      ADD: '/master/device/add',
      EDIT: '/master/device/details/:id',
    },
  },
  DEVICE: {
    ROOT: 'device',
  },
  MAIN: {
    ROOT: '/main',
    DETAIL: '/main/:id',
    LIST_DETAIL: '/main/list-detail',
    LIST_STATUS: '/main/list-status',
  },
  FORM_DEMO: {
    ROOT: '/form-demo',
  },
  PLAN: {
    ROOT: 'plan',
    LIST: 'plan/list',
    CALENDAR: 'plan/calendar',
    CREATE: 'plan/create',
    REVIEW: 'plan/:id',
    HISTORY: 'plan/history/:id',
    MAP: 'plan/map'
  },
  ANALYSIS: {
    ROOT: '/analysis',
  },
  NOT_FOUND: '/404',
  ADMIN: {
    ROOT: '/dashboard',
    LIST: '/dashboard/user',
    ADD: '/dashboard/user/add',
    EDIT: '/dashboard/user/details/:id',
    PROFILE: '/dashboard/profile',
  },
};
