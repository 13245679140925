import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row, Radio, Input, Form, InputRef, Spin, Tooltip } from 'antd';
import { confirmMessages, japanesePlanMessages as planMessages } from '../../../../utils/messages';
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Controller, useFieldArray } from 'react-hook-form';
import { ButtonWrap, ErrorMessage } from '../../../../components/commons';
import WorkDetail from './WorkDetail';
import Popup from '../../../commons/Popup';
import './style.scss';

interface ICheckListProps {
  props: any;
  disable?: boolean;
}
interface IJobError {
  isError: boolean;
  message: string;
}

const CheckListForm: FC<ICheckListProps> = ({ props, disable = false }) => {
  const {
    data,
    buildingItem,
    facility,
    control,
    setValue,
    errors,
    clearErrors,
    getValues,
    isSubmitting,
    buildingReset,
  } = props;
  const { fields, remove, insert, update } = useFieldArray({
    control,
    name: 'works',
  });
  const [visible, setVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState<number>(0);
  const [isMoreJob, setIsMoreJob] = useState<Boolean>(false);
  const [isEdit, setIsEdit] = useState<Boolean>(false);
  const [jobError, setJobError] = useState<IJobError>({ isError: false, message: '' });
  const [indexDeleteJob, setIndexDeleteJob] = useState<number>(-1);
  const inputAddMoreRef = useRef<InputRef>(null);
  const inputEditRef = useRef<InputRef>(null);
  const workRef = useRef<HTMLDivElement>(null);
  const [focusFlag, setFocusFlag] = useState<boolean>(true);
  const [inputJob, setInputJob] = useState<string>(getValues(`works.${selectedJob}.name`));
  const [spinning, setSpinning] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setSpinning(false), 500);
  }, [selectedJob]);

  useEffect(() => {
    setInputJob(getValues(`works.${selectedJob}.name`));
    setJobError({ isError: false, message: '' });
  }, [isEdit]);

  useEffect(() => {
    if (isMoreJob) {
      setInputJob('');
    }
  }, [isMoreJob]);

  useEffect(() => {
    if (isMoreJob && inputAddMoreRef.current && focusFlag) {
      inputAddMoreRef.current!.focus({
        cursor: 'end',
      });
      setFocusFlag(false);
    }
    if (!isMoreJob) {
      setFocusFlag(true);
    }
  }, [isMoreJob, inputAddMoreRef.current]);

  useEffect(() => {
    if (isEdit && inputEditRef.current && focusFlag) {
      inputEditRef.current!.focus({
        cursor: 'end',
      });
      setFocusFlag(false);
    }
    if (!isEdit) {
      setFocusFlag(true);
    }
  }, [isEdit, inputEditRef.current]);
  useEffect(() => {
    if (
      workRef.current &&
      errors?.works &&
      !errors?.frequency &&
      !errors?.type &&
      !errors?.name &&
      !errors?.building_id &&
      !errors?.user_id &&
      !errors?.plan_start_date &&
      !errors?.plan_start_time &&
      !errors?.plan_end_date &&
      !errors?.plan_end_time
    ) {
      workRef.current.scrollIntoView();
    }
  }, [isSubmitting, workRef.current]);

  const existName = (e: string): boolean => {
    if (e === '' || e.length === 0) {
      setJobError({ isError: true, message: planMessages.jobNameEmpty });
      return false;
    }
    return !fields?.some((each: any) => {
      if (each.name === e) {
        setJobError({ isError: true, message: planMessages.jobNameExist });
        return true;
      }
    });
  };

  const handleAddJob = (e: string) => {
    let flag = existName(e.trim());
    if (flag) {
      setSpinning(true);
      const newWork = {
        name: e.trim(),
        content: '',
        note: '',
        report: { type_report: 1, option_report: 'null' },
        documents: [],
      };
      insert(fields.length, newWork);
      setIsMoreJob(false);
      setJobError({ isError: false, message: '' });
      setSelectedJob(fields.length);
    }
  };

  const checkCloneName = (object: any) => {
    const isExistName = fields?.some((each: any) => {
      if (each.name === object.name) {
        return true;
      }
    });
    if (isExistName) {
      object.name = object.name.replace(/\((\d+)\)\s*$/, (match: any, p1: any) => {
        const nextNumber = +p1 + 1;
        return '(' + nextNumber + ')';
      });
      checkCloneName(object);
    } else {
      object.clone = true;
    }
  };

  const handleCloneWork = (index: number) => {
    setSpinning(true);
    const newClone = { ...control._formValues.works[index], content: '' };
    newClone.name = newClone.name + ' (2)';
    delete newClone['id'];
    checkCloneName(newClone);
    insert(index + 1, newClone);
    setIsMoreJob(false);
    setJobError({ isError: false, message: '' });
    setSelectedJob(index + 1);
    setTimeout(() => setSpinning(false), 500);
  };

  const handleToggleEdit = (index: number) => {
    setSelectedJob(index);
    setIsEdit(true);
  };

  const handleEditJobName = (e: string, index: number, currentName: string) => {
    let flag = existName(e.trim());
    if (e === currentName) flag = true;
    if (flag) {
      setIsEdit(false);
      const newEdit = control._formValues.works[index];
      newEdit.name = e.trim();
      update(index, newEdit);
      setJobError({ isError: false, message: '' });
    }
  };

  const handleCancelEdit = (index: number, value: any) => {
    setJobError({ isError: false, message: '' });
    setIsEdit(false);
  };

  const handleRemoveJob = (index: number) => {
    if (control._formValues?.works?.[index].id) {
      setValue(`remove_work_ids`, [...control._formValues.remove_work_ids, control._formValues?.works?.[index].id]);
    }

    if (index === selectedJob) {
      if (selectedJob === 0) {
        setSelectedJob(index);
      } else {
        setSelectedJob(index - 1);
      }
    }
    if (index < selectedJob) {
      setSelectedJob((prev) => prev - 1);
    }
    remove(index);
  };
  return (
    <div className="form-session">
      <Row gutter={[40, 16]}>
        <Col span={24}>
          <h2 className="form-title" ref={workRef}>
            {planMessages.titleListCheckList}
          </h2>
        </Col>
        <Col xs={24} md={8} className="job-list-container">
          <div className="job-list-content">
            <Radio.Group style={{ width: '100%' }} value={selectedJob}>
              {fields.map((each: any, index: number) => {
                return (
                  <div className="group-checklist" key={index}>
                    <div style={{ display: 'flex' }} className={`job `}>
                      <Radio
                        onChange={() => {
                          setSelectedJob(index);
                          setSpinning(true);
                        }}
                        className={isEdit && index === selectedJob ? 'editting' : ''}
                        value={index}
                        disabled={isEdit && index !== selectedJob ? true : false}
                      >
                        {isEdit && selectedJob === index ? (
                          <Controller
                            name={`works.${index}.name`}
                            control={control}
                            rules={{
                              required: planMessages.jobNameRequired,
                            }}
                            render={({ field }) => {
                              return (
                                <>
                                  <Form.Item
                                    style={{ marginTop: 20 }}
                                    help={
                                      jobError.isError ? (
                                        <ErrorMessage message={jobError.message}></ErrorMessage>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  >
                                    <Input
                                      name={field.name}
                                      placeholder={planMessages.placeholderJobName}
                                      className={jobError.isError ? 'border-error' : 'input-job'}
                                      defaultValue={field.value}
                                      onChange={(e) => {
                                        setJobError({ isError: false, message: '' });
                                        return setInputJob(e.target.value);
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == 13) {
                                          handleEditJobName(inputJob, index, each.name);
                                          e.preventDefault();
                                          return false;
                                        }
                                      }}
                                      ref={inputEditRef}
                                    />
                                  </Form.Item>
                                </>
                              );
                            }}
                          />
                        ) : (
                          <Controller
                            key={each.id + 2}
                            name={`works.${index}.name`}
                            control={control}
                            render={({ field }) => (
                              <>
                                <Tooltip title={field.value} placement="topLeft">
                                  <div className="job__radio__content" {...field}>
                                    {field.value}
                                  </div>
                                </Tooltip>
                              </>
                            )}
                          />
                        )}
                      </Radio>
                      {isEdit && selectedJob === index && (
                        <div className="job__radio__right">
                          <CheckOutlined
                            className={`radio-normal`}
                            style={{ color: 'green' }}
                            onClick={() => handleEditJobName(inputJob, index, each.name)}
                          />
                          <CloseOutlined
                            className={`radio-normal`}
                            onClick={() => handleCancelEdit(index, fields[index])}
                          />
                          {errors.works?.[index] !== undefined && <WarningOutlined className={`radio-normal`} />}
                        </div>
                      )}

                      {!disable && !isEdit && !isMoreJob && (
                        <div className="job__radio__right">
                          <EditOutlined
                            className={isEdit ? `d-none` : `radio-hide`}
                            onClick={() => handleToggleEdit(index)}
                          />
                          <CopyOutlined
                            className={isEdit ? `d-none` : `radio-hide`}
                            onClick={() => handleCloneWork(index)}
                          />
                          {fields.length > 1 && (
                            <DeleteOutlined
                              className="radio-hide"
                              onClick={() => {
                                setIndexDeleteJob(index);
                                setVisible(true);
                              }}
                            />
                          )}
                          {errors.works?.[index] !== undefined && <WarningOutlined className={`radio-normal`} />}
                        </div>
                      )}
                    </div>
                    <div className="group-checklist__endline"></div>
                  </div>
                );
              })}
            </Radio.Group>
            {!disable && !isEdit && (
              <>
                {isMoreJob ? (
                  <div className="more__job">
                    <Row gutter={[16, 16]} className="more__job__content">
                      <Col span={3} className="more__job__content__left">
                        <div style={{ width: 'auto', height: 'auto' }}>
                          <PlusCircleOutlined style={{ fontSize: 20, color: 'rgb(79,79,79)' }} />
                        </div>
                      </Col>
                      <Col span={18} className="more__job__content__middle">
                        <Form.Item
                          style={{ marginTop: 12 }}
                          help={
                            jobError.isError ? <ErrorMessage message={jobError.message}></ErrorMessage> : <div></div>
                          }
                        >
                          <Input
                            className={jobError.isError ? 'border-error' : ''}
                            placeholder={planMessages.placeholderJobName}
                            onChange={(e) => {
                              setJobError({ isError: false, message: '' });
                              return setInputJob(e.target.value);
                            }}
                            onKeyDown={(e: any) => {
                              if (e.keyCode == 13) {
                                handleAddJob(inputJob);
                                e.preventDefault();
                                return false;
                              }
                            }}
                            ref={inputAddMoreRef}
                          ></Input>
                        </Form.Item>
                      </Col>
                      <Col span={3} className="more__job__content__right">
                        <CheckOutlined
                          className={`radio-normal`}
                          style={{ color: 'green' }}
                          onClick={() => handleAddJob(inputJob)}
                        />
                        <CloseOutlined
                          className={`radio-normal`}
                          onClick={() => {
                            setIsMoreJob(false);
                            setJobError({ isError: false, message: '' });
                          }}
                          style={{ color: 'red' }}
                        />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="more__job" onClick={() => setIsMoreJob(true)}>
                    <ButtonWrap
                      color="#49ABB1"
                      className="addValueBtn"
                      type="primary"
                      text={planMessages.labelMoreJob}
                      width={'100%'}
                      height="56px"
                      iconLeft={<PlusCircleOutlined style={{ fontSize: 20, color: '#49ABB1' }} />}
                    ></ButtonWrap>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>

        <Col xs={24} md={16}>
          <Spin tip={planMessages.loading} spinning={spinning} className="plan-spin">
            {fields.length !== 0 &&
              fields.map((each: any, index: number) => (
                <WorkDetail
                  data={each}
                  className={selectedJob !== undefined ? (selectedJob === index ? 'd-block' : 'd-none') : 'd-none'}
                  index={index}
                  key={each.id}
                  review={disable}
                  props={{
                    buildingItem,
                    facility,
                    data,
                    fields,
                    insert,
                    remove,
                    control,
                    setValue,
                    errors,
                    clearErrors,
                    getValues,
                    buildingReset,
                  }}
                ></WorkDetail>
              ))}
          </Spin>
        </Col>
      </Row>
      {visible && (
        <Popup
          unVisible={() => setVisible(false)}
          title={planMessages.titleConfirm}
          actions={() => {
            handleRemoveJob(indexDeleteJob);
            setVisible(false);
          }}
          width="415px"
          text={planMessages.textDeleteConfirm}
          btn1={confirmMessages.yes}
          btn2={confirmMessages.no}
        />
      )}
    </div>
  );
};

export default CheckListForm;
