import React, { FC } from 'react';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import './style.scss';

export const statusStr = [
  planMessages.draftStatus,
  planMessages.openStatus,
  planMessages.ongoingStatus,
  planMessages.doneStatus,
  planMessages.cancelStatus,
];

export const statusText = ['draft', 'open', 'ongoing', 'done', 'cancel'];

interface IPlanStatusProps {
  status?: number;
  purpose: string;
  props?: any;
}
const PlanStatus: FC<IPlanStatusProps> = ({ status = 5, purpose, props }) => {
  return (
    <div className={`status ${purpose} ${statusText[status - 1]}`} {...props}>
      <div className="status__icon"></div>
      <div className="status__text">{`${statusStr[status - 1]}`}</div>
    </div>
  );
};

export default PlanStatus;
