import { FC, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import TableWrap from '../../components/TableWrap';
import {
  analysisMessages,
  confirmMessages,
  japanesePlanMessages as planMessages,
  userMgmtMessages,
} from '../../utils/messages';
import {
  ClockCircleOutlined,
  UserOutlined,
  EnvironmentOutlined,
  CopyOutlined,
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  FilterFilled,
} from '@ant-design/icons';
import { Plan } from '../../models';
import { useNavigate } from 'react-router-dom';
import PlanStatus from '../../components/commons/PlanStatus';
import { TitleContent } from '../../components/commons';
import { LoadingStatusIcon } from '../../assets/icons/LoadingStatusIcon';
import { ViewListIcon } from '../../assets/icons/ViewListIcon';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import { Dropdown, Menu, notification, Space, Spin, Tooltip } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import planApi from '../../api/planApi';
import PaginationWrapper from '../../components/commons/Pagination';
import Popup from '../../components/commons/Popup';
import useToggle from '../../hooks/useToggle ';
import { PLANTYPE } from '../../utils/plan';
import { generalMessages } from '../../utils/messages/general';
import { appRoutes } from '../../router/routes';
import { configs } from '../../configs';
import Loading from '../../components/commons/Loading';
import { getMoment } from '../../utils/helper';
import { planConstant } from '../../utils/constants';
import './style.scss';
import SearchField from '../../components/SearchField';

const moment = getMoment();
interface PlanManagementProps {}

interface DataType {
  id: number;
  buildingName: string;
  managerName: string;
  createdTime: Date;
}

const PlanManagement: FC<PlanManagementProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId]: any = useState<string>('');
  const [isLoadingClone, setIsLoadingClone] = useState(false);
  const navigate = useNavigate();
  const [sort, setSort]: any = useState({ sort_by_created_at: 'desc' });
  const [filter, setFilter]: any = useState();
  const [filter_status, setFilterStatus]: any = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isLoading, isError, data, error, isFetching }: any = useQuery(
    ['plan', currentPage, filter, filter_status, sort],
    () => {
      const params = new URLSearchParams(window.location.search);
      return planApi.getPlansByPage(
        currentPage,
        filter,
        type ? sort[type] : undefined,
        filter_status,
        type,
        params.get('building') || undefined
      );
    }
  );

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const queryClient = useQueryClient();

  const [activeIconHeader, setActiveIconHeader] = useToggle(true);
  const mutation = useMutation(planApi.clonePlan, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([queryClient.invalidateQueries(['plan', 1])]);
        notification.success({
          message: generalMessages.successMsg,
        });
        setIsLoadingClone(false);
        setVisible(false);
        navigate(`/${appRoutes.PLAN.ROOT}/history/${selectedId}`);

        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'plan' && query.queryKey[1] !== 1,
        });
      }
    },
    onError: (error: any) => {
      notification.error({
        message: generalMessages.error,
        description: error.status === 500 ? error?.message : '',
      });
      setIsLoadingClone(false);
      setVisible(false);
    },
  });
  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    setFilterStatus(undefined);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
  };
  const handleMenuClick = (e: any) => {
    setCurrentPage(1);
    setSort({ sort_by_plan_status: undefined });
    setFilterStatus(e.key);
  };

  const handleOpen = (e: any) => {
    setOpen(!open);
  };
  const menu = (
    <Menu
      selectedKeys={[filter_status]}
      onClick={(e) => handleMenuClick(e)}
      items={[
        {
          key: 'none',
          icon: <span className="menu__item">{analysisMessages.placeholer}</span>,
        },
        {
          key: '1',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(1)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '2',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(2)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '3',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(3)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '4',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(4)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '5',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(5)} purpose="plan" />
            </div>
          ),
        },
      ]}
    />
  );

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort" style={{ marginLeft: 20 }}>
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_plan_type: 'asc' })}
              style={{ color: sort.sort_by_plan_type == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_plan_type: 'desc' })}
              style={{ color: sort.sort_by_plan_type == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{planMessages.planType}</span>
        </div>
      ),
      dataIndex: 'type',
      key: 'type',
      render: (planType: number) => (
        <span className="planType__text text">
          {planType === PLANTYPE.PERIORD ? planMessages.planTypePeriod : planMessages.planTypeEveryDay}
        </span>
      ),
      width: '120px',
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_plan_name: 'asc' })}
              style={{ color: sort.sort_by_plan_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_plan_name: 'desc' })}
              style={{ color: sort.sort_by_plan_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{planMessages.labelJobName}</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (text: string) => (
        <Tooltip title={text} placement="topLeft">
          <div className="text">{text}</div>
        </Tooltip>
      ),
      width: '150px',
    },
    {
      title: (
        <>
          <div className="column__title">
            <div className="column__title__sort">
              <CaretUpOutlined
                onClick={() => handleSort({ sort_by_building_name: 'asc' })}
                style={{ color: sort.sort_by_building_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
              />
              <CaretDownOutlined
                onClick={() => handleSort({ sort_by_building_name: 'desc' })}
                style={{ color: sort.sort_by_building_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
              />
            </div>
            <div className="column__title__wrapper">
              <EnvironmentOutlined />
              {planMessages.planBuilding}
            </div>
          </div>
        </>
      ),
      dataIndex: 'building_name',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (text: string) => (
        <Tooltip title={text} placement="topLeft">
          <div className="text">{text}</div>
        </Tooltip>
      ),
      width: '120px',
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_created_by: 'asc' })}
              style={{ color: sort.sort_by_created_by == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_created_by: 'desc' })}
              style={{ color: sort.sort_by_created_by == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="column__title__wrapper">
            <UserOutlined />
            {planMessages.manager}
          </div>
        </div>
      ),
      dataIndex: 'created_by',
      key: 'created_by',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (field) => (
        <Tooltip title={field?.username} placement="topLeft">
          <div className="text">{field?.username}</div>
        </Tooltip>
      ),
      width: '140px',
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_staff_name: 'asc' })}
              style={{ color: sort.sort_by_staff_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_staff_name: 'desc' })}
              style={{ color: sort.sort_by_staff_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="column__title__wrapper">
            <UserOutlined />
            {planMessages.planWorker}
          </div>
        </div>
      ),
      dataIndex: 'user_plan',
      key: 'user_plan',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (field) => (
        <Tooltip title={field?.username} placement="topLeft">
          <div className="text">{field?.username}</div>
        </Tooltip>
      ),
      width: '150px',
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_created_at: 'asc' })}
              style={{ color: sort.sort_by_created_at == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_created_at: 'desc' })}
              style={{ color: sort.sort_by_created_at == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="column__title__wrapper">
            <ClockCircleOutlined />
            {planMessages.createdTime}
          </div>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => {
        return <div className="text">{moment(created_at).format('YYYY-MM-DD')}</div>;
      },
      width: '160px',
    },

    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_plan_status: 'asc' })}
              style={{ color: sort.sort_by_plan_status == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_plan_status: 'desc' })}
              style={{ color: sort.sort_by_plan_status == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="column__title__wrapper">
            <LoadingStatusIcon />
            {planMessages.planStatus}
            <div className="dropdown__status">
              <Dropdown overlay={menu}>
                <FilterFilled onClick={handleOpen} style={{ color: filter_status ? '#49ABB1' : '#BFBFBF' }} />
              </Dropdown>
            </div>
          </div>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      // render: (status: number) => <PlanStatus status={Number(status)} purpose="plan"></PlanStatus>,
      render: (status) => {
        return <PlanStatus status={Number(status)} purpose="plan"></PlanStatus>;
      },
      width: '120px',
    },
    {
      title: <></>,
      key: 'action',
      width: '5%',
      render: (record: any) => {
        return (
          <Space className="action">
            {record.status !== 5 && (
              <>
                {!record.isHightLight ? (
                  record.status === 1 ? (
                    <div
                      className="btn-action"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowDetail(record.id);
                      }}>
                      <EditOutlined />
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className="high-light">
                    <span
                      className="copy-icon btn-action"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisible(true);
                        setSelectedId(record.id);
                      }}>
                      <CopyOutlined />
                    </span>
                  </div>
                )}
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const handleClickRow = (plan: any) => {
    if (plan.status !== planConstant.planStatus.DELETE) {
      navigate(`/${appRoutes.PLAN.ROOT}/${plan.id}`);
    }
  };

  const handleShowDetail = (id: string) => {
    navigate(`/${appRoutes.PLAN.ROOT}/${id}`);
  };

  const handleBack = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('building')) {
      navigate(-1);
    } else {
      navigate(`/${appRoutes.PLAN.ROOT}`);
    }
  };
  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  return (
    <>
      {isFetching || isLoadingClone ? (
        <Loading />
      ) : (
        <section className="plan-management">
          <div className="plan-management__container">
            <div className="plan-management__title">
              <TitleContent
                leftIcon={true}
                title={planMessages.titleListPlan}
                rightIcon={{
                  front: (
                    <Space>
                      <div
                        className={`calendar-list__header__icon ${activeIconHeader ? 'active' : ''}`}
                        onClick={() => setActiveIconHeader(true)}>
                        <ViewListIcon />
                      </div>
                      <div
                        className={`calendar-list__header__icon ${!activeIconHeader ? 'active' : ''}`}
                        onClick={() => {
                          setActiveIconHeader(false);
                          navigate(`/${appRoutes.PLAN.CALENDAR}`);
                        }}>
                        <CalendarIcon />
                      </div>
                    </Space>
                  ),
                }}
                side={1}
                handleBack={handleBack}
              />
            </div>
            <div className="plan-management__table">
              <SearchField
                handleFilter={handleFilter}
                value={value?.trim()}
                onChange={(e: any) => {
                  setValue(e.target.value);
                }}
              />
              <TableWrap columns={columns} data={data?.data.data} onClickRow={handleClickRow} />
            </div>
            <div className="plan-management__pagination">
              {data?.data.pagination?.total > generalMessages.page_size && (
                <div className="plan-management__content__pagination">
                  <PaginationWrapper
                    current={currentPage}
                    total={data?.data.pagination.total}
                    pageSize={generalMessages.page_size}
                    setCurrent={(page: number) => setCurrentPage(page)}
                  />
                </div>
              )}
            </div>
          </div>
          {visible && (
            <Popup
              unVisible={() => setVisible(false)}
              title={planMessages.cloneTitle}
              actions={() => {
                if (selectedId) {
                  setVisible(false);
                  setIsLoadingClone(true);
                  mutation.mutate(selectedId);
                }
              }}
              width="415px"
              text={planMessages.textConfirm}
              btn1={confirmMessages.yes}
              btn2={confirmMessages.no}
            />
          )}
        </section>
      )}
    </>
  );
};

export default PlanManagement;
