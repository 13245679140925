export const masterMessages = {
  masterTitle: 'マスタ管理',
  titleFacility: '施設マスタ',
  titleDevice: '設備マスタ',
  titleBuilding: '建物マスタ',
  titleInformation: '情報',
  eraseAll: 'すべて消去',
  titleConfirm: '削除',
  delete: '削除',
  cancel: 'キャンセル',
  textConfirm: '削除してもよろしいですか。',
  yes: 'はい',
  no: 'いいえ',
  noDocumentMsg: '参考資料がありません。',
};
