export const CalendarIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.625 2.3125H14.6875V0.8125C14.6875 0.709375 14.6031 0.625 14.5 0.625H13.1875C13.0844 0.625 13 0.709375 13 0.8125V2.3125H7V0.8125C7 0.709375 6.91563 0.625 6.8125 0.625H5.5C5.39687 0.625 5.3125 0.709375 5.3125 0.8125V2.3125H1.375C0.960156 2.3125 0.625 2.64766 0.625 3.0625V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V3.0625C19.375 2.64766 19.0398 2.3125 18.625 2.3125ZM17.6875 17.6875H2.3125V8.78125H17.6875V17.6875ZM2.3125 7.1875V4H5.3125V5.125C5.3125 5.22813 5.39687 5.3125 5.5 5.3125H6.8125C6.91563 5.3125 7 5.22813 7 5.125V4H13V5.125C13 5.22813 13.0844 5.3125 13.1875 5.3125H14.5C14.6031 5.3125 14.6875 5.22813 14.6875 5.125V4H17.6875V7.1875H2.3125Z"
        fill="#828282"
      />
    </svg>
  );
};
