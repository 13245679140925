import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Modal, notification, Row } from 'antd';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import profileApi from '../../api/profileApi';
import { ButtonWrap, TitleContent } from '../../components/commons';
import HandleError from '../../components/commons/HandleError';
import Loading from '../../components/commons/Loading';
import RequiredLabel from '../../components/commons/RequiredLabel';
import InputFieldCustom from '../../components/InputFieldCustom';
import UploadWrapper from '../../components/Upload';
import { constant } from '../../utils/constants';
import { addUserMessages, generalMessages, profileMessages } from '../../utils/messages';
import { regex } from '../../utils/regex';
import ChangePasswordForm from '../Profile/ChangePasswordForm';
import './style.scss';

interface AdminProfileProps {}

const AdminProfile: FC<AdminProfileProps> = () => {
  const queryClient = useQueryClient();
  const [err, setError]: any = useState({});
  const [visible, setVisible] = useState(false);
  const [display, setDisplay] = useState('none');
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(true);
  const [resetState, setState] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { isLoading, data, isError }: any = useQuery('me', profileApi.getMyProfile);
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const mutation = useMutation(profileApi.editProfile, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries('me').then(() => {
          setDisplay('none');
          setEdit('flex');
          setDisable(true);
          setState(true);
          setLoading(false);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const onSubmit = (dataForm: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.set('full_name', dataForm.full_name);
    if (data?.data?.full_name_katakana !== dataForm.full_name_katakana)
      formData.set('full_name_katakana', dataForm.full_name_katakana);
    if (dataForm.avatar?.length === 1 && !!dataForm.avatar[0]?.name)
      formData.append('avatar', dataForm.avatar[0].originFileObj);
    else if (dataForm?.avatar[0]?.base64 !== data?.data?.base64) formData.append('avatar', 'null');
    mutation.mutate(formData);
  };
  const handleCancel = () => {
    reset(data?.data);
    setDisplay('none');
    setEdit('flex');
    setDisable(true);
    setState(true);
  };
  const handleEdit = () => {
    setDisplay('flex');
    setEdit('none');
    setDisable(false);
    if (errors) setState(false);
  };

  const handleChangePassword = () => {
    setVisible(true);
  };
  const handleCancelModal = () => {
    setVisible(false);
  };
  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="profile">
          <div className="profile__content">
            <div className="profile__content__title">
              <TitleContent leftIcon={false} title={profileMessages.title} />
            </div>
            <section className="create-user create-admin">
              <div className="create-user__container profile-admin">
                <div className="create-user__form">
                  <Form className="create-user__form__wrapper">
                    <Row className="create-user__form__input" gutter={[24, 0]}>
                      <Col span={24} className="create-user__form__item">
                        <Controller
                          name="full_name"
                          rules={{
                            required: addUserMessages.requiredFullname,
                            validate: (value: string) => {
                              if (value?.trim() == '') {
                                return addUserMessages.requiredFullname;
                              }
                              if (regex.validateName.test(value?.trim()) == false) {
                                return addUserMessages.validateFullname;
                              }
                            },
                          }}
                          defaultValue={data?.data.full_name}
                          control={control}
                          render={({ field }) => (
                            <InputFieldCustom
                              props={field}
                              maxLength={constant.maxLenghtFullname}
                              label={
                                <>
                                  {addUserMessages.fullname}
                                  <RequiredLabel />
                                </>
                              }
                              placeholder={!disable ? addUserMessages.placeholder : ''}
                              msgServer={!resetState && err['full_name']}
                              msgValidate={!resetState && errors?.full_name?.message}
                              disable={disable}
                              onBlur={(e: any) => setValue('full_name', e.target.value.trim())}
                              autoFocus={true}
                            />
                          )}
                        />
                      </Col>
                      <Col span={24} className="create-user__form__item">
                        <Controller
                          name="full_name_katakana"
                          rules={{
                            validate: (value: string) => {
                              if (!!value && regex.validateName.test(value?.trim()) == false) {
                                return addUserMessages.validateFullnameKatakana;
                              }
                            },
                          }}
                          defaultValue={data?.data.full_name_katakana}
                          control={control}
                          render={({ field }) => (
                            <InputFieldCustom
                              maxLength={constant.maxLenghtFullnameKatakana}
                              props={field}
                              label={addUserMessages.fullnameKatakana}
                              placeholder={!disable ? addUserMessages.placeholder : ''}
                              msgServer={!resetState && err['full_name_katakana']}
                              msgValidate={!resetState && errors?.full_name_katakana?.message}
                              disable={disable}
                              onBlur={(e: any) => setValue('full_name_katakana', e.target.value.trim())}
                            />
                          )}
                        />
                      </Col>
                      <Col span={24} className="create-user__form__item">
                        <InputFieldCustom
                          defaultValue={data?.data.email}
                          label={addUserMessages.email}
                          disable={true}
                        />
                      </Col>

                      <div className="popup-btn">
                        <Modal
                          title={addUserMessages.popupTitle}
                          visible={visible}
                          footer={null}
                          onCancel={handleCancelModal}
                          width={'650px'}>
                          <ChangePasswordForm isVisible={handleCancelModal} />
                        </Modal>
                      </div>
                      <Form.Item
                        className="create-user__form__item edit-col avatar-item avatar"
                        label={profileMessages.avatar}
                        colon={false}>
                        <>
                          {disable && !data?.data?.avatar && <>{profileMessages.noAvatar}</>}
                          {disable && data?.data?.base64 && (
                            <>
                              <div className="img__wrapper">
                                <img src={`data:image/png;base64,${data?.data?.base64}`} />
                              </div>
                            </>
                          )}

                          {!disable && (
                            <UploadWrapper
                              label=""
                              setValues={setValue}
                              multiple={false}
                              data={
                                data?.data?.base64
                                  ? [{ uuid: '', base64: data?.data.base64, filename: '', url: '', type: '' }]
                                  : []
                              }
                              typeFile="image"
                            />
                          )}
                        </>
                      </Form.Item>
                      <Col span={24} className="create-user__form__button">
                        <div className="button-edit" style={{ display: edit }}>
                          <ButtonWrap
                            type="default"
                            color="#49ABB1"
                            backgroundColor="#1C282F"
                            border="1px solid #247E84"
                            iconLeft={<EditOutlined />}
                            text={addUserMessages.editBtn}
                            width="100%"
                            height="56px"
                            onClick={handleEdit}
                          />
                          <ButtonWrap
                            type="default"
                            color="#49ABB1"
                            backgroundColor="#1C282F"
                            border="1px solid #247E84"
                            iconLeft={<EditOutlined />}
                            text={addUserMessages.changePassword}
                            width="100%"
                            height="56px"
                            display="flex"
                            onClick={handleChangePassword}
                          />
                        </div>
                        <div className="button-wrapper" style={{ display: display }}>
                          <ButtonWrap
                            type="default"
                            color="#49ABB1"
                            backgroundColor="#1C282F"
                            border="1px solid #247E84"
                            text={addUserMessages.cancel}
                            width="100%"
                            height="56px"
                            onClick={handleCancel}
                          />
                          <ButtonWrap
                            type="primary"
                            htmlType="submit"
                            text={addUserMessages.done}
                            width="100%"
                            height="56px"
                            onClick={handleSubmit(onSubmit)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
    </>
  );
};

export default AdminProfile;
