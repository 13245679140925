import { LeftOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import { Carousel, Form, notification, Spin, Upload, UploadProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { constant } from '../../utils/constants';
import UploadItem from './UploadItem';
import { buildingMasterMessages, masterMessages, profileMessages } from '../../utils/messages';
import Popup from '../commons/Popup';
import './style.scss';
interface UploadWrapperProps {
  label: string;
  data?:
    | [
        {
          uuid: string;
          base64: string;
          filename: string;
          url: string;
          type: string;
        }
      ]
    | [];
  setValues: UseFormSetValue<FieldValues>;
  multiple: boolean;
  typeFile?: string;
}

const UploadWrapper: FC<UploadWrapperProps> = ({ label, data, setValues, multiple, typeFile }) => {
  const [fileList, setFileList] = useState<any>(data);
  const [removeFiles, setRemoveFiles] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(false);
  const [limitTotal, setLimitTotal] = useState(0);
  const [errorFile, setErrorFile] = useState(false);
  const checkTypeImg = (file: any) => {
    const type = file?.name?.split('.').reverse()[0] ?? file?.filename.split('.').reverse()[0];
    const fileType = [constant.file__accept[0], constant.file__accept[1], constant.file__accept[2]].includes(
      file?.type
    );
    return constant.image__accept.includes(type) && fileType;
  };
  async function loadFile(file: any) {
    const readFile = async () => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = (e: any) => {
          return resolve(e.target.result);
        };
      });
    };
    const urlImg = await readFile();
    file.urlImg = urlImg;
    return file;
  }
  const handleValidateFile = (fileList: any) => {
    let size = 0;
    const isError = fileList.some((item: any) => {
      const isLt5M = item.size > constant.avatarSizeLimit;
      const fileType = item?.type?.split('/')[0];
      if (item.size === 0) {
        notification.error({
          message: profileMessages.errorType,
        });
        return true;
      }
      if ((!multiple && !checkTypeImg(item)) || (!multiple && isLt5M)) {
        if (isLt5M && checkTypeImg(item)) setErrorFile(true);
        setCount((preState: any) => preState + 1);
        if (!checkTypeImg(item))
          notification.error({
            message: profileMessages.errorType,
            description: profileMessages.typeRecommend,
          });
        return true;
      }
      if ((multiple && !constant.file__accept.includes(item.type)) || (fileType === 'image' && !checkTypeImg(item))) {
        setCount((preState: any) => preState + 1);
        return true;
      }
      size += item.size;
      if (multiple && item.size > constant.fileSizeLimit) {
        setLimit(true);
        return true;
      }
      return false;
    });
    if (size > constant.fileSizeLimit) setLimitTotal(size);
    return size > constant.totalSizeLimit || isError;
  };
  const handleUploadChange: UploadProps['onChange'] = (info: any) => {
    let total: number = 0;
    info.fileList.map((each: any) => {
      if (!!each.size && each.uid.includes('rc-')) total += each.size;
    });
    setLimitTotal(total);
    if (total <= constant.totalSizeLimit) setFileList(info.fileList);
    setCount(0);
  };
  useEffect(() => {
    if (count !== 0 && errorFile) {
      setErrorFile(false);
      notification.error({
        message: profileMessages.errorFile,
        description: profileMessages.descriptionErrFile,
      });
    }
    if (count !== 0 && multiple) {
      notification.error({
        message: profileMessages.errorType,
        description: profileMessages.type,
      });
    }
  }, [count]);
  useEffect(() => {
    if (limit && multiple)
      notification.error({
        message: profileMessages.descriptionError,
      });
    setLimit(false);
  }, [limit]);
  useEffect(() => {
    if (limitTotal > constant.totalSizeLimit && multiple)
      notification.error({
        message: profileMessages.totalLimitSize,
      });
  }, [limitTotal]);
  const handleDeleteAll = () => {
    setFileList([]);
    let uuids: any = [];
    data?.forEach((i: any) => {
      uuids.push(i.uuid);
    });
    setRemoveFiles([...removeFiles, ...uuids]);
  };
  const handleDeleteFile = (uuid: string) => {
    setRemoveFiles([...removeFiles, uuid]);
    setFileList(fileList.filter((item: any) => item?.uuid !== uuid));
  };
  const deleteFile = (uuid: string) => {
    setFileList(fileList.filter((item: any) => item?.uid !== uuid));
  };

  useEffect(() => {
    setValues('files', fileList);
    if (multiple === false) setValues('avatar', fileList);
    setValues('removeFiles', removeFiles);
  }, [fileList, removeFiles]);
  let slidesQuantity = 3;

  return (
    <>
      <Form.Item
        className="upload-container"
        label={label}
        style={fileList?.length > 0 ? { gap: '20px' } : {}}
        colon={false}>
        {!multiple ||
          (fileList?.length > 0 && (
            <div className="upload-container__erase">
              <span onClick={handleDeleteAll}>{masterMessages.eraseAll}</span>
            </div>
          ))}
        <div
          className={
            multiple === false
              ? 'upload-container__item__picture single-picture'
              : fileList?.length > 0
              ? 'upload-container__item__picture'
              : 'upload-container__item__text'
          }>
          <Upload
            showUploadList={false}
            type="select"
            accept={typeFile === 'image' ? '.png, .jpeg, .jpg' : '.png, .jpeg, .jpg, .pdf, .txt'}
            beforeUpload={async (file, fileList) => {
              if (!handleValidateFile(fileList)) {
                await loadFile(file);
                return false;
              } else {
                return Upload.LIST_IGNORE;
              }
            }}
            listType={fileList?.length > 0 ? 'picture-card' : 'text'}
            multiple={multiple}
            maxCount={multiple === false ? 1 : undefined}
            fileList={fileList}
            onChange={handleUploadChange}>
            <div>
              <UploadOutlined />
              <div>{multiple ? buildingMasterMessages.addFileBtn : buildingMasterMessages.uploadBtn}</div>
            </div>
          </Upload>
          {fileList?.length >= 0 && (
            <Carousel
              nextArrow={fileList.length >= 0 ? <RightOutlined /> : <></>}
              prevArrow={fileList.length >= 0 ? <LeftOutlined /> : <></>}
              arrows={true}
              dots={false}
              slidesToScroll={1}
              rows={1}
              infinite={false}
              slidesToShow={slidesQuantity}>
              {[...fileList].reverse().map((file: any, index: number) => {
                return (
                  <div className="img-list__wrapper">
                    <UploadItem
                      key={index}
                      multiple={multiple}
                      checkTypeImg={checkTypeImg}
                      file={file}
                      deleteFile={deleteFile}
                      handleDeleteFile={handleDeleteFile}
                    />
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      </Form.Item>
    </>
  );
};

export default UploadWrapper;
