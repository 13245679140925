import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Avatar } from 'antd';
import { headerMessages } from '../../utils/messages/header';
import { actions, useStore } from '../../store';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import profileApi from '../../api/profileApi';
import { appRoutes } from '../../router/routes';
import Popup from '../../components/commons/Popup';
import { loginMessages } from '../../utils/messages';
import { ROLE } from '../../router/role';
import userIcon from '../../assets/images/user.png';
import authApi from '../../api/authApi';
interface HeaderLayoutProps {
  size?: number;
  type?: 'primary' | 'link' | 'text' | 'default' | 'ghost' | 'dashed' | undefined;
  onClose: () => void;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ size = 40, type = 'primary', onClose }) => {
  const [, dispatch] = useStore();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useQuery('me', profileApi.getMyProfile);
  const mutation = useMutation(authApi.logout, {
    onSuccess: () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('role');
      queryClient.clear();
      dispatch(actions.setIsAuthenticated(''));
      navigate(`${appRoutes.AUTH.LOGIN}`, { replace: true });
    },
    onError: () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('role');
      queryClient.clear();
      dispatch(actions.setIsAuthenticated(''));
      navigate(`${appRoutes.AUTH.LOGIN}`, { replace: true });
    },
  });
  const handleLogout = () => {
    mutation.mutate();
  };
  const handleViewProfile = () => {
    onClose();
    if (localStorage.getItem('role') === ROLE.STAFF) navigate(`${appRoutes.AUTH.PROFILE}`);
    else navigate(`${appRoutes.ADMIN.PROFILE}`);
  };
  return (
    <>
      <div
        className={data?.data.base64 ? 'header__right__avatar' : 'header__right__avatar no__avatar'}
        onClick={handleViewProfile}>
        <Avatar
          size={size}
          src={
            data?.data.base64 ? (
              <img className="avatar" src={`data:image/png;base64,${data?.data.base64}`}></img>
            ) : (
              <img src={userIcon} />
            )
          }></Avatar>
      </div>
      <div className="header__right__name" onClick={handleViewProfile}>
        {localStorage.getItem('role') === ROLE.STAFF ? data?.data.username : data?.data.full_name}
      </div>
      <div className="header__right__logout">
        <Button type={type} ghost size="large" onClick={() => setVisible(true)}>
          {headerMessages.logout}
        </Button>
      </div>
      {visible && (
        <Popup
          unVisible={() => setVisible(false)}
          title={loginMessages.titleLogout}
          actions={() => {
            handleLogout();
            setVisible(false);
          }}
          width="415px"
          text={loginMessages.textPopupLogout}
          btn1={loginMessages.yes}
          btn2={loginMessages.no}
        />
      )}
    </>
  );
};

export default HeaderLayout;
