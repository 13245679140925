import { useForm } from 'react-hook-form';
import { Col, Form, notification, Row, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import PlanForm from '../../../components/Form/PlanForm';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import { ButtonWrap, TitleContent } from '../../../components/commons';
import CheckListForm from '../../../components/Form/CheckListForm/CreatePlan';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import planApi from '../../../api/planApi';
import { buildingDTO, handleData, staffDTO } from '../../../utils/dtos/plan/format-data';
import { generalMessages } from '../../../utils/messages/general';
import { appRoutes } from '../../../router/routes';
import Loading from '../../../components/commons/Loading';
import { getMoment } from '../../../utils/helper';
import './style.scss';

const moment = getMoment();

interface ICreatePlan {}

export interface IReportType {
  type_report: number;
  option_report: any;
}
export interface IContentListForm {
  work_id?: number;
  name: string;
  facility_id: number;
  building_item_id: number;
  content?: string;
  report?: IReportType;
  note?: string;
  documents?: Array<any>;
}

export interface IFormPlan {
  remove_documents: Array<string>;
  remove_work_ids: Array<number>;
  name?: string;
  type?: number;
  building_id?: number;
  building_location?: string;
  building_document?: string;
  plan_start_time: string;
  plan_end_time: string;
  plan_start_date: string;
  plan_end_date: string;
  user_id?: number;
  note?: string;
  works?: Array<IContentListForm>;
  frequency?: number | null;
}

export const formValues = {
  name: '',
  // frequency: 0,
  // building_id: -1,
  // user_id: 5,
  // plan_start_time: '00:00',
  // plan_end_time: '00:00',
  plan_start_date: moment().format('YYYY-MM-DD'),
  plan_end_date: moment().format('YYYY-MM-DD'),
  // note: ' ',
  // type: 2,
  remove_documents: [],
  remove_works: [],
  works: [
    {
      name: planMessages.jobName,
      // content: ' ',
      // facility_id: 1,
      // building_item_id: 1,
      // note: ' ',
      report: {
        type_report: 1,
        option_report: 'ok',
      },
      documents: [],
    },
  ],
};

const CreatePlan: FC<ICreatePlan> = () => {
  const data = { data: { ...formValues } };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [indexBuilding, setIndexBuilding] = useState<number>(-1);
  const [building, setBuilding] = useState<Array<any>>();
  const [staff, setStaff] = useState<Array<any>>();
  const [buildingReset, setBuildingReset] = useState(false);
  const {
    getValues,
    control,
    handleSubmit,
    setValue,
    clearErrors,

    formState: { errors, isSubmitted, isSubmitting },
  } = useForm({
    defaultValues: data.data,
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const suggestions: any = useQuery(['plan_suggestions'], () => planApi.getSuggestions());
  const staffQuery: any = useQuery(['plan_staff'], () => planApi.getStaff());

  useEffect(() => {
    const dataBuilding = buildingDTO(suggestions.data?.data);
    setBuilding(dataBuilding?.filter((each: any) => each?.deleted_at === null));
  }, [suggestions.isFetching]);

  useEffect(() => {
    const data = staffDTO(staffQuery.data);
    setStaff(data);
  }, [staffQuery.isFetching]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const mutation = useMutation(planApi.createPlan, {
    onSuccess: (data: any) => {
      if (data.success) {
        notification.success({
          message: generalMessages.successMsg,
        });
        const params = new URLSearchParams(window.location.search);
        if (params.get('building')) {
          navigate(-1);
        } else {
          navigate(`/${appRoutes.PLAN.LIST}`);
        }
      } else
        notification.error({
          message: data.message,
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries('plan');
    },
    onError: (error: any) => {
      setIsLoading(false);
      if (error?.response?.status === 413) {
        notification.error({
          message: generalMessages.error,
          description: planMessages.contentTooLarge,
        });
      } else {
        notification.error({
          message: generalMessages.error,
          description: error.response.data.message,
        });
      }
    },
    retry: false,
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const newData = { ...data, status: 2 };
    mutation.mutate(handleData(newData));
  };
  const onSave = (data: any) => {
    setIsLoading(true);
    const newData = { ...data, status: 1 };
    mutation.mutate(handleData(newData));
  };

  return (
    <>
      <div className="plan__title">
        <TitleContent leftIcon={true} title={planMessages.titleCreatePlan} handleBack={handleBack} />
      </div>
      {!suggestions.isLoading && !staffQuery.isLoading && !isLoading ? (
        <div className="plan__main">
          <Form layout="vertical">
            <div className="plan-main-form-wrapper">
              <div className="plan-main-form">
                <div className="plan__review__main__content">
                  <PlanForm
                    props={{
                      isSubmitted,
                      isSubmitting,
                      getValues,
                      building: building,
                      staff: staff?.filter((each: any) => each.deleted_at === null && each.status === '1'),
                      control,
                      errors,
                      clearErrors,
                      setValue,
                      indexBuilding,
                      setIndexBuilding,
                      setBuildingReset,
                    }}
                  />
                </div>
                <div className="plan__review__main__check">
                  <CheckListForm
                    props={{
                      buildingItem: building?.[indexBuilding]?.item_of_buildings?.filter(
                        (each: any) => each.deleted_at === null
                      ),
                      facility: building?.[indexBuilding]?.building_facilities?.filter(
                        (each: any) => each.deleted_at === null
                      ),
                      data,
                      control,
                      setValue,
                      errors,
                      clearErrors,
                      getValues,
                      isSubmitting,
                      buildingReset,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="plan-action-btn">
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <ButtonWrap
                    onClick={handleSubmit(onSave)}
                    className="common-btn"
                    text={planMessages.saveDraftBtn}
                    backgroundColor="#222E35"
                    width={'100%'}
                    height="56px"
                    border={'1px solid #247E84'}></ButtonWrap>
                </Col>
                <Col span={12}>
                  <ButtonWrap
                    onClick={handleSubmit(onSubmit)}
                    htmlType="submit"
                    className="common-btn"
                    type="primary"
                    text={planMessages.submitBtn}
                    color="white"
                    width={'100%'}
                    height="56px"
                    border={'1px solid #247E84'}></ButtonWrap>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CreatePlan;
