import { MessageOutlined } from '@ant-design/icons';
import { Col, Divider, notification, Row } from 'antd';
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import planApi from '../../../../../api/planApi';
import { actions, useStore } from '../../../../../store';
import { japanesePlanMessages as planMessages } from '../../../../../utils/messages';
import { generalMessages } from '../../../../../utils/messages/general';
import { ButtonWrap, TextAreaField } from '../../../../commons';
import CarouselComponent from '../../../../commons/CarouselComponent/CarouselComponent';
import { NotifyCmtIcon } from '../../../PlanForm/NotifyCmtIcon/NotifyCmtIcon';
import ReportCheckboxType from '../../CreatePlan/ReportTypeContent/ReportCheckboxType/ReportCheckboxType';
import ReportRadioType from '../../CreatePlan/ReportTypeContent/ReportRadioType/ReportRadioType';
import WorkCommentForm from '../../WorkCommentForm/index';

interface IInspectionItemProps {
  item?: any;
  round?: number;
  id: any;
  hasNewComment?: boolean;
  setHasNewCommentFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InspectionItem: FC<IInspectionItemProps> = ({ item, round, id, hasNewComment, setHasNewCommentFlag }) => {
  const { id: idPlan } = useParams();
  const [commentVisible, setCommentVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [, dispatch] = useStore();

  let myArr = JSON.parse(item?.option_report ? item?.option_report : '');

  const mutationReject = useMutation(planApi.rejectInspection, {
    onSuccess: (data: any) => {
      if (data.success) {
        notification.success({
          message: generalMessages.successMsg,
        });
        dispatch(actions.setIsLoading(false));
        queryClient.invalidateQueries(['inspections', id]);
        queryClient.invalidateQueries(['plan_detail', idPlan]);
        queryClient.invalidateQueries('plan');
      } else
        notification.error({
          message: data.message,
        });
    },
    onSettled: () => {},
    onError: (error: any) => {
      dispatch(actions.setIsLoading(false));
      let obsParrent: any = {};
      notification.error({
        message: generalMessages.error,
        description: error.response.data.message,
      });
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
      }
    },
  });

  const mutationAprove = useMutation(planApi.approveInspection, {
    onSuccess: (data: any) => {
      if (data.success) {
        notification.success({
          message: generalMessages.successMsg,
        });
        dispatch(actions.setIsLoading(false));
        queryClient.invalidateQueries(['inspections', id]);
        queryClient.invalidateQueries(['plan_detail', idPlan]);
        queryClient.invalidateQueries('plan');
      } else
        notification.error({
          message: data.message,
        });
    },
    onSettled: () => {},
    onError: (error: any) => {
      dispatch(actions.setIsLoading(false));
      let obsParrent: any = {};
      notification.error({
        message: generalMessages.error,
        description: error.response.data.message,
      });
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
      }
    },
  });
  const handleReject = (id: any) => {
    dispatch(actions.setIsLoading(true));

    mutationReject.mutate(id);
  };

  const handleApprove = (id: any) => {
    dispatch(actions.setIsLoading(true));
    mutationAprove.mutate(id);
  };
  return (
    <>
      <div className="result-review-container">
        <div className="round__item">
          <p>{round && `${round} ${planMessages.inspectionRound}`}</p>
          <div className="round__item__content">
            <Row gutter={[24, 0]}>
              <Col span={14}>
                {item?.status === 2 && (
                  <TextAreaField
                    label={planMessages.labelInspectionResult}
                    rows={9}
                    props={{ defaultValue: '' }}
                    disable={true}
                  />
                )}
                {(item?.type_report === 1 || item?.type_report === 2) && (
                  <TextAreaField
                    name={`review-content-${item.id}`}
                    label={planMessages.labelInspectionResult}
                    rows={9}
                    props={{ defaultValue: item.result }}
                    disable={true}
                  />
                )}
                {item?.type_report === 3 && (
                  <ReportCheckboxType
                    review={true}
                    key={id}
                    id={item?.id}
                    data={myArr.map((each: any) => {
                      return {
                        ...each,
                        isChecked:
                          JSON.parse(item?.result) instanceof Array
                            ? JSON.parse(item?.result)?.some((i: any) => {
                                return i === each.Input;
                              })
                            : item?.result === each.Input,
                      };
                    })}
                    isSubmitted={true}
                    setIsSubmitted={() => {}}
                    props={{
                      clearErrors: () => {},
                      handleBack: () => {},
                      setCatchReportValue: () => {},
                    }}></ReportCheckboxType>
                )}
                {item?.type_report === 4 && (
                  <ReportRadioType
                    review={true}
                    key={id}
                    id={item?.id}
                    data={myArr.map((each: any) => {
                      return {
                        ...each,
                        isChecked: item?.result === each.Input,
                      };
                    })}
                    isSubmitted={true}
                    setIsSubmitted={() => {}}
                    props={{
                      clearErrors: () => {},
                      handleBack: () => {},
                      setCatchReportValue: () => {},
                    }}></ReportRadioType>
                )}

                {/* : */}
              </Col>
              <Col span={10}>
                <div className="attachments">
                  {item?.attachments && item?.attachments.length > 0 && (
                    <>
                      <p>{planMessages.inspectionActtachments}</p>
                      <CarouselComponent data={item.attachments} slidesQuantity={2} />
                    </>
                  )}
                </div>
                <div className="voices" style={{ marginTop: '16px' }}>
                  {item?.voices && item?.voices.length > 0 && (
                    <>
                      <p>{planMessages.inspectionVoice}</p>
                      <CarouselComponent data={item?.voices} slidesQuantity={2} />
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Divider style={{ background: '#4F4F4F' }} />
            <div className="review-group-btns">
              <Row gutter={[16, 16]}>
                {item?.status === 3 && (
                  <>
                    <Col span={8}>
                      <ButtonWrap
                        border="solid 0px"
                        text={planMessages.inspectionReject}
                        width={'100%'}
                        height="56px"
                        onClick={() => handleReject(item?.id)}
                        backgroundColor="#EB5757"
                        color="white"
                        className="common-btn"></ButtonWrap>
                    </Col>

                    <Col span={8}>
                      <ButtonWrap
                        type="primary"
                        className="common-btn"
                        text={planMessages.inspectionApprove}
                        width={'100%'}
                        height="56px"
                        color="white"
                        onClick={() => handleApprove(item?.id)}></ButtonWrap>
                    </Col>
                  </>
                )}

                {item?.status === 4 && (
                  <Col span={16}>
                    <ButtonWrap
                      text={planMessages.inspectionApprove}
                      width={'100%'}
                      height="56px"
                      backgroundColor="#3f8f91"
                      color="#4F4F4F"
                      className="common-btn"
                      disabled={true}></ButtonWrap>
                  </Col>
                )}
                {item?.status === 5 && (
                  <Col span={16}>
                    <ButtonWrap
                      border="solid 0px"
                      text={planMessages.inspectionReject}
                      width={'100%'}
                      height="56px"
                      backgroundColor="#EB5757"
                      color="#4F4F4F"
                      className="common-btn"
                      disabled={true}></ButtonWrap>
                  </Col>
                )}

                <Col span={8}>
                  <ButtonWrap
                    text={planMessages.inspectionComment}
                    width={'100%'}
                    height="56px"
                    color="#49ABB1"
                    backgroundColor="#1C282F"
                    border={'1px solid #247E84'}
                    className="common-btn"
                    iconLeft={<NotifyCmtIcon hasNewComment={hasNewComment} />}
                    onClick={() => {
                      setCommentVisible(true);
                    }}></ButtonWrap>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {commentVisible && (
          <WorkCommentForm
            id={id}
            commentVisible={commentVisible}
            setCommentVisible={setCommentVisible}
            setHasNewCommentFlag={setHasNewCommentFlag}
            idPlan={idPlan}
          />
        )}
      </div>
    </>
  );
};
