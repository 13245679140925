import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import profileApi from '../../api/profileApi';
import { ButtonWrap, TitleContent } from '../../components/commons';
import RequiredLabel from '../../components/commons/RequiredLabel';
import { addUserMessages, generalMessages, profileMessages, typeFileMsg } from '../../utils/messages';
import PhoneIcon from '../../components/commons/PhoneIcon';
import { regex } from '../../utils/regex';
import ChangePasswordForm from './ChangePasswordForm';
import UploadWrapper from '../../components/Upload';
import HandleError from '../../components/commons/HandleError';
import Loading from '../../components/commons/Loading';
import InputFieldCustom from '../../components/InputFieldCustom';
import { constant } from '../../utils/constants';
import './style.scss';

interface IProfileProps {}

const Profile: FC<IProfileProps> = (props) => {
  const queryClient = useQueryClient();
  const [err, setError]: any = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState('none');
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(true);
  const [resetState, setState] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { isLoading, data, isError }: any = useQuery('me', profileApi.getMyProfile);
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const mutation = useMutation(profileApi.editProfile, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries('me').then(() => {
          setLoading(false);
          setDisplay('none');
          setEdit('flex');
          setDisable(true);
          setState(true);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'plan',
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const onSubmit = (dataForm: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.set('username', dataForm.username);
    formData.set('full_name', dataForm.full_name);
    if (data?.data?.full_name_katakana !== dataForm.full_name_katakana)
      formData.set('full_name_katakana', dataForm.full_name_katakana);
    if (data?.data?.number_phone !== dataForm.number_phone) formData.set('number_phone', dataForm.number_phone);
    if (dataForm.avatar?.length === 1 && !!dataForm.avatar[0]?.name)
      formData.append('avatar', dataForm.avatar[0].originFileObj);
    else if (dataForm?.avatar[0]?.base64 !== data?.data?.base64) formData.append('avatar', 'null');
    formData.set('address_1', dataForm.address_1);
    if (data?.data?.address_2 !== dataForm.address_2) formData.set('address_2', dataForm.address_2);
    if (data?.data?.postal_code !== dataForm.postal_code) formData.set('postal_code', dataForm.postal_code);
    mutation.mutate(formData);
  };
  const handleCancel = () => {
    reset(data?.data);
    setDisplay('none');
    setEdit('flex');
    setDisable(true);
    setState(true);
  };
  const handleEdit = () => {
    setDisplay('flex');
    setEdit('none');
    setDisable(false);
    if (errors) setState(false);
  };

  const handleChangePassword = () => {
    setVisible(true);
  };
  const handleCancelModal = () => {
    setVisible(false);
  };

  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="profile">
          <div className="profile__content">
            <div className="profile__content__title">
              <TitleContent leftIcon={false} title={profileMessages.title} />
            </div>
            <section className="create-user">
              <div className="create-user__container">
                <div className="create-user__form">
                  <Form className="create-user__form__wrapper">
                    <Row className="create-user__form__input" gutter={[24, 0]}>
                      <Col xs={24} lg={12} className="create-user__form__input__left">
                        <Row className="create-user__form__item">
                          <Controller
                            name="username"
                            rules={{
                              required: addUserMessages.requiredUsername,
                              validate: (value: string) => {
                                if (value?.trim() == '') {
                                  return addUserMessages.requiredUsername;
                                }
                                if (regex.validateUsername.test(value?.trim()) == false) {
                                  return addUserMessages.validateUsername;
                                }
                              },
                            }}
                            defaultValue={data?.data.username}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                props={field}
                                label={
                                  <>
                                    {addUserMessages.username}
                                    <RequiredLabel />
                                  </>
                                }
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                disable={disable}
                                maxLength={constant.maxLenghtUsername}
                                msgServer={!resetState && err['username']}
                                msgValidate={!resetState && errors?.username?.message}
                                onBlur={(e: any) => setValue('username', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <Controller
                            name="full_name"
                            rules={{
                              required: addUserMessages.requiredFullname,
                              validate: (value: string) => {
                                if (value?.trim() == '') {
                                  return addUserMessages.requiredFullname;
                                }
                                if (regex.validateName.test(value?.trim()) == false) {
                                  return addUserMessages.validateFullname;
                                }
                              },
                            }}
                            defaultValue={data?.data.full_name}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                props={field}
                                maxLength={constant.maxLenghtFullname}
                                label={
                                  <>
                                    {addUserMessages.fullname}
                                    <RequiredLabel />
                                  </>
                                }
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                msgServer={!resetState && err['full_name']}
                                msgValidate={!resetState && errors?.full_name?.message}
                                disable={disable}
                                onBlur={(e: any) => setValue('full_name', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <Controller
                            name="full_name_katakana"
                            rules={{
                              validate: (value: string) => {
                                if (!!value && regex.validateName.test(value?.trim()) == false) {
                                  return addUserMessages.validateFullnameKatakana;
                                }
                              },
                            }}
                            defaultValue={data?.data.full_name_katakana}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                maxLength={constant.maxLenghtFullnameKatakana}
                                props={field}
                                label={addUserMessages.fullnameKatakana}
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                msgServer={!resetState && err['full_name_katakana']}
                                msgValidate={!resetState && errors?.full_name_katakana?.message}
                                disable={disable}
                                onBlur={(e: any) => setValue('full_name_katakana', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <InputFieldCustom
                            defaultValue={data?.data.email}
                            maxLength={constant.maxLenghtEmail}
                            label={addUserMessages.email}
                            disable={true}
                          />
                        </Row>
                      </Col>
                      <Col xs={24} lg={12} className="create-user__form__input__right">
                        <Row className="create-user__form__item">
                          <Controller
                            name="address_1"
                            defaultValue={data?.data.address_1}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                props={field}
                                label={addUserMessages.address1}
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                disable={disable}
                                maxLength={constant.maxLenghtAddress}
                                msgServer={!resetState && err['address_1']}
                                msgValidate={!resetState && errors?.address_1?.message}
                                onBlur={(e: any) => setValue('address_1', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <Controller
                            name="address_2"
                            defaultValue={data?.data.address_2}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                props={field}
                                maxLength={constant.maxLenghtAddress}
                                label={addUserMessages.address2}
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                msgServer={!resetState && err['address_2']}
                                disable={disable}
                                onBlur={(e: any) => setValue('address_2', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <Controller
                            name="postal_code"
                            rules={{
                              validate: (value: string) => {
                                if (!!value && regex.validatePostalCode.test(value?.trim()) == false) {
                                  return addUserMessages.validatePostalCode;
                                }
                              },
                            }}
                            defaultValue={data?.data.postal_code}
                            control={control}
                            render={({ field }) => (
                              <InputFieldCustom
                                props={field}
                                maxLength={constant.maxLenghtPostalCode}
                                placeholder={!disable ? addUserMessages.placeholder : ''}
                                label={addUserMessages.postalCode}
                                disable={disable}
                                msgServer={!resetState && err['postal_code']}
                                msgValidate={!resetState && errors?.postal_code?.message}
                                onBlur={(e: any) => setValue('postal_code', e.target.value.trim())}
                              />
                            )}
                          />
                        </Row>
                        <Row className="create-user__form__item">
                          <Controller
                            name="number_phone"
                            rules={{
                              validate: (value: string) => {
                                if (!!value && regex.validatePhone.test(value?.trim()) == false) {
                                  return addUserMessages.validatePhoneNumber;
                                }
                              },
                            }}
                            defaultValue={data?.data.number_phone}
                            control={control}
                            render={({ field }) => (
                              <Form.Item label={addUserMessages.phoneNumber} className="holder__phone" colon={false}>
                                <Input.Group compact>
                                  <PhoneIcon />
                                  <div
                                    className="input-field__phone"
                                    style={resetState ? { backgroundColor: '#384044' } : {}}>
                                    <InputFieldCustom
                                      msgServer={!resetState && err['number_phone']}
                                      msgValidate={!resetState && errors?.number_phone?.message}
                                      disable={disable}
                                      placeholder={!disable ? addUserMessages.placeholder : ''}
                                      props={field}
                                      maxLength={constant.maxLenghtPhone}
                                      onBlur={(e: any) => setValue('number_phone', e.target.value.trim())}
                                    />
                                  </div>
                                </Input.Group>
                              </Form.Item>
                            )}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Form.Item
                      className="create-user__form__input avatar-item"
                      label={profileMessages.avatar}
                      colon={false}>
                      <>
                        {disable && !data?.data?.avatar && <>{profileMessages.noAvatar}</>}
                        {disable && data?.data?.base64 && (
                          <>
                            <div className="img__wrapper">
                              <img src={`data:image/png;base64,${data?.data?.base64}`} />
                            </div>
                          </>
                        )}

                        {!disable && (
                          <UploadWrapper
                            label=""
                            setValues={setValue}
                            multiple={false}
                            data={
                              data?.data?.base64
                                ? [{ uuid: '', base64: data?.data.base64, filename: '', url: '', type: '' }]
                                : []
                            }
                            typeFile="image"
                          />
                        )}
                      </>
                    </Form.Item>
                    <div className="create-user__form__button">
                      <div className="button-edit" style={{ display: edit }}>
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #247E84"
                          iconLeft={<EditOutlined />}
                          text={addUserMessages.editBtn}
                          width="100%"
                          height="56px"
                          onClick={handleEdit}
                        />
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #247E84"
                          iconLeft={<EditOutlined />}
                          text={addUserMessages.changePassword}
                          width="100%"
                          height="56px"
                          display="flex"
                          onClick={handleChangePassword}
                        />
                      </div>
                      <div className="button-wrapper" style={{ display: display }}>
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #247E84"
                          text={addUserMessages.cancel}
                          width="100%"
                          height="56px"
                          onClick={handleCancel}
                        />
                        <ButtonWrap
                          type="primary"
                          htmlType="submit"
                          text={addUserMessages.done}
                          width="100%"
                          height="56px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
      <Modal
        title={addUserMessages.popupTitle}
        visible={visible}
        footer={null}
        onCancel={handleCancelModal}
        width={'650px'}>
        <ChangePasswordForm isVisible={handleCancelModal} />
      </Modal>
    </>
  );
};

export default Profile;
