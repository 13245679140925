import React, { FC } from 'react';
import './style.scss';

interface IProps {}

const FooterLayout: FC<IProps> = (props) => {
  return <div className="footer__text">Copyright © 2022 - 設備@CITY - All Rights Reserved.</div>;
};

export default FooterLayout;
