export const profileMessages = {
  title: 'プロフィール',
  avatar: 'プロフィール写真',
  noAvatar: 'プロフィール写真がありません。',
  errorType: 'ファイルの形式が正しくありません。',
  errorFile: 'ファイルのサイズが制限を超えています。',
  descriptionErrFile: '5MB以下のファイルを追加してください。',
  descriptionError: 'ファイルの最大サイズは、20MBです。',
  totalLimitSize: 'ファイルすべての合計サイズの上限は50MBです。',
  typeRecommend: '※登録可能な添付ファイルの形式は画像（拡張子：png、jpeg、jpg）',
  type: '※登録可能なファイル形式は拡張子：png、jpeg、jpg、pdf、txtです。',
};
