import { IContentListForm, IFormPlan } from '../../../pages/PlanManagement/CreatePlan';
import { getMoment } from '../../helper';
import { FREQUENCY } from '../../plan';

const moment = getMoment();

const buildingDTO = (data: any) => {
  if (!data) return [];
  const result = data?.map((each: any) => {
    let newDocuments: any = [];
    if (each.document_type) {
      newDocuments = each?.document_type.map((each: any) => {
        return {
          type: each.type,
          name: each.filename,
          url_preview: each.url_preview,
          url_download: each.url_download,
          uid: each.uuid,
        };
      });
    }
    return {
      id: each.id,
      name: `${each.name} - ${each.building_code}`,
      address: `${each?.address_1}${each?.address_2 !== null ? ', ' + each?.address_2 : ''}`,
      documents: newDocuments,
      deleted_at: each.deleted_at,
      building_code: each.building_code,
      building_facilities: facilityDTO(each.building_facilities),
      item_of_buildings: buildingItemDTO(each.item_of_buildings),
    };
  });
  return result;
};

const staffDTO = (data: any) => {
  if (!data) return [];
  const result = data?.map((each: any) => {
    return {
      id: each.user_id,
      name: each.username,
      deleted_at: each.deleted_at,
      status: each.status,
      email: each.email,
    };
  });
  return result;
};

const facilityDTO = (data: any) => {
  if (!data) return [];
  const result = data?.map((each: any) => {
    let newDocuments: any = [];
    if (each.document_type_facility) {
      newDocuments = each?.document_type_facility.map((each: any) => {
        return {
          type: each.type,
          name: each.filename,
          url_preview: each.url_preview,
          url_download: each.url_download,
          uid: each.uuid,
        };
      });
    }
    return {
      id: each.building_facility_id,
      name: `${each.special_name} (${each.facility_name})`,
      deleted_at: each.building_facility_delete || each.facility_delete,
      documents: newDocuments,
    };
  });
  return result;
};

const buildingItemDTO = (data: any) => {
  if (!data) return [];
  const result = data?.map((each: any) => {
    let newDocuments: any = [];
    if (each.document_type_building_item) {
      newDocuments = each?.document_type_building_item.map((each: any) => {
        return {
          type: each.type,
          name: each.filename,
          url_preview: each.url_preview,
          url_download: each.url_download,
          uid: each.uuid,
        };
      });
    }
    return {
      id: each.item_of_building_id,
      name: `${each.special_name} (${each.building_item_name})`,
      deleted_at: each.item_of_building_delete || each.building_item_delete,
      documents: newDocuments,
    };
  });
  return result;
};

const planDTO = (data: any) => {
  const newWorks = data?.data.works?.map((item: any) => {
    const newDocuments = item.documents?.map((each: any, indexs: number) => {
      return {
        type: each.type,
        name: each.filename,
        url_preview: each.url_preview,
        url_download: each.url_download,
        uid: each.uuid,
      };
    });

    return {
      ...item,
      report: {
        ...item.report,
        option_report: JSON.parse(item.report.option_report),
      },
      documents: newDocuments,
      inspections: [],
    };
  });
  const newData: IFormPlan = {
    ...data?.data,
    plan_start_date: moment(data?.data?.plan_start_date).format('YYYY-MM-DD'),
    plan_end_date: moment(data?.data?.plan_end_date).format('YYYY-MM-DD'),
    remove_documents: [],
    remove_work_ids: [],
    frequency: data?.data.frequency !== null ? data?.data.frequency : 1,
    works: newWorks,
  };
  return newData;
};

const handleData = (data: any) => {
  const newWorks = data.works.map((item: IContentListForm) => {
    return {
      ...item,
      report: { ...item.report, option_report: JSON.stringify(item.report?.option_report) },
      documents: item.documents,
    };
  });
  const newData = {
    ...data,
    frequency: data.type === FREQUENCY.MONTH ? data.frequency : FREQUENCY.MONTH,
    plan_start_time: data.plan_start_time ? moment(data.plan_start_time, 'HH:mm').format('HH:mm') : '',
    plan_end_time: data.plan_end_time ? moment(data.plan_end_time, 'HH:mm').format('HH:mm') : '',
    plan_start_date: moment(data.plan_start_date).format('YYYY-MM-DD'),
    plan_end_date: moment(data.plan_end_date).format('YYYY-MM-DD'),
    works: newWorks,
  };
  const formData = new FormData();
  for (let key in newData) {
    if (key === 'works') {
      for (var i = 0; i < newData[key].length; i++) {
        for (let prop in newData[key][i]) {
          if (prop === 'report') {
            formData.append(`works[${i}][report][type_report]`, newData[key][i]['report']['type_report']);
            formData.append(`works[${i}][report][option_report]`, newData[key][i]['report']['option_report']);
          } else if (prop === 'documents') {
            for (var m = 0; m < newData[key][i]['documents'].length; m++) {
              formData.append(`works[${i}][documents][${m}]`, newData[key][i]['documents'][m].originFileObj);
            }
          } else if (prop === 'note') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'content') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'facility_id') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'building_item_id') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else {
            formData.append(`works[${i}][${prop}]`, newData[key][i][prop]);
          }
        }
      }
    } else if (key === 'note') {
      newData['note'] ? formData.append(key, newData['note']) : formData.append(key, '');
    } else {
      formData.append(key, newData[key]);
    }
  }
  return formData;
};

const handleDataEdit = (data: any) => {
  const newWorks = data.works.map((item: IContentListForm) => {
    return {
      ...item,
      report: { ...item.report, option_report: JSON.stringify(item.report?.option_report) },
      documents: item?.documents?.filter((each: any) => each?.uid?.includes('rc-')),
    };
  });
  const newData = {
    ...data,
    frequency: data.type === FREQUENCY.MONTH ? data.frequency : FREQUENCY.MONTH,
    plan_start_time: data.plan_start_time ? moment(data.plan_start_time, 'HH:mm').format('HH:mm') : '',
    plan_end_time: data.plan_end_time ? moment(data.plan_end_time, 'HH:mm').format('HH:mm') : '',
    plan_start_date: moment(data.plan_start_date).format('YYYY-MM-DD'),
    plan_end_date: moment(data.plan_end_date).format('YYYY-MM-DD'),
    works: newWorks,
  };
  const formData = new FormData();
  for (let key in newData) {
    if (key === 'works') {
      for (var i = 0; i < newData[key].length; i++) {
        for (let prop in newData[key][i]) {
          if (prop === 'report') {
            formData.append(`works[${i}][report][type_report]`, newData[key][i]['report']['type_report']);
            formData.append(`works[${i}][report][option_report]`, newData[key][i]['report']['option_report']);
          } else if (prop === 'documents') {
            if (newData[key][i]['documents'] !== undefined) {
              for (var m = 0; m < newData[key][i]['documents'].length; m++) {
                formData.append(`works[${i}][documents][${m}]`, newData[key][i]['documents'][m].originFileObj);
              }
            }
          } else if (prop === 'note') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'content') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'facility_id') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else if (prop === 'building_item_id') {
            newData[key][i][prop]
              ? formData.append(`works[${i}][${prop}]`, newData[key][i][prop])
              : formData.append(`works[${i}][${prop}]`, '');
          } else {
            formData.append(`works[${i}][${prop}]`, newData[key][i][prop]);
          }
        }
      }
    } else if (key === 'remove_work_ids') {
      if (newData[key].length !== 0) {
        newData[key].map((each: any, index: number) => formData.append(`remove_work_ids[${index}]`, each));
      } else {
        formData.append('remove_work_ids[]', newData[key]);
      }
    } else if (key === 'remove_documents') {
      if (newData[key].length !== 0) {
        newData[key].map((each: any, index: number) => formData.append(`remove_documents[${index}]`, each));
      } else {
        formData.append('remove_documents[]', newData[key]);
      }
    } else {
      formData.append(key, newData[key]);
    }
  }
  return formData;
};

const convertFile = (data: any) => {
  return {
    type: data.type === 'attachment' ? 'image/png' : 'audio/mp4',
    name: data.filename,
    url_preview: data.url_preview,
    url_download: data.url_download,
    uid: data.uuid,
  };
};
const inspectionsDto = (data: any) => {
  const newInspections = data?.map((each: any) => {
    const newAttachment = each?.files
      ?.filter((item: any) => item.type === 'attachment')
      .map((each: any) => convertFile(each));
    const voice = each?.files
      ?.filter((each: any) => {
        return each.type === 'voice';
      })
      .map((each: any) => convertFile(each));
    return {
      ...each,
      attachments: newAttachment,
      voices: voice,
    };
  });
  return newInspections;
};
export { planDTO, handleData, buildingDTO, staffDTO, buildingItemDTO, facilityDTO, handleDataEdit, inspectionsDto };
