import { configs } from '../configs';
import { ListResponse } from '../models';
import { Facility } from '../models/facility';
import { generalMessages } from '../utils/messages/general';
import axiosClient from './axiosClient';

const facility = 'facility';

const facilityApi = {
  getAll(): Promise<ListResponse<Facility>> {
    const url = facility;
    return axiosClient.get(url);
  },
  get(id: string | undefined): Promise<ListResponse<Facility>> {
    const url = `${facility}/${id}`;
    return axiosClient.get(url);
  },
  getPage(
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    type: string | undefined
  ): Promise<ListResponse<Facility>> {
    const url = `${facility}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}`;
    return axiosClient.get(url);
  },
  add(data: any): Promise<ListResponse<Facility>> {
    const url = facility;
    return axiosClient.post(url, data, configs.header);
  },
  edit({ id, data }: { id: string; data: any }): Promise<ListResponse<Facility>> {
    const url = `${facility}/${id}`;
    return axiosClient.post(url, data, configs.header);
  },
  delete(id: string): Promise<ListResponse<Facility>> {
    const url = `${facility}/${id}`;
    return axiosClient.delete(url);
  },
};

export default facilityApi;
