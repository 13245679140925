export const typeFileMsg = {
  image: 'image',
  imageJpeg: 'image/jpeg',
  imagePng: 'image/png',
  jpeg: 'jpeg',
  png: 'png',
  jpg: 'jpg',
  voice: 'audio',
  mp3: 'mp3',
  wma: 'wma',
  other: 'others',
  string: 'string',
  active: '1',
  inActive: '0',
  error: 'error',
};
