import React, { FC } from 'react';
import { Col, notification, Row, Spin } from 'antd';
import CalendarContentRight from './CalendarContentRight';
import MainCalendar from './CalendarContentLeft/MainCalendar';
import { useState } from 'react';
import { useQuery } from 'react-query';
import planApi from '../../../api/planApi';
import { japanesePlanMessages as planMessages, userMgmtMessages } from '../../../utils/messages';

interface CalendarContentProps {}

const CalendarContent: FC<CalendarContentProps> = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const { isLoading, isError, data, error }: any = useQuery(
    ['plans_per_month', selectedDate.getMonth() + 1, selectedDate.getFullYear()],
    () => planApi.getPlansPerMonthCalendar(selectedDate.getMonth() + 1, selectedDate.getFullYear()),
    { retry: false }
  );

  if (isError || data?.success === false) {
    notification.error({
      message: error.code || userMgmtMessages.error,
      description: error?.message || data?.message,
    });
  }

  return (
    <>
      {isLoading ? (
        <Spin tip={planMessages.loading}></Spin>
      ) : (
        <div className="calendar-list__content">
          <Row gutter={[32, 32]}>
            <Col xs={18}>
              <MainCalendar
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                eventsList={data?.data.data}
              />
            </Col>
            <Col xs={6} style={{ overflow: 'hidden' }}>
              <CalendarContentRight selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CalendarContent;
