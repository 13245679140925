import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Radio, Row, Select, Space, Col } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { japanesePlanMessages as planMessages } from '../../../../../../utils/messages';
import { ButtonWrap, ErrorMessage, InputField } from '../../../../../commons';
import BtnActionsGroup from '../BtnActionsGroup/BtnActionsGroup';
import './style.scss';

const { Option } = Select;

interface IReportTypeComponent {
  setValue?: any;
  selectedJob?: number;
  data: any;
  isSubmitted: boolean;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  name?: string;
  props?: any;
  review?: boolean;
}

const ReportRadioType: React.FC<IReportTypeComponent> = ({
  setValue,
  selectedJob,
  data,
  isSubmitted,
  setIsSubmitted,
  id,
  name,
  props,
  review = false,
}) => {
  const { clearErrors, handleBack, setCatchReportValue, catchReportValue } = props;
  const form = Form.useFormInstance();
  const refForm: any = useRef();
  const [isValid, setIsValid] = useState<boolean>(true);
  useEffect(() => {
    if (data instanceof Array) {
      form.setFieldsValue({
        [`report-radio-form-${id}`]: data,
      });
    } else {
      form.setFieldsValue({
        [`report-radio-form-${id}`]: [],
      });
    }
  }, []);

  useEffect(() => {
    if (!isSubmitted) {
      setValue(`works.${selectedJob}.report.option_report`, []);
    }
  }, [isSubmitted]);

  const handleCancell = () => {
    setValue(`works.${selectedJob}.report.option_report`, form.getFieldValue(`report-radio-form-${id}`));
    handleBack();
    form.setFieldsValue({
      [`report-radio-form-${id}`]: catchReportValue.current.reportOption,
    });
    clearErrors(name);
  };

  const handleDone = () => {
    if (form.getFieldValue(`report-radio-form-${id}`).length >= 2) {
      refForm.current.submit();
    } else {
      setIsValid(false);
      setIsSubmitted(false);
    }
  };

  const onFinish = (values: any) => {
    setIsSubmitted(true);
    setIsValid(true);
    clearErrors(name);
    setCatchReportValue(form.getFieldValue(`report-radio-form-${id}`));
    setValue(`works.${selectedJob}.report.option_report`, form.getFieldValue(`report-radio-form-${id}`));
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsSubmitted(false);
  };

  return (
    <>
      <Form
        onSubmitCapture={(e) => e.preventDefault()}
        ref={refForm}
        name={`radio-form-${id}`}
        key={id}
        labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="on">
        <Form.List name={`report-radio-form-${id}`} initialValue={data instanceof Array ? data : []}>
          {(fields, { add, remove }) => {
            return (
              <>
                <Radio.Group
                  name="radiogroup"
                  style={{ width: '100%' }}
                  value={form.getFieldValue(`report-radio-form-${id}`)?.findIndex((each: any) => {
                    return each?.isChecked === true;
                  })}>
                  {fields.map((field) => {
                    return (
                      <Row style={{ height: !review ? '70px' : '50px' }}>
                        <Col span={24}>
                          <div key={field.key} className="bonus-radio-form">
                            <Space direction="vertical" style={{ marginTop: '8px' }}>
                              <Form.Item>
                                <Radio value={field.key} disabled={true}></Radio>
                              </Form.Item>
                            </Space>

                            <InputField
                              {...field}
                              rules={[
                                {
                                  required: true,
                                  message: planMessages.radioInputReportRequired,
                                },
                              ]}
                              name={[field.name, 'Input']}
                              placeholder={planMessages.placeholderRadioInputReport}
                              initialValue=""
                              disable={isSubmitted}
                              props={{ onPressEnter: () => add({ Input: '' }) }}
                            />

                            <div
                              className={`close-icon-container ${isSubmitted && 'd-none'}`}
                              style={{ marginTop: '5px' }}>
                              <Button type="text" disabled={isSubmitted} onClick={() => remove(field.name)}>
                                <CloseOutlined />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Radio.Group>
                {!isSubmitted && (
                  <Form.Item>
                    <Form.Item noStyle>
                      <Row>
                        <Col span={24}>
                          <ButtonWrap
                            className="addValueBtn"
                            text={planMessages.addValue}
                            width={'100%'}
                            height="56px"
                            color={isSubmitted ? '#247e84' : '#49ABB1'}
                            backgroundColor="transparent"
                            disabled={isSubmitted}
                            iconLeft={
                              <PlusCircleOutlined
                                style={{
                                  color: 'inherit',
                                }}
                              />
                            }
                            onClick={() => {
                              add({
                                Input: '',
                              });
                            }}></ButtonWrap>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.List>
        {!isValid && <ErrorMessage message={planMessages.radioEmptyMessage} />}
        {!isSubmitted && <BtnActionsGroup onCancel={handleCancell} onDone={handleDone} />}
      </Form>
    </>
  );
};

export default ReportRadioType;
