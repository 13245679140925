import { MessageOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

import './styles.scss';

interface NotifyCmtIconProps {
  hasNewComment: any;
  idPlan?: string | undefined;
}

export const NotifyCmtIcon: FC<NotifyCmtIconProps> = ({ hasNewComment, idPlan }) => {
  return (
    <div className="notify-cmt-icon">
      <MessageOutlined />
      {hasNewComment && <span className="red-dot"></span>}
    </div>
  );
};
