import React, { FC, useState } from 'react';
import { Drawer, Empty, Form, Input, List, notification, Spin } from 'antd';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import { Divider } from 'antd';
import { ButtonWrap } from '../../commons';
import { getMoment } from '../../../utils/helper';

const moment = getMoment();

interface CommentItemProps {
  item: any;
  purpose: string;
  commentContent: string;
}

const CommentItem: FC<CommentItemProps> = ({ purpose, item, commentContent }) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const renderCommentContent = (commentContent: string) => {
    if (commentContent.length > 400) {
      return (
        <>
          {isShowMore ? (
            <>
              <p className="comment-text">{commentContent}</p>
              <div className="comment-btn-container">
                <ButtonWrap
                  className="cmt-more-btn"
                  type="primary"
                  text={planMessages.commentLess}
                  width={'100px'}
                  height="32px"
                  color="white"
                  onClick={() => {
                    setIsShowMore((prevState) => !prevState);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="comment-text">{commentContent.substring(0, 400) + '...'}</p>
              <div className="comment-btn-container">
                <ButtonWrap
                  className="cmt-more-btn"
                  type="primary"
                  text={planMessages.commentMore}
                  width={'100px'}
                  height="32px"
                  color="white"
                  onClick={() => {
                    setIsShowMore((prevState) => !prevState);
                  }}
                />
              </div>
            </>
          )}
        </>
      );
    }
    return <p className="comment-text">{commentContent}</p>;
  };

  return (
    <List.Item style={{ borderBottom: 'none' }}>
      <List.Item.Meta
        title={
          <div>
            <span>{item?.created_by?.username}</span>
            <span style={{ float: 'right', display: 'flex', gap: 20 }}>
              <div>{`${moment(item?.created_at).format('YYYY/MM/DD')}`}</div>
              <div>{`${moment(item?.created_at).format('HH:mm:ss')}`}</div>
            </span>
          </div>
        }
        description={
          <>
            <div className="comment-content-container">{renderCommentContent(commentContent)}</div>
            <Divider style={{ background: 'white' }} />
          </>
        }
      />
    </List.Item>
  );
};

export default CommentItem;
