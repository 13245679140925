import { SET_IS_AUTHENTICATED, SET_IS_LOADING } from './constants';

export const setIsAuthenticated = (payload: any) => ({
  type: SET_IS_AUTHENTICATED,
  payload,
});

export const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});
