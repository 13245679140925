export const LoadingStatusIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1.3335V2.66683" stroke="#49ABB1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.3334 2.22656L10.6667 3.38125"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7736 4.66699L12.6189 5.33366"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.3333 8H13" stroke="#49ABB1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.7736 11.3337L12.6189 10.667"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 13.7738L10.6667 12.6191"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.6668V13.3335"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 13.7738L5.33341 12.6191"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.22656 11.3337L3.38125 10.667"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33337 8H2.66671"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.22656 4.66699L3.38125 5.33366"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 2.22656L5.33341 3.38125"
        stroke="#49ABB1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
