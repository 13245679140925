import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Tooltip } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { japanesePlanMessages as planMessages } from '../../../../../utils/messages';
import { SelectField, TextAreaField } from '../../../../commons';
import CarouselComponent from '../../../../commons/CarouselComponent/CarouselComponent';
import ErrorMessage from '../../../../commons/ErrorMessage';
import RequiredLabel from '../../../../commons/RequiredLabel';
import ReportCheckboxType from '../../CreatePlan/ReportTypeContent/ReportCheckboxType/ReportCheckboxType';
import ReportInputNumberType from '../../CreatePlan/ReportTypeContent/ReportInputNumberType/ReportInputNumberType';
import ReportInputTextType from '../../CreatePlan/ReportTypeContent/ReportInputTextType/ReportInputTextType';
import ReportRadioType from '../../CreatePlan/ReportTypeContent/ReportRadioType/ReportRadioType';
import UploadComponent from '../../CreatePlan/UploadComponent/UploadComponent';

interface IContentCheckList {
  props: any;
  index: number;
  className?: string;
  review?: boolean;
  id: number;
  data: any;
}

const fetchData = {
  reportTypeOptions: [
    { key: 1, value: '文字入力' },
    { key: 2, value: '数値入力' },
    { key: 3, value: 'チェックボックス選択' },
    { key: 4, value: 'ラジオボタン選択' },
  ],
};
const ContentCheckList: FC<IContentCheckList> = ({ data, props, index, className, review = false, id }) => {
  const { buildingItem, facility, control, setValue, errors, clearErrors, getValues, buildingReset } = props;
  const [reportTypeValue, setReportTypeValue] = useState<number>(
    Number(getValues(`works.${index}.report.type_report`))
  );
  const [facilityIndex, setFalicityIndex] = useState<number>(-1);
  const [buildingItemIndex, setBuildingItemIndex] = useState<number>(-1);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(true);

  const catchReportValue = useRef({
    reportType: Number(getValues(`works.${index}.report.type_report`)),
    reportOption: getValues(`works.${index}.report.option_report`),
  });

  const setCatchReportValue = useCallback(
    (reportOption: any) => {
      catchReportValue.current = {
        reportType: Number(getValues(`works.${index}.report.type_report`)),
        reportOption: reportOption,
      };
    },
    [catchReportValue]
  );

  const handleBack = () => {
    setReportTypeValue(Number(catchReportValue.current.reportType));
    setValue(`works.${index}.report.type_report`, catchReportValue.current.reportType);
    setValue(`works.${index}.report.option_report`, catchReportValue.current.reportOption);
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (facility !== undefined && buildingReset) {
      setFalicityIndex(-1);
      setValue(`works.${index}.facility_id`, null);
    }
  }, [facility]);

  useEffect(() => {
    if (buildingItem !== undefined && buildingReset) {
      setBuildingItemIndex(-1);
      setValue(`works.${index}.building_item_id`, null);
    }
  }, [buildingItem]);

  useEffect(() => {
    if (facility !== undefined) {
      setFalicityIndex(
        facility?.findIndex((each: any) => Number(control._formValues.works?.[index]?.facility_id) === each.id)
      );
    }
    if (buildingItem !== undefined) {
      setBuildingItemIndex(
        buildingItem?.findIndex((each: any) => Number(control._formValues.works?.[index]?.building_item_id) === each.id)
      );
    }
  }, []);

  const handleChangeSelect = (value: string) => {
    setReportTypeValue(Number(value));
    setValue(`works.${index}.report.type_report`, value);
  };

  const handleSelectedFacility = (value: string) => {
    const temp = facility?.findIndex((each: any) => Number(value) === each.id);
    setFalicityIndex(temp);
    setValue(`works.${index}.facility_id`, value);
  };

  const handleSelectedBuildingItem = (value: string) => {
    const temp = buildingItem?.findIndex((each: any) => Number(value) === each.id);
    setBuildingItemIndex(temp);
    setValue(`works.${index}.building_item_id`, value);
  };
  const facilitySelected = useMemo(() => facility?.[facilityIndex]?.documents, [facilityIndex]);
  const buildingItemSelected = useMemo(() => buildingItem?.[buildingItemIndex]?.documents, [buildingItemIndex]);

  return (
    <Col span={24} className={className}>
      <div className="checklist-detail">
        <Row gutter={[20, 28]}>
          <div className="group-column-to-row facility_selection">
            <Col span={24}>
              <Controller
                key={id}
                name={`works.${index}.facility_id`}
                control={control}
                render={({ field }) => (
                  <>
                    <SelectField
                      disable={review}
                      help={<ErrorMessage message={errors?.works?.[index]?.facility_id?.message} />}
                      options={
                        facility
                          ? facility.map((each: any) => {
                              return {
                                key: each.id,
                                value: (
                                  <Tooltip placement="topLeft" title={each.name}>
                                    <div className="deleted-selection-wrap">
                                      <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                      {each.deleted_at && (
                                        <span className="option-wrap__active">{planMessages.deleted}</span>
                                      )}
                                    </div>
                                  </Tooltip>
                                ),
                              };
                            })
                          : []
                      }
                      placeholder={planMessages.placeholderFacility}
                      label={<>{planMessages.labelFacility}</>}
                      defaultValue={field.value}
                      props={{
                        ...field,

                        onChange: handleSelectedFacility,
                        value: facilityIndex === -1 ? null : field.value,

                        onClear: () => {
                          setValue(`works.${index}.facility_id`, null);
                          setFalicityIndex(-1);
                        },
                      }}
                      isSearch={true}
                      allowClear={true}
                      isElementValue></SelectField>
                  </>
                )}
              />
            </Col>
            <Col span={24}>
              <Form.Item label={planMessages.labelBuildingDocument}>
                {facility ? (
                  <CarouselComponent data={facilitySelected} slidesQuantity={4} />
                ) : (
                  <div className="plan__validation__mess" style={{ textAlign: 'center', color: '#828282' }}>
                    建物名を選択してください
                  </div>
                )}
              </Form.Item>
            </Col>
          </div>

          <div className="group-column-to-row building_item_selection">
            <Col span={24}>
              <Controller
                key={id}
                name={`works.${index}.building_item_id`}
                control={control}
                render={({ field }) => (
                  <>
                    <SelectField
                      disable={review}
                      help={<ErrorMessage message={errors?.works?.[index]?.building_item_id?.message} />}
                      label={<>{planMessages.labelJobBuildingItem}</>}
                      options={
                        buildingItem
                          ? buildingItem.map((each: any) => {
                              return {
                                key: each.id,
                                value: (
                                  <Tooltip placement="topLeft" title={each.name}>
                                    <div className="deleted-selection-wrap">
                                      <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                      {each.deleted_at && (
                                        <span className="option-wrap__active">{planMessages.deleted}</span>
                                      )}
                                    </div>
                                  </Tooltip>
                                ),
                              };
                            })
                          : []
                      }
                      placeholder={planMessages.placeholderJobBuildingItem}
                      defaultValue={field.value}
                      props={{
                        ...field,
                        onChange: handleSelectedBuildingItem,
                        value: buildingItemIndex === -1 ? null : field.value,
                        onClear: () => {
                          setValue(`works.${index}.building_item_id`, null);
                          setBuildingItemIndex(-1);
                        },
                      }}
                      isSearch={true}
                      allowClear={true}
                      isElementValue
                    />
                  </>
                )}
              />
            </Col>

            <Col span={24}>
              <Form.Item label={planMessages.labelBuildingDocument}>
                {buildingItem ? (
                  <CarouselComponent data={buildingItemSelected} slidesQuantity={4} />
                ) : (
                  <div className="plan__validation__mess" style={{ textAlign: 'center', color: '#828282' }}>
                    建物名を選択してください
                  </div>
                )}
              </Form.Item>
            </Col>
          </div>

          <Col span={24} className="padding-lr-none">
            <Controller
              name={`works.${index}.content`}
              control={control}
              rules={{
                required: planMessages.jobContentRequired,
              }}
              render={({ field }) => (
                <>
                  <TextAreaField
                    disable={review}
                    help={<ErrorMessage message={errors?.works?.[index]?.content?.message} />}
                    className={
                      errors.works !== undefined && errors?.works?.[index]?.content !== undefined ? 'border-error' : ''
                    }
                    label={
                      <>
                        {planMessages.labelJobContent} {!review && <RequiredLabel />}
                      </>
                    }
                    placeholder={planMessages.placeholderJobContent}
                    rows={8}
                    props={field}
                    onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                  />
                </>
              )}
            />
          </Col>

          <div className="report__sub__text">
            <div className="report__sub__text__right">
              <p>{planMessages.lableTypeReport}</p>
              {!review && <RequiredLabel />}
            </div>

            {isSubmitted && !review && (
              <Button type="text" style={{ padding: 0, height: 'auto' }} onClick={() => setIsSubmitted(false)}>
                <EditOutlined />
              </Button>
            )}
          </div>

          <div className="reportType-container" style={{ width: '100%' }}>
            <Col span={24}>
              <Controller
                name={`works.${index}.report.type_report`}
                control={control}
                rules={{
                  required: planMessages.reportTypeRequired,
                }}
                render={({ field }) => (
                  <>
                    <SelectField
                      error={errors?.works?.[index]?.report?.option_report}
                      help={<ErrorMessage message={errors?.works?.[index]?.report?.type_report?.message} />}
                      options={fetchData.reportTypeOptions}
                      placeholder={planMessages.lableTypeReport}
                      defaultValue={field.value}
                      disable={isSubmitted}
                      props={{ ...field, onChange: handleChangeSelect }}
                      listHeight={150}
                    />
                  </>
                )}
              />
              <Controller
                name={`works.${index}.report.option_report`}
                control={control}
                rules={{
                  required: planMessages.reportOptionsRequired,
                }}
                render={({ field }) => {
                  return (
                    <>
                      {reportTypeValue === 1 ? (
                        <ReportInputTextType
                          setValue={setValue}
                          selectedJob={index}
                          isSubmitted={isSubmitted}
                          setIsSubmitted={setIsSubmitted}
                          props={{
                            clearErrors,
                            handleBack,
                            setCatchReportValue,
                          }}
                          {...field}
                        />
                      ) : reportTypeValue === 2 ? (
                        <ReportInputNumberType
                          setValue={setValue}
                          selectedJob={index}
                          isSubmitted={isSubmitted}
                          setIsSubmitted={setIsSubmitted}
                          props={{ clearErrors, handleBack, setCatchReportValue }}
                          {...field}
                        />
                      ) : reportTypeValue === 3 ? (
                        <ReportCheckboxType
                          key={id}
                          id={id}
                          setValue={setValue}
                          selectedJob={index}
                          data={field.value}
                          isSubmitted={isSubmitted}
                          setIsSubmitted={setIsSubmitted}
                          props={{ clearErrors, handleBack, setCatchReportValue, catchReportValue }}
                          review={review}
                          {...field}></ReportCheckboxType>
                      ) : (
                        <ReportRadioType
                          id={id}
                          key={id}
                          setValue={setValue}
                          data={field.value}
                          selectedJob={index}
                          isSubmitted={isSubmitted}
                          setIsSubmitted={setIsSubmitted}
                          props={{
                            clearErrors,
                            handleBack,
                            setCatchReportValue,
                            catchReportValue,
                          }}
                          review={review}
                          {...field}></ReportRadioType>
                      )}
                      {errors.works !== undefined && (
                        <>
                          <ErrorMessage message={errors?.works?.[index]?.report?.option_report?.message} />
                        </>
                      )}
                    </>
                  );
                }}
              />
            </Col>
          </div>

          <Col span={24} className="padding-lr-none">
            <Controller
              name={`works.${index}.note`}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <TextAreaField
                      disable={review}
                      className={
                        errors.works !== undefined && errors?.works?.[index]?.note !== undefined ? 'border-error' : ''
                      }
                      label={<>{planMessages.labelJobNote}</>}
                      placeholder={planMessages.placeholderJobNote}
                      rows={8}
                      props={field}
                      onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                    />
                  </>
                );
              }}
            />
          </Col>
          <Col span={24} className="padding-lr-none">
            <Controller
              name={`works.${index}.documents`}
              control={control}
              render={({ field }) => (
                <>
                  <Form.Item label={planMessages.labelFiles}>
                    <UploadComponent
                      control={control}
                      data={field.value}
                      setValue={setValue}
                      name={field.name}
                      disable={review}
                    />
                  </Form.Item>
                  {errors?.works && <ErrorMessage message={errors?.works?.[index]?.documents?.message} />}
                </>
              )}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default ContentCheckList;
