import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React, { FC, useState } from 'react';
import { typeFileMsg } from '../../../utils/messages/typeFile';
import './style.scss';
interface InputRulesProps {
  required?: boolean;
  message?: string;
  [key: string]: any;
}

interface InputFieldProps {
  label: React.ReactNode;
  name?: string;
  placeholder?: string;
  rules?: InputRulesProps[];
  props?: any;
  state?: string;
  disable?: boolean;
  help?: React.ReactNode;
  autoFocus?: boolean;
}

const error = {
  border: '1px solid #EB5757',
  borderRadius: '4px',
  boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
};

const disabled = {
  border: '0.5px solid #828282',
  borderRadius: '4px',
  backgroundColor: '#384044',
};

const InputPassword: FC<InputFieldProps> = ({
  label,
  name,
  rules,
  placeholder,
  props,
  state,
  disable,
  help,
  autoFocus,
}) => {
  const [style, setStyle] = useState<Object>({ border: '0.5px solid #828282', borderRadius: '4px' });
  const handleFocus = () => {
    setStyle({ border: '1px solid #f2f2f2', boxShadow: '0px 0px 15px rgba(160, 235, 230, 0.35)', borderRadius: '4px' });
  };
  const handleBlur = () => {
    setStyle({ border: '0.5px solid #828282', boxShadow: 'none', borderRadius: '4px' });
  };

  return (
    <Form.Item label={label} name={name} rules={rules} className="password-field" help={help} colon={false}>
      <Input.Password
        {...props}
        autoComplete="off"
        placeholder={placeholder}
        iconRender={(visible) =>
          visible ? <EyeOutlined style={{ color: '#828282' }} /> : <EyeInvisibleOutlined style={{ color: '#828282' }} />
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={disable ? disabled : state === typeFileMsg.error ? error : style}
        disabled={disable}
        autoFocus={autoFocus ?? undefined}
      />
    </Form.Item>
  );
};

export default InputPassword;
