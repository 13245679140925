import { FC } from 'react';
import { ROLE } from '../../router/role';
import EditAdmin from './Admin';
import EditStaff from './Staff';

interface EditProps {}

const EditUser: FC<EditProps> = (props) => {
  return <>{localStorage.getItem('role') === ROLE.STAFF ? <EditStaff /> : <EditAdmin />}</>;
};

export default EditUser;
