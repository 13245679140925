import { Col, Form, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { ButtonWrap, PlanStatus, TitleContent } from '../../../components/commons';
import PlanForm from '../../../components/Form/PlanForm';
import { confirmMessages, japanesePlanMessages as planMessages } from '../../../utils/messages';
import { useForm } from 'react-hook-form';
import CheckListForm from '../../../components/Form/CheckListForm/CreatePlan';
import { notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import planApi from '../../../api/planApi';
import { useNavigate, useParams } from 'react-router-dom';
import { buildingDTO, handleDataEdit, planDTO, staffDTO } from '../../../utils/dtos/plan/format-data';
import { generalMessages } from '../../../utils/messages/general';
import Popup from '../../../components/commons/Popup';
import { appRoutes } from '../../../router/routes';
import { useStore } from '../../../store';
import Loading from '../../../components/commons/Loading';
import HandleError from '../../../components/commons/HandleError';
import './style.scss';

interface ICreatePlan {}

const ReviewPlan: FC<ICreatePlan> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [indexBuilding, setIndexBuilding] = useState<number>(-1);
  const [building, setBuilding] = useState<Array<any>>();
  const [staff, setStaff] = useState<Array<any>>();
  const [visible, setVisible] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [buildingReset, setBuildingReset] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    reset,
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const { id } = useParams();

  const { isFetching: isLoadingData, data }: any = useQuery(['plan_detail', id], () => planApi.getOne(id), {
    onError: (error: any) => {
      if (error && error.response.status === 404) {
        navigate(appRoutes.NOT_FOUND);
      }
    },
  });

  useEffect(() => {
    if (data?.success === false) {
      reset({});
      notification.error({
        message: generalMessages.error,
        description: data.message,
      });
      setIsLoading(false);
    }
  }, [data?.success]);

  const suggestions: any = useQuery(['plan_suggestions'], () => planApi.getSuggestions(), { retry: false });
  const staffQuery: any = useQuery(['plan_staff'], () => planApi.getStaff(), { retry: false });

  useEffect(() => {
    const dataBuilding = buildingDTO(suggestions?.data?.data);
    setBuilding(dataBuilding);
  }, [suggestions.isFetching, isLoadingData]);

  useEffect(() => {
    const data = staffDTO(staffQuery.data);
    setStaff(data);
  }, [staffQuery.isFetching, isLoadingData]);

  if (suggestions.isError) {
    notification.error({
      message: `Suggestions : ${suggestions.error.message}`,
    });
  }

  if (staffQuery.isError) {
    notification.error({
      message: `Staff : ${staffQuery.isError.message}`,
    });
  }
  useEffect(() => {
    if (data && data?.success && !suggestions.isFetching && !staffQuery.isFetching) {
      const newData = planDTO(data);
      reset(newData);
      const index =
        data?.data?.status !== 1
          ? buildingDTO(suggestions?.data?.data).findIndex((each: any) => {
              return each?.id === data?.data?.building_id;
            })
          : buildingDTO(suggestions?.data?.data)
              .filter((each: any) => each.deleted_at === null)
              .findIndex((each: any) => {
                return each?.id === data?.data?.building_id;
              });
      setIndexBuilding(index as number);
      setIsLoading(false);
      // setBuildingReset(true);
    }
  }, [data, suggestions.isFetching, staffQuery.isFetching]);

  const [state, dispatch] = useStore();

  const mutation = useMutation(planApi.editPlan, {
    onSuccess: (data: any) => {
      if (data.success) {
        notification.success({
          message: generalMessages.successMsg,
        });
        navigate(`/${appRoutes.PLAN.LIST}`);
      } else
        notification.error({
          message: data.message,
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries('plan');
      queryClient.invalidateQueries(['plan_detail', id]);
    },
    onError: (error: any) => {
      setSubmitLoading(false);
      notification.error({
        message: generalMessages.error,
        description: error.response.data.message,
      });
    },
  });

  const deleteMutation = useMutation(planApi.deletePlan, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries('plan');
        navigate(`/${appRoutes.PLAN.LIST}`);
        notification.success({
          message: generalMessages.successMsg,
        });
        setLoadingDelete(false);
      }
    },
    onError: (error: any) => {
      setLoadingDelete(false);
      HandleError(error);
    },
  });

  const handleBack = () => navigate(-1);

  const onSubmit = (data: any) => {
    setSubmitLoading(true);
    const newData = { ...data, status: 2 };
    const result = handleDataEdit(newData);
    mutation.mutate({ result, id });
  };
  const onSave = (data: any) => {
    setSubmitLoading(true);
    const newData = { ...data, status: 1 };
    const result = handleDataEdit(newData);
    mutation.mutate({ result, id });
  };

  return (
    <>
      {(state.isLoading || loadingDelete) && <Loading />}

      <div className="review__plan">
        <div className="plan__title">
          <TitleContent leftIcon={true} title={planMessages.titleReviewPlan} handleBack={handleBack} />
        </div>
        {isLoading || submitLoading ? (
          <Loading />
        ) : (
          <div className="review__plan__main plan__main">
            {data?.data?.status !== 1 && (
              <div style={{ marginBottom: 24 }}>
                <PlanStatus status={data?.data?.status} purpose="plan"></PlanStatus>
              </div>
            )}
            <Form layout="vertical">
              <div className="plan-main-form-wrapper">
                <div className="plan-main-form">
                  <div className="review__plan__main__content">
                    <PlanForm
                      review={data?.data?.status !== 1}
                      done={data?.data?.status === 4}
                      hasNewComment={data?.data?.has_new_comment}
                      props={{
                        isSubmitted,
                        isSubmitting,
                        building:
                          data?.data?.status !== 1
                            ? building
                            : building?.filter((each: any) => each.deleted_at === null),
                        staff:
                          data?.data?.status !== 1
                            ? staff
                            : staff?.filter((each: any) => each.deleted_at === null && each.status === '1'),
                        data,
                        getValues,
                        control,
                        errors,
                        clearErrors,
                        setValue,
                        indexBuilding,
                        setIndexBuilding,
                        setBuildingReset,
                      }}
                      isValid={data?.success}
                      idPlan={id}
                    />
                  </div>
                  <div className="review__plan__main__check plan__review__main__check">
                    <CheckListForm
                      disable={data?.data?.status !== 1}
                      props={{
                        buildingItem:
                          data?.data?.status !== 1
                            ? building?.[indexBuilding]?.item_of_buildings
                            : building
                                ?.filter((each: any) => each.deleted_at === null)
                                ?.[indexBuilding]?.item_of_buildings?.filter((each: any) => each.deleted_at === null),
                        facility:
                          data?.data?.status !== 1
                            ? building?.[indexBuilding]?.building_facilities
                            : building
                                ?.filter((each: any) => each.deleted_at === null)
                                ?.[indexBuilding]?.building_facilities?.filter((each: any) => each.deleted_at === null),
                        data,
                        control,
                        setValue,
                        errors,
                        clearErrors,
                        getValues,
                        isSubmitting,
                        buildingReset: buildingReset,
                      }}
                    />
                  </div>
                </div>
              </div>
              {data?.data?.status === 1 ? (
                <div className="plan-action-btn">
                  <Row gutter={[24, 214]} style={{ marginBottom: 30 }}>
                    <Col span={8}>
                      <ButtonWrap
                        onClick={handleSubmit(onSave)}
                        className="common-btn"
                        text={planMessages.saveDraftBtn}
                        backgroundColor="#222E35"
                        width={'100%'}
                        height="56px"
                        border={'1px solid #247E84'}></ButtonWrap>
                    </Col>
                    <Col span={8}>
                      <ButtonWrap
                        onClick={() => {
                          setVisible(true);
                        }}
                        className="common-btn"
                        text={planMessages.cancelBtn}
                        backgroundColor="#222E35"
                        width={'100%'}
                        height="56px"
                        border={'1px solid #247E84'}></ButtonWrap>
                    </Col>

                    <Col span={8}>
                      <ButtonWrap
                        onClick={handleSubmit(onSubmit)}
                        htmlType="submit"
                        className="common-btn"
                        type="primary"
                        text={planMessages.submitBtn}
                        color="white"
                        width={'100%'}
                        height="56px"
                        border={'1px solid #247E84'}></ButtonWrap>
                    </Col>
                  </Row>
                </div>
              ) : data?.data?.status === 2 ? (
                <div className="plan-action-btn">
                  <Row gutter={[24, 214]} style={{ marginBottom: 30 }}>
                    <Col span={24}>
                      <ButtonWrap
                        onClick={() => {
                          setVisible(true);
                        }}
                        className="common-btn"
                        text={planMessages.cancelBtn}
                        backgroundColor="#222E35"
                        width={'100%'}
                        height="56px"
                        border={'1px solid #247E84'}></ButtonWrap>
                    </Col>
                  </Row>
                </div>
              ) : (
                <></>
              )}
            </Form>
          </div>
        )}
        {visible && (
          <Popup
            unVisible={() => setVisible(false)}
            title={planMessages.titleConfirm}
            actions={() => {
              if (id) {
                setLoadingDelete(true);
                deleteMutation.mutate(id);
                setVisible(false);
              }
            }}
            width="415px"
            text={planMessages.textDeleteConfirm}
            btn1={confirmMessages.yes}
            btn2={confirmMessages.no}
          />
        )}
      </div>
    </>
  );
};

export default ReviewPlan;
