import { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Col, notification, Row, Spin, Tooltip } from 'antd';
import { Chart as ChartJS, registerables } from 'chart.js';
import { analysisMessages } from '../../../utils/messages';
import { DateTimePicker, ErrorMessage, SelectField, TitleContent } from '../../../components/commons';
import { DownloadOutlined } from '@ant-design/icons';
import listApi from '../../../api/listApi';
import { useQuery } from 'react-query';
import analysisApi from '../../../api/analysisApi';
import { generalMessages } from '../../../utils/messages/general';
import { Controller, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import EmptyWrap from '../../../components/commons/EmptyWrap';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import { constant } from '../../../utils/constants';

ChartJS.register(...registerables);

interface BarChartProps {}

const BarChart: FC<BarChartProps> = (props) => {
  const users: any = useQuery('list-staffs', listApi.getStaffs);
  const buildings: any = useQuery('list-building', listApi.getBuilding);
  const [isDownload, setDownload] = useState(false);
  const [isEnable, setEnable] = useState(true);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  if (buildings.isError || buildings.data?.success === false || users.isError || users.data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const { control } = useForm();
  const user_id = useWatch({
    control,
    name: 'user',
  });
  const building_id = useWatch({
    control,
    name: 'building',
  });
  const options: any = {
    plugins: {
      tooltip: {
        callbacks: {
          title: (data: any) => `${analysisMessages.time}: ${data[0].label}`,
        },
        displayColors: false,
        borderColor: analysisMessages.tooltipBorderColor,
        backgroundColor: analysisMessages.tooltipBackgroundColor,
        borderWidth: 1,
        borderRadius: 0,
        bodyColor: analysisMessages.tooltipTexColor,
        titleColor: analysisMessages.tooltipTexColor,
        titleFont: {
          size: constant.tooltipFontSize,
          weight: constant.tooltipFontWeight,
          style: analysisMessages.tooltipFontStyle,
          family: analysisMessages.tooltipFontFamily,
        },
        bodyFont: {
          size: constant.tooltipFontSize,
          weight: constant.tooltipFontWeight,
          style: analysisMessages.tooltipFontStyle,
          family: analysisMessages.tooltipFontFamily,
        },
        xAlign: 'left',
        padding: 9,
      },
      legend: {
        display: true,
        padding: 8,
        position: analysisMessages.positionLegend,
        labels: {
          color: analysisMessages.colorLegend,
          padding: 30,
          boxHeight: 12,
          boxWidth: 70,
        },
      },
    },
    offsetGridLines: true,
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: analysisMessages.colorStickScale,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          drawBorder: false,
          color: (context: any) => analysisMessages.tooltipBorderColor,
        },
        ticks: {
          color: analysisMessages.colorStickScale,
          min: 0,
          callback: function (value: any, index: any, values: any) {
            if (Math.floor(value) === value) {
              return value;
            }
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const diffMonth = Math.round(endDate.diff(startDate, 'months', true));
  const { isLoading, data, isFetching }: any = useQuery(
    [
      'analysis-plan',
      startDate.format('YYYY-MM'),
      endDate?.format('YYYY-MM'),
      user_id ? user_id : '',
      building_id ? building_id : '',
    ],
    () => analysisApi.plan(startDate.format('YYYY-MM'), endDate.format('YYYY-MM'), user_id, building_id),
    {
      enabled: startDate <= endDate && diffMonth <= 12 && diffMonth >= 0,
    }
  );
  const dataDaily = data?.map((i: any) => i.countDaily);
  const dataRegular = data?.map((i: any) => i.countRegular);
  const yearMonth = data?.map((i: any) => i.yearMonth);
  const state = {
    labels: yearMonth,
    datasets: [
      {
        label: analysisMessages.labelperiodical,
        backgroundColor: '#6FCF97',
        borderColor: '#6FCF97',
        categoryPercentage: 1.0,
        maxBarThickness: 64,
        barPercentage: 0.5,
        data: dataRegular,
      },
      {
        label: analysisMessages.labelDaily,
        backgroundColor: '#2D9CDB',
        borderColor: '#2D9CDB',
        categoryPercentage: 1.0,
        maxBarThickness: 64,
        barPercentage: 0.5,
        data: dataDaily,
      },
    ],
  };
  const handleDownload = () => {
    setDownload(true);
  };
  const checkDate =
    startDate > endDate &&
    (moment(startDate).month() !== endDate.month() || moment(startDate).year() !== endDate.year());
  const download: any = useQuery(
    ['download-plan', startDate, endDate?.format('YYYY-MM'), user_id ? user_id : '', building_id ? building_id : ''],
    () => analysisApi.downloadPlan(startDate.format('YYYY-MM'), endDate.format('YYYY-MM'), user_id, building_id),
    {
      enabled: isDownload && diffMonth <= 12 && diffMonth >= 0,
      staleTime: 0,
      onSuccess: () => setDownload(false),
    }
  );
  useEffect(() => {
    setEnable(data?.length > 0);
  }, [data]);
  useEffect(() => {
    setDownload(false);
  }, [startDate, endDate]);
  const className = `${
    !checkDate && data && data?.length > 0
      ? 'analysis-content__plan__title__icon'
      : 'analysis-content__plan__title__icon__disabled'
  } `;
  let msg: string | undefined;
  if (checkDate) msg = analysisMessages.errMonthMsg;
  else if (!(diffMonth <= 12 && diffMonth >= 0)) msg = analysisMessages.limitMonthMsg;
  return (
    <>
      <section className="analysis-content__plan">
        <div className="analysis-content__plan__title">
          <TitleContent
            leftIcon={false}
            title={analysisMessages.titlePlan}
            rightIcon={{
              front: (
                <>
                  <div className={className} onClick={!checkDate && isEnable ? handleDownload : undefined}>
                    <DownloadOutlined />
                  </div>
                </>
              ),
            }}
            side={1}
          />
        </div>
        <Row className="analysis-content__plan__filter" gutter={[30, 20]}>
          <Col xs={12} lg={6} className="analysis-content__plan__filter__item">
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <SelectField
                  props={field}
                  options={users?.data
                    ?.map((i: { user_id: number; username: string; deleted_at: string; status: string }) => ({
                      key: i.user_id.toString(),
                      value: (
                        <Tooltip placement="topLeft" title={`${i.username}`}>
                          {!i.deleted_at && i.status === generalMessages.status ? (
                            <div className="deleted-selection-wrap">
                              <span className="deleted-selection-wrap__content">{`${i.username}`}</span>
                            </div>
                          ) : (
                            <div className="deleted-selection-wrap">
                              <span className="deleted-selection-wrap__content">{`${i.username}`}</span>
                              <span className="option-wrap__active">{`${
                                i.deleted_at ? analysisMessages.deleted : analysisMessages.invalidStatus
                              }`}</span>
                            </div>
                          )}
                        </Tooltip>
                      ),
                    }))
                    .concat([
                      {
                        key: '',
                        value: (
                          <Tooltip placement="topLeft" title={analysisMessages.placeholer}>
                            {analysisMessages.placeholer}
                          </Tooltip>
                        ),
                      },
                    ])}
                  label={analysisMessages.implementPlan}
                  placeholder={analysisMessages.placeholer}
                  isSearch={true}
                  isElementValue
                />
              )}
            />
          </Col>
          <Col xs={12} lg={6} className="analysis-content__plan__filter__item">
            <Controller
              name="building"
              control={control}
              render={({ field }) => (
                <SelectField
                  props={field}
                  options={
                    buildings &&
                    buildings.data
                      ?.map(
                        (i: {
                          address_1: string;
                          address_2: string;
                          id: number;
                          name: string;
                          deleted_at: string;
                        }) => ({
                          key: i.id.toString(),
                          value: (
                            <Tooltip placement="topLeft" title={`${i.name}`}>
                              <div className="deleted-selection-wrap">
                                <span className="deleted-selection-wrap__content">{`${i.name}`}</span>
                                {!i.deleted_at && (
                                  <span className="option-wrap__active">{analysisMessages.deleted}</span>
                                )}
                              </div>
                            </Tooltip>
                          ),
                        })
                      )
                      .concat([
                        {
                          key: '',
                          value: (
                            <Tooltip placement="topLeft" title={analysisMessages.placeholer}>
                              {analysisMessages.placeholer}
                            </Tooltip>
                          ),
                        },
                      ])
                  }
                  label={analysisMessages.titleBuilding}
                  placeholder={analysisMessages.placeholer}
                  isSearch={true}
                  isElementValue
                />
              )}
            />
          </Col>
          <Col xs={12} lg={6} className="analysis-content__plan__filter__item">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  props={{
                    ...field,
                    onBlur: (e: any) => {
                      setStartDate(e.target.value ? moment(e.target.value, 'YYYY年MM月') : moment());
                    },
                    value: moment(startDate, 'YYYY年MM月'),
                    onChange: (e: any) => {
                      field.onChange(e);
                      setStartDate(e ? e : moment());
                    },
                  }}
                  label={analysisMessages.time}
                  picker="month"
                  dateFormat="YYYY年MM月"
                  allowClear={false}
                  isError={checkDate || !(diffMonth <= 12 && diffMonth >= 0)}
                  help={<ErrorMessage message={msg} />}
                />
              )}
            />
          </Col>
          <Col xs={12} lg={6} className="analysis-content__plan__filter__item">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  props={{
                    ...field,
                    onBlur: (e: any) => {
                      setEndDate(e.target.value ? moment(e.target.value, 'YYYY年MM月') : moment());
                    },
                    value: moment(endDate, 'YYYY年MM月'),
                    onChange: (e: any) => {
                      field.onChange(e);
                      setEndDate(e ? e : moment());
                    },
                  }}
                  label=""
                  picker="month"
                  dateFormat="YYYY年MM月"
                  allowClear={false}
                  isError={checkDate || !(diffMonth <= 12 && diffMonth >= 0)}
                  help={<ErrorMessage message="" />}
                />
              )}
            />
          </Col>
        </Row>
        <div className="analysis-content__plan__bar-chart">
          {isLoading ||
          isFetching ||
          download.isLoading ||
          download.isFetching ||
          users.isLoading ||
          buildings.isLoading ? (
            <Loading />
          ) : (
            <>
              {data?.length > 0 ? (
                <div className="analysis-content__plan__bar-chart__wrapper">
                  <Bar data={state} options={options} />
                </div>
              ) : (
                <EmptyWrap />
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default BarChart;
