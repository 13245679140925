import { LeftOutlined } from '@ant-design/icons';
import { FC, memo } from 'react';
import { Col, Row } from 'antd';
import Title from './Title';
import './style.scss';

interface TitleContentProps {
  leftIcon: boolean;
  title: string;
  rightIcon?: any;
  side?: number;
  handleBack?: () => void;
}

const TitleContent: FC<TitleContentProps> = ({ leftIcon, title, rightIcon, side, handleBack }) => {
  return (
    <Row className="title-content">
      <Col span={6} className="title-content__back">
        {leftIcon && (
          <div className="title-content__icon" onClick={handleBack}>
            <LeftOutlined />
          </div>
        )}
      </Col>
      <Title title={title}></Title>
      <Col span={6} className="title-content__action">
        {rightIcon === undefined || side === undefined ? (
          <></>
        ) : side && side === 1 ? (
          <>{rightIcon.front}</>
        ) : (
          <>{rightIcon.back}</>
        )}
      </Col>
    </Row>
  );
};

export default memo(TitleContent);
