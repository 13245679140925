import React, { FC, useEffect, useState, memo } from 'react';
import { Col, Form, notification, Row, Spin, Tooltip } from 'antd';
import {
  DateTimePicker,
  RadioButton,
  SelectField,
  InputField,
  TextAreaField,
  ButtonWrap,
  TitleContent,
} from '../../commons';
import { confirmMessages, generalMessages, japanesePlanMessages as planMessages } from '../../../utils/messages';
import { DownloadOutlined } from '@ant-design/icons';
import { Controller } from 'react-hook-form';
import ErrorMessage from '../../commons/ErrorMessage';
import TimePickerItem from '../../commons/TimePicker';
import CarouselComponent from '../../commons/CarouselComponent/CarouselComponent';
import PlanCommentForm from './PlanCommentForm';
import { useNavigate, useParams } from 'react-router-dom';
import RequiredLabel from '../../commons/RequiredLabel';
import { appRoutes } from '../../../router/routes';
import { useQuery } from 'react-query';
import planApi from '../../../api/planApi';
import PlanAddressItem from './PlanAddressItem';
import { actions, useStore } from '../../../store';
import { getMoment } from '../../../utils/helper';
import { regex } from '../../../utils/regex';
import { NotifyCmtIcon } from './NotifyCmtIcon/NotifyCmtIcon';
import Popup from '../../commons/Popup';
import useConfirm from '../../../hooks/useConfirm';
import './styles.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';

const moment = getMoment();

interface IPlanFormProps {
  props: any;
  review?: boolean;
  edit?: boolean;
  done?: boolean;
  isValid?: boolean;
  idPlan?: string;
  hasNewComment?: boolean;
}
const fetchData = {
  typeOptions: [
    { key: 2, value: '日常' },
    { key: 1, value: '定期' },
  ],
  frequencyOptions: [
    { key: 2, value: '毎年' },
    { key: 1, value: '毎月' },
  ],
};
const PlanForm: FC<IPlanFormProps> = ({ props, review = false, done = false, isValid, idPlan, hasNewComment }) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [, dispatch] = useStore();
  const {
    staff,
    building,
    control,
    errors,
    setValue,
    getValues,
    clearErrors,
    isSubmitted,
    isSubmitting,
    indexBuilding,
    setIndexBuilding,
    setBuildingReset,
  } = props;
  const [onSelectPeriodically, setOnSelectPeriodically] = useState<Boolean>(false);
  const [enabledState, setEnabledState] = useState(false);
  const [commentVisible, setCommentVisible] = useState<boolean>(false);
  const [timeValidation, setTimeValidation] = useState<boolean>(true);
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [hasNewCommentFlag, setHasNewCommentFlag] = useState<boolean>(hasNewComment ? hasNewComment : false);
  const { confirm, onConfirm, onCancel, confirmState, setConfirmState } = useConfirm();
  const [matchSmallerScreen] = useMediaQuery(768);

  const checkExistFalicityAndBuildingItem = () => {
    return control._formValues.works.some((each: any) => each.facility_id || each.building_item_id);
  };

  const [valueStartDate, setValueStartDate] = useState(
    getValues('plan_start_date') ? getValues('plan_start_date') : moment().format('YYYY-MM-DD')
  );
  const [valueEndDate, setValueEndDate] = useState(
    getValues('plan_end_date') ? getValues('plan_end_date') : moment().format('YYYY-MM-DD')
  );

  const [valueStartTime, setValueStartTime] = useState<any>(
    getValues('plan_start_time') ? moment(getValues('plan_start_time'), 'HH:mm') : null
  );

  const [valueEndTime, setValueEndTime] = useState<any>(
    getValues('plan_end_time') ? moment(getValues('plan_end_time'), 'HH:mm') : null
  );

  const checkValidationTime = () => {
    if (!getValues('plan_start_time') || !getValues('plan_end_time')) {
      return true;
    }
    if (!moment(getValues('plan_end_date')).isSame(moment(getValues('plan_start_date')))) {
      return true;
    }

    return (
      moment(getValues('plan_end_time'), 'HH:mm').isAfter(moment(getValues('plan_start_time'), 'HH:mm')) ||
      moment(getValues('plan_end_time'), 'HH:mm').isSame(moment(getValues('plan_start_time'), 'HH:mm'))
    );
  };
  useEffect(() => {
    if (errors?.type || errors?.frequency) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [isSubmitted, isSubmitting]);

  useEffect(() => {
    if (isSubmitted) {
      if (moment(valueStartDate, 'YYYY-MM-DD').isSame(moment(valueEndDate, 'YYYY-MM-DD'))) {
        if (
          moment(valueEndTime, 'HH:mm').isAfter(moment(valueStartTime, 'HH:mm')) ||
          moment(valueEndTime, 'HH:mm').isSame(moment(valueStartTime, 'HH:mm'))
        ) {
          setTimeValidation(true);
        } else if (valueStartTime === null || valueEndTime === null) {
          setTimeValidation(true);
        } else {
          setTimeValidation(false);
        }
        setDateValidation(true);
      } else if (moment(valueEndDate, 'YYYY-MM-DD').isAfter(moment(valueStartDate, 'YYYY-MM-DD'))) {
        setDateValidation(true);
        setTimeValidation(true);
      } else {
        setDateValidation(false);
        setTimeValidation(true);
      }
    }
  }, [
    getValues('plan_start_date'),
    getValues('plan_end_date'),
    getValues('plan_start_time'),
    getValues('plan_end_time'),
    valueStartDate,
    valueEndDate,
    valueStartTime,
    valueEndTime,
    isSubmitted,
  ]);

  const { id } = useParams();
  const {
    isLoading,
    isError,
    isSuccess,
    data: dataDownload,
    error,
    isFetching: isFetchingDownload,
    isRefetchError,
    refetch,
  } = useQuery(['plan_download'], () => planApi.downloadPlan(id), {
    retry: false,
    enabled: review && done && enabledState,
  });

  if (isError) {
    notification.error({
      message: generalMessages.error,
      description: dataDownload?.message,
    });
  }

  useEffect(() => {
    if (!staff?.some((each: any) => each.id === getValues('user_id'))) {
      setValue('user_id', null);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (dataDownload) {
      setEnabledState(false);
      dispatch(actions.setIsLoading(false));
    }
  }, [dataDownload]);

  useEffect(() => {
    if (control._formValues.type === 1) {
      setOnSelectPeriodically(true);
    } else {
      setOnSelectPeriodically(false);
    }
  }, [control._formValues.type]);

  const handleSelectedType = async (e: any) => {
    setValue('type', e.target.value);
    clearErrors('type');
    if (e.target.value === 1) {
      setOnSelectPeriodically(true);
    } else {
      setOnSelectPeriodically(false);
    }
  };

  const handleSelectedFrequence = (e: any) => {
    setValue('type', 1);
    setValue('frequency', e.target.value);
    clearErrors('frequency');
    setOnSelectPeriodically(true);
  };

  const handleChangeSelect = (value: string) => {
    const needConfirm = checkExistFalicityAndBuildingItem();
    if (needConfirm) {
      confirm().then((data) => {
        if (data) {
          const temp = building?.findIndex((each: any) => Number(value) === each.id);
          setIndexBuilding(temp);
          setValue(`building_id`, value);
          clearErrors('building_id');
          setBuildingReset(true);
        }
      });
    } else {
      const temp = building?.findIndex((each: any) => Number(value) === each.id);
      setIndexBuilding(temp);
      setValue(`building_id`, value);
      clearErrors('building_id');
    }
  };

  const handleSelectTimePicker = (name: any, value: any, time: any, moment: any) => {
    if (!name) {
      return;
    }
    if (name === 'plan_start_time') {
      setValueStartTime(time.format('HH:mm'));
    }
    if (name === 'plan_end_time') {
      setValueEndTime(time.format('HH:mm'));
    }
  };

  const handleChangeDatePicker = (name: string, value: any, date: any, dateString: string) => {
    if (!name) {
      return;
    }
    if (name === 'plan_start_date') {
      setValueStartDate(dateString);
    }
    if (name === 'plan_end_date') {
      setValueEndDate(dateString);
    }
    setValue(name, dateString);
  };
  useEffect(() => {
    if (params.get('building')) {
      const temp = building?.findIndex((each: any) => Number(params.get('building')!) === each.id);
      setIndexBuilding(temp);
      setValue(`building_id`, Number(params.get('building')!));
    }
  }, [building]);
  return (
    <>
      <div className="form-session">
        <Row gutter={[40, 10]}>
          <Col span={24}>
            <TitleContent
              leftIcon={false}
              title={planMessages.titlePlanContent}
              rightIcon={{
                front: (
                  <>
                    {review && done && (
                      <div
                        className="download__plan__icon"
                        onClick={(prevState) => {
                          setEnabledState(true);
                          dispatch(actions.setIsLoading(true));
                        }}
                      >
                        <DownloadOutlined />
                      </div>
                    )}
                  </>
                ),
              }}
              side={1}
            />
          </Col>
          <Col xs={24} md={12}>
            <Col span={24} className="padding-lr-none">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: planMessages.planNameRequired,
                }}
                render={({ field }) => (
                  <>
                    <InputField
                      help={<ErrorMessage message={errors?.name?.message} />}
                      className={errors.name ? 'border-error' : ''}
                      label={
                        <>
                          {planMessages.labelNamePlan} {!review && <RequiredLabel />}
                        </>
                      }
                      placeholder={planMessages.placeholderPlanContent}
                      props={field}
                      disable={review}
                      onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                    />
                  </>
                )}
              />
            </Col>
            <Col span={24} className="group-column-to-row" style={{ marginTop: 10 }}>
              <Col span={24} className="padding-lr-none building_selection">
                <Controller
                  name="building_id"
                  control={control}
                  rules={{ required: planMessages.buildingRequired }}
                  render={({ field }) => (
                    <>
                      <SelectField
                        error={errors?.building_id}
                        help={<ErrorMessage message={errors?.building_id?.message} />}
                        className={errors.building_id ? 'border-error' : ''}
                        label={
                          <>
                            {planMessages.labelNameBuilding} {!review && <RequiredLabel />}
                          </>
                        }
                        options={
                          building
                            ? building?.map((each: any) => {
                                return {
                                  key: each.id,
                                  value: (
                                    <Tooltip placement="topLeft" title={each.name}>
                                      <div className="deleted-selection-wrap">
                                        <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                        {each.deleted_at && (
                                          <span className="option-wrap__active">{planMessages.deleted}</span>
                                        )}
                                      </div>
                                    </Tooltip>
                                  ),
                                };
                              })
                            : []
                        }
                        placeholder={planMessages.placeholderNameBuilding}
                        defaultValue={field.value}
                        props={{ ...field, onChange: handleChangeSelect, defaultValue: field.value }}
                        disable={review}
                        isSearch={true}
                        isElementValue
                      />
                      {}
                    </>
                  )}
                />
              </Col>
              <Col span={24} className="padding-lr-none">
                <PlanAddressItem address={indexBuilding >= 0 ? building?.[indexBuilding]?.address : ''} />
              </Col>
              <Col span={24} className="padding-lr-none" style={{ paddingBottom: 20 }}>
                <Form.Item label={planMessages.labelBuildingDocument}>
                  {indexBuilding >= 0 ? (
                    <>
                      {building?.[indexBuilding]?.documents?.length > 0 ? (
                        <CarouselComponent data={building && building?.[indexBuilding]?.documents} slidesQuantity={4} />
                      ) : (
                        <div
                          className="plan__validation__mess"
                          style={{ textAlign: 'center', color: '#828282', fontSize: 13 }}
                        >
                          {planMessages.placeholderBuildingAttachmentEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className="plan__validation__mess please-choose"
                      style={{ textAlign: 'center', color: '#828282', fontSize: 13 }}
                    >
                      {planMessages.placeholderBuildingAttachment}
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Col>
          </Col>
          <Col xs={24} md={12}>
            <Col span={24} className={`padding-lr-none ${onSelectPeriodically ? 'period-user' : 'normal-user'}`}>
              <div className={`wrap-radio ${errors.status ? 'border-error' : ''}`}>
                <div style={{ marginTop: '0px', width: '100%' }}>
                  <Controller
                    name="type"
                    control={control}
                    rules={{
                      required: planMessages.typeRequired,
                    }}
                    render={({ field }) => (
                      <div className="period-radio-container">
                        <label>
                          {planMessages.labelTypeInspection}
                          {!review && <RequiredLabel />}
                        </label>
                        <RadioButton
                          help={<ErrorMessage message={errors?.type?.message} />}
                          options={fetchData.typeOptions}
                          defaultValue={field.value}
                          {...field}
                          onChange={handleSelectedType}
                          // props={{ ref: type }}
                          disable={review}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </Col>
            {onSelectPeriodically && (
              <Col span={24} className="padding-lr-none">
                <div className={`wrap-radio ${errors.type ? 'border-error' : ''}`}>
                  <div style={{ marginTop: '-30px', width: '100%' }}>
                    <Controller
                      name="frequency"
                      control={control}
                      rules={{ required: planMessages.frequencyRequired }}
                      render={({ field }) => (
                        <div className="period-radio-container">
                          <label>
                            {planMessages.labelFrequencyInspection} {!review && <RequiredLabel />}
                          </label>
                          <RadioButton
                            help={<ErrorMessage message={errors?.frequency?.message} />}
                            options={fetchData.frequencyOptions}
                            defaultValue={field.value}
                            {...field}
                            onChange={handleSelectedFrequence}
                            disable={review}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col span={24} className="padding-lr-none user_selection">
              <Controller
                name="user_id"
                control={control}
                rules={{
                  required: planMessages.assignedUsersRequired,
                }}
                render={({ field }) => (
                  <>
                    <SelectField
                      error={errors?.user_id}
                      help={<ErrorMessage message={errors?.user_id?.message} />}
                      label={
                        <>
                          {planMessages.labelAssignedUsers} {!review && <RequiredLabel />}
                        </>
                      }
                      options={
                        staff
                          ? staff?.map((each: any) => {
                              return {
                                key: each.id,
                                value: (
                                  <Tooltip placement="topLeft" title={`${each.name} - ${each.email}`}>
                                    <div className="deleted-selection-wrap">
                                      <span className="deleted-selection-wrap__content">{`${each.name} - ${each.email}`}</span>
                                      {each.deleted_at && (
                                        <span className="option-wrap__active">{planMessages.deleted}</span>
                                      )}
                                    </div>
                                  </Tooltip>
                                ),
                              };
                            })
                          : []
                      }
                      placeholder={planMessages.placeholderAssignedUsers}
                      defaultValue={staff?.some((each: any) => each.id === field.value) ? field.value : null}
                      props={{ onChange: field.onChange, ref: field.ref, name: field.name, onBlur: field.onBlur }}
                      disable={review}
                      isSearch={true}
                      isElementValue
                    />
                  </>
                )}
              />
            </Col>

            <Col span={24} className="padding-lr-none" style={{ marginTop: 6, marginBottom: 10 }}>
              <Row gutter={[24, 16]}>
                <Col span={14}>
                  <Controller
                    name="plan_start_date"
                    control={control}
                    rules={{
                      required: planMessages.startDateRequired,
                      validate: () => {
                        return moment(getValues('plan_end_date')) >= moment(getValues('plan_start_date'));
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <DateTimePicker
                          isError={!dateValidation}
                          disable={review}
                          help={!dateValidation ? <ErrorMessage message={planMessages.validationStartDate} /> : ''}
                          label={
                            <>
                              {planMessages.labelStartDate} {!review && <RequiredLabel />}
                            </>
                          }
                          placeholder={planMessages.placeholderStartDate}
                          className={`date-time-picker-custom ${errors.endDate ? 'border-error' : ''}`}
                          dateFormat="YYYY-MM-DD"
                          defaultValue={moment(field.value).format('YYYY-MM-DD')}
                          props={{
                            name: field.name,
                            ref: field.ref,
                            value: moment(moment(valueStartDate).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                            onChange: (date: any, dateString: string) => {
                              handleChangeDatePicker(field.name, field.value, date, dateString);
                            },
                            onBlur: (e: any) => {
                              if (e.target.value === '' || !regex.validDate.test(e.target.value)) {
                                setValueStartDate(moment().format('YYYY-MM-DD'));
                                setValue(field.name, moment().format('YYYY-MM-DD'));
                              } else {
                                field.onChange(e);
                                setValueStartDate(e.target.value);
                              }
                            },
                          }}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={10}>
                  <Controller
                    name="plan_start_time"
                    control={control}
                    rules={{
                      validate: () => checkValidationTime(),
                    }}
                    render={({ field }) => {
                      return (
                        <>
                          <TimePickerItem
                            isError={!timeValidation}
                            format="HH:mm"
                            key={`${field.value}-starttime`}
                            help={<ErrorMessage message={!timeValidation ? planMessages.validationStartTime : ''} />}
                            label={<>{planMessages.labelStartTime}</>}
                            placeholder={planMessages.placeholderStartTime}
                            handleBlur={(e: any) => {
                              if (e.target.value === '' || !regex.validateTime.test(e.target.value)) {
                                setValue(field.name, null);
                                setValueStartTime(null);
                              } else {
                                setValue(field.name, e.target.value);
                                setValueStartTime(e.target.value);
                              }
                            }}
                            props={{
                              name: field.name,
                              ref: field.ref,
                              onChange: field.onChange,
                              value: valueStartTime === null ? '' : moment(valueStartTime, 'HH:mm'),
                              onKeyDown: (e: any) => {
                                if (e.keyCode === 13) {
                                  e.target.blur();
                                }
                              },
                            }}
                            disable={review}
                            handleSelect={(time, moment) => {
                              handleSelectTimePicker(field.name, field.value, time, moment);
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} className="padding-lr-none" style={{ marginTop: 6, marginBottom: 10 }}>
              <Row gutter={[24, 16]}>
                <Col span={14}>
                  <Controller
                    name="plan_end_date"
                    control={control}
                    rules={{
                      required: planMessages.endDateRequired,
                      validate: () => {
                        return moment(getValues('plan_end_date')) >= moment(getValues('plan_start_date'));
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <DateTimePicker
                          isError={!dateValidation}
                          dateFormat="YYYY-MM-DD"
                          placeholder={planMessages.placeholderEndDate}
                          disable={review}
                          help={!dateValidation ? <ErrorMessage message={planMessages.validationEndDate} /> : ''}
                          label={
                            <>
                              {planMessages.labelEndDate} {!review && <RequiredLabel />}
                            </>
                          }
                          defaultValue={moment(field.value).format('YYYY-MM-DD')}
                          props={{
                            name: field.name,
                            ref: field.ref,
                            value: moment(moment(valueEndDate).format('YYYY-MM-DD'), 'YYYY-MM-DD'),

                            onChange: (date: any, dateString: string) => {
                              handleChangeDatePicker(field.name, field.value, date, dateString);
                            },

                            onBlur: (e: any) => {
                              if (e.target.value === '' || !regex.validDate.test(e.target.value)) {
                                setValueEndDate(moment().format('YYYY-MM-DD'));
                                setValue(field.name, moment().format('YYYY-MM-DD'));
                              } else {
                                field.onChange(e);
                                setValueEndDate(e.target.value);
                              }
                            },
                          }}
                          className={`date-time-picker-custom`}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={10}>
                  <Controller
                    name="plan_end_time"
                    control={control}
                    rules={{
                      validate: () => checkValidationTime(),
                    }}
                    render={({ field }) => {
                      return (
                        <>
                          <TimePickerItem
                            isError={!timeValidation}
                            key={`${field.value}-endtime`}
                            format="HH:mm"
                            help={<ErrorMessage message={!timeValidation ? planMessages.validationEndTime : ''} />}
                            defaultValue={field.value}
                            placeholder={planMessages.placeholderEndTime}
                            label={<>{planMessages.labelEndTime}</>}
                            handleBlur={(e: any) => {
                              if (e.target.value === '' || !regex.validateTime.test(e.target.value)) {
                                setValue(field.name, null);
                                setValueEndTime(null);
                              } else {
                                setValue(field.name, e.target.value);
                                setValueEndTime(e.target.value);
                              }
                            }}
                            props={{
                              name: field.name,
                              ref: field.ref,
                              onChange: field.onChange,
                              value: valueEndTime === null ? null : moment(valueEndTime, 'HH:mm'),
                              onKeyDown: (e: any) => {
                                if (e.keyCode === 13) {
                                  e.target.blur();
                                }
                              },
                            }}
                            disable={review}
                            handleSelect={(time, moment) => {
                              handleSelectTimePicker(field.name, field.value, time, moment);
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </Col>

                {review && done && (
                  <>
                    <Col span={12}>
                      <Controller
                        name="start_datetime"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DateTimePicker
                              dateFormat="YYYY-MM-DD HH:mm"
                              placeholder={planMessages.placeholderEndDate}
                              disable={review}
                              label={<>{planMessages.labelRealStartTime}</>}
                              help={<ErrorMessage message={''} />}
                              defaultValue={moment(field.value).format('YYYY-MM-DD HH:mm')}
                              props={{
                                name: field.name,
                                ref: field.ref,
                              }}
                              className={`date-time-picker-custom`}
                            />
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Controller
                        name="end_datetime"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DateTimePicker
                              dateFormat="YYYY-MM-DD HH:mm"
                              placeholder={planMessages.placeholderEndDate}
                              disable={review}
                              label={<>{planMessages.labelRealEndTime}</>}
                              help={<ErrorMessage message={''} />}
                              defaultValue={moment(field.value).format('YYYY-MM-DD HH:mm')}
                              props={{
                                name: field.name,
                                ref: field.ref,
                              }}
                              className={`date-time-picker-custom`}
                            />
                          </>
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Col>
        </Row>
        <Row gutter={[40, 10]}>
          <Col
            xs={24}
            md={12}
            style={{
              marginTop:
                indexBuilding >= 0 && building?.[indexBuilding]?.documents?.length > 0
                  ? !done
                    ? onSelectPeriodically
                      ? 18
                      : 20
                    : onSelectPeriodically
                    ? matchSmallerScreen
                      ? 0
                      : '-80px'
                    : '-20px'
                  : !done
                  ? onSelectPeriodically
                    ? '-44px'
                    : 10
                  : onSelectPeriodically
                  ? '-160px'
                  : '-100px',
            }}
          >
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <>
                  <TextAreaField
                    disable={review}
                    help={<ErrorMessage message={errors.note?.message} />}
                    className={errors.note ? 'border-error' : ''}
                    label={planMessages.labelPlanNote}
                    placeholder={planMessages.placeholderPlanNote}
                    rows={8}
                    props={field}
                    onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                  />
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
            {review && isValid && (
              <Row gutter={[20, 16]} className="plan-action-btn">
                <Col span={onSelectPeriodically ? 12 : 24}>
                  <ButtonWrap
                    className="common-btn comment-btn"
                    type="primary"
                    text={planMessages.labelPlanComment}
                    width={'100%'}
                    height="56px"
                    color="white"
                    iconLeft={<NotifyCmtIcon hasNewComment={hasNewCommentFlag} />}
                    onClick={() => {
                      setCommentVisible(true);
                    }}
                  />
                </Col>
                {onSelectPeriodically && (
                  <Col span={12}>
                    <ButtonWrap
                      text={planMessages.labelPlanHistory}
                      color="#49ABB1"
                      width={'100%'}
                      backgroundColor="#1C282F"
                      border={'1px solid #247E84'}
                      className="common-btn"
                      onClick={() => {
                        navigate(`/${appRoutes.PLAN.ROOT}/history/${idPlan}`);
                      }}
                    ></ButtonWrap>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </div>

      {review && commentVisible && (
        <PlanCommentForm
          id={control._formValues.id}
          commentVisible={commentVisible}
          setCommentVisible={setCommentVisible}
          setHasNewCommentFlag={setHasNewCommentFlag}
        />
      )}
      {confirmState && (
        <Popup
          unVisible={onCancel}
          title={planMessages.titleConfirm}
          actions={onConfirm}
          width="630px"
          text={planMessages.textChangeBuildingConfirm}
          btn1={confirmMessages.yes}
          btn2={confirmMessages.no}
        />
      )}
    </>
  );
};

export default memo(PlanForm);
