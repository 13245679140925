import { Col, Form, Input, notification, Row, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import userApi from '../../../api/userApi';
import { ErrorMessage, SelectField, TitleContent } from '../../../components/commons';
import ButtonWrap from '../../../components/commons/ButtonWrap';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import PhoneIcon from '../../../components/commons/PhoneIcon';
import { appRoutes } from '../../../router/routes';
import { addUserMessages, generalMessages, userMgmtMessages } from '../../../utils/messages';
import { regex } from '../../../utils/regex';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import InputFieldCustom from '../../../components/InputFieldCustom';
import { constant } from '../../../utils/constants';
import '../style.scss';
import './style.scss';

interface AdminProps {}

const Admin: FC<AdminProps> = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState({});
  const [list, setList] = useState([]);
  const { isLoading, data, isFetching }: any = useQuery('contracts', userApi.getContracts);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const mutation = useMutation(userApi.add, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['user', 1]).then(() => {
          notification.success({
            message: generalMessages.successMsg,
          });
          navigate(`${appRoutes.ADMIN.LIST}`);
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'user' && query.queryKey[1] !== 1,
        });
        queryClient.invalidateQueries(['contracts']);
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });

  const onSubmit = (data: any) => {
    setError({});
    data.user_type = 1;
    setLoading(true);
    mutation.mutate(data);
  };
  const handleCancel = () => {
    if (!loading) navigate(`${appRoutes.ADMIN.ROOT}`);
  };

  useEffect(() => {
    if (!data) return;
    let keyDisable: any = [];
    (data || []).forEach((i: any) => {
      if (i.status === true) keyDisable.push(i.id);
    });
    setList(keyDisable);
  }, [data]);

  return (
    <>
      {isLoading || loading || isFetching ? (
        <Loading />
      ) : (
        <section className="create-user create-admin">
          <div className="create-user__container">
            <div className="create-user__title">
              <TitleContent
                leftIcon={true}
                title={addUserMessages.titleAdd}
                handleBack={() => navigate(appRoutes.ADMIN.ROOT)}
              />
            </div>
            <div className="create-user__form">
              <Form className="create-user__form__wrapper">
                <Row className="create-user__form__input" gutter={[24, 0]}>
                  <Col xs={24} lg={12} className="create-user__form__input__left">
                    <Row className="create-user__form__item">
                      <Controller
                        name="contract_id"
                        control={control}
                        rules={{
                          required: addUserMessages.requiredContract,
                        }}
                        render={({ field }) => (
                          <SelectField
                            placeholder={generalMessages.pleaseSelect}
                            help={<ErrorMessage message={errors.contract_id?.message ?? error['contract_id']} />}
                            props={field}
                            error={errors.contract_id || error['contract_id']}
                            label={
                              <>
                                {addUserMessages.contract}
                                <RequiredLabel />
                              </>
                            }
                            options={data?.map((i: { id: number; contract_code: string }) => ({
                              key: i.id,
                              value: (
                                <Tooltip placement="topLeft" title={i.contract_code}>
                                  <div className="deleted-selection-wrap">
                                    <span className="deleted-selection-wrap__content">{`${i.contract_code}`}</span>
                                  </div>
                                </Tooltip>
                              ),
                            }))}
                            isSearch={true}
                            disableOption={list}
                            autoFocus={true}
                            isElementValue
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="username"
                        rules={{
                          required: addUserMessages.requiredUsername,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredUsername;
                            }
                            if (regex.validateUsername.test(value?.trim()) == false) {
                              return addUserMessages.validateUsername;
                            }
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtUsername}
                            label={
                              <>
                                {addUserMessages.username}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['username']}
                            msgValidate={errors?.username?.message}
                            onBlur={(e: any) => setValue('username', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="full_name"
                        rules={{
                          required: addUserMessages.requiredFullname,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredFullname;
                            }
                            if (regex.validateName.test(value?.trim()) == false) {
                              return addUserMessages.validateFullname;
                            }
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtFullname}
                            label={
                              <>
                                {addUserMessages.fullname}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['full_name']}
                            msgValidate={errors?.full_name?.message}
                            onBlur={(e: any) => setValue('full_name', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="full_name_katakana"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validateName.test(value?.trim()) == false) {
                              return addUserMessages.validateFullnameKatakana;
                            }
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtFullnameKatakana}
                            label={addUserMessages.fullnameKatakana}
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['full_name_katakana']}
                            msgValidate={errors?.full_name_katakana?.message}
                            onBlur={(e: any) => setValue('full_name_katakana', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>

                    <Row className="create-user__form__item">
                      <Controller
                        name="email"
                        rules={{
                          required: addUserMessages.requiredEmail,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredEmail;
                            }
                            if (regex.validateEmail.test(value?.trim()) == false) {
                              return addUserMessages.validateEmail;
                            }
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtEmail}
                            label={
                              <>
                                {addUserMessages.email}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['email']}
                            msgValidate={errors?.email?.message}
                            onBlur={(e: any) => setValue('email', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} lg={12} className="create-user__form__input__right">
                    <Row className="create-user__form__item">
                      <Controller
                        name="address_1"
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={addUserMessages.address1}
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['address_1']}
                            msgValidate={errors.address_1?.message}
                            onBlur={(e: any) => setValue('address_1', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="address_2"
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={addUserMessages.address2}
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['address_2']}
                            msgValidate={errors?.address_2?.message}
                            onBlur={(e: any) => setValue('address_2', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="postal_code"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validatePostalCode.test(value?.trim()) == false) {
                              return addUserMessages.validatePostalCode;
                            }
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtPostalCode}
                            label={addUserMessages.postalCode}
                            placeholder={addUserMessages.placeholder}
                            msgServer={error['postal_code']}
                            msgValidate={errors?.postal_code?.message}
                            onBlur={(e: any) => setValue('postal_code', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <InputFieldCustom
                        label={addUserMessages.userType}
                        defaultValue={userMgmtMessages.typeManageContract}
                        disable={true}
                      />
                    </Row>
                    <div className="create-user__form__item">
                      <Row className="holder" gutter={[24, 10]}>
                        <Col xs={24} lg={14} className="holder__email">
                          <Controller
                            name="number_phone"
                            rules={{
                              validate: (value: string) => {
                                if (!!value && regex.validatePhone.test(value?.trim()) == false) {
                                  return addUserMessages.validatePhoneNumber;
                                }
                              },
                            }}
                            control={control}
                            render={({ field }) => (
                              <Form.Item label={addUserMessages.phoneNumber} className="holder__phone" colon={false}>
                                <Input.Group compact>
                                  <PhoneIcon />
                                  <div className="input-field__phone">
                                    <InputFieldCustom
                                      msgServer={error['number_phone']}
                                      msgValidate={errors?.number_phone?.message}
                                      placeholder={addUserMessages.placeholder}
                                      props={field}
                                      maxLength={constant.maxLenghtPhone}
                                      onBlur={(e: any) => setValue('number_phone', e.target.value.trim())}
                                    />
                                  </div>
                                </Input.Group>
                              </Form.Item>
                            )}
                          />
                        </Col>
                        <Col xs={24} lg={10} className="holder__phone__wrapper">
                          <Controller
                            name="status"
                            control={control}
                            rules={{
                              required: addUserMessages.requiredStatus,
                            }}
                            render={({ field }) => (
                              <SelectField
                                placeholder={generalMessages.pleaseSelect}
                                help={<ErrorMessage message={error['status'] ?? errors.status?.message} />}
                                props={field}
                                label={
                                  <>
                                    {addUserMessages.status}
                                    <RequiredLabel />
                                  </>
                                }
                                options={[
                                  {
                                    key: '0',
                                    value: (
                                      <Tooltip placement="topLeft" title={userMgmtMessages.invalid}>
                                        <div className="deleted-selection-wrap">
                                          <span className="deleted-selection-wrap__content">{`${userMgmtMessages.invalid}`}</span>
                                        </div>
                                      </Tooltip>
                                    ),
                                  },
                                  {
                                    key: '1',
                                    value: (
                                      <Tooltip placement="topLeft" title={userMgmtMessages.valid}>
                                        <div className="deleted-selection-wrap">
                                          <span className="deleted-selection-wrap__content">{`${userMgmtMessages.valid}`}</span>
                                        </div>
                                      </Tooltip>
                                    ),
                                  },
                                ]}
                                error={errors.status || error['status']}
                                isElementValue
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <div className="create-user__form__button">
                  <div className="button-wrapper">
                    <ButtonWrap
                      type="default"
                      color="#49ABB1"
                      backgroundColor="#1C282F"
                      border="1px solid #247E84"
                      text={addUserMessages.cancel}
                      width="100%"
                      height="57px"
                      onClick={handleCancel}
                    />
                    <ButtonWrap
                      type="primary"
                      htmlType="submit"
                      text={addUserMessages.done}
                      width="100%"
                      height="57px"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Admin;
