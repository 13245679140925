import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import documentApi from '../../../api/documentApi';
import { FileTextIcon } from '../../../assets/icons/FileTextIcon';
import { ImageIcon } from '../../../assets/icons/ImageIcon';
import { configs } from '../../../configs';
import { masterMessages } from '../../../utils/messages';
import Popup from '../../commons/Popup';

interface UploadItemProps {
  multiple: any;
  checkTypeImg(file: any): boolean;
  file: any;
  deleteFile(id: any): void;
  handleDeleteFile(id: any): void;
}

const UploadItem: FC<UploadItemProps> = ({ handleDeleteFile, multiple, deleteFile, checkTypeImg, file }) => {
  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingPreview } = useQuery(
    ['file', file.uuid],
    () => documentApi.download(file.type, configs.domain + file.url_preview, file.uuid, file.filename, true),
    {
      enabled: !queryClient.getQueryData(['file', file?.uuid]) && !!file.uuid && !!file.uid && checkTypeImg(file),
      staleTime: Infinity,
    }
  );
  let style;
  const checkFile = (file: any) => Object.keys(file).length <= 6;
  const background = 'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(';
  if (!multiple && file?.filename === '' && file?.base64) {
    style = {
      backgroundImage: `${background}'data:image/png;base64,${file.base64}')`,
    };
  } else if (checkFile(file) && checkTypeImg(file))
    style = {
      backgroundImage: `${background}'${queryClient.getQueryData(['file', file?.filename]) ?? data}')`,
    };
  else if (!checkTypeImg(file))
    style = {
      background: '#4F4F4F',
    };
  else
    style = {
      backgroundImage: `${background}'${file.originFileObj.urlImg}')`,
    };
  const handleName = (fileName: string) => fileName?.substring(0, fileName.lastIndexOf('.'));
  return (
    <>
      <div className="img-list">
        <Spin spinning={checkFile(file) ? isLoadingPreview : false}>
          <div className="img-list__item">
            <div className="img-list__item__content" style={style}>
              {multiple ? (
                <>
                  {checkFile(file) ? (
                    <>
                      <div className="file__content">
                        <div className="file__content__icon">
                          {checkTypeImg(file) ? <ImageIcon /> : <FileTextIcon />}
                        </div>
                        <div className="file__content__name">
                          <p>{handleName(file?.filename)}</p>
                          <div>.{file?.filename?.split('.')?.reverse()[0]}</div>
                        </div>
                      </div>
                      <div className="close__wrapper">
                        <CloseOutlined onClick={() => handleDeleteFile(file?.uuid)} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="file__content">
                        <div className="file__content__icon">
                          {checkTypeImg(file) ? <ImageIcon /> : <FileTextIcon />}
                        </div>
                        <div className="file__content__name">
                          <p>{handleName(file?.name)}</p>
                          <div>.{file?.name.split('.').reverse()[0]}</div>
                        </div>
                      </div>
                      <div className="close__wrapper">
                        <CloseOutlined onClick={() => deleteFile(file?.uid)} />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="close__wrapper">
                    <CloseOutlined onClick={() => deleteFile(file?.uid)} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default UploadItem;
