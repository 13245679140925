import { Form } from 'antd';
import { TimePicker } from 'antd';
import React, { FC, useEffect, useState, useRef } from 'react';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import { ClockCircleOutlined } from '@ant-design/icons';
import './style.scss';

interface ITimePickerItem {
  className?: string;
  label: string | any;
  required?: boolean;
  message?: string;
  format?: string;
  defaultValue?: string;
  help?: React.ReactNode;
  disable?: boolean;
  placeholder?: string;
  props?: any;
  isError?: boolean;
  handleBlur?: any;
  handleSelect?: (time: any, moment: any) => void;
}

const TimePickerItem: FC<ITimePickerItem> = ({
  className,
  label,
  required,
  message,
  format = 'HH:mm:ss',
  disable,
  help,
  placeholder,
  props,
  isError,
  handleSelect,
  handleBlur,
}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const timePickerRef = useRef<any>(null);

  return (
    <div ref={timePickerRef}>
      <Form.Item label={label} rules={[{ required, message }]} help={help}>
        <TimePicker
          format={format}
          disabled={disable}
          className={className}
          style={
            isError
              ? {
                  border: '1px solid #EB5757',
                  boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
                  width: '100%',
                }
              : { width: '100%' }
          }
          placeholder={placeholder}
          clearIcon={<></>}
          onSelect={(time: any, moment: any) => (handleSelect ? handleSelect(time, moment) : null)}
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              now: planMessages.nowTimePicker,
              ok: planMessages.okTimePicker,
            },
          }}
          popupStyle={{ display: isOpen ? '' : 'none' }}
          suffixIcon={
            <ClockCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
            />
          }
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            return;
          }}
          onBlur={(e: any) => {
            handleBlur(e);
            setIsOpen(false);
          }}
          onOpenChange={(open: boolean) => {
            if (!open) {
              timePickerRef?.current?.getElementsByTagName('input')[0].blur();
              setIsOpen(false);
            }
          }}
          {...props}
        />
      </Form.Item>
    </div>
  );
};

export default TimePickerItem;
