export const analysisMessages = {
  title: '集計',
  labelperiodical: '定期',
  labelDaily: '日常',
  titlePlan: '点検計画',
  titleBuilding: '建物',
  building1: '建物１',
  building2: '建物2',
  building3: '建物3',
  colorBuilding1: '#F2994A',
  colorBuilding2: '#6FCF97',
  colorBuilding3: '#2D9CDB',
  colorBuilding4: '#FF4154',
  colorLegend: '#E0E0E0',
  positionLegend: 'bottom',
  implementPlan: '実施ユーザー',
  time: '期間',
  placeholer: 'すべて',
  tooltipBackgroundColor: '#222E35',
  tooltipBorderColor: '#4F4F4F',
  tooltipTexColor: '#E0E0E0',
  tooltipFontStyle: 'normal',
  tooltipFontFamily: 'Inter',
  colorStickScale: '#BDBDBD',
  total: '合計',
  deleted: '削除',
  invalidStatus: '無効',
  errMonthMsg: '終了月は開始月以降に指定してください。',
  limitMonthMsg: '期間を12ヶ月以内で指定してください。',
};
