export const facilityDto = (data: any) => {
  if (!data) return [];
  const result = data
    ?.filter((i: any) => !i.building_facility_delete)
    .map((item: any) => {
      return {
        ...item,
        name: item.specical_name,
        facility_id: item.building_facility_id,
      };
    });
  return result;
};
export const buildingItemDto = (data: any) => {
  if (!data) return [];
  const result = data
    ?.filter((i: any) => !i.item_of_building_delete)
    .map((item: any) => {
      return {
        ...item,
        name: item.specical_name,
        building_item_id: item.item_of_building_id,
      };
    });
  return result;
};

export const updateBuildingDto = (submitArr: any, defaultArr: any, key: any) => {
  if (!submitArr) return [];

  const result = submitArr.map((item: any, index: any) => {
    const foundItem = defaultArr.find((each: any, i: any) => {
      return item[key] === each[key];
    });
    if (foundItem) {
      if (foundItem.id !== item.id) {
        return {
          id: item.id,
          name: item.name,
        };
      }
    }
    return {
      id: item.id,
      [key]: item[key],
      name: item.name,
    };
  });
  return result;
};
