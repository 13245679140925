import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC } from 'react';
import { generalMessages } from '../../utils/messages';
import './style.scss';

interface SeachFieldProps {
  value: any;
  onChange: (e: any) => void;
  handleFilter: () => void;
}

const SearchField: FC<SeachFieldProps> = ({ value, onChange, handleFilter }) => {
  return (
    <div className="list__filter">
      <Input
        className="filter__wrapper"
        placeholder={generalMessages.pleaseSelect}
        onKeyDown={(e: any) => {
          if (e.keyCode == 13) handleFilter();
        }}
        value={value}
        onChange={onChange}
        suffix={<SearchOutlined onClick={() => handleFilter()} />}
      />
    </div>
  );
};

export default SearchField;
