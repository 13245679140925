import { LeftOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row, Spin } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import authApi from '../../api/authApi';
import { LogoIcon } from '../../assets/icons';
import { ButtonWrap, ErrorMessage, InputField } from '../../components/commons';
import { forgotPassMessages, generalMessages, typeFileMsg } from '../../utils/messages';
import { regex } from '../../utils/regex';
import './style.scss';

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  const { error, mutateAsync, data }: any = useMutation('forgot-password', authApi.forgotPassword);
  const [forgotForm] = Form.useForm();
  const [isSubmit, setIsSubmit] = useState(false);
  const [emailError, setEmailError] = useState({ enable: false, helpText: forgotPassMessages.requireAccount });
  const emailErrorRef = useRef(emailError);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // validate email field
  useEffect(() => {
    emailErrorRef.current = emailError;
    if (emailError.enable) {
      forgotForm.validateFields();
    }
  }, [emailError]);

  // update message error
  useEffect(() => {
    if (error || data?.success === false) {
      setLoading(false);
      setEmailError({ enable: true, helpText: error.response?.data?.message || data?.message });
      notification.error({
        message: generalMessages.error,
      });
    }
    if (data?.success) {
      setLoading(false);
      notification.success({
        message: data.data,
      });
      forgotForm.resetFields();
      setIsSubmit(false);
      setEmailError({ enable: false, helpText: forgotPassMessages.requireAccount });
    }
  }, [error || data?.success]);

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="forgot-password">
        <div className="forgot-password__header">
          <div className="forgot-password__header__background-first"></div>
          <div className="forgot-password__header__background-second"></div>
          <div className="forgot-password__header__image-logo">
            <LogoIcon />
          </div>
          <h1>{forgotPassMessages.title}</h1>
        </div>
        <div className="form-block">
          <Form
            name="basic"
            layout="vertical"
            onFinish={async (value) => {
              setIsSubmit(true);
              if (emailError.helpText === '') {
                setLoading(true);
                await mutateAsync(value);
              }
            }}
            onChange={(props: any) => {
              if (!props.target.value) {
                setEmailError({ enable: false, helpText: forgotPassMessages.requireAccount });
                return;
              }
              if (!regex.validateEmail.test(props.target.value?.trim())) {
                setEmailError({ enable: emailError.enable, helpText: forgotPassMessages.validateEmail });
                return;
              }
              if (regex.validateEmail.test(props.target.value?.trim())) {
                setEmailError({ enable: emailError.enable, helpText: '' });
                return;
              }
            }}
            form={forgotForm}
            autoComplete="off">
            <Row>
              <h4>{forgotPassMessages.label}</h4>
            </Row>
            <Row className="form-block__row-input">
              <Col span={24}>
                <InputField
                  help={<ErrorMessage message={isSubmit && emailError.helpText !== '' ? emailError.helpText : ''} />}
                  label={forgotPassMessages.lbEmail}
                  name={'email'}
                  placeholder={forgotPassMessages.placeholderAccount}
                  state={isSubmit && emailError.helpText !== '' ? typeFileMsg.error : ''}
                  autoFocus={true}
                  disable={loading}
                />
              </Col>
            </Row>
            <Row className="form-block__row-button">
              <Col span={24}>
                <ButtonWrap
                  type="primary"
                  htmlType="submit"
                  text={forgotPassMessages.btnGetPassword}
                  width={'100%'}
                  height="57px"
                  loading={loading}
                />
              </Col>
              <Col span={24}>
                <ButtonWrap
                  type="primary"
                  text={forgotPassMessages.back}
                  width={'100%'}
                  height="57px"
                  border="1px solid #247E84"
                  color="#49ABB1"
                  ghost={true}
                  disabled={loading}
                  iconLeft={<LeftOutlined />}
                  onClick={handleClickBack}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
