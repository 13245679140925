import { DownloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import documentApi from '../../../api/documentApi';
import { configs } from '../../../configs';
import { constant } from '../../../utils/constants';
import { buttonMessages } from '../../../utils/messages';

interface CarouselItemProps {
  preview: any;
  filename: string;
  overallType: string;
  isImg: boolean;
  handleFile: (isImg: boolean) => void;
  typeFile: string;
  url: string;
  uuid: string;
}

const CarouselItem: FC<CarouselItemProps> = ({
  preview,
  filename,
  overallType,
  isImg,
  handleFile,
  typeFile,
  url,
  uuid,
}) => {
  const { data, isLoading } = useQuery(
    ['file', uuid],
    () => documentApi.download(typeFile, configs.domain + preview, uuid, filename, true),
    {
      enabled:
        !!uuid &&
        !!uuid &&
        !!preview &&
        !!typeFile &&
        typeFile !== constant.file__accept[3] &&
        typeFile !== constant.file__accept[4],
      staleTime: Infinity,
    }
  );

  const [isDownload, setDownload] = useState(false);
  const handleDownload = () => {
    setDownload(true);
  };
  const download: any = useQuery(
    ['file__download', uuid],
    () => documentApi.download(typeFile, configs.domain + url, uuid, filename, false),
    {
      enabled: !!isDownload,
      staleTime: Infinity,
    }
  );
  const fileName = `${filename || ''}`.substring(0, `${filename || ''}`.lastIndexOf('.'));
  return (
    <>
      <Spin spinning={isLoading || download.isLoading}>
        <div className={`file-item ${overallType}`}>
          <div
            className="file-item_content"
            style={
              isImg
                ? {
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url('${data}')`,
                  }
                : {}
            }
          >
            <>{handleFile(isImg)}</>
            <div className="file__content">
              <div className="file__content__top">{fileName}</div>
              <div className="file__content__bottom">.{filename?.split('.')?.reverse()[0]}</div>
            </div>
          </div>
          {download?.data ? (
            <div className="file-overlay">
              <a className="file-overlay_content" href={download.data} download={filename}>
                <DownloadOutlined />
                <p>{buttonMessages.download}</p>
              </a>
            </div>
          ) : (
            <div className="file-overlay" onClick={handleDownload}>
              <div className="file-overlay_content">
                <DownloadOutlined />
                <p>{buttonMessages.download}</p>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};

export default CarouselItem;
