import axiosClient from './axiosClient';
import { download } from './common/download';
const documentApi = {
  get(filename: string): Promise<any> {
    const url = `${filename}`;
    return axiosClient.get(url);
  },
  async download(typeFile: string, url: string, uuid?: string, filename?: string, preview?: boolean): Promise<any> {
    const headers: any = { responseType: 'arraybuffer' };
    const response: any = await axiosClient.get(url + '?fileName=' + uuid, headers);
    const result = 'data:' + typeFile + ';base64,' + Buffer.from(response, 'binary').toString('base64');
    if (!preview) download(result, `${filename}`);
    return result;
  },
};

export default documentApi;
