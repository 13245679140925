import { Pagination, PaginationProps } from 'antd';
import { FC, useState } from 'react';
import './style.scss';

interface PaginationWrapperProps {
  current: number;
  total: number;
  pageSize: number;
  setCurrent: (page: number) => void;
}

const PaginationWrapper: FC<PaginationWrapperProps> = ({ current, total, pageSize, setCurrent }) => {
  const [currentPage, setCurrentPage] = useState(current);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
    setCurrent(page);
  };

  return (
    <div className="pagination-wrapper">
      <Pagination
        className="pagination"
        current={currentPage}
        defaultPageSize={pageSize}
        total={total}
        onChange={onChange}
        showSizeChanger={false}
        showLessItems={true}
      />
    </div>
  );
};

export default PaginationWrapper;
