import { AimOutlined, CloseOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Modal, notification, Row, Tooltip } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import buildingApi from '../../../api/buildingApi';
import CarouselCustom from '../../../components/CarouselCustom';
import { ButtonWrap, ErrorMessage, InputField, SelectField, TitleContent } from '../../../components/commons';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputFieldCustom from '../../../components/InputFieldCustom';
import UploadWrapper from '../../../components/Upload';
import { appRoutes } from '../../../router/routes';
import {
  addUserMessages,
  masterMessages,
  generalMessages,
  buildingMasterMessages,
  japanesePlanMessages as planMessages,
} from '../../../utils/messages';
import { regex } from '../../../utils/regex';
import { constant } from '../../../utils/constants';
import './style.scss';
import GoogleMap from '../../../components/commons/GoogleMap';
import { buildingItemDto, facilityDto, updateBuildingDto } from './buildingDtoForm';
import listApi from '../../../api/listApi';

interface EditBuildingMasterProps {}

const EditBuildingMaster: FC<EditBuildingMasterProps> = (props) => {
  const prevCoor = useRef('');
  const queryClient = useQueryClient();
  const location: any = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitted },
    control,
    setValue,
    reset,
    getValues,
    watch,
  } = useForm();

  const { fields, remove, append, insert, update } = useFieldArray({
    control,
    name: 'facilities',
  });

  const {
    fields: buildingItemFields,
    remove: removeBuildingItem,
    append: appendBuildingItem,
    insert: insertBuildingItem,
    update: updateBuildingItem,
  } = useFieldArray({
    control,
    name: 'building_items',
  });

  const [err, setError]: any = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [facilitiesState, setFacilitiesState] = useState<Array<any>>();
  const [buildingItemsState, setBuildingItemsState] = useState<Array<any>>();

  const { isLoading, isError, data }: any = useQuery(['building_detail', id], () => buildingApi.get(id), {
    onSuccess: (data: any) => {
      if (data?.data?.lat) {
        prevCoor.current = data?.data?.lat + ',' + data?.data?.lng;
        setValue('coordinates', data?.data?.lat + ',' + data?.data?.lng);
      }
      setValue(
        'facilities',
        facilityDto(data?.data?.facilities).length === 0
          ? [{ id: null, name: null }]
          : facilityDto(data?.data?.facilities)
      );
      setValue(
        'building_items',
        buildingItemDto(data?.data?.building_items).length === 0
          ? [{ id: null, name: null }]
          : buildingItemDto(data?.data?.building_items)
      );
    },
    onError: (error: any) => {
      if (error && error.response.status === 404) {
        navigate(appRoutes.NOT_FOUND);
      }
    },
  });

  const fac_buildingItem: any = useQuery('list-fac-buildingItems', listApi.getFacBuildingItem);

  useEffect(() => {
    setFacilitiesState(fac_buildingItem?.data?.data?.facility.filter((i: any) => !i.deleted_at));
    setBuildingItemsState(fac_buildingItem?.data?.data?.buildingItem.filter((i: any) => !i.deleted_at));
  }, [fac_buildingItem.isFetching]);

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const [resetState, setState] = useState(false);
  const [display, setDisplay] = useState('none');
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(location?.state?.view ?? true);
  const [showMap, setShowMap] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mutation = useMutation(buildingApi.edit, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([
          queryClient.invalidateQueries(['building_detail', id]),
          queryClient.invalidateQueries(['building', 1]),
        ]);
        if (!location.state?.view) {
          navigate(`${appRoutes.MASTER.BUILDING.ROOT}`);
          notification.success({
            message: generalMessages.successMsg,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'building' && query.queryKey[1] !== 1,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'plan',
          });
          return;
        }
        setDisplay('none');
        setDisable(true);
        setEdit('flex');
        setState(true);
        setLoading(false);
        notification.success({
          message: generalMessages.successMsg,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'building' && query.queryKey[1] !== 1,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'plan',
        });
      }
    },
    onError: (error: any) => {
      HandleError(error);
      setLoading(false);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });

  const handleEdit = () => {
    setDisplay('flex');
    setDisable(false);
    setEdit('none');
    if (errors) setState(false);
  };
  const handleCancel = () => {
    if (!location.state?.view) navigate(`${appRoutes.MASTER.BUILDING.ROOT}`);
    else {
      reset({
        ...data?.data,
        facilities:
          facilityDto(data?.data?.facilities).length === 0
            ? [{ id: null, name: null }]
            : facilityDto(data?.data?.facilities),
        building_items:
          buildingItemDto(data?.data?.building_items).length === 0
            ? [{ id: null, name: null }]
            : buildingItemDto(data?.data?.building_items),
      });

      setDisplay('none');
      setDisable(true);
      setEdit('flex');
      setState(true);
      setError({});
    }
  };
  const onSubmit = (dataForm: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.set('name', dataForm.name);
    formData.set('building_code', dataForm.building_code);
    formData.set('name_katakana', dataForm.name_katakana ? dataForm.name_katakana : '');
    formData.set('postal_code', dataForm.postal_code ? dataForm.postal_code : '');
    formData.set('address_1', dataForm.address_1);
    formData.set('address_2', dataForm.address_2 ? dataForm.address_2 : '');
    if (dataForm.coordinates) {
      formData.set('lat', dataForm.coordinates.split(',')[0]);
      formData.set('lng', dataForm.coordinates.split(',')[1]);
    } else {
      formData.set('lat', '');
      formData.set('lng', '');
    }
    dataForm.files?.map((file: any) => {
      if (Object.keys(file).includes('urlImg')) formData.append('files[]', file.originFileObj);
    });
    if (dataForm.files?.length === 0) {
      data?.data?.documents?.map((file: any) => formData.append('fileRemoves[]', file.uuid));
    }
    dataForm.removeFiles?.map((remove_file: any) => {
      formData.append('fileRemoves[]', remove_file);
    });

    if (dataForm.facilities) {
      if (!(fields.length === 1 && !getValues(`facilities.${0}.name`) && !getValues(`facilities.${0}.id`))) {
        formData.append(
          'facilities',
          JSON.stringify(updateBuildingDto(dataForm.facilities, data?.data.facilities, 'building_facility_id'))
        );
      } else {
        formData.append('facilities', JSON.stringify([]));
      }
    }
    if (dataForm.building_items) {
      if (
        !(
          buildingItemFields.length === 1 &&
          !getValues(`building_items.${0}.name`) &&
          !getValues(`building_items.${0}.id`)
        )
      ) {
        formData.append(
          'building_items',
          JSON.stringify(updateBuildingDto(dataForm.building_items, data?.data?.building_items, 'item_of_building_id'))
        );
      } else {
        formData.append('building_items', JSON.stringify([]));
      }
    }

    if (id) mutation.mutate({ id, data: formData });
  };
  const files = data?.data?.documents?.map((i: any) => i.base64);
  const documents = data?.data?.documents;
  useEffect(() => {
    if (!location.state?.view) handleEdit();
  }, []);

  const validateFacility = (index: any) => {
    if (getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      return true;
    } else {
      if (!getValues(`facilities.${index}.id`) && getValues(`facilities.${index}.name`)) {
        return false;
      }
    }
  };

  const checkExistNickName = (index: any, array: any, purpose: string) => {
    let isExist = false;
    switch (purpose) {
      case 'facility':
        isExist = array.some((item: any, i: number) => {
          return item.name === getValues(`facilities.${index}.name`) && i !== index;
        });
        break;

      case 'building_item': {
        isExist = array.some((item: any, i: number) => {
          return item.name === getValues(`building_items.${index}.name`) && i !== index;
        });
        break;
      }
      default:
        break;
    }

    return isExist;
  };

  const validateFacilityNickName = (index: any) => {
    if (getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      return !checkExistNickName(index, control._formValues.facilities, 'facility');
    } else {
      if (!getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
        return false;
      }

      if (getValues(`facilities.${index}.name`) && !getValues(`facilities.${index}.id`)) {
        return !checkExistNickName(index, control._formValues.facilities, 'facility');
      }
    }
  };

  const validateBDItem = (index: any) => {
    if (getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
      return true;
    } else {
      if (!getValues(`building_items.${index}.id`) && getValues(`building_items.${index}.name`)) {
        return false;
      }
    }
  };

  const renderMessFacNick = (index: any) => {
    if (!getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      if (!(errors?.facilities as any)?.[index]?.name?.type) {
        return '';
      }

      return buildingMasterMessages.facilityNickNameRequired;
    } else {
      return (errors?.facilities as any)?.[index]?.name?.type === 'required'
        ? buildingMasterMessages.facilityNickNameRequired
        : (errors?.facilities as any)?.[index]?.name?.type === 'validate'
        ? buildingMasterMessages.existFacilityNickname
        : '';
    }
  };
  const renderMessBDItemNick = (index: any) => {
    if (!getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`) && isSubmitted) {
      if (!(errors?.building_items as any)?.[index]?.name?.type) {
        return '';
      }

      return buildingMasterMessages.buildingNicknameRequired;
    } else {
      return (errors?.building_items as any)?.[index]?.name?.type === 'required'
        ? buildingMasterMessages.buildingNicknameRequired
        : (errors?.building_items as any)?.[index]?.name?.type === 'validate'
        ? buildingMasterMessages.existBuildingNickname
        : '';
    }
  };

  const handleAddRow = (type: string) => {
    const newRow = {
      id: null,
      name: null,
    };

    switch (type) {
      case 'facility': {
        append(newRow);
        break;
      }
      case 'building_item': {
        appendBuildingItem(newRow);
        break;
      }
      default:
        break;
    }
  };

  const onRemoveFacilityRow = (index: any) => {
    if (
      index === 0 &&
      fields.length === 1 &&
      (getValues(`facilities.${index}.name`) || getValues(`facilities.${index}.id`))
    ) {
      update(0, {
        id: null,
        name: null,
      });
      clearErrors(`facilities.${index}.id`);
      clearErrors(`facilities.${index}.name`);
    } else {
      if (fields.length > 1) {
        remove(index);
      }
    }
  };

  const onRemoveBuildingRow = (index: any) => {
    if (
      index === 0 &&
      buildingItemFields.length === 1 &&
      (getValues(`building_items.${index}.name`) || getValues(`building_items.${index}.id`))
    ) {
      updateBuildingItem(0, {
        id: null,
        name: null,
      });
      clearErrors(`building_items.${index}.id`);
      clearErrors(`building_items.${index}.name`);
    } else {
      if (buildingItemFields.length > 1) {
        removeBuildingItem(index);
      }
    }
  };

  const validateBDItemNickName = (index: any) => {
    if (getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
      return checkExistNickName(index, control._formValues.building_items, 'building_item') ? false : true;
    } else {
      if (!getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
        return false;
      }

      if (getValues(`building_items.${index}.name`) && !getValues(`building_items.${index}.id`)) {
        return checkExistNickName(index, control._formValues.building_items, 'building_item') ? false : true;
      }
    }
  };

  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="edit-building-master">
          <div className="edit-building-master__container">
            <div className="edit-building-master__title">
              <TitleContent
                leftIcon={true}
                title={buildingMasterMessages.editTitle}
                handleBack={() => navigate(`${appRoutes.MASTER.BUILDING.ROOT}`)}
              />
            </div>
            <div className="edit-building-master__form__container">
              <div className="edit-building-master__form">
                <Form className="edit-building-master__form__wrapper">
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="building_code"
                        rules={{
                          required: buildingMasterMessages.requireBuildingCode,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return buildingMasterMessages.requireBuildingCode;
                            }
                          },
                        }}
                        defaultValue={data?.data?.building_code}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxBuildingCode}
                            label={
                              <>
                                {buildingMasterMessages.buildingCode}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['building_code']}
                            msgValidate={!resetState && errors?.building_code?.message}
                            onBlur={(e: any) => setValue('building_code', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="name"
                        rules={{
                          required: buildingMasterMessages.requireName,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return buildingMasterMessages.requireName;
                            }
                            if (regex.validateNameDevice.test(value?.trim()) == false) {
                              return buildingMasterMessages.validateName;
                            }
                          },
                        }}
                        defaultValue={data?.data?.name}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxName}
                            label={
                              <>
                                {buildingMasterMessages.name}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['name']}
                            msgValidate={!resetState && errors?.name?.message}
                            onBlur={(e: any) => setValue('name', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="name_katakana"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validateNameDevice.test(value?.trim()) == false) {
                              return buildingMasterMessages.validateNameKatakana;
                            }
                          },
                        }}
                        defaultValue={data?.data?.name_katakana}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxNameKatakana}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            label={buildingMasterMessages.nameKatakana}
                            disable={disable}
                            msgServer={!resetState && err['name_katakana']}
                            msgValidate={!resetState && errors?.name_katakana?.message}
                            onBlur={(e: any) => setValue('name_katakana', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="postal_code"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validatePostalCode.test(value?.trim()) == false) {
                              return addUserMessages.validatePostalCode;
                            }
                          },
                        }}
                        defaultValue={data?.data?.postal_code}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtPostalCode}
                            label={addUserMessages.postalCode}
                            disable={disable}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['postal_code']}
                            msgValidate={!resetState && errors?.postal_code?.message}
                            onBlur={(e: any) => setValue('postal_code', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="address_1"
                        rules={{
                          required: addUserMessages.requiredAddress1,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredAddress1;
                            }
                          },
                        }}
                        defaultValue={data?.data?.address_1}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={
                              <>
                                {buildingMasterMessages.address1}
                                <RequiredLabel />
                              </>
                            }
                            disable={disable}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['address_1']}
                            msgValidate={!resetState && errors?.address_1?.message}
                            onBlur={(e: any) => setValue('address_1', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="address_2"
                        defaultValue={data?.data?.address_2}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={buildingMasterMessages.address2}
                            disable={disable}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['address_2']}
                            msgValidate={!resetState && errors?.address_2?.message}
                            onBlur={(e: any) => setValue('address_2', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Row className="edit-building-master__form__item facility__container">
                    <Col span={24}>
                      {fields.map((field, index: number) => {
                        return (
                          <Row
                            gutter={[20, 10]}
                            className="facility__row facility_selection"
                            key={field.id}
                            style={{ display: 'flex', marginBottom: 8 }}
                          >
                            <Col span={11}>
                              <Controller
                                name={`facilities.${index}.name` as const}
                                rules={{
                                  required: fields.length > 1,
                                  validate: () => validateFacilityNickName(index),
                                }}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <InputField
                                      help={<ErrorMessage message={renderMessFacNick(index)} />}
                                      className={(errors?.facilities as any)?.[index]?.name?.type ? 'border-error' : ''}
                                      label={
                                        index === 0 && (
                                          <>
                                            <span>{buildingMasterMessages.facilityNicknameLabel}</span>
                                          </>
                                        )
                                      }
                                      placeholder={addUserMessages.placeholder}
                                      disable={disable || getValues(`facilities.${index}.hasPlan`)}
                                      props={{
                                        ...field,
                                        value: field.value,
                                      }}
                                      onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                                    />
                                  );
                                }}
                              />
                            </Col>

                            <Col span={11}>
                              <Controller
                                name={`facilities.${index}.id` as const}
                                rules={{
                                  required: fields.length > 1,
                                  validate: () => validateFacility(index),
                                }}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <>
                                      <SelectField
                                        help={
                                          <ErrorMessage
                                            message={
                                              (errors?.facilities as any)?.[index]?.id?.type
                                                ? buildingMasterMessages.facilityIdRequired
                                                : ''
                                            }
                                          />
                                        }
                                        defaultValue={
                                          facilitiesState?.some((each: any) => each.id === field.value)
                                            ? field.value
                                            : null
                                        }
                                        options={
                                          facilitiesState
                                            ? facilitiesState.map((each: any) => {
                                                return {
                                                  key: each.id,
                                                  value: (
                                                    <Tooltip placement="topLeft" title={each.name}>
                                                      <div className="deleted-selection-wrap">
                                                        <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                                      </div>
                                                    </Tooltip>
                                                  ),
                                                };
                                              })
                                            : []
                                        }
                                        disable={disable || getValues(`facilities.${index}.hasPlan`)}
                                        error={(errors?.facilities as any)?.[index]?.id?.type}
                                        placeholder={generalMessages.pleaseSelect}
                                        label={
                                          index === 0 && (
                                            <>
                                              <span>{planMessages.labelFacility}</span>
                                            </>
                                          )
                                        }
                                        props={{ ...field }}
                                        isSearch={true}
                                        isElementValue
                                      ></SelectField>
                                    </>
                                  );
                                }}
                              />
                            </Col>

                            <Col span={2}>
                              <Controller
                                name={`facilities.${index}.hasPlan` as const}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <div
                                      className={`${index === 0 && 'first-close'} ${
                                        disable && 'not_allow'
                                      } close-icon-container`}
                                      style={{ marginTop: '5px' }}
                                    >
                                      <Button
                                        type="text"
                                        onClick={() => {
                                          if (!disable) {
                                            if (field.value) {
                                              notification.error({
                                                message: generalMessages.error,
                                                description: buildingMasterMessages.alreadyAssignInPlan,
                                              });
                                            } else {
                                              onRemoveFacilityRow(index);
                                            }
                                          }
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </div>
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })}

                      <Row>
                        <Col span={24}>
                          <ButtonWrap
                            className={`addValue_btn ${disable && 'not_allow'}`}
                            text={planMessages.addValue}
                            width={'100%'}
                            height="40px"
                            color={`${disable ? '#828282' : '#49ABB1'}`}
                            backgroundColor="transparent"
                            iconLeft={
                              <PlusCircleOutlined
                                style={{
                                  color: 'inherit',
                                }}
                              />
                            }
                            onClick={() => {
                              if (!disable) {
                                handleAddRow('facility');
                              }
                            }}
                          ></ButtonWrap>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Divider style={{ background: '#828282' }} />

                  <Row className="edit-building-master__form__item building__container">
                    <Col span={24}>
                      {buildingItemFields.map((field, index: number) => {
                        return (
                          <Row
                            gutter={[20, 10]}
                            className="buildingItem__row building_item_selection"
                            key={field.id}
                            style={{ display: 'flex', marginBottom: 8 }}
                          >
                            <Col span={11}>
                              <Controller
                                name={`building_items.${index}.name` as const}
                                rules={{
                                  required: buildingItemFields.length > 1,
                                  validate: () => validateBDItemNickName(index),
                                }}
                                control={control}
                                render={({ field }) => (
                                  <InputField
                                    help={<ErrorMessage message={renderMessBDItemNick(index)} />}
                                    className={
                                      (errors?.building_items as any)?.[index]?.name?.type ? 'border-error' : ''
                                    }
                                    label={
                                      index === 0 && (
                                        <>
                                          <span>{buildingMasterMessages.buildingNicknameLabel}</span>
                                        </>
                                      )
                                    }
                                    placeholder={addUserMessages.placeholder}
                                    disable={disable || getValues(`building_items.${index}.hasPlan`)}
                                    props={{
                                      ...field,
                                      value: field.value,
                                    }}
                                    onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                                    autoFocus={false}
                                  />
                                )}
                              />
                            </Col>

                            <Col span={11}>
                              <Controller
                                name={`building_items.${index}.id` as const}
                                rules={{
                                  required: buildingItemFields.length > 1,

                                  validate: () => validateBDItem(index),
                                }}
                                defaultValue=""
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <>
                                      <SelectField
                                        help={
                                          <ErrorMessage
                                            message={
                                              (errors?.building_items as any)?.[index]?.id?.type
                                                ? buildingMasterMessages.buildingIdRequired
                                                : ''
                                            }
                                          />
                                        }
                                        options={
                                          buildingItemsState
                                            ? buildingItemsState.map((each: any) => {
                                                return {
                                                  key: each.id,
                                                  value: (
                                                    <Tooltip placement="topLeft" title={each.name}>
                                                      <div className="deleted-selection-wrap">
                                                        <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                                      </div>
                                                    </Tooltip>
                                                  ),
                                                };
                                              })
                                            : []
                                        }
                                        disable={disable || getValues(`building_items.${index}.hasPlan`)}
                                        error={(errors?.building_items as any)?.[index]?.id?.type}
                                        placeholder={generalMessages.pleaseSelect}
                                        label={
                                          index === 0 && (
                                            <>
                                              <span>{planMessages.labelJobBuildingItem}</span>
                                            </>
                                          )
                                        }
                                        props={field}
                                        isSearch={true}
                                        isElementValue
                                      ></SelectField>
                                    </>
                                  );
                                }}
                              />
                            </Col>

                            <Col span={2}>
                              <Controller
                                name={`building_items.${index}.hasPlan` as const}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <div
                                      className={`${index === 0 && 'first-close'} ${
                                        disable && 'not_allow'
                                      } close-icon-container`}
                                      style={{ marginTop: '5px' }}
                                    >
                                      <Button
                                        type="text"
                                        onClick={() => {
                                          if (!disable) {
                                            if (field.value) {
                                              notification.error({
                                                message: generalMessages.error,
                                                description: buildingMasterMessages.alreadyAssignInPlan,
                                              });
                                            } else {
                                              onRemoveBuildingRow(index);
                                            }
                                          }
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </div>
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })}

                      <Row>
                        <Col span={24}>
                          <ButtonWrap
                            className={`addValue_btn ${disable && 'not_allow'}`}
                            text={planMessages.addValue}
                            width={'100%'}
                            height="40px"
                            color={`${disable ? '#828282' : '#49ABB1'}`}
                            backgroundColor="transparent"
                            iconLeft={
                              <PlusCircleOutlined
                                style={{
                                  color: 'inherit',
                                }}
                              />
                            }
                            onClick={() => {
                              if (!disable) {
                                handleAddRow('building_item');
                              }
                            }}
                          ></ButtonWrap>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="coordinates"
                        control={control}
                        defaultValue={data?.data?.lat ? `${data?.data?.lat},${data?.data?.lng}` : null}
                        rules={{
                          pattern: {
                            value: regex.validateCoordinate,
                            message: buildingMasterMessages.coordinateError,
                          },
                        }}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            placeholder={addUserMessages.placeholder}
                            label={buildingMasterMessages.coordinates}
                            msgServer={!resetState && err['coordinates']}
                            msgValidate={errors?.coordinates?.message}
                            onBlur={(e: any) => {
                              prevCoor.current = e.target.value.trim();
                              setValue('coordinates', e.target.value.trim());
                            }}
                            disable={disable}
                            addonAfter={
                              <Button
                                disabled={disable}
                                style={{ height: 16, marginTop: 0 }}
                                icon={<AimOutlined />}
                                type="link"
                                onClick={() => setShowMap(true)}
                              />
                            }
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      {!disable ? (
                        <>
                          <UploadWrapper
                            label={buildingMasterMessages.document}
                            data={documents}
                            setValues={setValue}
                            multiple={true}
                          />
                          <ErrorMessage message={Object.keys(err).reverse()[0]?.includes('files')} />
                        </>
                      ) : (
                        <Form.Item
                          label={buildingMasterMessages.document}
                          className={files?.length <= 0 ? 'no-document' : ''}
                          colon={false}
                        >
                          {files?.length <= 0 && <span>{masterMessages.noDocumentMsg}</span>}
                          <CarouselCustom data={documents} slidesQuantity={4} />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      {location.state?.view && disable && (
                        <div style={{ display: edit }} className="edit-btn">
                          <ButtonWrap
                            iconLeft={<EditOutlined />}
                            text={buildingMasterMessages.submitBtn}
                            backgroundColor="#1C282F"
                            onClick={handleEdit}
                          />
                        </div>
                      )}

                      <div className="btn-wrapper" style={{ display: display }}>
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #247E84"
                          text={buildingMasterMessages.cancel}
                          width="100%"
                          height="56px"
                          onClick={handleCancel}
                        />
                        <ButtonWrap
                          type="primary"
                          htmlType="submit"
                          text={buildingMasterMessages.done}
                          width="100%"
                          height="56px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Modal
                  visible={showMap}
                  footer={null}
                  onCancel={() => setShowMap(false)}
                  width={700}
                  title={buildingMasterMessages.map}
                  destroyOnClose={true}
                  wrapClassName={isFullScreen ? 'map-full' : ''}
                >
                  <div className={isFullScreen ? 'map-full__map-full-container' : ''}>
                    <GoogleMap
                      height={isFullScreen ? '100%' : '500px'}
                      width="100%"
                      address={getValues('address_1')}
                      postalCode={getValues('postal_code')}
                      lat={watch('coordinates') && Number(watch('coordinates').split(',')[0])}
                      lng={watch('coordinates') && Number(watch('coordinates').split(',')[1])}
                      coordinates={
                        (watch('coordinates') && [
                          {
                            lat: Number(watch('coordinates').split(',')[0]),
                            lng: Number(watch('coordinates').split(',')[1]),
                          },
                        ]) ||
                        []
                      }
                      mode="popup"
                      setCoordinate={(val: any) => {
                        setValue('coordinates', val.lat + ',' + val.lng);
                      }}
                      isFullScreen={isFullScreen}
                      setIsFullScreen={() => setIsFullScreen(!isFullScreen)}
                    />
                  </div>
                  <div className="popup__form__button">
                    <div className="button-wrapper">
                      <ButtonWrap
                        type="default"
                        color="#49ABB1"
                        backgroundColor="#1C282F"
                        border="1px solid #247E84"
                        text={addUserMessages.cancel}
                        width="100%"
                        height="56px"
                        onClick={() => {
                          setValue('coordinates', prevCoor.current);
                          setShowMap(false);
                          setIsFullScreen(false);
                        }}
                      />
                      <ButtonWrap
                        type="primary"
                        htmlType="submit"
                        text={'確定'}
                        width="100%"
                        height="56px"
                        backgroundColor="#247E84"
                        onClick={() => {
                          prevCoor.current = getValues('coordinates');
                          setShowMap(false);
                          setIsFullScreen(false);
                        }}
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditBuildingMaster;
