import React, { FC, useEffect } from 'react';
import BtnActionsGroup from '../BtnActionsGroup/BtnActionsGroup';

interface IReportInputTextType {
  setValue: any;
  selectedJob: number;
  onChange?: (e: any) => void;
  name: string;
  isSubmitted: boolean;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  props?: any;
}

const ReportInputTextType: FC<IReportInputTextType> = ({
  setValue,
  selectedJob,
  isSubmitted,
  setIsSubmitted,
  name,
  props,
}) => {
  const { clearErrors, handleBack, setCatchReportValue } = props;
  useEffect(() => {
    if (!isSubmitted) {
      setValue(`works.${selectedJob}.report.option_report`, '');
    }
  }, [isSubmitted]);

  const handleDone = () => {
    setValue(`works.${selectedJob}.report.option_report`, 'ok');
    clearErrors(name);
    setIsSubmitted(true);
    setCatchReportValue('');
  };
  const handleCancell = () => {
    setValue(`works.${selectedJob}.report.option_report`, ' ');
    handleBack();
    clearErrors(name);
  };

  return <>{!isSubmitted && <BtnActionsGroup onCancel={handleCancell} onDone={handleDone} />}</>;
};

export default ReportInputTextType;
