import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { FC, useState } from 'react';
import { generalMessages } from '../../../utils/messages/general';
import ButtonWrap from '../ButtonWrap';
import './style.scss';

interface ErrorPopupProps {
  unVisible: any;
  actions: any;
  width?: string;
}

const ErrorPopup: FC<ErrorPopupProps> = ({ unVisible, actions, width }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCancel = () => {
    setIsVisible(false);
    unVisible();
  };
  return (
    <Modal
      title={generalMessages.error}
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={width}
      className="popup-container">
      <ExclamationCircleOutlined />
      <h1>{generalMessages.errorUnauthorizedLogin}</h1>
      <ButtonWrap
        text={generalMessages.dismiss}
        onClick={actions}
        width={'636px'}
        height={'56px'}
        backgroundColor={'#F5F5F5'}
        border={'1px solid #247E84'}
        color={'#49ABB1'}
      />
    </Modal>
  );
};

export default ErrorPopup;
