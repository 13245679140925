import React, { FC, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ja';
import { momentLocalizer, Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MonthEvent from './Support/MonthEvent';
import { Popover, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Plan } from '../../../../../models';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../../router/routes';
import { statusText } from '../../../../../components/commons/PlanStatus';
import { japanesePlanMessages as planMessages } from '../../../../../utils/messages';

const localizer = momentLocalizer(moment);

interface IEvent {
  name: String;
  updated_at: Date;
  plan_start_date: Date;
  plan_end_date: Date;
  status?: Number;
}

interface MainCalendarProps {
  setSelectedDate: React.Dispatch<any>;
  eventsList: Plan[] | any;
  selectedDate: Date;
}

const MainCalendar: FC<MainCalendarProps> = ({ selectedDate, setSelectedDate, eventsList }) => {
  const navigate = useNavigate();
  const [morePlans, setMorePlans] = useState<Array<any>>([]);
  const [dateMore, setDateMore] = useState<string>('');

  const convertEventList = eventsList.map((item: any, index: any) => {
    return {
      ...item,
      plan_start_date: moment(item.plan_start_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') + 'Z',
      plan_end_date: moment(item.plan_end_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') + 'Z',
    };
  });

  const handlePreMonth = () => {
    var date = new Date(selectedDate);
    date.setMonth(date.getMonth() - 1);
    setSelectedDate(date);
  };

  const handleNextMonth = () => {
    var date = new Date(selectedDate);
    date.setMonth(date.getMonth() + 1);
    setSelectedDate(date);
  };

  return (
    <div className="main-calendar">
      <Space className="main-calendar__title">
        <p>
          {moment(selectedDate).format('YYYY')}年{selectedDate.getMonth() + 1}月
        </p>
        <LeftOutlined className="main-calendar__icon" onClick={() => handlePreMonth()} />
        <RightOutlined className="main-calendar__icon" onClick={() => handleNextMonth()} />
      </Space>
      <div className="main-calendar__wrapper">
        <Calendar
          style={{ minHeight: 700 }}
          selectable
          localizer={localizer}
          events={convertEventList}
          startAccessor="plan_start_date"
          date={new Date(selectedDate)}
          endAccessor="plan_end_date"
          className="custom-calendar"
          popup={false}
          defaultView="month"
          views={{
            month: true,
          }}
          onSelectSlot={(e: any) => {
            setSelectedDate(e.start);
          }}
          onSelectEvent={(event: IEvent) => {
            setSelectedDate(new Date(event.plan_start_date));
          }}
          slotPropGetter={(date) => {
            return { style: { display: 'flex' } };
          }}
          onShowMore={(eventsList, date) => {
            setMorePlans(eventsList);
            setDateMore(moment(date).format('YYYY/MM/DD'));
          }}
          messages={{
            showMore: (count: number): any => (
              <Popover
                className="show-more-btn"
                trigger="click"
                content={
                  <div className="more-plans">
                    {morePlans.map((item: any, index: number) => {
                      return (
                        <div
                          className={`event ${statusText[item.status - 1]}`}
                          key={index}
                          onClick={() => {
                            navigate(`/${appRoutes.PLAN.ROOT}/${item.id}`);
                          }}>
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                }
                title={dateMore}>
                <span>
                  +{count} {planMessages.moreText}
                </span>
              </Popover>
            ),
          }}
          dayPropGetter={(date) => {
            let newStyle = {
              background: `linear-gradient(0deg, rgba(86, 86, 86, 0.35), rgba(86, 86, 86, 0.35)), rgba(0, 0, 0, 0.1)`,
              color: '#969696',
            };
            let newStyle2 = {
              backgroundColor: '#49ABB1',
              fontSize: '40px',
              color: 'white !important',
            };
            return {
              style: selectedDate.toDateString() === date.toDateString() ? newStyle2 : newStyle,
            };
          }}
          components={{
            toolbar: () => {
              return <></>;
            },
            month: {
              dateHeader: ({ date, label }) =>
                date.toDateString() === selectedDate.toDateString() ? (
                  <div style={{ color: 'white', textAlign: 'left', margin: '6px 0 0px 0' }}>{date.getDate()}</div>
                ) : (
                  <div style={{ color: 'rgba(150, 150, 150, 1)', textAlign: 'left', margin: '6px 0 0px 0' }}>
                    {date.getDate()}
                  </div>
                ),
              event: MonthEvent,
            },
          }}
        />
      </div>
    </div>
  );
};

export default MainCalendar;
