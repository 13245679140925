import { Form, Input } from 'antd';
import { FC } from 'react';
import { typeFileMsg } from '../../../utils/messages/typeFile';
import './style.scss';

interface InputRulesProps {
  required?: boolean;
  message?: string;
  [key: string]: any;
}
interface IInputFieldProps {
  label?: React.ReactNode;
  name?: string | any;
  required?: boolean;
  message?: string;
  placeholder?: string;
  props?: any;
  state?: string;
  prefix?: any;
  suffix?: any;
  disable?: boolean;
  rules?: InputRulesProps[];
  defaultValue?: string;
  help?: React.ReactNode;
  maxLength?: number;
  initialValue?: string;
  className?: string;
  onBlur?: any;
  autoFocus?: boolean;
  addonAfter?: any;
}

const error = {
  border: '1px solid #EB5757',
  boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
};

const InputField: FC<IInputFieldProps> = ({
  label,
  name,
  placeholder,
  props,
  state,
  prefix,
  suffix,
  disable,
  rules,
  defaultValue,
  help,
  maxLength,
  initialValue,
  className,
  onBlur,
  autoFocus,
  addonAfter,
}) => {
  return (
    <Form.Item label={label} name={name} rules={rules} help={help} className="input-field" colon={false}>
      <Input
        placeholder={placeholder}
        {...props}
        style={state === typeFileMsg.error ? error : {}}
        prefix={prefix}
        suffix={suffix}
        disabled={disable}
        defaultValue={defaultValue}
        maxLength={maxLength}
        initialval={initialValue}
        className={className}
        onBlur={onBlur}
        autoFocus={autoFocus ?? undefined}
        addonAfter={addonAfter}
      />
    </Form.Item>
  );
};

export default InputField;
