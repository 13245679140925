import { Empty } from 'antd';
import { FC } from 'react';
import { generalMessages } from '../../../utils/messages';

interface EmptyWrapProps {}

const EmptyWrap: FC<EmptyWrapProps> = () => {
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={generalMessages.noData} />;
};

export default EmptyWrap;
