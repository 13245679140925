import { Form, Select } from 'antd';
import { FC } from 'react';
import './style.scss';

const { Option } = Select;

interface IOption {
  key: string | number;
  value: string | any;
}

interface ISelectFieldProps {
  label?: string | any;
  name?: string;
  required?: boolean;
  message?: string;
  options: Array<IOption>;
  placeholder?: string;
  allowClear?: boolean;
  props?: any;
  disable?: boolean;
  defaultValue?: string;
  help?: React.ReactNode;
  error?: string;
  className?: string;
  isSearch?: boolean;
  listHeight?: number;
  loading?: boolean;
  disableOption?: any;
  autoFocus?: boolean;
  isElementValue?: boolean;
}

const SelectField: FC<ISelectFieldProps> = ({
  label,
  name,
  required,
  message,
  placeholder,
  options,
  allowClear,
  props,
  disable,
  defaultValue,
  help,
  error,
  className,
  isSearch,
  listHeight,
  loading,
  disableOption,
  autoFocus,
  isElementValue = false,
}) => {
  const handleField = () => {
    if (disable) return 'disable-select';
    if (error) return 'error-select';
    if (isSearch) return 'typing-select';
    return 'default-select';
  };

  return (
    <Form.Item label={label} name={name} rules={[{ required, message }]} help={help} colon={false}>
      <Select
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={disable}
        {...props}
        className={handleField()}
        defaultValue={defaultValue}
        showSearch={isSearch ?? undefined}
        filterOption={
          isSearch
            ? (input, option: any) => {
                return (
                  isElementValue
                    ? (option!.children?.props?.title as unknown as string)
                    : (option!.children as unknown as string)
                )
                  .toLowerCase()
                  .includes(input?.toLowerCase());
              }
            : undefined
        }
        listHeight={listHeight ?? 100}
        loading={loading}
        autoFocus={autoFocus ?? undefined}
      >
        {options?.map(({ key, value }, index) => {
          return (
            <Option key={index} value={key} disabled={disableOption ? disableOption.includes(key) : undefined}>
              {value}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectField;
