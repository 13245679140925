import React, { FC, useEffect, useRef, useState } from 'react';
import GoogleMapReact from "google-map-react";
import axios from 'axios';
import { Button, Dropdown, Menu } from 'antd';
import './style.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { japanesePlanMessages } from '../../../utils/messages';
import { mapConstant } from '../../../utils/constants';
import { ZoomIn } from '../../../assets/icons/ZoomIn';
import { ZoomOut } from '../../../assets/icons/ZoomOut';
export interface MarkerProps {
  lat: number;
  lng: number;
  name?: string
  address?: string
  id?: number
  match?: boolean
}
interface IGGMAPProps {
  width: string;
  height: string;
  lat?: number;
  lng?: number;
  zoom?: number;
  coordinates?: MarkerProps[]
  setCoordinate?: any;
  address?: string;
  postalCode?: string;
  mode?: string;
  setIsFullScreen?: () => void;
  isFullScreen?: boolean;
}

const Marker = (props: any) => {
  const navigate = useNavigate();

  const renderSwitch = (match: any) => {
    switch (match) {
      case true:
        return <img style={{ width: '22px', height: '22px', cursor: 'pointer' }} src={'/match.png'} alt="pin" />
      case false:
        return <img style={{ width: '22px', height: '22px', cursor: 'pointer' }} src={'/notmatch.png'} alt="pin" />  
      default:
         return <img style={{ width: '16px', height: '16px', cursor: 'pointer' }} src={'/marker.png'} alt="pin" />
    }
  }
  return (
    <Dropdown
      disabled={props.mode === 'popup'}
      trigger={["click"]}
      overlay={<Menu
        theme='dark'
        items={[
          {
            label: (
              <div className='building-info'>
                <p className='building-name'>{props.building.name}</p>
                <p>{props.building.address_1}</p>
                <Button
                  onClick={() => navigate(`/plan/create?building=${props.building.id}`) }
                >
                  {japanesePlanMessages.mapCreatePlan}
                </Button>
                <Button
                  onClick={() =>  navigate(`/plan/list?building=${props.building.id}`)}
                >
                  {japanesePlanMessages.mapListPlan}
                </Button>
              </div>),
            key: '0',
          },
        ]}
      />}
      placement="topRight"
      overlayStyle={{ backgroundColor: '#222E35' }}
    >
      {renderSwitch(props.building?.match)}
    </Dropdown>
  )
};
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
const GoogleMap: FC<IGGMAPProps> = ({
  width,
  height,
  lat,
  lng,
  zoom,
  coordinates,
  setCoordinate,
  address,
  postalCode,
  mode,
  setIsFullScreen,
  isFullScreen
}) => {
  const [latitude, setlatitude] = useState<any>()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [longitude, setLongitude] = useState<any>()
  const [defaultZoom, setDefaultZoom] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const isZooming = useRef(false)
  const getCoordinate = () => {
    let url = `${mapConstant.geoURL}?address=${address?.replaceAll(" ", "+") || postalCode}&region=ja&key=${API_KEY}`
    axios.get(url).then(res => {
      setlatitude(res.data.results[0]?.geometry.location.lat)
      setLongitude(res.data.results[0]?.geometry.location.lng)
      setDefaultZoom(15)
    }).catch(err => {
      console.log(err)
    })
  }
  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((val) => {
        setlatitude(val.coords.latitude)
        setLongitude(val.coords.longitude)
        setDefaultZoom(15)
      })
    }
  }
  useEffect(() => {
    if (mode === 'popup') {
      if (!coordinates && !address && !postalCode) {
        getCurrentPosition()
      } else {
        if ((address || postalCode) && (!coordinates || !coordinates.length)) {
          getCoordinate()
        }
        if (coordinates && coordinates.length === 1) {
          setlatitude(coordinates[0].lat)
          setLongitude(coordinates[0].lng)
          setDefaultZoom(15)
        }
      }
    } 
  }, [address, postalCode, coordinates])
  useEffect(() => {
    if (mode !== 'popup') {
      if (!lat) {
        getCurrentPosition()
      } else {
        setLongitude(lng)
        setlatitude(lat)
        setDefaultZoom(zoom)
      }
    }
  }, [lat, lng, zoom])
  return (
    <div style={{ width: width, height: height, position: 'relative' }}> 
      {isFullScreen !== undefined && <div className='full-screen-btn' onClick={setIsFullScreen}>
        { isFullScreen ? <ZoomOut /> : <ZoomIn/>}  
      </div>}
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY, language: 'ja' }}
        options={{
          fullscreenControl: isFullScreen === undefined ? true : false
        }}
        center={{ lat: latitude || mapConstant.defaultLat , lng: longitude || mapConstant.defaultLng }}
        zoom={defaultZoom || mapConstant.defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={(val) => {
          if (isZooming.current && mode !== 'popup') {
              searchParams.set('lat', String(val.center.lat))
              searchParams.set('lng', String(val.center.lng))
            setSearchParams(searchParams)
          }
          isZooming.current = false
        }}
        onDragEnd={(val) => {
          if (mode !== 'popup') {
            searchParams.set('lat', val.getCenter().lat())
            searchParams.set('lng', val.getCenter().lng())
            setSearchParams(searchParams)
          }
        }}
        onZoomAnimationStart={() => isZooming.current = true}
        onZoomAnimationEnd={(zoom) => {
          if (mode !== 'popup') {
            searchParams.set('zoom', zoom)
            setSearchParams(searchParams)
          }
        }}
        onTilesLoaded={() => setMapLoaded(true)}
        onGoogleApiLoaded={({ map }) => {
          map.addListener('click', (e: any) => {
            setCoordinate && setCoordinate({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          })
        }}
      >
        {mapLoaded && Array.isArray(coordinates) && !!coordinates.length && coordinates.map((marker: MarkerProps, index: number) => {
          return marker?.lat && <Marker building={marker} mode={mode} key={index} lat={marker.lat} lng={marker.lng} />
        })}
      </GoogleMapReact>
    </div>
  );
};

export default React.memo(GoogleMap);
