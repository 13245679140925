import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { statusStr, statusText } from '../../../../../../components/commons/PlanStatus';
import { appRoutes } from '../../../../../../router/routes';
import { planMessages } from '../../../../../../utils/messages';

interface IMonthEventProps {
  event:
    | {
        name: string;
        start: Date;
        end: Date;
        status: number;
      }
    | {
        name: string;
        start: Date;
        end: Date;
        status?: undefined;
      };
}

const MonthEvent: FC<any> = (event) => {
  const navigate = useNavigate();

  return (
    <div
      className={`event  ${statusText[event.event.status - 1]}`}
      onClick={(e) => {
        navigate(`/${appRoutes.PLAN.ROOT}/${event.event.id}`);
      }}>
      {event.event.name}
    </div>
  );
};

export default MonthEvent;
