import { FC, useState } from 'react';
import { Drawer, Button } from 'antd';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import { useNavigate } from 'react-router-dom';
import './style.scss';

interface HeaderLayoutProps {}

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <nav className="header">
        <div className="header__background"></div>
        <div className="header__wrap-left">
          <div className="header__left">
            <div className="header__left__icon" onClick={() => navigate('/')} />
            <div className="header__left__divider" />
          </div>
          <div className="header__center left-menu">
            <LeftHeader />
          </div>
        </div>
        <div className="header__right right-menu">
          <RightHeader onClose={() => {}} />
        </div>
        <Button className="bars-menu" type="primary" onClick={showDrawer}>
          <span className="bars-menu__bars-btn" />
        </Button>
        <Drawer placement="right" closable={false} onClose={onClose} visible={visible}>
          <div className="header__menu-bar">
            <LeftHeader onClickMenu={onClose} mode="vertical" />
          </div>
          <div className="header__user-block">
            <RightHeader size={80} onClose={onClose} />
          </div>
        </Drawer>
      </nav>
    </>
  );
};

export default HeaderLayout;
