import { UploadOutlined } from '@ant-design/icons';
import { notification, Upload, UploadFile } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { confirmMessages, japanesePlanMessages as planMessages, profileMessages } from '../../../../../utils/messages';
import type { UploadProps } from 'antd/es/upload';
import { ButtonWrap } from '../../../../commons';
import CarouselComponent from '../../../../commons/CarouselComponent/CarouselComponent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { constant } from '../../../../../utils/constants';
import { FILELIMIT, FILELIMITONEFILE } from '../../../../../utils/plan';
import './style.scss';

interface UploadComponentProps {
  data: Array<any>;
  setValue: any;
  name: string;
  disable?: boolean;
  control: any;
}

const UploadComponent: FC<UploadComponentProps> = ({ data = [], setValue, name, disable = false, control }) => {
  const [fileList, setFileList] = useState<any>(data);
  const [countErrorFile, setCountErrorFile] = useState(0);
  const [countErrorCapacityOneFile, setErrorCapacityOneFile] = useState(0);
  const [countErrorCapacity, setCountErrorCapacity] = useState(0);

  const [matchBiggerScreen] = useMediaQuery(1200);
  const [matchSmallerScreen] = useMediaQuery(906);

  useEffect(() => {
    if (countErrorFile !== 0) {
      notification.error({
        message: planMessages.errorType,
        description: planMessages.validationFileText,
      });
    }
  }, [countErrorFile]);

  useEffect(() => {
    if (countErrorCapacityOneFile !== 0) {
      notification.error({
        message: planMessages.oneFileCapacityErr,
      });
    }
  }, [countErrorCapacityOneFile]);

  useEffect(() => {
    if (countErrorCapacity !== 0) {
      notification.error({
        message: <>{planMessages.validationFilesCapacity}</>,
      });
    }
  }, [countErrorCapacity]);

  async function loadFile(file: any) {
    const readFile = async () => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = (e: any) => {
          return resolve(e.target.result);
        };
      });
    };
    const urlImg = await readFile();
    file.urlImg = urlImg;
    return file;
  }

  const handleValidateFile = (fileList: any) => {
    let isError = fileList.some((item: any) => {
      return (
        !constant.file__accept.includes(item.type) ||
        item.name.match(constant.file__decline) ||
        (item.type === 'text/plain' && item.size === 0)
      );
    });

    if (isError) {
      setCountErrorFile((preState: any) => {
        return preState + 1;
      });
      return isError;
    }
    isError = fileList.some((item: any) => {
      return item.size > FILELIMITONEFILE;
    });
    if (isError) {
      setErrorCapacityOneFile((preState: any) => {
        return preState + 1;
      });
      return isError;
    }
    return isError;
  };

  const handleUploadChange: UploadProps['onChange'] = (info: any) => {
    let total: number = info.fileList.reduce((pre: any, item: any) => {
      return pre + item.size;
    }, 0);
    if (total > FILELIMIT) {
      setCountErrorCapacity((preState: any) => {
        return preState + 1;
      });
    } else {
      setFileList(info.fileList);
      setValue(name, info.fileList);
    }
  };
  const handleDeleteFile = (fileId: string) => {
    let index = fileList.findIndex((file: any) => {
      return file.uid === fileId;
    });
    if (!fileList?.[index].uid.includes('rc-'))
      setValue(`remove_documents`, [...control._formValues.remove_documents, fileList?.[index].uid]);
    if (index >= 0) {
      setFileList((prevState: any) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
      const clonedArr = [...fileList];
      clonedArr.splice(index, 1);
      setValue(name, clonedArr);
    }
  };

  const handleDeleteAll = () => {
    setFileList((prevState: any) => {
      return [];
    });
    const deleteFiles = fileList?.map((item: any) => {
      if (!item.uid.includes('rc-')) {
        return item.uid;
      }
    });
    setValue(`remove_documents`, [...control._formValues?.remove_documents, ...deleteFiles]);
    setValue(name, []);
  };

  let slidesQuantity = 4;
  if (matchBiggerScreen) slidesQuantity = 3;
  if (matchSmallerScreen) slidesQuantity = 2;

  return (
    <>
      {fileList.length > 0 && !disable && (
        <p className="delete-all" onClick={() => handleDeleteAll()}>
          {planMessages.deleteAllText}
        </p>
      )}
      <div className="-container">
        {!disable && (
          <Upload
            listType={fileList.length === 0 ? 'text' : 'picture-card'}
            type="select"
            name="file"
            headers={{ authorization: 'authorization-text' }}
            onChange={handleUploadChange}
            multiple
            fileList={fileList}
            showUploadList={false}
            beforeUpload={async (file, fileList) => {
              if (!handleValidateFile(fileList)) {
                await loadFile(file);
              } else {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            className={`-section ${fileList.length === 0 ? 'no-file' : 'have-file'}`}>
            <ButtonWrap
              className={`common-btn ${fileList.length > 0 && 'upload-small-btn'}`}
              text={planMessages.uploadBtn}
              backgroundColor="#222E35"
              iconLeft={<UploadOutlined style={{ color: 'inherit' }} />}
              border={'1px solid #247E84'}></ButtonWrap>
          </Upload>
        )}

        {fileList.length > 0 ? (
          <CarouselComponent
            data={[...fileList].reverse()}
            slidesQuantity={slidesQuantity}
            deletable={!disable}
            onDelete={handleDeleteFile}
          />
        ) : (
          disable && <div>{planMessages.placeholderDocuments}</div>
        )}
      </div>
    </>
  );
};

export default UploadComponent;
