export const generalMessages = {
  page_size: 10,
  deleteErrorMsg: '削除できません。再度削除してよろしいですかしてください。',
  successMsg: '成功',
  required: '必須',
  noData: 'データがありません。',
  error: 'エラー',
  errorUnauthorizedLogin: 'システムにアクセスする権限がありません。',
  dismiss: '閉じる',
  loading: '読み込み中',
  pleaseSelect: '選択してください。',
  serverErr: '',
  status: '1',
  no_data: 'データがありません',
};
