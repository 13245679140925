import { FC } from 'react';
import { Col } from 'antd';
import './title.scss';

interface TitleProps {
  title: string;
}

const Title: FC<TitleProps> = ({ title }) => {
  return (
    <Col span={12} className="title-page">
      <h1>{title}</h1>
    </Col>
  );
};

export default Title;
