import { PhoneOutlined } from '@ant-design/icons';
import { FC } from 'react';
import './style.scss';

interface PhoneIconProps {}

const PhoneIcon: FC<PhoneIconProps> = ({}) => {
  return (
    <div className="img-wrapper">
      <PhoneOutlined />
    </div>
  );
};

export default PhoneIcon;
