import { FC } from 'react';
import { generalMessages } from '../../../utils/messages/general';
import './style.scss';

interface RequiredLabelProps {}

const RequiredLabel: FC<RequiredLabelProps> = () => {
  return (
    <div className="required-label">
      <span>{generalMessages.required}</span>
    </div>
  );
};

export default RequiredLabel;
