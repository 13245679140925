import React, { FC, useEffect } from 'react';
import BtnActionsGroup from '../BtnActionsGroup/BtnActionsGroup';

interface IReportInputNumberType {
  setValue: any;
  selectedJob: number;
  onChange: (e: number) => void;
  name: string;
  isSubmitted: boolean;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  props?: any;
}

const ReportInputNumberType: FC<IReportInputNumberType> = ({
  setValue,
  selectedJob,
  name,
  isSubmitted,
  setIsSubmitted,
  props,
}) => {
  const { clearErrors, handleBack, setCatchReportValue } = props;

  useEffect(() => {
    if (!isSubmitted) {
      setValue(`works.${selectedJob}.report.option_report`, '');
    }
  }, [isSubmitted]);
  const handleDone = () => {
    setValue(`works[${selectedJob}].report.option_report`, 'ok');
    clearErrors(name);
    setIsSubmitted(true);
    setCatchReportValue(' ');
  };
  const handleCancell = () => {
    setValue(`works.${selectedJob}.report.option_report`, ' ');
    handleBack();
    clearErrors(name);
  };

  return <>{!isSubmitted && <BtnActionsGroup onCancel={handleCancell} onDone={handleDone} />}</>;
};

export default ReportInputNumberType;
