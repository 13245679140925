export const equipmentMessages = {
  title: '端末管理',
  macAddress: 'デバイスID',
  username: 'ユーザー名',
  cancel: 'キャンセル',
  add: '追加',
  save: '保存',
  equipmentDetailsTitle: '端末情報',
  addEquipmentTitle: '端末追加',
  requireUsername: 'ユーザー名は必須です。',
  requireMacAddress: 'デバイスIDは必須です。',
  titleConfirm: '削除',
  delete: '削除',
  textConfirm: '削除してもよろしいですか。',
  yes: 'はい',
  no: 'いいえ',
  placeholder: '入力してください。',
};
