import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useStore } from '../store';

interface IProps {}

const OpenSiteRoutes: FC<IProps> = (props) => {
  const [state] = useStore();
  const { isAuthenticated } = state;
  const location = useLocation();
  return isAuthenticated === localStorage.getItem('access_token') ? (
    <Navigate to={'/'} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default OpenSiteRoutes;
