import { forgotPassword, userLogin } from '../models';
import axiosClient from './axiosClient';

const authApi = {
  login(query: userLogin) {
    const url = '/login';
    return axiosClient.post(url, query);
  },

  forgotPassword(query: forgotPassword) {
    const url = '/forgot-password';
    return axiosClient.post(url, query);
  },
  logout() {
    const url = '/logout';
    return axiosClient.post(url);
  },
};

export default authApi;
