export const forgotPassMessages = {
  title: 'パスワードをお忘れの場合',
  label: '登録したアカウントのメールアドレスに再設定したパスワードを送信します。',
  lbEmail: 'メールアドレス',
  placeholderAccount: '例）xxxx＠xxxx.xx.jp',
  requireAccount: 'メールアドレスは必須です。',
  validateEmail: 'メールアドレスの形式が正しくありません。',
  btnGetPassword: '送信',
  back: '戻る',
};
