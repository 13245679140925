import React, { Children, FC, ReactNode } from 'react';
import { Row, Col, Form } from 'antd';
import { ButtonWrap } from '../../../../../commons';
import { japanesePlanMessages as planMessages } from '../../../../../../utils/messages';

interface IBtnActionsGroupProps {
  onCancel?: () => void;
  onDone?: () => void;
}

const BtnActionsGroup: FC<IBtnActionsGroupProps> = ({ onCancel, onDone }) => {
  return (
    <Form.Item style={{ marginTop: '40px' }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <ButtonWrap
            text={planMessages.cancelReportOptionBtn}
            width={'100%'}
            height="56px"
            onClick={onCancel}
            color="#49ABB1"
            backgroundColor="#1C282F"
            border={'1px solid #247E84'}
            className="common-btn"></ButtonWrap>
        </Col>
        <Col span={12}>
          <ButtonWrap
            type="primary"
            className="common-btn"
            text={planMessages.doneReportOptionBtn}
            width={'100%'}
            height="56px"
            color="white"
            onClick={onDone}></ButtonWrap>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default BtnActionsGroup;
