import { configs } from '../configs';
import { Plan, DetailsResponse, ListResponse } from '../models';
import { japanesePlanMessages as planMessages } from '../utils/messages';
import { generalMessages } from '../utils/messages/general';
import axiosClient from './axiosClient';

export const PrefixUrlComments = {
  addCommentPlan: (id: any) => {
    return `/plans/${id}/comment`;
  },
  addCommentWork: (id: any) => {
    return `/works/${id}/comment`;
  },
};

const plans = 'plans';
const base64 = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

const planApi = {
  getAll(): Promise<ListResponse<Plan>> {
    const url = `${plans}?page_size=10&page=1`;
    return axiosClient.get(url);
  },
  createPlan(data: any | undefined): Promise<ListResponse<Plan>> {
    const url = plans;
    return axiosClient.post(url, data, configs.header);
  },
  editPlan({ result, id }: { result: any; id: string | undefined }): Promise<ListResponse<Plan>> {
    const url = `${plans}/${id}?_method=PUT`;
    return axiosClient.post(url, result, configs.header);
  },
  getOne(id: string | undefined): Promise<ListResponse<Plan>> {
    const url = `${plans}/${id}`;
    return axiosClient.get(url);
  },
  getSuggestions(): Promise<ListResponse<Plan>> {
    const url = `list/suggestions`;
    return axiosClient.get(url);
  },
  getStaff(): Promise<ListResponse<Plan>> {
    const url = `list/staffs`;
    return axiosClient.get(url);
  },
  rejectInspection(id: string | undefined): Promise<ListResponse<Plan>> {
    const url = `inspection/reject/${id}`;
    return axiosClient.get(url);
  },
  approveInspection(id: string | undefined): Promise<ListResponse<Plan>> {
    const url = `inspection/approve/${id}`;
    return axiosClient.get(url);
  },
  getPlanHistoryById(
    id: any,
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    filter_status: string | undefined,
    type: string | undefined
  ): Promise<ListResponse<Plan>> {
    const url = `${plans}/${id}/histories?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&text_search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}${
      filter_status && filter_status !== 'none' ? `&filter_status=${filter_status}` : ''
    }`;
    return axiosClient.get(url);
  },
  getPlansPerMonthCalendar(
    month: string | number | undefined,
    year: string | number | undefined
  ): Promise<ListResponse<Plan>> {
    const url = `${plans}/calendar?month=${month}&year=${year}&all`;

    return axiosClient.get(url);
  },
  getPlansPerDateCalendar(
    month: string | number | undefined,
    year: string | number | undefined,
    date: string | number | undefined,
    page?: string | undefined
  ): Promise<ListResponse<Plan>> {
    const url = `${plans}/calendar?month=${month}&year=${year}&day=${date}&all`;
    return axiosClient.get(url);
  },
  getPlansByPage(
    pageParams: number | undefined,
    search: string | undefined,
    sort: string | undefined,
    filter_status: string | undefined,
    type: string | undefined,
    buildingId: any,
  ): Promise<DetailsResponse<Plan>> {
    const url = `${plans}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&text_search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}${
      filter_status && filter_status !== 'none' ? `&filter_status=${filter_status}` : ''
    }${buildingId ? `&building_id=${buildingId}` : ''}`;
    return axiosClient.get(url);
  },
  addCommentPlan({ id, data }: { id: string | undefined; data: any }): any {
    const url = `${plans}/${id}/history`;
    return axiosClient.post(url, data);
  },
  addComment({ prefix, data }: any): any {
    return axiosClient.post(prefix, data);
  },
  getAllPlanComments(id: string | undefined): Promise<ListResponse<any>> {
    const url = `${plans}/${id}/comments`;
    return axiosClient.get(url);
  },
  getAllWorkComments(id: string | undefined): Promise<ListResponse<any>> {
    const url = `works/${id}/comments`;
    return axiosClient.get(url);
  },
  clonePlan(id: string | undefined): Promise<DetailsResponse<Plan>> {
    const url = `${plans}/${id}/clone`;
    return axiosClient.post(url);
  },
  deletePlan(id: string | undefined): Promise<DetailsResponse<Plan>> {
    const url = `${plans}/${id}`;
    return axiosClient.delete(url);
  },
  inspectionsWork(id: string | undefined): Promise<DetailsResponse<Plan>> {
    const url = `works/${id}/inspections`;
    return axiosClient.get(url);
  },
  async downloadPlan(id: string | undefined): Promise<any> {
    const url = `${plans}/export/${id}`;
    const headers: any = { responseType: 'arraybuffer' };
    let response: any = await axiosClient.get(`${url}`, headers);

    const result = base64 + Buffer.from(response, 'binary').toString('base64');
    const aElement = document.createElement('a');
    aElement.href = result;
    aElement.download = `${planMessages.downloadPlanText}.xlsx`;
    aElement.click();
    return result;
  },
};

export default planApi;
