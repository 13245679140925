import { useCallback, useState } from 'react';

const useToggle = (param: boolean): [boolean, any] => {
  const [state, setState] = useState<boolean>(param);

  const toggle = useCallback((): void => setState((state) => state), []);
  return [state, toggle];
};

export default useToggle;
