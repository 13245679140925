import { FC } from 'react';
import { TitleContent } from '../../components/commons';
import TableWrap from '../../components/TableWrap';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Building } from '../../models/building';
import { BuildingItem } from '../../models/buildingItem';
import { Facility } from '../../models/facility';
import PaginationWrapper from '../../components/commons/Pagination';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/commons/Loading';
import { appRoutes } from '../../router/routes';
import SearchField from '../../components/SearchField';

interface MasterProps<T> {
  title: string;
  handleOpen: () => void;
  columns: any[];
  data?: T[];
  onClickRow?: any;
  loading?: boolean;
  total: number;
  pageSize: number;
  setCurrent: (page: number) => void;
  currentPage: number;
  route: string;
  value: any;
  onChange: (e: any) => void;
  handleFilter: () => void;
}
const MasterTable: FC<MasterProps<Building | BuildingItem | Facility>> = ({
  title,
  handleOpen,
  columns,
  data = [],
  onClickRow,
  loading,
  setCurrent,
  total,
  pageSize,
  currentPage,
  route,
  value,
  onChange,
  handleFilter,
}) => {
  const navigate = useNavigate();

  const handleClickRow = (record: Building | BuildingItem | Facility) => {
    navigate(`${route}${record.id}`, { state: { view: true } });
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="equipment-management">
          <div className="equipment-management__container">
            <div className="equipment-management__title">
              <TitleContent
                leftIcon={true}
                title={title}
                rightIcon={{
                  front: (
                    <>
                      <div className="holder__icon" onClick={handleOpen}>
                        <PlusCircleOutlined />
                      </div>
                    </>
                  ),
                }}
                side={1}
                handleBack={() => navigate(`${appRoutes.MASTER.ROOT}`)}
              />
            </div>
            <div className={data?.length !== 0 ? 'equipment-management__table' : 'no-data'}>
              <SearchField handleFilter={handleFilter} value={value?.trim()} onChange={onChange} />
              <TableWrap columns={columns} data={data} onClickRow={handleClickRow} />
            </div>
            {total > pageSize && (
              <div className="equipment-management__pagination">
                <PaginationWrapper current={currentPage} total={total} pageSize={pageSize} setCurrent={setCurrent} />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default MasterTable;
