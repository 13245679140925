import React, { FC, useState } from 'react';
import { japanesePlanMessages as planMessages } from '../../utils/messages';
import { Space } from 'antd';
import CalendarContent from './CalendarContent';
import useToggle from '../../hooks/useToggle ';
import { useNavigate } from 'react-router-dom';
import { TitleContent } from '../../components/commons';
import { ViewListIcon } from '../../assets/icons/ViewListIcon';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import { appRoutes } from '../../router/routes';
import './style.scss';

interface CalendarListProps {}

const CalendarList: FC<CalendarListProps> = (props) => {
  const [activeIconHeader, setActiveIconHeader] = useToggle(false);
  const navigate = useNavigate();

  const handleBack = () => navigate(`/${appRoutes.PLAN.ROOT}`);

  return (
    <>
      <div className="calendar-list">
        <div className="calendar-list__title">
          <TitleContent
            leftIcon={true}
            title={`${planMessages.titleListPlan}`}
            rightIcon={{
              front: (
                <Space>
                  <div
                    className={`calendar-list__header__icon ${activeIconHeader ? 'active' : ''}`}
                    onClick={() => {
                      setActiveIconHeader(true);
                      navigate(`/${appRoutes.PLAN.LIST}`);
                    }}>
                    <ViewListIcon />
                  </div>
                  <div
                    className={`calendar-list__header__icon ${!activeIconHeader ? 'active' : ''}`}
                    onClick={() => {
                      setActiveIconHeader(false);
                    }}>
                    <CalendarIcon />
                  </div>
                </Space>
              ),
            }}
            side={1}
            handleBack={handleBack}
          />
        </div>

        <div className="calendar__wrapper">
          <CalendarContent />
        </div>
      </div>
    </>
  );
};

export default CalendarList;
