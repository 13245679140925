import { Form, notification, Spin, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import deviceApi from '../../../api/deviceApi';
import listApi from '../../../api/listApi';
import { ButtonWrap, ErrorMessage, SelectField } from '../../../components/commons';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputFieldCustom from '../../../components/InputFieldCustom';
import { constant } from '../../../utils/constants';
import { equipmentMessages, generalMessages } from '../../../utils/messages';
import './style.scss';

interface EquipmentDetailProps {
  isVisible: () => void;
  id: number;
}
const EquipmentDetail: FC<EquipmentDetailProps> = ({ isVisible, id }) => {
  const queryClient = useQueryClient();
  const [err, setError]: any = useState({});
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, data }: any = useQuery(['device_detail', id], () => deviceApi.get(id));
  const staffs: any = useQuery('list-staffs', listApi.getStaffs);
  const listUsers = staffs?.data?.filter((i: any) => !i.deleted_at && i.status === generalMessages.status);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }

  const mutation = useMutation(deviceApi.edit, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([
          queryClient.invalidateQueries(['device', 1]),
          queryClient.invalidateQueries(['device_detail', id]),
        ]);
        notification.success({
          message: generalMessages.successMsg,
        });
        isVisible();
        setLoading(false);
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'device' && query.queryKey[1] !== 1,
        });
        queryClient.invalidateQueries(['list-staffs']);
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });

  useEffect(() => {
    reset(data?.data);
    setError({});
  }, [isVisible]);

  const handleCancel = () => {
    isVisible();
  };

  const onSubmit = (data: any) => {
    setError({});
    setLoading(true);
    if (id) mutation.mutate({ id, data });
  };

  return (
    <>
      {loading && <Loading />}
      {isLoading || staffs?.isLoading || staffs?.isFetching ? (
        <Spin tip={generalMessages.loading} />
      ) : (
        <div className="popup__form username__selection">
          <Form className="popup__form__wrapper">
            <div className="popup__form__input">
              <div className="popup__form__item">
                <Controller
                  name="user_id"
                  control={control}
                  defaultValue={data?.data.user_id}
                  render={({ field }) => (
                    <SelectField
                      help={<ErrorMessage message="" />}
                      props={field}
                      label={
                        <>
                          {equipmentMessages.username}
                          <RequiredLabel />
                        </>
                      }
                      options={listUsers?.map((i: any) => ({
                        key: i.user_id,
                        value: (
                          <Tooltip placement="topLeft" title={i.username + ' - ' + i.email}>
                            <div className="deleted-selection-wrap">
                              <span className="deleted-selection-wrap__content">{i.username + ' - ' + i.email}</span>
                            </div>
                          </Tooltip>
                        ),
                      }))}
                      isSearch={true}
                      autoFocus={true}
                    />
                  )}
                />
              </div>
              <div className="popup__form__item">
                <Controller
                  name="device_id"
                  rules={{
                    required: equipmentMessages.requireMacAddress,
                    validate: (value: string) => {
                      if (value?.trim() == '' || !value) {
                        return equipmentMessages.requireMacAddress;
                      }
                    },
                  }}
                  control={control}
                  defaultValue={data?.data.device_id}
                  render={({ field }) => (
                    <InputFieldCustom
                      props={field}
                      maxLength={constant.maxLengthMacAddress}
                      msgServer={err['device_id']}
                      msgValidate={errors?.device_id?.message}
                      label={
                        <>
                          {equipmentMessages.macAddress}
                          <RequiredLabel />
                        </>
                      }
                      placeholder={equipmentMessages.placeholder}
                      onBlur={(e: any) => setValue('device_id', e.target.value.trim())}
                    />
                  )}
                />
              </div>
            </div>

            <div className="popup__form__button">
              <div className="button-wrapper">
                <ButtonWrap
                  type="default"
                  color="#49ABB1"
                  backgroundColor="#1C282F"
                  border="1px solid #247E84"
                  text={equipmentMessages.cancel}
                  width="100%"
                  height="56px"
                  onClick={handleCancel}
                />
                <ButtonWrap
                  type="primary"
                  htmlType="submit"
                  text={equipmentMessages.save}
                  width="100%"
                  height="56px"
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default EquipmentDetail;
