import React, { FC, useState, memo } from 'react';
import planApi, { PrefixUrlComments } from '../../../../api/planApi';
import NoteForm from '../../NoteForm';
import { useQuery } from 'react-query';
import { Spin } from 'antd';
import { japanesePlanMessages as planMessages } from '../../../../utils/messages';

interface IPlanCommentFormProps {
  id: string;
  commentVisible: boolean;
  setCommentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setHasNewCommentFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlanCommentForm: FC<IPlanCommentFormProps> = ({
  id,
  commentVisible,
  setCommentVisible,
  setHasNewCommentFlag,
}) => {
  const { isLoading, isError, data, error, isFetching }: any = useQuery(['plan-comments', id], () =>
    planApi.getAllPlanComments(id)
  );

  if (!isLoading) {
    setHasNewCommentFlag(false);
  }

  return (
    <>
      <NoteForm
        id={id}
        visible={commentVisible}
        title={planMessages.commentTitle}
        data={data?.data}
        PrefixUrlComment={PrefixUrlComments.addCommentPlan(id)}
        isLoading={isLoading}
        isFetching={isFetching}
        setCommentVisible={setCommentVisible}
        purpose="plan-comment"
      />
    </>
  );
};

export default memo(PlanCommentForm);
