import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import planApi from '../../../../../api/planApi';
import { actions, useStore } from '../../../../../store';
import { inspectionsDto } from '../../../../../utils/dtos/plan/format-data';
import { japanesePlanMessages as planMessages } from '../../../../../utils/messages';
import { InspectionItem } from '../Inspection/InspectionItem';
import './style.scss';

interface IResultTabProps {
  props: any;
}
const ResultTab: FC<IResultTabProps> = ({ props }) => {
  const { id, hasNewComment } = props;
  const [inspections, setInspections] = useState<any>([]);
  const { isLoading, data, isFetching }: any = useQuery(['inspections', id], () => planApi.inspectionsWork(id));
  const [, dispatch] = useStore();
  const [hasNewCommentFlag, setHasNewCommentFlag] = useState<boolean>(hasNewComment ? hasNewComment : false);

  useEffect(() => {
    setInspections(inspectionsDto(data?.data?.filter((each: any) => each?.status !== 1 && each?.status !== 2)));
  }, [data]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(actions.setIsLoading(false));
    } else {
    }
  }, [isFetching]);

  return isLoading ? (
    <Spin spinning={isLoading} tip={planMessages.loading}>
      <div style={{ width: 200, height: 200 }}></div>
    </Spin>
  ) : (
    <>
      {inspections && inspections.length ? (
        inspections?.map((item: any, index: number) => {
          return (
            <InspectionItem
              round={inspections?.length - index}
              id={id}
              item={item}
              hasNewComment={hasNewCommentFlag}
              setHasNewCommentFlag={setHasNewCommentFlag}></InspectionItem>
          );
        })
      ) : (
        <InspectionItem
          id={id}
          item={{ status: 2, option_report: JSON.stringify('') }}
          hasNewComment={hasNewCommentFlag}
          setHasNewCommentFlag={setHasNewCommentFlag}></InspectionItem>
      )}
    </>
  );
};

export default ResultTab;
