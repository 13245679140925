import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import FooterLayout from '../layout/Footer';
import HeaderLayout from '../layout/Header';
import NotFound from '../pages/NotFound';
import background from '../assets/images/background-1.png';

RoleRoute.propTypes = {
  roles: PropTypes.array,
};

function RoleRoute(props: any) {
  const { roles } = props;
  const role = localStorage.getItem('role');

  return roles.includes(role) ? (
    <Outlet />
  ) : (
    <>
      <div className="main">
        <div className="main__content" style={{ backgroundImage: `url(${background})` }}>
          {<HeaderLayout />}
          <div className="main__component">
            <NotFound />
          </div>
          <div className="main__footer">
            <FooterLayout />
          </div>
        </div>
      </div>
    </>
  );
}

export default RoleRoute;
