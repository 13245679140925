import { Col, Row } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardWrap, TitleContent } from '../../components/commons';
import { appRoutes } from '../../router/routes';
import { masterMessages } from '../../utils/messages';
import iconFacility from '../../assets/images/facility.png';
import iconBuilding from '../../assets/images/building.png';
import iconBuildingItem from '../../assets/images/device.png';
import './style.scss';

interface MasterActionProps {}

const MasterAction: FC<MasterActionProps> = () => {
  const navigate = useNavigate();

  const handleInfrastructure = () => {
    navigate(`${appRoutes.MASTER.FACILITY.ROOT}`);
  };
  const handleBuilding = () => {
    navigate(`${appRoutes.MASTER.BUILDING.ROOT}`);
  };
  const handleDevice = () => {
    navigate(`${appRoutes.MASTER.DEVICE.ROOT}`);
  };

  return (
    <div className="master__action__container">
      <div className="master__action__title">
        <TitleContent leftIcon={false} title={masterMessages.masterTitle} />
      </div>
      <div className="master__action__item">
        <Row className="master__action__item__box" gutter={[30, 30]}>
          <Col xs={24} lg={8}>
            <CardWrap
              className="box"
              handleAction={handleInfrastructure}
              title={masterMessages.titleFacility}
              img={iconFacility}
              width={'413.33px'}
              height={'190px'}
            />
          </Col>
          <Col xs={24} lg={8}>
            <CardWrap
              className="box"
              handleAction={handleBuilding}
              title={masterMessages.titleBuilding}
              img={iconBuilding}
              width={'413.33px'}
              height={'190px'}
            />
          </Col>
          <Col xs={24} lg={8}>
            <CardWrap
              className="box"
              handleAction={handleDevice}
              title={masterMessages.titleDevice}
              img={iconBuildingItem}
              width={'413.33px'}
              height={'190px'}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MasterAction;
