import { EditOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import facilityApi from '../../../api/facilityApi';
import CarouselCustom from '../../../components/CarouselCustom';
import { ButtonWrap, ErrorMessage, TextAreaField, TitleContent } from '../../../components/commons';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputFieldCustom from '../../../components/InputFieldCustom';
import UploadWrapper from '../../../components/Upload';
import { appRoutes } from '../../../router/routes';
import { constant } from '../../../utils/constants';
import { generalMessages, infrastructureMasterMessages, masterMessages } from '../../../utils/messages';
import { typeFileMsg } from '../../../utils/messages/typeFile';
import { regex } from '../../../utils/regex';

interface EditFacilityMasterProps {}

const EditFacilityMaster: FC<EditFacilityMasterProps> = (props) => {
  const queryClient = useQueryClient();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, data }: any = useQuery(['facility_detail', id], () => facilityApi.get(id), {
    onError: (error: any) => {
      if (error && error.response.status === 404) {
        navigate(appRoutes.NOT_FOUND);
      }
    },
  });
  const [err, setError]: any = useState({});
  const [resetState, setState] = useState(false);
  const [display, setDisplay] = useState('none');
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(location?.state?.view ?? true);

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const mutation = useMutation(facilityApi.edit, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([
          queryClient.invalidateQueries(['facility_detail', id]),
          queryClient.invalidateQueries(['facility', 1]),
        ]);
        if (!location.state?.view) {
          navigate(`${appRoutes.MASTER.FACILITY.ROOT}`);
          notification.success({
            message: generalMessages.successMsg,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'building-item' && query.queryKey[1] !== 1,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'plan',
          });
          return;
        }
        setDisplay('none');
        setDisable(true);
        setEdit('flex');
        setState(true);
        setLoading(false);
        notification.success({
          message: generalMessages.successMsg,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'facility' && query.queryKey[1] !== 1,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'plan',
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const handleEdit = () => {
    setDisplay('flex');
    setDisable(false);
    setEdit('none');
    if (errors) setState(false);
  };
  const handleCancel = () => {
    if (!location.state?.view) navigate(`${appRoutes.MASTER.FACILITY.ROOT}`);
    else {
      setDisplay('none');
      setDisable(true);
      setEdit('flex');
      setState(true);
      reset(data?.data);
      setError({});
    }
  };
  const onSubmit = (dataForm: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.set('name', dataForm.name);
    formData.set('note', dataForm.note.toString());
    dataForm.files?.map((file: any) => {
      if (Object.keys(file).includes('urlImg')) formData.append('files[]', file.originFileObj);
    });
    if (dataForm.files?.length === 0)
      data?.data?.documents?.map((file: any) => formData.append('fileRemoves[]', file.uuid));
    dataForm.removeFiles?.map((remove_file: any) => {
      formData.append('fileRemoves[]', remove_file);
    });
    if (id) mutation.mutate({ id, data: formData });
  };
  const files = data?.data?.documents?.map((i: any) => i.base64);
  const documents = data?.data?.documents;
  useEffect(() => {
    if (!location.state?.view) handleEdit();
  }, []);
  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="edit-building-master">
          <div className="edit-building-master__container">
            <div className="edit-building-master__title">
              <TitleContent
                leftIcon={true}
                title={infrastructureMasterMessages.editTitle}
                handleBack={() => navigate(`${appRoutes.MASTER.FACILITY.ROOT}`)}
              />
            </div>
            <div className="edit-building-master__form__container">
              <div className="edit-building-master__form mini">
                <Form className="edit-building-master__form__wrapper">
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="name"
                        rules={{
                          required: infrastructureMasterMessages.requireInfrastructureCode,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return infrastructureMasterMessages.requireInfrastructureCode;
                            }
                            if (regex.validateNameDevice.test(value?.trim()) == false) {
                              return infrastructureMasterMessages.validateName;
                            }
                          },
                        }}
                        defaultValue={data?.data?.name}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            label={
                              <>
                                {infrastructureMasterMessages.infrastructureCode}
                                <RequiredLabel />
                              </>
                            }
                            disable={disable}
                            placeholder={!disable ? infrastructureMasterMessages.placeholder : ''}
                            maxLength={constant.maxLengthName}
                            msgServer={!resetState && err['name']}
                            msgValidate={!resetState && errors?.name?.message}
                            onBlur={(e: any) => setValue('name', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="note"
                        control={control}
                        defaultValue={data?.data?.note}
                        render={({ field }) => (
                          <TextAreaField
                            help={<ErrorMessage message={errors.note?.message ?? err['note']} />}
                            label={infrastructureMasterMessages.note}
                            props={field}
                            placeholder={!disable ? infrastructureMasterMessages.placeholder : ''}
                            disable={disable}
                            state={(!resetState && errors.note) || err['note'] ? typeFileMsg.error : ''}
                            onBlur={(e: any) => setValue('note', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      {!disable ? (
                        <>
                          <UploadWrapper
                            label={infrastructureMasterMessages.document}
                            data={documents}
                            setValues={setValue}
                            multiple={true}
                          />
                          <ErrorMessage message={Object.keys(err).reverse()[0]?.includes('files')} />
                        </>
                      ) : (
                        <>
                          <Form.Item
                            label={infrastructureMasterMessages.document}
                            className={files?.length <= 0 ? 'no-document' : ''}
                            colon={false}>
                            {files?.length <= 0 && <span>{masterMessages.noDocumentMsg}</span>}
                            <CarouselCustom data={documents} slidesQuantity={4} />
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      {location.state?.view && disable && (
                        <div style={{ display: edit }} className="edit-btn">
                          <ButtonWrap
                            iconLeft={<EditOutlined />}
                            text={infrastructureMasterMessages.submitBtn}
                            backgroundColor="#1C282F"
                            onClick={handleEdit}
                          />
                        </div>
                      )}

                      <div className="btn-wrapper" style={{ display: display }}>
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #247E84"
                          text={infrastructureMasterMessages.cancel}
                          width="100%"
                          height="56px"
                          onClick={handleCancel}
                        />
                        <ButtonWrap
                          type="primary"
                          htmlType="submit"
                          text={infrastructureMasterMessages.done}
                          width="100%"
                          height="56px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditFacilityMaster;
