import React, { FC } from 'react';
import { notification, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DetailEvent } from './DetailCalendar';
import planApi from '../../../../api/planApi';
import { useQuery } from 'react-query';
import { japanesePlanMessages as planMessages, userMgmtMessages } from '../../../../utils/messages';
import EmptyWrap from '../../../../components/commons/EmptyWrap';
import './style.scss';

interface CalendarContentRightProps {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarContentRight: FC<CalendarContentRightProps> = ({ selectedDate, setSelectedDate }) => {
  const { isFetching, isError, data, error }: any = useQuery(['plans_per_date', selectedDate.toDateString()], () =>
    planApi.getPlansPerDateCalendar(selectedDate?.getMonth() + 1, selectedDate?.getFullYear(), selectedDate?.getDate())
  );
  if (isError || data?.success === false) {
    notification.error({
      message: error.code || userMgmtMessages.error,
      description: error?.message || data?.message,
    });
  }

  const convertDay = (data: Date | undefined) => {
    if (data) {
      const day = data.getDay();
      const days = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
      return days[day];
    }
  };

  const handlePreDate = () => {
    var date = new Date(selectedDate);
    date.setDate(date.getDate() - 1);
    setSelectedDate(date);
  };

  const handleNextDate = () => {
    var date = new Date(selectedDate);
    date.setDate(date.getDate() + 1);
    setSelectedDate(date);
  };

  return (
    <>
      <div className="calendar-list__content__Right">
        <div className="calendar-list__content__Right__title">
          <div className="calendar-list__content__Right__title__previous" onClick={handlePreDate}>
            <LeftOutlined />
          </div>

          <div className="calendar-list__content__Right__title__middle">
            <div className="calendar-list__content__Right__title__middle__day">{selectedDate?.getDate()}</div>

            <div className="calendar-list__content__Right__title__middle__month">
              <div className="calendar-list__content__Right__title__middle__month__top">{convertDay(selectedDate)}</div>
              <div className="calendar-list__content__Right__title__middle__month__bottom">
                {`${selectedDate?.getFullYear()}年${selectedDate?.getMonth() + 1}月`}
              </div>
            </div>
          </div>
          <div className="calendar-list__content__Right__title__next" onClick={handleNextDate}>
            <RightOutlined />
          </div>
        </div>
        <div className="calendar-list__content__Right__content" id="plans-scroll-div">
          {isFetching ? (
            <Spin tip={planMessages.loading}></Spin>
          ) : data && data?.data.data.length > 0 ? (
            data?.data.data.map((plan: any) => (
              <DetailEvent key={plan.id} status={plan.status} plan={plan}></DetailEvent>
            ))
          ) : (
            <EmptyWrap />
          )}
        </div>
      </div>
    </>
  );
};

export default CalendarContentRight;
