import { notification } from 'antd';
import { generalMessages } from '../../../utils/messages';

const HandleError = (error: any) => {
  const status = error?.response?.status;
  if (status === 500 || status === 504)
    notification.error({
      message: generalMessages.error,
      description: error?.response?.data?.message ? error?.response.data.message : error?.message,
    });
  else
    notification.error({
      message: generalMessages.error,
      description: error?.response?.data?.message,
    });
};

export default HandleError;
