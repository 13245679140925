import { Building, Staff } from '../models';
import axiosClient from './axiosClient';

const list = 'list/';

const listApi = {
  getStaffs(): Promise<Staff[]> {
    const url = `${list}staffs`;
    return axiosClient.get(url);
  },
  getBuilding(): Promise<Building[]> {
    const url = `${list}buildings`;
    return axiosClient.get(url);
  },

  getFacBuildingItem(): Promise<any[]> {
    const url = `${list}facility-building-item`;
    return axiosClient.get(url);
  },
};
export default listApi;
