import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '../App';
import { configs } from '../configs';
import { appRoutes } from '../router/routes';

const axiosClient = axios.create({
  baseURL: `${configs.domain}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const access_token = localStorage.getItem('access_token');
    if (access_token && config.headers) {
      config.headers
        ? (config.headers.Authorization = `Bearer ${access_token}`)
        : (config.headers = {
            Authorization: `Bearer ${access_token}`,
          });
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error) {
    switch (error.response.status) {
      case 419:
        return error.response.data;
      case 401:
        return localStorage.removeItem('access_token');
      case 402:
        return error.response.data;
      case 403:
        return history.push(`${appRoutes.HOME.ROOT}`);
      case 502:
        return error;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
