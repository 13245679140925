import { DetailsResponse, Device, ListResponse } from '../models';
import { generalMessages } from '../utils/messages/general';
import axiosClient from './axiosClient';

const device = 'device';

const deviceApi = {
  getAll(): Promise<ListResponse<Device>> {
    const url = device;
    return axiosClient.get(url);
  },
  get(id: number | undefined): Promise<ListResponse<Device>> {
    const url = `${device}/${id}`;
    return axiosClient.get(url);
  },
  getPage(
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    type: string | undefined
  ): Promise<DetailsResponse<Device>> {
    const url = `${device}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}`;
    return axiosClient.get(url);
  },
  add(data: any): Promise<ListResponse<Device>> {
    const url = device;
    return axiosClient.post(url, data);
  },
  edit({ id, data }: { id: number; data: any }): Promise<DetailsResponse<Device>> {
    const url = `${device}/${id}`;
    return axiosClient.put(url, data);
  },
  delete(id: number): Promise<DetailsResponse<Device>> {
    const url = `${device}/${id}`;
    return axiosClient.delete(url);
  },
};

export default deviceApi;
