export const addUserMessages = {
  cancel: 'キャンセル',
  done: '確定',
  editBtn: '編集',
  save: '保存',
  titleAdd: 'ユーザー作成',
  titleEdit: 'ユーザー情報',
  contract: '契約コード',
  username: 'ユーザー名',
  fullname: '名前',
  fullnameKatakana: '名前カナ',
  status: 'ステータス',
  address1: '住所1',
  address2: '住所2',
  postalCode: '郵便番号',
  userType: 'ユーザー種別',
  phoneNumber: '電話番号',
  email: 'メールアドレス',
  placeholder: '入力してください。',
  requiredUsername: 'ユーザー名は必須です。',
  requiredContract: '契約コードは必須です。',
  requiredFullname: '名前は必須です。',
  requiredStatus: 'ステータスは必須です。',
  requiredFullnameKatakana: '名前カナは必須です。',
  requiredPostalCode: '郵便番号は必須です。',
  requiredUserType: 'ユーザー種別は必須です。',
  requiredEmail: 'メールアドレスは必須です。',
  requiredPhoneNumber: '電話番号は必須です。',
  requiredAddress1: '住所1は必須です。',
  validateUsername: 'ユーザー名の形式が正しくありません。',
  validateFullname: '名前の形式が正しくありません。',
  validateFullnameKatakana: '名前カナの形式が正しくありません。',
  validatePostalCode: '郵便番号の形式が正しくありません。',
  validateConfirmPassword: '新しいパスワードと新しいパスワード・確認が一致しません',
  existPassword: '現在のパスワードと新しいパスワードが同じです。',
  validateEmail: 'メールアドレスの形式が正しくありません。',
  validatePhoneNumber: '電話番号の形式が正しくありません。',
  validateAddress1: '住所の形式が正しくありません。',
  password: '新しいパスワード',
  confirmPassword: '新しいパスワード・確認',
  requiredPassword: '新しいパスワードは必須です。',
  requiredConfirmPassword: '新しいパスワード・確認は必須です。',
  passwordDefault: '********',
  changePassword: 'パスワード変更',
  popupTitle: 'パスワード変更',
  currentPwd: '現在のパスワード',
  newPwd: '新しいパスワード',
  confirmPwd: '新しいパスワード・確認',
  currentPwdPlaceholder: '現在のパスワード',
  newPwdPlaceholder: '新しいパスワード',
  confirmPwdPlaceholder: '新しいパスワード・確認',
  validatePassword: '新しいパスワードの形式が正しくありません。',
  validateCurrentPassword: '現在のパスワードが不正です。',
  rulePassword1: '8文字以上で、英字・数字・記号※を各１文字以上含んでください。',
  rulePassword2: '※記号 ( @!#"$%&-^[;:],./<>?_+*}`(){=~|¥ )',
  requireCurrentPwd: '現在のパスワードは必須です。',
};
