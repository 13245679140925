import React, { FC } from 'react';
import './style.scss';

interface IProps {}

const Register: FC<IProps> = (props) => {
  return <>Register</>;
};

export default Register;
