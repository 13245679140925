import { useState } from 'react';

let resolveCallback: any;
function useConfirm() {
  const [confirmState, setConfirmState] = useState(false);
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = () => {
    setConfirmState(true)
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    setConfirmState(false);
  };

  return { confirm, onConfirm, onCancel, confirmState, setConfirmState };
}

export default useConfirm;
