export const FileTextIcon = () => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2797 5.26406L11.2359 0.220313C11.0953 0.0796875 10.9055 0 10.7062 0H0.75C0.335156 0 0 0.335156 0 0.75V20.25C0 20.6648 0.335156 21 0.75 21H15.75C16.1648 21 16.5 20.6648 16.5 20.25V5.79609C16.5 5.59687 16.4203 5.40469 16.2797 5.26406ZM14.7703 6.14062H10.3594V1.72969L14.7703 6.14062ZM14.8125 19.3125H1.6875V1.6875H8.76562V6.75C8.76562 7.01107 8.86934 7.26145 9.05394 7.44606C9.23855 7.63066 9.48893 7.73438 9.75 7.73438H14.8125V19.3125ZM8.0625 12.9844H3.75C3.64687 12.9844 3.5625 13.0687 3.5625 13.1719V14.2969C3.5625 14.4 3.64687 14.4844 3.75 14.4844H8.0625C8.16563 14.4844 8.25 14.4 8.25 14.2969V13.1719C8.25 13.0687 8.16563 12.9844 8.0625 12.9844ZM3.5625 9.98438V11.1094C3.5625 11.2125 3.64687 11.2969 3.75 11.2969H12.75C12.8531 11.2969 12.9375 11.2125 12.9375 11.1094V9.98438C12.9375 9.88125 12.8531 9.79688 12.75 9.79688H3.75C3.64687 9.79688 3.5625 9.88125 3.5625 9.98438Z"
        fill="#828282"
      />
    </svg>
  );
};
