import moment from 'moment-timezone';

export const truncateString = (string = '', length = 20) => {
  if (string.length > length) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};

export const getMoment = () => {
  moment.tz.setDefault(process.env.TIME_ZONE || 'Asia/Tokyo');
  return moment;
};
