import { createBrowserHistory } from 'history';
import { FC } from 'react';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import './App.less';
import './App.scss';
import MainLayout from './layout/Main';
import AddUser from './pages/AddUser';
import AdminProfile from './pages/AdminProfile';
import Analyze from './pages/Analysis';
import CalendarList from './pages/CalendarList';
import CreateBuildingMaster from './pages/CreateMaster/Building';
import CreateDeviceMaster from './pages/CreateMaster/Device';
import CreateInfrastructurMaster from './pages/CreateMaster/Facility';
import EditBuildingMaster from './pages/EditMaster/Building';
import EditDeviceMaster from './pages/EditMaster/Device';
import EditFacilityMaster from './pages/EditMaster/Facility';
import EditUser from './pages/EditUser';
import EquipmentManagement from './pages/EquipmentManagement';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Login from './pages/Login';
import MasterBuilding from './pages/Master/Building';
import MasterDevice from './pages/Master/Device';
import MasterFacility from './pages/Master/Facility';
import MasterAction from './pages/MasterAction';
import BuildingMap from './pages/Plan/Map';
import NotFound from './pages/NotFound';
import History from './pages/Plan/History';
import PlanAction from './pages/PlanAction';
import PlanManagement from './pages/PlanManagement';
import CreatePlan from './pages/PlanManagement/CreatePlan';
import ReviewPlan from './pages/PlanManagement/ReviewPlan';
import Profile from './pages/Profile';
import Register from './pages/Register';
import UserAction from './pages/UserAction';
import UserManagement from './pages/UserManagement';
import OpenSiteRoutes from './router/openSiteRoute';
import PrivateRoute from './router/privateRoute';
import { ROLE } from './router/role';
import RoleRoute from './router/roleRoute';
import { appRoutes } from './router/routes';

export const history = createBrowserHistory();
interface IProps {}

const App: FC<IProps> = (props) => {
  return (
    <div className="app">
      <HistoryRouter history={history}>
        <Routes>
          <Route path="/" element={<OpenSiteRoutes />}>
            <Route element={<MainLayout header={false} />}>
              <Route path={`${appRoutes.AUTH.LOGIN}`} element={<Login />} />
              <Route path={`${appRoutes.AUTH.FORGOT_PASSWORD}`} element={<ForgotPassword />} />
            </Route>
            <Route element={<MainLayout header={true} />}>
              <Route path={`${appRoutes.AUTH.REGISTER}`} element={<Register />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<RoleRoute roles={[ROLE.ADMIN]} />}>
              <Route element={<MainLayout header={true} />}>
                <Route path={`${appRoutes.ADMIN.ROOT}`} element={<UserAction />} />
                <Route path={`${appRoutes.ADMIN.ADD}`} element={<AddUser />} />
                <Route path={`${appRoutes.ADMIN.EDIT}`} element={<EditUser />} />
                <Route path={`${appRoutes.ADMIN.LIST}`} element={<UserManagement />} />
                <Route path={`${appRoutes.ADMIN.PROFILE}`} element={<AdminProfile />} />
              </Route>
            </Route>
            <Route element={<RoleRoute roles={[ROLE.STAFF]} />}>
              <Route element={<MainLayout header={true} />}>
                <Route path={`${appRoutes.MASTER.ROOT}`} element={<MasterAction />} />
                <Route path={`${appRoutes.MASTER.FACILITY.ROOT}`} element={<MasterFacility />} />
                <Route path={`${appRoutes.MASTER.FACILITY.ADD}`} element={<CreateInfrastructurMaster />} />
                <Route path={`${appRoutes.MASTER.FACILITY.EDIT}`} element={<EditFacilityMaster />} />
                <Route path={`${appRoutes.MASTER.BUILDING.ROOT}`} element={<MasterBuilding />} />
                <Route path={`${appRoutes.MASTER.BUILDING.ADD}`} element={<CreateBuildingMaster />} />
                <Route path={`${appRoutes.MASTER.BUILDING.EDIT}`} element={<EditBuildingMaster />} />
                <Route path={`${appRoutes.MASTER.DEVICE.ROOT}`} element={<MasterDevice />} />
                <Route path={`${appRoutes.MASTER.DEVICE.ADD}`} element={<CreateDeviceMaster />} />
                <Route path={`${appRoutes.MASTER.DEVICE.EDIT}`} element={<EditDeviceMaster />} />
                <Route path={`${appRoutes.USER.ADD}`} element={<AddUser />} />
                <Route path={`${appRoutes.USER.EDIT}`} element={<EditUser />} />
                <Route path={`${appRoutes.USER.ROOT}`} element={<UserAction />} />
                <Route path={`${appRoutes.USER.LIST}`} element={<UserManagement />} />
                <Route path={`${appRoutes.USER.PROFILE}`} element={<Profile />} />
                <Route path={`${appRoutes.DEVICE.ROOT}`} element={<EquipmentManagement />} />
                <Route path={`${appRoutes.PLAN.ROOT}`} element={<PlanAction />} />
                <Route path={`${appRoutes.PLAN.LIST}`} element={<PlanManagement />} />
                <Route path={`${appRoutes.PLAN.CALENDAR}`} element={<CalendarList />} />
                <Route path={`${appRoutes.PLAN.CREATE}`} element={<CreatePlan />} />
                <Route path={`${appRoutes.PLAN.REVIEW}`} element={<ReviewPlan />} />
                <Route path={`${appRoutes.PLAN.HISTORY}`} element={<History />} />
                <Route path={`${appRoutes.PLAN.MAP}`} element={<BuildingMap />} />
                <Route path={`${appRoutes.ANALYSIS.ROOT}`} element={<Analyze />} />
              </Route>
            </Route>
            <Route element={<RoleRoute roles={[ROLE.STAFF, ROLE.ADMIN]} />}>
              <Route element={<MainLayout header={true} />}>
                <Route index element={<Home />} />
              </Route>
            </Route>
          </Route>
          <Route element={<MainLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </HistoryRouter>
    </div>
  );
};

export default App;
