import { Spin } from 'antd';
import { FC } from 'react';
import { generalMessages } from '../../../utils/messages';
import './style.scss';

interface LoadingProps {}
const Loading: FC<LoadingProps> = () => {
  return (
    <div className="loading-component">
      <Spin size="large" tip={generalMessages.loading} />
    </div>
  );
};

export default Loading;
