import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonWrap, ErrorMessage, TextAreaField, TitleContent } from '../../../components/commons';
import { Col, Form, notification, Row } from 'antd';
import UploadWrapper from '../../../components/Upload';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import facilityApi from '../../../api/facilityApi';
import { appRoutes } from '../../../router/routes';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import { generalMessages, infrastructureMasterMessages, typeFileMsg } from '../../../utils/messages';
import { regex } from '../../../utils/regex';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import InputFieldCustom from '../../../components/InputFieldCustom';
import { constant } from '../../../utils/constants';
import './style.scss';

interface CreateFacilityMasterProps {}

const CreateFacilityMaster: FC<CreateFacilityMasterProps> = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [err, setError]: any = useState({});
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(facilityApi.add, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['facility', 1]).then(() => {
          navigate(`${appRoutes.MASTER.FACILITY.ROOT}`);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'facility' && query.queryKey[1] !== 1,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const handleCancel = () => {
    if (!loading) navigate(`${appRoutes.MASTER.FACILITY.ROOT}`);
  };
  const onSubmit = (data: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.append('name', data.name);
    if (data.note) formData.append('note', data.note);
    data.files?.map((file: any) => {
      formData.append('files[]', file.originFileObj);
    });
    mutation.mutate(formData);
  };

  return (
    <>
      {loading && <Loading />}
      <section className="create-master">
        <div className="create-master__container">
          <div className="create-master__title">
            <TitleContent
              leftIcon={true}
              title={infrastructureMasterMessages.addTitle}
              handleBack={() => navigate(`${appRoutes.MASTER.FACILITY.ROOT}`)}
            />
          </div>
          <div className="create-master__form__wrapper">
            <div className="create-master__form">
              <Form>
                <Row className="create-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="name"
                      rules={{
                        required: infrastructureMasterMessages.requireInfrastructureCode,
                        validate: (value: string) => {
                          if (value?.trim() == '') {
                            return infrastructureMasterMessages.requireInfrastructureCode;
                          }
                          if (regex.validateNameDevice.test(value?.trim()) == false) {
                            return infrastructureMasterMessages.validateName;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxLengthName}
                          label={
                            <>
                              {infrastructureMasterMessages.infrastructureCode}
                              <RequiredLabel />
                            </>
                          }
                          placeholder={infrastructureMasterMessages.placeholder}
                          msgServer={err['name']}
                          msgValidate={errors?.name?.message}
                          onBlur={(e: any) => setValue('name', e.target.value.trim())}
                          autoFocus={true}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="create-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="note"
                      control={control}
                      render={({ field }) => (
                        <TextAreaField
                          help={<ErrorMessage message={err['note'] ?? errors.note?.message} />}
                          label={infrastructureMasterMessages.note}
                          placeholder={infrastructureMasterMessages.placeholder}
                          props={field}
                          state={errors.note || err['note'] ? typeFileMsg.error : ''}
                          onBlur={(e: any) => setValue('note', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="create-master__form__item">
                  <Col span={24}>
                    <UploadWrapper label={infrastructureMasterMessages.document} setValues={setValue} multiple={true} />
                  </Col>
                </Row>
                <Row className="create-master__form__item btn-wrapper" gutter={[24, 0]}>
                  <Col span={12}>
                    <ButtonWrap
                      type="default"
                      color="#49ABB1"
                      backgroundColor="#1C282F"
                      border="1px solid #247E84"
                      text={infrastructureMasterMessages.cancel}
                      width="100%"
                      height="56px"
                      onClick={handleCancel}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonWrap
                      type="primary"
                      htmlType="submit"
                      text={infrastructureMasterMessages.sure}
                      width="100%"
                      height="56px"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateFacilityMaster;
