export const userMgmtMessages = {
  title: 'ユーザー一覧',
  titleUserAction: 'ユーザー管理',
  name: 'ユーザー名',
  email: 'メールアドレス',
  phone: '電話番号',
  role: '役割',
  status: 'ステータス',
  cancel: 'キャンセル',
  delete: '削除',
  error: 'エラー',
  valid: '有効',
  invalid: '無効',
  manager: '管理者',
  userManagementTitle: 'ユーザー一覧',
  addUserTitle: 'ユーザー作成',
  typeManageContract: '契約管理者',
  typeManagePlan: '作業計画者',
  typeImplementPlan: '作業者',
  titleConfirm: '削除',
  textConfirm: '削除してもよろしいですか。',
  yes: 'はい',
  no: 'いいえ',
};
