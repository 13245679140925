import { FC } from 'react';
import UserAction from '../UserAction';
import { ROLE } from '../../router/role';
import PlanAction from '../PlanAction';

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const role = localStorage.getItem('role');
  return (
    <>
      {role && role === ROLE.STAFF && <PlanAction />}
      {role && role === '9' && <UserAction />}
    </>
  );
};

export default Home;
