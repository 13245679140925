import { Card, Image } from 'antd';
import { FC } from 'react';
import { ReactNode } from 'react';
import './style.scss';

interface CardWrapProps {
  width?: string;
  height?: string;
  img?: string;
  handleAction: any;
  title: string;
  widthImg?: string;
  heightImg?: string;
  description?: string;
  className?: string;
  icon?: ReactNode;
}

const CardWrap: FC<CardWrapProps> = ({
  width,
  height,
  img,
  handleAction,
  title,
  widthImg,
  heightImg,
  description,
  className,
  icon,
}) => {
  return (
    <div className={className}>
      <Card onClick={handleAction} className="card-holder" style={{ width: width, height: height }}>
        {img && (
          <div className="card-holder__icon">
            <Image width={widthImg} height={heightImg} preview={false} src={img} />
          </div>
        )}
        {icon && (
          <div className="card-holder__icon">
            <div
              className="icon-container"
              style={{
                width: widthImg,
                height: heightImg,
              }}>
              {icon}
            </div>
          </div>
        )}
        <div className="card-holder__title">{title}</div>
        {description && <div className="card-holder__description">{description}</div>}
      </Card>
    </div>
  );
};

export default CardWrap;
