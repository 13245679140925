import { Col, Form, Row } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import authApi from '../../api/authApi';
import { LogoIcon } from '../../assets/icons';
import { ButtonWrap, ErrorMessage, InputField, InputPassword } from '../../components/commons';
import ErrorPopup from '../../components/commons/Error';
import { appRoutes } from '../../router/routes';
import { actions } from '../../store';
import { useStore } from '../../store/hooks';
import { loginMessages, typeFileMsg } from '../../utils/messages';
import { regex } from '../../utils/regex';
import './style.scss';

interface LoginProps {}

const Login: FC<LoginProps> = (props) => {
  const [, dispatch] = useStore();
  const { error, mutateAsync, data }: any = useMutation('login', authApi.login);
  const [loginForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState({ enable: false, helpText: loginMessages.requireAccount });
  const emailErrorRef = useRef(emailError);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [password, setPassword] = useState(loginMessages.requirePassword);
  if (data?.success) {
    localStorage.setItem('access_token', data.data.access_token);
    localStorage.setItem('role', data.data.role);
    dispatch(actions.setIsAuthenticated(data.data.access_token));
  }

  // validate email field
  useEffect(() => {
    emailErrorRef.current = emailError;
    if (emailError.enable) {
      loginForm.validateFields();
    }
  }, [emailError]);

  // update message error
  useEffect(() => {
    if (error || data?.success === false) {
      setLoading(false);
      setEmailError({ enable: true, helpText: error.response?.data?.message || data?.message });
    }
  }, [error || data?.success]);

  const handleForgotPassword = () => {
    navigate(`${appRoutes.AUTH.FORGOT_PASSWORD}`);
  };
  return (
    <>
      <div className="login">
        <div className="login__header">
          <div className="login__header__background-first"></div>
          <div className="login__header__background-second"></div>
          <div className="login__header__image-logo">
            <LogoIcon />
          </div>
          <h1>{loginMessages.titleLogin}</h1>
        </div>
        <div className="form-block">
          <Form
            name="basic"
            layout="vertical"
            onFinish={async (value) => {
              setIsSubmit(true);
              if (emailError.helpText === '' && password === '') {
                setLoading(true);
                await mutateAsync(value);
              }
            }}
            onChange={(props: any) => {
              if (props.target.id === 'basic_email') {
                if (!props.target.value) setEmailError({ enable: false, helpText: loginMessages.requireAccount });
                else if (!regex.validateEmail.test(props.target.value))
                  setEmailError({ enable: emailError.enable, helpText: loginMessages.validateEmail });
                else if (regex.validateEmail.test(props.target.value))
                  setEmailError({ enable: emailError.enable, helpText: '' });
              }
              if (props.target.id === 'basic_password') {
                if (
                  !(
                    emailError.helpText === loginMessages.requireAccount ||
                    emailError.helpText === loginMessages.validateEmail ||
                    emailError.helpText === ''
                  )
                )
                  setEmailError({ enable: emailError.enable, helpText: '' });
                if (!props.target.value) setPassword(loginMessages.requirePassword);
                else setPassword('');
              }
            }}
            form={loginForm}
            autoComplete="off">
            <Row className="form-block__row-input">
              <Col span={24}>
                <InputField
                  help={<ErrorMessage message={isSubmit && emailError.helpText !== '' ? emailError.helpText : ''} />}
                  label={loginMessages.email}
                  name={'email'}
                  placeholder={loginMessages.placeholderAccount}
                  autoFocus={true}
                  state={isSubmit && emailError.helpText !== '' ? typeFileMsg.error : ''}
                  disable={loading}
                />
              </Col>
            </Row>
            <Row className="form-block__row-input">
              <Col span={24}>
                <InputPassword
                  help={<ErrorMessage message={(isSubmit && password) ?? ''} />}
                  label={loginMessages.password}
                  name={'password'}
                  placeholder={loginMessages.placeholderPassword}
                  state={isSubmit && password !== '' ? typeFileMsg.error : ''}
                  disable={loading}
                />
              </Col>
            </Row>
            <Row className="form-block__row-button">
              <Col span={24}>
                <ButtonWrap
                  type="primary"
                  htmlType="submit"
                  text={loginMessages.login}
                  width={'100%'}
                  height="57px"
                  loading={loading}
                />
              </Col>
              <Col span={24}>
                <ButtonWrap
                  type="primary"
                  htmlType="button"
                  text={loginMessages.forgotPassword}
                  width={'100%'}
                  height="57px"
                  border="1px solid #247E84"
                  color="#247E84"
                  ghost={true}
                  disabled={loading}
                  onClick={handleForgotPassword}
                />
              </Col>
            </Row>
          </Form>
        </div>
        {visible && (
          <ErrorPopup
            unVisible={() => setVisible(false)}
            actions={() => {
              localStorage.removeItem('access_token');
              setVisible(false);
            }}
            width="415px"
          />
        )}
      </div>
    </>
  );
};

export default Login;
