import { configs } from '../configs';
import { DetailsResponse, ListResponse } from '../models';
import { BuildingItem } from '../models/buildingItem';
import { generalMessages } from '../utils/messages/general';
import axiosClient from './axiosClient';

const buildingItem = 'building-item';

const buildingItemApi = {
  getAll(): Promise<ListResponse<BuildingItem>> {
    const url = buildingItem;
    return axiosClient.get(url);
  },
  get(id: string | undefined): Promise<DetailsResponse<BuildingItem>> {
    const url = `${buildingItem}/${id}`;
    return axiosClient.get(url);
  },
  getPage(
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    type: string | undefined
  ): Promise<ListResponse<BuildingItem>> {
    const url = `${buildingItem}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}`;
    return axiosClient.get(url);
  },
  add(data: any): Promise<DetailsResponse<BuildingItem>> {
    const url = buildingItem;
    return axiosClient.post(url, data, configs.header);
  },
  edit({ id, data }: { id: string; data: any }): Promise<DetailsResponse<BuildingItem>> {
    const url = `${buildingItem}/${id}`;
    return axiosClient.post(url, data, configs.header);
  },
  delete(id: string): Promise<DetailsResponse<BuildingItem>> {
    const url = `${buildingItem}/${id}`;
    return axiosClient.delete(url);
  },
};

export default buildingItemApi;
