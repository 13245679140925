import { RightOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import PlanStatus, { statusStr, statusText } from '../../../../../components/commons/PlanStatus';
import { Plan } from '../../../../../models';
import { japanesePlanMessages as planMessages } from '../../../../../utils/messages/index';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../../router/routes';
import './style.scss';

interface DetailEventProps {
  status?: number;
  plan: any;
}

export const DetailEvent: FC<DetailEventProps> = ({ status = 0, plan }) => {
  const navigate = useNavigate();

  return (
    <div className="calendar-list__content__Right__content__detail">
      <div className={`calendar-list__content__Right__content__detail__header ${statusText[status - 1]}`}>
        <span className="plan-name">{plan.name}</span>
      </div>
      <div className={`calendar-list__content__Right__content__detail__status ${statusText[status - 1]}`}>
        <PlanStatus status={Number(status)} purpose="calendar"></PlanStatus>
      </div>
      {plan.note !== '' && (
        <div className="calendar-list__content__Right__content__detail__content">
          <div className="calendar-list__content__Right__content__detail__content__title">
            {planMessages.planContent}
          </div>
          <div className="calendar-list__content__Right__content__detail__content__text">{plan.note}</div>
        </div>
      )}
      <div
        className="calendar-list__content__Right__content__detail__move"
        onClick={() => {
          navigate(`/${appRoutes.PLAN.ROOT}/${plan.id}`);
        }}>
        <div>{planMessages.detailCalendarBtn}</div>
        <div className="calendar-list__content__Right__content__detail__move__icon">
          <RightOutlined />
        </div>
      </div>
    </div>
  );
};
