export const ImageIcon = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96328 10.932L8.13984 13.257L7.17656 12.0289C7.15902 12.0065 7.13661 11.9884 7.11103 11.9759C7.08545 11.9634 7.05736 11.957 7.02891 11.957C7.00045 11.957 6.97236 11.9634 6.94678 11.9759C6.9212 11.9884 6.89879 12.0065 6.88125 12.0289L4.54219 15.0102C4.52055 15.0378 4.50712 15.071 4.50344 15.1059C4.49976 15.1408 4.50598 15.176 4.52138 15.2075C4.53678 15.2391 4.56075 15.2657 4.59054 15.2842C4.62033 15.3028 4.65474 15.3126 4.68984 15.3125H13.3125C13.4695 15.3125 13.5562 15.132 13.4602 15.0102L10.2609 10.932C10.2432 10.9096 10.2205 10.8915 10.1948 10.879C10.169 10.8665 10.1407 10.86 10.1121 10.86C10.0835 10.86 10.0552 10.8665 10.0294 10.879C10.0037 10.8915 9.98105 10.9096 9.96328 10.932V10.932ZM5.4375 9.35938C5.4375 9.60802 5.53627 9.84647 5.71209 10.0223C5.8879 10.1981 6.12636 10.2969 6.375 10.2969C6.62364 10.2969 6.8621 10.1981 7.03791 10.0223C7.21373 9.84647 7.3125 9.60802 7.3125 9.35938C7.3125 9.11073 7.21373 8.87228 7.03791 8.69646C6.8621 8.52065 6.62364 8.42188 6.375 8.42188C6.12636 8.42188 5.8879 8.52065 5.71209 8.69646C5.53627 8.87228 5.4375 9.11073 5.4375 9.35938ZM17.0297 5.76406L11.9859 0.720313C11.8453 0.579688 11.6555 0.5 11.4562 0.5H1.5C1.08516 0.5 0.75 0.835156 0.75 1.25V20.75C0.75 21.1648 1.08516 21.5 1.5 21.5H16.5C16.9148 21.5 17.25 21.1648 17.25 20.75V6.29609C17.25 6.09687 17.1703 5.90469 17.0297 5.76406ZM15.5203 6.64062H11.1094V2.22969L15.5203 6.64062ZM15.5625 19.8125H2.4375V2.1875H9.51562V7.25C9.51562 7.51107 9.61934 7.76145 9.80394 7.94606C9.98855 8.13066 10.2389 8.23438 10.5 8.23438H15.5625V19.8125Z"
        fill="#828282"
      />
    </svg>
  );
};
