import { FC } from 'react';
import { TitleContent } from '../../components/commons';
import { analysisMessages } from '../../utils/messages';
import BarChart from './BarChart';
import DoughnutChart from './DoughnutChart';
import './style.scss';

interface AnalysisProps {}

const Analysis: FC<AnalysisProps> = (props) => {
  return (
    <>
      <div className="analysis-title">
        <TitleContent leftIcon={false} title={analysisMessages.title} />
      </div>
      <section className="analysis-container">
        <div className="analysis-container__wrapper">
          <div className="analysis-content-wrapper">
            <BarChart />
            <DoughnutChart />
          </div>
        </div>
      </section>
    </>
  );
};

export default Analysis;
