import { useEffect, useState } from 'react';

export default function useMediaQuery(maxWidth: number) {
  const [query, setQuery] = useState(`(max-width: ${maxWidth}px)`);
  const [matches, setMatches] = useState(false);

  const setMaxWidth = (maxWidth: number) => setQuery(`(max-width: ${maxWidth}px)`);

  // check query and listen for media change.
  useEffect(() => {
    if (!query) return;

    const _onChange = (mql: MediaQueryListEvent) => {
      setMatches(mql.matches);
    };

    const mql = window.matchMedia(query); //create an object with type MediaQueryListEvent
    setMatches(mql.matches);

    try {
      mql.addEventListener('change', _onChange);
    } catch {
      mql.addListener(_onChange);
    }

    return () => {
      try {
        mql.removeEventListener('change', _onChange);
      } catch {
        mql.removeListener(_onChange);
      }
    };
  }, [query]);

  return [matches, setMaxWidth];
}
