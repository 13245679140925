import { configs } from '../configs';
import { DetailsResponse, Me } from '../models';
import axiosClient from './axiosClient';

const me = 'me/';
const profileApi = {
  getMyProfile(): Promise<DetailsResponse<Me>> {
    const url = me;
    return axiosClient.get(url);
  },
  editProfile(data: any): Promise<DetailsResponse<Me>> {
    const url = me;
    return axiosClient.post(url, data, configs.header);
  },
  changePassword(data: any): Promise<DetailsResponse<any>> {
    const url = me;
    return axiosClient.post(url, data);
  },
};

export default profileApi;
