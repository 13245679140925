import { Form, Radio } from 'antd';
import React, { FC } from 'react';
import './style.scss';

interface IOption {
  key: string | any;
  value: string;
}

interface IRadioButtonProps {
  label?: string;
  name: string;
  required?: boolean;
  message?: string;
  options: Array<IOption>;
  onChange?: (...event: any[]) => void;
  className?: string;
  disable?: boolean;
  value?: string;
  defaultValue?: string;
  help?: React.ReactNode;
  props?: any;
}

const RadioButton: FC<IRadioButtonProps> = ({
  label,
  name,
  required,
  message,
  options,
  onChange,
  className,
  disable = false,
  value,
  defaultValue,
  help,
  props,
}) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required, message }]} help={help}>
      <Radio.Group
        onChange={onChange}
        className={className}
        value={value}
        defaultValue={defaultValue}
        {...props}
        disabled={disable}
      >
        {options.map(({ key, value }) => (
          <Radio key={key} value={key}>
            {value}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioButton;
