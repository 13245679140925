export const planMessages = {
  titleReviewPlan: 'Review kế hoạch',
  titleListPlan: 'Danh sách kế hoạch',
  titleJobCategory: 'Phân loại công việc',
  labelMaterialFacilities: 'Cơ sở vật chất',
  placeholderMaterialFacilities: 'input',
  labelLocationJob: 'Địa chỉ làm việc',
  placeholderLocationJob: 'Địa chỉ làm việc',
  labelCategoryInspection: 'Phân loại kiểm định',
  placeholderCategoryInspection: 'Phân loại kiểm định',
  labelImplementStaff: 'Nhân viên thực hiện',
  placeholderImplementStaff: 'Nhân viên thực hiện',
  labelStartAt: 'Ngày bắt đầu dự tính',
  labelEndtAt: 'Ngày kết thúc dự tính',
  labelImplementUser: 'User viên thực hiện',
  placeholderImplementUser: 'User viên thực hiện',
  labelDevice: 'Thiết bị',
  placeholderDevice: 'Input',
  labelDocument: 'Tài liệu tham khảo',
  placeholderDocument: 'Chuyển sang default khi chọn csvs',
  labelNote: 'Note',
  placeholderNote: 'Note',
  masterTitle: 'Quản lý Master',
  userTitle: 'Quản lý User',
  deviceTitle: 'Quản lý thiết bị',
  planTitle: 'Kế hoạch',
  analysisTitle: 'Phân tích',
  titleLogout: 'Đăng xuất',
  createUser: 'Tạo user',
  planContent: 'Nội dung kế hoạch',
  detailCalendarBtn: 'Chi tiết',

  saveDraftBtn: 'Save Draft',
  cancelBtn: 'Cancel',
  submitBtn: 'Submit',

  manager: 'Người phụ trách',
  createdTime: 'Thời gian tạo',
  planStatus: 'Status',
  planBuilding: 'Building',
  planType: 'Loại',
  planTypeEveryDay: 'Hằng ngày',
  planTypePeriod: 'Định kỳ',

  doneStatus: 'done',
  ongoingStatus: 'ongoing',
  openStatus: 'open',
  draftStatus: 'draft',

  //Content Form

  titleCreatePlan: 'Lập kế hoạch',

  titlePlanContent: 'Nội dung kế hoạch',
  btnDownLoad: 'Download',
  //Label
  labelNamePlan: 'Tên kế hoạch',
  labelTypeInspection: 'Chủng loại kiểm định',
  labelNameBuilding: 'Tên tòa nhà',
  labelLocationBuilding: 'Địa chỉ tòa nhà',
  labelBuildingDocument: 'Tài liệu tham khảo',
  labelFrequencyInspection: 'Tần suất kiểm định',
  labelAssignedUsers: 'User dự định thực hiện',
  labelStartTime: 'Giờ bắt đầu',
  labelStartDate: 'Ngày bắt đầu dự tính',
  labelEndTime: 'Giờ kết thúc',
  labelEndDate: 'Ngày kết thúc dự tính',
  labelPlanNote: 'Note',
  labelPlanComment: 'Comment',
  labelPlanHistory: 'History',

  //Placeholder
  placeholderPlanContent: 'Nhập kế hoạch',
  placeholderTypeInspection: 'Chủng loại kiểm định',
  placeholderNameBuilding: 'Tên tòa nhà',
  placeholderLocationBuilding: 'Địa chỉ tòa nhà',
  placeholderAssignedUsers: 'Select assigned Users',
  placeholderFrequencyInspection: 'Tần suất kiểm định',
  placeholderStartTime: 'hh:mm:ss',
  placeholderStartDate: 'yyyy/MM/dd',
  placeholderEndTime: 'hh:mm:ss',
  placeholderEndDate: 'yyyy/MM/dd',
  placeholderPlanNote: 'Note',

  // Validation message
  planNameRequired: 'plan name is required',
  typeRequired: 'type is required',
  buildingRequired: 'building is required',
  buildingLocationRequired: 'building location is required',
  frequencyRequired: 'frequency is required',
  assignedUsersRequired: 'user is required',
  startTimeRequired: 'empty',
  startDateRequired: 'start date is required',
  endTimeRequired: 'end time is required',
  endDateRequired: 'end date is required',
  planNoteRequired: 'plan note is required',

  //CheckList Form
  titleListCheckList: 'Danh sách kiểm tra',
  addValue: 'Thêm',
  labelContent: 'Nội dung',
  labelResult: 'Kết quả',
  labelMoreJob: 'Thêm',
  uploadBtn: 'Upload',
  doneReportOptionBtn: 'Xong',
  cancelReportOptionBtn: 'Hủy',

  //Label
  labelJobName: 'Tên công việc',
  labelFacility: 'Cơ sở vật chất',
  labelFalicityDocument: 'Tài liệu tham khảo',
  labelJobBuildingItem: 'Thiết bị',
  labelJobBuildingDocument: 'Tài liệu tham khảo',
  labelJobContent: 'Nội dung công việc',
  lableTypeReport: 'Kiểu báo cáo',
  labelJobNote: 'Note',
  labelFiles: 'Attachment',

  //Placeholder
  placeholderJobName: 'Tên công việc',
  placeholderFacility: 'Choose',
  placeholderJobBuildingItem: 'Choose',
  placeholderJobContent: 'Nội dung công việc',
  placeholderTypeReport: 'Kiểu báo cáo',
  placeholderCheckBoxInputReport: 'Nhập nội dung',
  placeholderRadioInputReport: 'Nhập nội dung',

  placeholderJobNote: 'Nhập nội dung',

  //Validation
  jobNameRequired: 'Job name is required',
  jobNameEmpty: 'Please input new job name',
  jobNameExist: 'Job name already exist',
  facilityRequired: 'facility is required',
  buildingJobItemRequired: 'device id is required',
  jobContentRequired: 'job content is required',
  reportTypeRequired: 'report type is required',
  reportOptionsRequired: 'report options is required',

  checkboxInputReportRequired: 'Please input your content',
  checkBoxEmptyMessage: 'Please choose an item',
  radioInputReportRequired: 'Please input your content',
  radioEmptyMessage: 'Please choose an item',

  noteJobRequired: 'note job is required',
  filesRequired: 'files are required',
  planName: 'Tên kế hoạch',
  planTime: 'Thời gian thực hiện - kết thúc',
  hisotryTitle: 'History',
  textConfirm: 'Are you sure to clone?',

  //inspection
  inspectionRound: 'Round',
  inspectionActtachments: 'Attachment',
  inspectionVoice: 'Voice',
  inspectionReject: 'Reject',
  inspectionApprove: 'Approve',
  inspectionComment: 'Comment',

  //loading
  loading: 'loading...',
};

export const japanesePlanMessages = {
  mapCreatePlan: '計画作成',
  mapListPlan: '計画一覧',
  planTitle: '点検計画管理',
  titleListPlan: '点検計画一覧',
  titleShowMap: '地図検索',
  titleReviewPlan: '点検計画確認',
  //Content Form
  titleCreatePlan: '点検計画作成',
  titlePlanContent: '点検計画',
  btnDownLoad: 'ダウンロード<',
  //Label
  labelNamePlan: '点検計画名',
  labelTypeInspection: '点検種別',
  labelNameBuilding: '建物名',
  labelLocationBuilding: '住所',
  labelBuildingDocument: '参考資料',
  labelFrequencyInspection: '点検頻度',
  labelAssignedUsers: '実施ユーザー',
  labelStartTime: '開始予定時刻',
  labelStartDate: '開始予定日',
  labelEndTime: '終了予定時刻',
  labelEndDate: '終了予定日',
  labelPlanNote: '備考',
  labelPlanComment: 'コメント',
  labelPlanHistory: '履歴',
  labelInspectionResult: '点検結果',

  //Placeholder
  placeholderPlanContent: '入力してください',
  placeholderTypeInspection: 'Chủng loại kiểm định',
  placeholderNameBuilding: '選択してください',
  placeholderLocationBuilding: '',
  placeholderAssignedUsers: '選択してください',
  placeholderFrequencyInspection: 'Tần suất kiểm định',
  placeholderStartTime: 'HH:mm',
  placeholderStartDate: 'yyyy/MM/dd',
  placeholderEndTime: 'HH:mm',
  placeholderEndDate: 'yyyy/MM/dd',
  placeholderPlanNote: '入力してください',
  placeholderBuildingAttachment: '建物名を選択してください。',
  placeholderBuildingAttachmentEmpty: '添付ファイルがありません。',

  // Validation message
  planNameRequired: '点検計画名は必須です。',
  typeRequired: '点検種別は必須です。',
  buildingRequired: '建物名は必須です。',
  frequencyRequired: '点検頻度は必須です。',
  assignedUsersRequired: '実施ユーザーは必須です。',
  startDateRequired: '開始予定日は必須です。',
  endDateRequired: '終了予定日は必須です。',
  validationStartDate: '開始予定日が正しくありません。',
  validationEndDate: '終了予定日が正しくありません。',
  validationStartTime: '開始予定時刻が正しくありません',
  validationEndTime: '終了予定時刻が正しくありません',

  //CheckList Form
  titleListCheckList: '点検内容一覧',
  addValue: '追加',
  labelContent: '点検内容',
  labelResult: '点検結果',
  labelMoreJob: '点検内容追加',
  uploadBtn: 'ファイル追加',
  doneReportOptionBtn: '確定',
  cancelReportOptionBtn: 'キャンセル',

  //Label
  labelJobName: '点検計画名',
  labelFacility: '施設',
  labelFalicityDocument: '参考資料',
  labelJobBuildingItem: '設備',
  labelJobBuildingDocument: '参考資料',
  labelJobContent: '点検内容',
  lableTypeReport: '点検結果報告区分',
  labelJobNote: '備考',
  labelFiles: '添付ファイル',
  labelRealStartTime: '開始日時',
  labelRealEndTime: '終了日時',

  //Placeholder
  placeholderJobName: '入力してください',
  placeholderFacility: '選択してください',
  placeholderJobBuildingItem: '選択してください',
  placeholderJobContent: '入力してください',
  placeholderTypeReport: 'ラジオ選択',
  placeholderCheckBoxInputReport: '入力してください',
  placeholderRadioInputReport: '入力してください',
  placeholderJobNote: '入力してください',
  placeholderDocuments: '添付ファイルがありません。',

  //Validation
  jobNameRequired: '点検内容名は必須です。',
  jobNameEmpty: '点検内容名は必須です。',
  jobNameExist: '点検内容名が重複しています。',
  jobContentRequired: '点検内容は必須です。',
  reportTypeRequired: '点検結果報告区分は必須です。',
  reportOptionsRequired: '点検結果報告区分は必須です。',

  checkboxInputReportRequired: 'オプションの内容は必須です。',
  checkBoxEmptyMessage: 'オプションは一つ以上設定してください。',
  radioInputReportRequired: 'オプションの内容は必須です。',
  radioEmptyMessage: 'オプションは二つ以上設定してください。',
  validationFile: [
    '- ファイルの形式が正しくありません。',
    '※ファイル追加可能な添付ファイルの形式はいかのようになります。',
    '・画像（拡張子：png、jpeg、jpg）',
    '・テキスト（拡張子：txt）',
    '・PDF（拡張子：pdf）',
  ],
  errorType: 'ファイルの形式が正しくありません。',
  validationFileText: '※登録可能なファイル形式は拡張子：png、jpeg、jpg、pdf、txtです。',
  errorFile: 'ファイルのサイズが制限を超えています。',
  validationFilesCapacity: 'ファイルすべての合計サイズの上限は50MBです。',
  saveDraftBtn: '下書き保存',
  cancelBtn: 'キャンセル',
  submitBtn: '確定',
  oneFileCapacityErr: 'アップロードできる最大サイズは、20MBです。',
  contentTooLarge: '413 request entity too large',

  manager: '管理者',
  createdTime: '作成日時',
  planName: '点検計画名',
  planStatus: 'ステータス',
  planBuilding: '建物名',
  planType: '点検種別',
  planTypeEveryDay: '日常',
  planTypePeriod: '定期',

  // status plan
  doneStatus: '完了',
  ongoingStatus: '作業中',
  openStatus: '未着手',
  draftStatus: '下書き',
  cancelStatus: 'キャンセル',

  deleteAllText: 'すべて消去',

  //API Message
  errorSuggestions: 'Can not load suggestions',

  //Default data plan
  jobName: '点検内容1',

  //
  planWorker: '実施ユーザー',
  planTime: '開始予定日時～終了予定日時',
  hisotryTitle: '履歴',
  cloneTitle: 'クローン',
  textConfirm: 'クローンしてもよろしいですか ',
  commentBtn: 'コメント追加',
  commentTitle: '点検内容のコメント',

  //inspection
  inspectionRound: '回目',
  inspectionActtachments: '添付ファイル',
  inspectionVoice: '音声',
  inspectionReject: '否認',
  inspectionApprove: '承認',
  inspectionComment: 'コメント',

  masterTitle: 'マスタ管理',
  userTitle: 'ユーザー管理',
  deviceTitle: '端末管理',
  analysisTitle: '集計',
  titleLogout: 'ログアウト',

  //loading
  loading: '読み込み中',

  titleConfirm: '削除',
  textDeleteConfirm: '削除してもよろしいですか。',

  //comment
  commentPlaceHolder: '入力してください',
  commentRequiredText: 'コメントは必須です。',
  commentMore: 'もっと見る',
  commentLess: '閉じる',

  //time picker
  nowTimePicker: '現在',
  okTimePicker: '確定',

  //download
  downloadPlanText: '点検計画確認',

  //calendar
  moreText: 'もっと見る',
  detailCalendarBtn: '詳細',
  planContent: '点検内容',

  deleted: '削除',
  searchBuildingPlaceholder: '建物名を入力してください。',
  selectFilterplaceholder: '選択してください',

  //confirm
  textChangeBuildingConfirm:
    '建物名を変更すると、点検内容一覧での点検計画に設定されている施設と設備の全てデータがリセットされます。建物名を変更してもよろしいですか。',
};
