export const infrastructureMasterMessages = {
  title: '施設一覧',
  editTitle: '施設情報',
  addTitle: '施設作成',
  submitBtn: '編集',
  cancel: 'キャンセル',
  sure: '確定',
  done: '保存',
  infrastructureCode: '施設名',
  placeholder: '入力してください。',
  note: '詳細',
  document: '参考資料',
  requireInfrastructureCode: '施設名は必須です。',
  requireNote: '詳細を入力してください。',
  validateName: '設備名の形式が正しくありません。',
};
