import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Input, notification, Row, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import userApi from '../../../api/userApi';
import { ErrorMessage, SelectField, TitleContent } from '../../../components/commons';
import ButtonWrap from '../../../components/commons/ButtonWrap';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import { addUserMessages, generalMessages, userMgmtMessages } from '../../../utils/messages';
import PhoneIcon from '../../../components/commons/PhoneIcon';
import { regex } from '../../../utils/regex';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import InputFieldCustom from '../../../components/InputFieldCustom';
import { constant } from '../../../utils/constants';
import { appRoutes } from '../../../router/routes';
import '../style.scss';

interface EditAdminProps {}

const EditAdmin: FC<EditAdminProps> = (props) => {
  const queryClient = useQueryClient();
  const location: any = useLocation();
  const [display, setDisplay] = useState('none');
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(location.state?.view ?? true);
  const [resetState, setState] = useState(false);
  const { id } = useParams();
  const [err, setError]: any = useState({});
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, data, error }: any = useQuery(['user_detail', id], () => userApi.get(id), {
    onError: (error: any) => {
      if (error && error.response.status === 404) {
        navigate(appRoutes.NOT_FOUND);
      }
    },
  });
  const contracts: any = useQuery('contracts', userApi.getContracts);
  const navigate = useNavigate();

  if (isError || data?.success === false) {
    notification.error({
      message: error?.code || userMgmtMessages.error,
      description: error?.message || data?.message,
    });
  }
  const mutation = useMutation(userApi.edit, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([
          queryClient.invalidateQueries(['user', 1]),
          queryClient.invalidateQueries(['user_detail', id]),
        ]);
        if (!location.state?.view) {
          navigate(`${appRoutes.ADMIN.LIST}`);
          notification.success({
            message: generalMessages.successMsg,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'user' && query.queryKey[1] !== 1,
          });
          return;
        }
        setLoading(false);
        setDisplay('none');
        setEdit('flex');
        setDisable(true);
        setState(true);
        notification.success({
          message: generalMessages.successMsg,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'user' && query.queryKey[1] !== 1,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setError({});
    setLoading(true);
    if (id) mutation.mutate({ id, data });
  };
  const handleCancel = () => {
    if (!location.state?.view) navigate(`${appRoutes.ADMIN.LIST}`);
    else {
      reset(data?.data);
      setError({});
      setDisplay('none');
      setEdit('flex');
      setDisable(true);
      setState(true);
    }
  };
  const handleEdit = () => {
    setDisplay('flex');
    setEdit('none');
    setDisable(false);
    if (errors) setState(false);
  };
  useEffect(() => {
    if (!location.state?.view) handleEdit();
  }, []);
  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="create-user create-admin">
          <div className="create-user__container">
            <div className="create-user__title">
              <TitleContent
                leftIcon={true}
                title={addUserMessages.titleEdit}
                handleBack={() => navigate(appRoutes.ADMIN.LIST)}
              />
            </div>
            <div className="create-user__form">
              <Form className="create-user__form__wrapper">
                <Row className="create-user__form__input" gutter={[24, 0]}>
                  <Col xs={24} lg={12} className="create-user__form__input__left">
                    <Row className="create-user__form__item">
                      <SelectField
                        help={<ErrorMessage message="" />}
                        disable={true}
                        label={addUserMessages.contract}
                        defaultValue={data?.data.contract_id}
                        options={contracts.data?.map((i: { id: number; contract_code: string }) => ({
                          key: i.id,
                          value: (
                            <Tooltip placement="topLeft" title={i.contract_code}>
                              <div className="deleted-selection-wrap">
                                <span className="deleted-selection-wrap__content">{`${i.contract_code}`}</span>
                              </div>
                            </Tooltip>
                          ),
                        }))}
                        isElementValue
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="username"
                        rules={{
                          required: addUserMessages.requiredUsername,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredUsername;
                            }
                            if (regex.validateUsername.test(value?.trim()) == false) {
                              return addUserMessages.validateUsername;
                            }
                          },
                        }}
                        defaultValue={data?.data?.username}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtUsername}
                            label={
                              <>
                                {addUserMessages.username}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['username']}
                            msgValidate={!resetState && errors?.username?.message}
                            onBlur={(e: any) => setValue('username', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="full_name"
                        rules={{
                          required: addUserMessages.requiredFullname,
                          validate: (value: string) => {
                            if (value?.trim() == '') {
                              return addUserMessages.requiredFullname;
                            }
                            if (regex.validateName.test(value?.trim()) == false) {
                              return addUserMessages.validateFullname;
                            }
                          },
                        }}
                        defaultValue={data?.data?.full_name}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtFullname}
                            label={
                              <>
                                {addUserMessages.fullname}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['full_name']}
                            msgValidate={!resetState && errors?.full_name?.message}
                            disable={disable}
                            onBlur={(e: any) => setValue('full_name', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="full_name_katakana"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validateName.test(value?.trim()) == false) {
                              return addUserMessages.validateFullnameKatakana;
                            }
                          },
                        }}
                        defaultValue={data?.data?.full_name_katakana}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtFullnameKatakana}
                            label={addUserMessages.fullnameKatakana}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['full_name_katakana']}
                            msgValidate={!resetState && errors?.full_name_katakana?.message}
                            disable={disable}
                            onBlur={(e: any) => setValue('full_name_katakana', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="status"
                        control={control}
                        defaultValue={data?.data?.status}
                        render={({ field }) => (
                          <SelectField
                            help={!resetState && <ErrorMessage message={errors.status?.message ?? err['status']} />}
                            props={field}
                            label={
                              <>
                                {addUserMessages.status}
                                <RequiredLabel />
                              </>
                            }
                            disable={disable}
                            options={[
                              {
                                key: '1',
                                value: (
                                  <Tooltip placement="topLeft" title={userMgmtMessages.valid}>
                                    <div className="deleted-selection-wrap">
                                      <span className="deleted-selection-wrap__content">{`${userMgmtMessages.valid}`}</span>
                                    </div>
                                  </Tooltip>
                                ),
                              },
                              {
                                key: '0',
                                value: (
                                  <Tooltip placement="topLeft" title={userMgmtMessages.invalid}>
                                    <div className="deleted-selection-wrap">
                                      <span className="deleted-selection-wrap__content">{`${userMgmtMessages.invalid}`}</span>
                                    </div>
                                  </Tooltip>
                                ),
                              },
                            ]}
                            error={(!resetState && err['status']) || errors.status?.message}
                            isElementValue
                          />
                        )}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} lg={12} className="create-user__form__input__right">
                    <Row className="create-user__form__item">
                      <Controller
                        name="address_1"
                        defaultValue={data?.data?.address_1}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={addUserMessages.address1}
                            disable={disable}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            msgServer={!resetState && err['address_1']}
                            msgValidate={!resetState && errors?.address_1?.message}
                            onBlur={(e: any) => setValue('address_1', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <Controller
                        name="address_2"
                        control={control}
                        defaultValue={data?.data?.address_2}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtAddress}
                            label={addUserMessages.address2}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['address_2']}
                            msgValidate={!resetState && errors?.address_2?.message}
                            onBlur={(e: any) => setValue('address_2', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>

                    <Row className="create-user__form__item">
                      <Controller
                        name="postal_code"
                        rules={{
                          validate: (value: string) => {
                            if (!!value && regex.validatePostalCode.test(value?.trim()) == false) {
                              return addUserMessages.validatePostalCode;
                            }
                          },
                        }}
                        defaultValue={data?.data?.postal_code}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLenghtPostalCode}
                            label={addUserMessages.postalCode}
                            placeholder={!disable ? addUserMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['postal_code']}
                            msgValidate={!resetState && errors?.postal_code?.message}
                            onBlur={(e: any) => setValue('postal_code', e.target.value.trim())}
                          />
                        )}
                      />
                    </Row>
                    <Row className="create-user__form__item">
                      <InputFieldCustom
                        label={addUserMessages.userType}
                        defaultValue={userMgmtMessages.typeManageContract}
                        disable={true}
                      />
                    </Row>
                    <div className="create-user__form__item">
                      <Row className="holder" gutter={[24, 10]}>
                        <Col xs={24} lg={12} className="holder__email">
                          <InputFieldCustom
                            defaultValue={data?.data?.email}
                            label={addUserMessages.email}
                            disable={true}
                          />
                        </Col>
                        <Col xs={24} lg={12} className="holder__phone__wrapper">
                          <Controller
                            name="number_phone"
                            rules={{
                              validate: (value: string) => {
                                if (!!value && regex.validatePhone.test(value?.trim()) == false) {
                                  return addUserMessages.validatePhoneNumber;
                                }
                              },
                              minLength: {
                                value: constant.minLenghtPhone,
                                message: addUserMessages.validatePhoneNumber,
                              },
                            }}
                            defaultValue={data?.data?.number_phone}
                            control={control}
                            render={({ field }) => (
                              <Form.Item label={addUserMessages.phoneNumber} className="holder__phone" colon={false}>
                                <Input.Group compact>
                                  <PhoneIcon />
                                  <div
                                    className="input-field__phone"
                                    style={resetState ? { backgroundColor: '#384044' } : {}}
                                  >
                                    <InputFieldCustom
                                      msgServer={!resetState && err['number_phone']}
                                      msgValidate={!resetState && errors?.number_phone?.message}
                                      disable={disable}
                                      placeholder={!disable ? addUserMessages.placeholder : ''}
                                      props={field}
                                      maxLength={constant.maxLenghtPhone}
                                      onBlur={(e: any) => setValue('number_phone', e.target.value.trim())}
                                    />
                                  </div>
                                </Input.Group>
                              </Form.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div className="create-user__form__button">
                  {location.state?.view && disable && (
                    <div className="button-edit" style={{ display: edit }}>
                      <ButtonWrap
                        type="default"
                        color="#49ABB1"
                        backgroundColor="#1C282F"
                        border="1px solid #247E84"
                        iconLeft={<EditOutlined />}
                        text={addUserMessages.editBtn}
                        width="100%"
                        height="56px"
                        onClick={handleEdit}
                      />
                    </div>
                  )}

                  <div className="button-wrapper" style={{ display: display }}>
                    <ButtonWrap
                      type="default"
                      color="#49ABB1"
                      backgroundColor="#1C282F"
                      border="1px solid #247E84"
                      text={addUserMessages.cancel}
                      width="100%"
                      height="56px"
                      onClick={handleCancel}
                    />
                    <ButtonWrap
                      type="primary"
                      htmlType="submit"
                      text={addUserMessages.save}
                      width="100%"
                      height="56px"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditAdmin;
