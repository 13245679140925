export const configs = {
  domain: process.env.REACT_APP_API_URL || `${document.location.origin}/api/`,
  header: {
    headers: {
      'Content-Type': 'multipart/form-data',
      type: 'formData',
    },
  },
  staleTime: { staleTime: Infinity },
};
