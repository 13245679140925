import { FC } from 'react';
import { ROLE } from '../../router/role';
import Admin from './Admin';
import Staff from './Staff';

interface AddUserProps {}

const AddUser: FC<AddUserProps> = (props) => {
  return <>{localStorage.getItem('role') === ROLE.STAFF ? <Staff /> : <Admin />}</>;
};

export default AddUser;
