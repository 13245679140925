export const loginMessages = {
  placeholderAccount: 'メールアドレスを入力してください。',
  requireAccount: 'メールアドレスは必須です。',
  placeholderPassword: 'パスワードを入力してください。',
  requirePassword: 'パスワードは必須です。',
  forgotPassword: 'パスワードをお忘れの場合',
  titleLogout: 'ログアウト',
  titleLogin: 'ログイン',
  email: 'メールアドレス',
  password: 'パスワード',
  login: 'サインイン',
  textPopupLogout: 'ログアウトしてよろしいですか。',
  yes: 'はい',
  no: 'いいえ',
  validateEmail: 'メールアドレスの形式が正しくありません。',
};
