import React, { FC, useState } from 'react';
import { PlanStatus, TitleContent } from '../../../components/commons';
import TableWrap from '../../../components/TableWrap';
import { useQuery } from 'react-query';
import { analysisMessages, generalMessages, japanesePlanMessages as planMessages } from '../../../utils/messages';
import planApi from '../../../api/planApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Menu, notification, Space, Spin, Tooltip } from 'antd';
import PaginationWrapper from '../../../components/commons/Pagination';
import { Plan } from '../../../models';
import { CaretDownOutlined, CaretUpOutlined, EditOutlined, FilterFilled } from '@ant-design/icons';
import { appRoutes } from '../../../router/routes';
import { getMoment } from '../../../utils/helper';
import Loading from '../../../components/commons/Loading';
import SearchField from '../../../components/SearchField';
import './styles.scss';

const moment = getMoment();

interface IHistoryProps {}

const History: FC<IHistoryProps> = (props) => {
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [sort, setSort]: any = useState({});
  const [filter, setFilter]: any = useState();
  const [filter_status, setFilterStatus]: any = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isError, data, error, isFetching }: any = useQuery(
    ['plan_history', id, currentPage, filter, filter_status, sort],
    () => planApi.getPlanHistoryById(id, currentPage, filter, type ? sort[type] : undefined, filter_status, type),
    {
      onError: (error: any) => {
        if (error && error.response.status === 404) {
          navigate(appRoutes.NOT_FOUND);
        }
      },
    }
  );

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const handleMenuClick = (e: any) => {
    setCurrentPage(1);
    setFilterStatus(e.key);
    setSort({ sort_by_plan_status: undefined });
  };
  const handleOpen = (e: any) => {
    setOpen(!open);
  };

  const menu = (
    <Menu
      selectedKeys={[filter_status]}
      onClick={(e) => handleMenuClick(e)}
      items={[
        {
          key: 'none',
          icon: <span className="menu__item">{analysisMessages.placeholer}</span>,
        },
        {
          key: '1',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(1)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '2',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(2)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '3',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(3)} purpose="plan" />
            </div>
          ),
        },
        {
          key: '4',
          icon: (
            <div className="menu__item">
              <PlanStatus status={Number(4)} purpose="plan" />
            </div>
          ),
        },
      ]}
    />
  );

  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    setFilterStatus(undefined);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  const columns = [
    {
      title: (
        <div className="column__title">
          <div style={{ marginLeft: 20 }} className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_plan_name: 'asc' })}
              style={{ color: sort.sort_by_plan_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_plan_name: 'desc' })}
              style={{ color: sort.sort_by_plan_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{planMessages.planName}</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (text: string) => (
        <Tooltip title={text} placement="topLeft">
          <div className="planName text">{text}</div>
        </Tooltip>
      ),
      width: '20%',
    },
    {
      title: (
        <div className="column__title">
          <span>{planMessages.planTime}</span>
        </div>
      ),
      dataIndex: '',
      key: '',
      render: (field: any) => {
        return (
          <div>
            {moment(field?.plan_start_date).format('YYYY-MM-DD')} - {moment(field?.plan_end_date).format('YYYY-MM-DD')}{' '}
          </div>
        );
      },
      width: '25%',
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_created_by: 'asc' })}
              style={{ color: sort.sort_by_created_by == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_created_by: 'desc' })}
              style={{ color: sort.sort_by_created_by == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{planMessages.manager}</span>
        </div>
      ),
      key: 'created_by',
      dataIndex: 'created_by',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (field: any) => (
        <Tooltip title={field?.username} placement="topLeft">
          <div className="text">{field?.username}</div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_staff_name: 'asc' })}
              style={{ color: sort.sort_by_staff_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_staff_name: 'desc' })}
              style={{ color: sort.sort_by_staff_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{planMessages.planWorker}</span>
        </div>
      ),
      key: 'user_plan',
      dataIndex: 'user_plan',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 100,
          },
        };
      },
      render: (field: any) => (
        <Tooltip title={field?.username} placement="topLeft">
          <div className="text">{field?.username}</div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div className="column__title">
          <div className="column__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_plan_status: 'asc' })}
              style={{ color: sort.sort_by_plan_status == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_plan_status: 'desc' })}
              style={{ color: sort.sort_by_plan_status == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="column__title__wrapper">
            <span>{planMessages.planStatus}</span>
            <div className="dropdown__status">
              <Dropdown overlay={menu}>
                <FilterFilled onClick={handleOpen} style={{ color: filter_status ? '#49ABB1' : '#BFBFBF' }} />
              </Dropdown>
            </div>
          </div>
        </div>
      ),
      key: 'status',
      dataIndex: 'status',
      render: (status: number) => <PlanStatus status={Number(status)} purpose="plan"></PlanStatus>,
    },
    {
      title: <></>,
      key: 'action',
      width: '6%',
      render: (record: any) => {
        return record.status === 1 ? (
          <Space className="action">
            <div
              className="btn-action"
              onClick={(e) => {
                e.stopPropagation();
                return handleShowDetail(record.id);
              }}
            >
              <EditOutlined />
            </div>
          </Space>
        ) : (
          <></>
        );
      },
    },
  ];

  const handleShowDetail = (id: string) => {
    navigate(`/${appRoutes.PLAN.ROOT}/${id}`);
  };

  const handleClickRow = (plan: Plan) => {
    navigate(`/${appRoutes.PLAN.ROOT}/${plan.id}`);
  };

  const handleBack = () => navigate(`/${appRoutes.PLAN.ROOT}`);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <section className="history-management">
          <div className="history-management__container">
            <div className="history-management__title">
              <TitleContent leftIcon={true} title={planMessages.hisotryTitle} handleBack={handleBack} />
            </div>
            <div className="history-management__table">
              <SearchField
                handleFilter={handleFilter}
                value={value?.trim()}
                onChange={(e: any) => {
                  setValue(e.target.value);
                }}
              />
              <TableWrap columns={columns} onClickRow={handleClickRow} data={data?.data.data} />
            </div>

            <div className="plan-management__pagination">
              {data?.data.pagination?.total > generalMessages.page_size && (
                <div className="user-management__content__pagination">
                  <PaginationWrapper
                    current={currentPage}
                    total={data?.data.pagination.total}
                    pageSize={generalMessages.page_size}
                    setCurrent={(page: number) => setCurrentPage(page)}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default History;
