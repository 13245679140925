import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { notification, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import profileApi from '../../api/profileApi';
import userApi from '../../api/userApi';
import { LoadingStatusIcon } from '../../assets/icons';
import { TitleContent } from '../../components/commons';
import HandleError from '../../components/commons/HandleError';
import Loading from '../../components/commons/Loading';
import PaginationWrapper from '../../components/commons/Pagination';
import Popup from '../../components/commons/Popup';
import SearchField from '../../components/SearchField';
import TableWrap from '../../components/TableWrap';
import { User } from '../../models';
import { ROLE } from '../../router/role';
import { appRoutes } from '../../router/routes';
import { generalMessages, typeFileMsg, userMgmtMessages } from '../../utils/messages';
import './style.scss';

interface UserManagementProps {}

interface DataType {
  id: string;
  name: string;
  email: string;
  phone: string;
  role: number;
  status: boolean;
}
const UserManagement: FC<UserManagementProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [sort, setSort]: any = useState({});
  const [filter, setFilter]: any = useState();
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isFetching, isError, data }: any = useQuery(
    ['user', currentPage, filter, sort],
    () => userApi.getPage(currentPage, filter, type ? sort[type] : undefined, type),
    { staleTime: filter ? 0 : Infinity }
  );

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iD, setId] = useState('');
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const mutation = useMutation(userApi.delete, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['user', currentPage]).then(() => {
          const dat: any = queryClient.getQueryData(['user', currentPage]);
          if (dat?.data?.length === 0) {
            setCurrentPage((preState: any) => preState - 1);
          }
          if (!isFetching) setLoading(false);
          setVisible(false);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'user' && query.queryKey[1] !== currentPage,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      if (error && error.response.status === 404) {
        queryClient.clear();
      }
    },
  });

  const onDelete = () => {
    setLoading(true);
    if (iD) mutation.mutate(iD);
  };

  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
    queryClient.invalidateQueries(['user']);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  const handleClickRow = (record: any) => {
    if (localStorage.getItem('role') === ROLE.STAFF)
      navigate(`${appRoutes.USER.ROOT}/details/${record.id}`, { state: { view: true } });
    else navigate(`${appRoutes.ADMIN.LIST}/details/${record.id}`, { state: { view: true } });
  };

  const handleDelete = (e: any, id: string) => {
    e.stopPropagation();
    setId(id);
    setVisible(true);
  };

  const handleBack = () => {
    if (localStorage.getItem('role') === ROLE.STAFF) navigate(`${appRoutes.USER.ROOT}`);
    else navigate(`${appRoutes.ADMIN.ROOT}`);
  };

  const handleEdit = (e: any, id: string) => {
    e.stopPropagation();
    if (localStorage.getItem('role') === ROLE.STAFF) navigate(`${appRoutes.USER.ROOT}/details/${id}`);
    else navigate(`${appRoutes.ADMIN.LIST}/details/${id}`);
  };

  const me: any = useQuery('me', profileApi.getMyProfile);

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <div className="user-management__content__main__title__wrapper">
          <div className="user-management__content__main__sort user-management__content__main__title">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_username: 'asc' })}
              style={{ color: sort.sort_by_username == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_username: 'desc' })}
              style={{ color: sort.sort_by_username == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div>{userMgmtMessages.name}</div>
        </div>
      ),
      width: '24%',
      dataIndex: 'username',
      key: 'name',
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string, record: User) => (
        <div className="user-management__content__name">
          <Tooltip title={text} placement="topLeft">
            <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {text}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <>
          <div className="user-management__content__main__title__wrapper">
            <div className="user-management__content__main__sort">
              <CaretUpOutlined
                onClick={() => handleSort({ sort_by_email: 'asc' })}
                style={{ color: sort.sort_by_email == 'asc' ? '#49ABB1' : '#BFBFBF' }}
              />
              <CaretDownOutlined
                onClick={() => handleSort({ sort_by_email: 'desc' })}
                style={{ color: sort.sort_by_email == 'desc' ? '#49ABB1' : '#BFBFBF' }}
              />
            </div>
            <div className="status-column">
              <span>
                <MailOutlined />
              </span>
              {userMgmtMessages.email}
            </div>
          </div>
        </>
      ),
      width: '24%',
      dataIndex: 'email',
      key: 'email',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <Tooltip title={text} placement="topLeft">
          <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div className="user-management__content__main__title__wrapper">
          <div className="user-management__content__main__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_phone: 'asc' })}
              style={{ color: sort.sort_by_phone == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_phone: 'desc' })}
              style={{ color: sort.sort_by_phone == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="status-column">
            <span>
              <PhoneOutlined />
            </span>
            {userMgmtMessages.phone}
          </div>
        </div>
      ),
      width: '18%',
      dataIndex: 'number_phone',
      key: 'phone',
    },
    {
      title: (
        <>
          <div className="user-management__content__main__title__wrapper">
            <div className="user-management__content__main__sort">
              <CaretUpOutlined
                onClick={() => handleSort({ sort_by_type: 'asc' })}
                style={{ color: sort.sort_by_type == 'asc' ? '#49ABB1' : '#BFBFBF' }}
              />
              <CaretDownOutlined
                onClick={() => handleSort({ sort_by_type: 'desc' })}
                style={{ color: sort.sort_by_type == 'desc' ? '#49ABB1' : '#BFBFBF' }}
              />
            </div>
            <div className="status-column">
              <span>
                <UserOutlined />
              </span>
              {userMgmtMessages.role}
            </div>
          </div>
        </>
      ),
      width: '12%',
      key: 'role',
      dataIndex: 'user_type',
      render: (role: string) => (
        <span>
          {role === '1'
            ? userMgmtMessages.typeManageContract
            : role === '2'
            ? userMgmtMessages.typeManagePlan
            : userMgmtMessages.typeImplementPlan}
        </span>
      ),
    },
    {
      title: (
        <div className="user-management__content__main__title__wrapper">
          <div className="user-management__content__main__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_status: 'asc' })}
              style={{ color: sort.sort_by_status == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_status: 'desc' })}
              style={{ color: sort.sort_by_status == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <div className="status-column">
            <span>
              <LoadingStatusIcon />
            </span>
            {userMgmtMessages.status}
          </div>
        </div>
      ),
      width: '16%',
      key: 'status',
      dataIndex: 'status',
      render: (state: string) => {
        return (
          <div className="user-management__content__status">
            <div className={state === typeFileMsg.active ? 'checkactive' : 'checkinactive'}> </div>
            <span className={state === typeFileMsg.active ? 'active' : 'inactive'}>
              {state === typeFileMsg.active ? userMgmtMessages.valid : userMgmtMessages.invalid}
            </span>
          </div>
        );
      },
    },
    {
      title: <></>,
      key: 'action',
      width: '6%',
      render: (_: any, record: User) => {
        return (
          <Space className="action">
            <div className="btn-action" onClick={(e) => handleEdit(e, record.id)}>
              <EditOutlined />
            </div>
            {me?.data?.data?.username !== undefined ? (
              <div className="btn-action" onClick={(e) => handleDelete(e, record.id)}>
                <CloseOutlined />
              </div>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <>
      {isFetching || loading ? (
        <Loading />
      ) : (
        <section className="user-management">
          <div className="user-management__content">
            <div className="user-management__content__title">
              <TitleContent leftIcon={true} title={userMgmtMessages.title} handleBack={handleBack} />
            </div>
            <div className="user-management__content__main">
              <SearchField
                handleFilter={handleFilter}
                value={value?.trim()}
                onChange={(e: any) => {
                  setValue(e.target.value);
                }}
              />
              <TableWrap columns={columns} data={data?.data} onClickRow={handleClickRow} />
            </div>
            {data?.total > generalMessages.page_size && (
              <div className="user-management__content__pagination">
                <PaginationWrapper
                  current={currentPage}
                  total={data?.total}
                  pageSize={generalMessages.page_size}
                  setCurrent={(page: number) => setCurrentPage(page)}
                />
              </div>
            )}
          </div>
        </section>
      )}

      {visible && (
        <Popup
          unVisible={() => setVisible(false)}
          title={userMgmtMessages.titleConfirm}
          actions={() => {
            onDelete();
            setVisible(false);
          }}
          width="415px"
          text={userMgmtMessages.textConfirm}
          btn1={userMgmtMessages.yes}
          btn2={userMgmtMessages.no}
        />
      )}
    </>
  );
};

export default UserManagement;
