import { configs } from '../configs';
import { Building, BuildingMap, DetailsResponse, ListBuilidingResponse, ListResponse } from '../models';
import { generalMessages } from '../utils/messages/general';
import axiosClient from './axiosClient';

const building = 'building';

const buildingApi = {
  getAll(): Promise<ListResponse<Building>> {
    const url = building;
    return axiosClient.get(url);
  },
  get(id: string | undefined): Promise<DetailsResponse<Building>> {
    const url = `${building}/${id}`;
    return axiosClient.get(url);
  },
  getPage(
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    type: string | undefined
  ): Promise<DetailsResponse<Building>> {
    const url = `${building}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}`;
    return axiosClient.get(url);
  },
  add(data: any): Promise<DetailsResponse<Building>> {
    const url = building;
    return axiosClient.post(url, data, configs.header);
  },
  edit({ id, data }: { id: string; data: any }): Promise<DetailsResponse<Building>> {
    const url = `${building}/${id}`;
    return axiosClient.post(url, data, configs.header);
  },
  delete(id: string): Promise<DetailsResponse<Building>> {
    const url = `${building}/${id}`;
    return axiosClient.delete(url);
  },
  getBuilidng(): Promise<ListBuilidingResponse<BuildingMap>> {
    const url = `${building}/building-faci-building-item`
    return axiosClient.get(url)
  },
};

export default buildingApi;
