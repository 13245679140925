import { Form, Input } from 'antd';
import React, { FC } from 'react';
import { japanesePlanMessages as planMessages } from '../../../../utils/messages';

interface IPlanAddressItem {
  address: string;
}

const PlanAddressItem: FC<IPlanAddressItem> = ({ address }) => {
  return (
    <Form.Item label={planMessages.labelLocationBuilding} help={' '} className="input-field">
      <Input disabled placeholder={planMessages.placeholderLocationBuilding} value={address} />
    </Form.Item>
  );
};

export default React.memo(PlanAddressItem);
