import React, { FC } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CarouselItem } from './CarouselItem';
import './style.scss';

interface CarouselComponentProps {
  data: any[];
  slidesQuantity: number;
  deletable?: boolean;
  onDelete?: (id: string) => void;
}

const CarouselComponent: FC<CarouselComponentProps> = ({ data = [], slidesQuantity, deletable = false, onDelete }) => {
  return (
    <Carousel
      arrows={true}
      dots={false}
      slidesToScroll={1}
      rows={1}
      infinite={false}
      nextArrow={<RightOutlined />}
      prevArrow={<LeftOutlined />}
      slidesToShow={data?.length > slidesQuantity ? slidesQuantity : data?.length}
      className={`carousel-component ${data?.length >= slidesQuantity ? 'over_than_quantity' : 'less_than_quantity'}`}>
      {data?.map((file: any, index: number) => {
        return <CarouselItem key={index} file={file} onDelete={onDelete} deletable={deletable} />;
      })}
    </Carousel>
  );
};

export default CarouselComponent;
