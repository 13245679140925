export const regex = {
  validateName: /.*/,
  validateUsername: /^([_.-]{0,}[0-9０-９a-zA-Zぁ-んァ-龯][_.-]{0,})+$/,
  validateNameDevice: /.*/,
  validatePostalCode: /^(?:[0-9]{7}|[0-9]{3}-[[0-9]{4}|)$/,
  validateEmail:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  validatePhone: /^(?:[0-9]{10}|[0-9]{11}|)$/,
  validatePassword: /^(?=[^\s]*[a-zA-Z]+)(?=[^\s]*[0-9]+)(?=[^\s]*[\/@!#"$%&\-^[;:\],.<>?_+*}`(){=~|¥]+)[^\s]{8,}$/,
  validateCurrentPassword: /^[^\s]{8,}$/,
  validateTime: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
  validDate: new RegExp(
    '^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$'
  ),
  validateCoordinate: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
};
