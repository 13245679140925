import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleContent from '../../components/commons/TitleContent';
import { PlusCircleOutlined, BarsOutlined, AimOutlined } from '@ant-design/icons';
import { japanesePlanMessages as planMessages } from '../../utils/messages';
import { Col, Row } from 'antd';
import { CardWrap } from '../../components/commons';
import { appRoutes } from '../../router/routes';
import './style.scss';

interface PlanActionProps {}

const PlanAction: FC<PlanActionProps> = (props) => {
  const navigate = useNavigate();

  const handleCreatePlan = () => {
    navigate(`/${appRoutes.PLAN.CREATE}`);
  };
  const handleShowPlansList = () => {
    navigate(`/${appRoutes.PLAN.LIST}`);
  };
  const handleShowMap = () => {
    navigate(`/${appRoutes.PLAN.MAP}`)
  }
  return (
    <>
      <div className="plan__action__container user__action__container">
        <div className="plan__action__title">
          <TitleContent title={planMessages.planTitle} leftIcon={false} />
        </div>
        <div className="plan__action">
          <Row className="user__action__item" gutter={[30, 30]}>
            <Col xs={24} lg={8}>
              <CardWrap
                title={planMessages.titleCreatePlan}
                className="box box__add"
                width={'413.33px'}
                height={'190px'}
                icon={<PlusCircleOutlined />}
                widthImg={'58.75px'}
                heightImg={'62.19px'}
                handleAction={handleCreatePlan}
              />
            </Col>
            <Col xs={24} lg={8}>
              <CardWrap
                title={planMessages.titleListPlan}
                width={'413.33px'}
                height={'190px'}
                className="box box__list"
                widthImg={'58.67px'}
                heightImg={'64.22px'}
                icon={<BarsOutlined />}
                handleAction={handleShowPlansList}
              />
            </Col>
            <Col xs={24} lg={8}>
              <CardWrap
                title={planMessages.titleShowMap}
                width={'413.33px'}
                height={'190px'}
                className="box box__map"
                widthImg={'58.67px'}
                heightImg={'64.22px'}
                icon={<img src='map.png' />}
                handleAction={handleShowMap}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PlanAction;
