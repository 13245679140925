import { AimOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, notification, Row, Space, Tooltip } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import buildingApi from '../../../api/buildingApi';
import { ButtonWrap, ErrorMessage, InputField, SelectField, TitleContent } from '../../../components/commons';
import GoogleMap from '../../../components/commons/GoogleMap';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputFieldCustom from '../../../components/InputFieldCustom';
import UploadWrapper from '../../../components/Upload';
import { appRoutes } from '../../../router/routes';
import { constant } from '../../../utils/constants';
import { addUserMessages, buildingMasterMessages, generalMessages } from '../../../utils/messages';
import { regex } from '../../../utils/regex';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import listApi from '../../../api/listApi';

interface CreateBuildingMasterProps {}

const CreateBuildingMaster: FC<CreateBuildingMasterProps> = (props) => {
  const prevCoor = useRef(null);
  const [error, setError]: any = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [facilitiesState, setFacilities] = useState<Array<any>>();
  const [buildingItemsState, setBuildingItemsState] = useState<Array<any>>();
  const fac_buildingItem: any = useQuery('list-fac-buildingItems', listApi.getFacBuildingItem);

  const mutation = useMutation(buildingApi.add, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['building', 1]).then(() => {
          navigate(`${appRoutes.MASTER.BUILDING.ROOT}`);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'building' && query.queryKey[1] !== 1,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const {
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitted },
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const handleCancel = () => {
    if (!loading) navigate(`${appRoutes.MASTER.BUILDING.ROOT}`);
  };

  const { fields, remove, append, insert, update } = useFieldArray({
    control,
    name: 'facilities',
  });

  const {
    fields: buildingItemFields,
    remove: removeBuildingItem,
    append: appendBuildingItem,
    insert: insertBuildingItem,
    update: updateBuildingItem,
  } = useFieldArray({
    control,
    name: 'building_items',
  });

  useEffect(() => {
    append(
      { id: null, name: null },
      {
        shouldFocus: false,
      }
    );
    appendBuildingItem(
      { id: null, name: null },
      {
        shouldFocus: false,
      }
    );
  }, []);

  useEffect(() => {
    setFacilities(fac_buildingItem?.data?.data?.facility.filter((i: any) => !i.deleted_at));
    setBuildingItemsState(fac_buildingItem?.data?.data?.buildingItem.filter((i: any) => !i.deleted_at));
  }, [fac_buildingItem.isFetching]);

  const onSubmit = (data: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.set('building_code', data.building_code);
    formData.set('name', data.name);
    if (data.name_katakana) formData.set('name_katakana', data.name_katakana);
    if (data.postal_code) formData.set('postal_code', data.postal_code);
    formData.set('address_1', data.address_1);
    if (data.address_2) formData.set('address_2', data.address_2);
    data.files?.map((file: any) => {
      formData.append('files[]', file.originFileObj);
    });
    if (data.coordinates) {
      formData.set('lat', data.coordinates.split(',')[0]);
      formData.set('lng', data.coordinates.split(',')[1]);
    }

    if (
      data.facilities &&
      !(fields.length === 1 && !getValues(`facilities.${0}.name`) && !getValues(`facilities.${0}.id`))
    ) {
      formData.append('facilities', JSON.stringify(data.facilities));
    }
    if (
      data.building_items &&
      !(
        buildingItemFields.length === 1 &&
        !getValues(`building_items.${0}.name`) &&
        !getValues(`building_items.${0}.id`)
      )
    ) {
      formData.append('building_items', JSON.stringify(data.building_items));
    }

    mutation.mutate(formData);
  };

  const onRemoveFacilityRow = (index: any) => {
    if (index === 0 && fields.length === 1) {
      update(0, {
        id: null,
        name: null,
      });
      clearErrors(`facilities.${index}.id`);
      clearErrors(`facilities.${index}.name`);
    } else {
      if (fields.length > 1) {
        remove(index);
      }
    }
  };

  const onRemoveBuildingRow = (index: any) => {
    if (index === 0 && buildingItemFields.length === 1) {
      updateBuildingItem(0, {
        id: null,
        name: null,
      });
      clearErrors(`building_items.${index}.id`);
      clearErrors(`building_items.${index}.name`);
    } else {
      if (buildingItemFields.length > 1) {
        removeBuildingItem(index);
      }
    }
  };

  const handleAddRow = (type: string) => {
    const newRow = {
      id: null,
      name: null,
    };

    switch (type) {
      case 'facility': {
        append(newRow);
        break;
      }
      case 'building_item': {
        appendBuildingItem(newRow);
        break;
      }
      default:
        break;
    }
  };

  const validateFacility = (index: any) => {
    if (getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      return true;
    } else {
      if (!getValues(`facilities.${index}.id`) && getValues(`facilities.${index}.name`)) {
        return false;
      }
    }
  };

  const checkExistNickName = (index: any, array: any, purpose: string) => {
    let isExist = false;
    switch (purpose) {
      case 'facility':
        isExist = array.some((item: any, i: number) => {
          return item.name === getValues(`facilities.${index}.name`) && i !== index;
        });
        break;

      case 'building_item': {
        isExist = array.some((item: any, i: number) => {
          return item.name === getValues(`building_items.${index}.name`) && i !== index;
        });
        break;
      }
      default:
        break;
    }

    return isExist;
  };

  const validateFacilityNickName = (index: any) => {
    if (getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      return !checkExistNickName(index, control._formValues.facilities, 'facility');
    } else {
      if (!getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
        return false;
      }

      if (getValues(`facilities.${index}.name`) && !getValues(`facilities.${index}.id`)) {
        return !checkExistNickName(index, control._formValues.facilities, 'facility');
      }
    }
  };

  const validateBDItem = (index: any) => {
    if (getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
      return true;
    } else {
      if (!getValues(`building_items.${index}.id`) && getValues(`building_items.${index}.name`)) {
        return false;
      }
    }
  };

  const validateBDItemNickName = (index: any) => {
    if (getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
      return !checkExistNickName(index, control._formValues.building_items, 'building_item');
    } else {
      if (!getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`)) {
        return false;
      }

      if (getValues(`building_items.${index}.name`) && !getValues(`building_items.${index}.id`)) {
        return !checkExistNickName(index, control._formValues.building_items, 'building_item');
      }
    }
  };

  const renderMessFacNick = (index: any) => {
    if (!getValues(`facilities.${index}.name`) && getValues(`facilities.${index}.id`)) {
      if (!(errors?.facilities as any)?.[index]?.name?.type) {
        return '';
      }

      return buildingMasterMessages.facilityNickNameRequired;
    } else {
      return (errors?.facilities as any)?.[index]?.name?.type === 'required'
        ? buildingMasterMessages.facilityNickNameRequired
        : (errors?.facilities as any)?.[index]?.name?.type === 'validate'
        ? buildingMasterMessages.existFacilityNickname
        : '';
    }
  };

  const renderMessBDItemNick = (index: any) => {
    if (!getValues(`building_items.${index}.name`) && getValues(`building_items.${index}.id`) && isSubmitted) {
      if (!(errors?.building_items as any)?.[index]?.name?.type) {
        return '';
      }

      return buildingMasterMessages.buildingNicknameRequired;
    } else {
      return (errors?.building_items as any)?.[index]?.name?.type === 'required'
        ? buildingMasterMessages.buildingNicknameRequired
        : (errors?.building_items as any)?.[index]?.name?.type === 'validate'
        ? buildingMasterMessages.existBuildingNickname
        : '';
    }
  };

  return (
    <>
      {loading && <Loading />}
      <section className="edit-building-master">
        <div className="edit-building-master__container">
          <div className="edit-building-master__title">
            <TitleContent
              leftIcon={true}
              title={buildingMasterMessages.addTitle}
              handleBack={() => navigate(`${appRoutes.MASTER.BUILDING.ROOT}`)}
            />
          </div>
          <div className="edit-building-master__form__container">
            <div className="edit-building-master__form">
              <Form className="edit-building-master__form__wrapper">
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="building_code"
                      rules={{
                        required: buildingMasterMessages.requireBuildingCode,
                        validate: (value: string) => {
                          if (value?.trim() == '') {
                            return buildingMasterMessages.requireBuildingCode;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxBuildingCode}
                          label={
                            <>
                              {buildingMasterMessages.buildingCode}
                              <RequiredLabel />
                            </>
                          }
                          placeholder={addUserMessages.placeholder}
                          msgServer={error['building_code']}
                          msgValidate={errors?.building_code?.message}
                          onBlur={(e: any) => setValue('building_code', e.target.value.trim())}
                          autoFocus={true}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="name"
                      rules={{
                        required: buildingMasterMessages.requireName,
                        validate: (value: string) => {
                          if (value?.trim() == '') {
                            return buildingMasterMessages.requireName;
                          }
                          if (regex.validateNameDevice.test(value?.trim()) == false) {
                            return buildingMasterMessages.validateName;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxName}
                          label={
                            <>
                              {buildingMasterMessages.name}
                              <RequiredLabel />
                            </>
                          }
                          placeholder={addUserMessages.placeholder}
                          msgServer={error['name']}
                          msgValidate={errors?.name?.message}
                          onBlur={(e: any) => setValue('name', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="name_katakana"
                      rules={{
                        validate: (value: string) => {
                          if (!!value && regex.validateNameDevice.test(value?.trim()) == false) {
                            return buildingMasterMessages.validateNameKatakana;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxNameKatakana}
                          placeholder={addUserMessages.placeholder}
                          label={buildingMasterMessages.nameKatakana}
                          msgServer={error['name_katakana']}
                          msgValidate={errors?.name_katakana?.message}
                          onBlur={(e: any) => setValue('name_katakana', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="postal_code"
                      rules={{
                        validate: (value: string) => {
                          if (!!value && regex.validatePostalCode.test(value?.trim()) == false) {
                            return addUserMessages.validatePostalCode;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxLenghtPostalCode}
                          label={addUserMessages.postalCode}
                          placeholder={addUserMessages.placeholder}
                          msgServer={error['postal_code']}
                          msgValidate={errors?.postal_code?.message}
                          onBlur={(e: any) => setValue('postal_code', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="address_1"
                      rules={{
                        required: addUserMessages.requiredAddress1,
                        validate: (value: string) => {
                          if (value?.trim() == '') {
                            return addUserMessages.requiredAddress1;
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxLenghtAddress}
                          placeholder={addUserMessages.placeholder}
                          label={
                            <>
                              {buildingMasterMessages.address1}
                              <RequiredLabel />
                            </>
                          }
                          msgServer={error['address_1']}
                          msgValidate={errors?.address_1?.message}
                          onBlur={(e: any) => setValue('address_1', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="address_2"
                      control={control}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          maxLength={constant.maxLenghtAddress}
                          placeholder={addUserMessages.placeholder}
                          label={buildingMasterMessages.address2}
                          msgServer={error['address_2']}
                          msgValidate={errors?.address_2?.message}
                          onBlur={(e: any) => setValue('address_2', e.target.value.trim())}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className="edit-building-master__form__item facility__container">
                  <Col span={24}>
                    {fields.map((field, index: number) => {
                      return (
                        <Row
                          gutter={[20, 10]}
                          className="facility__row"
                          key={field.id}
                          style={{ display: 'flex', marginBottom: 8 }}
                        >
                          <Col span={11}>
                            <Controller
                              name={`facilities.${index}.name` as const}
                              rules={{
                                required: fields.length > 1,
                                validate: () => validateFacilityNickName(index),
                              }}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <InputField
                                    help={<ErrorMessage message={renderMessFacNick(index)} />}
                                    className={(errors?.facilities as any)?.[index]?.name?.type ? 'border-error' : ''}
                                    label={
                                      index === 0 && (
                                        <>
                                          <span>{buildingMasterMessages.facilityNicknameLabel}</span>
                                        </>
                                      )
                                    }
                                    placeholder={addUserMessages.placeholder}
                                    props={{
                                      ...field,
                                      value: field.value,
                                    }}
                                    onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                                  />
                                );
                              }}
                            />
                          </Col>

                          <Col span={11}>
                            <Controller
                              name={`facilities.${index}.id` as const}
                              rules={{
                                required: fields.length > 1,
                                validate: () => validateFacility(index),
                              }}
                              defaultValue=""
                              control={control}
                              render={({ field }) => {
                                return (
                                  <>
                                    <SelectField
                                      help={
                                        <ErrorMessage
                                          message={
                                            (errors?.facilities as any)?.[index]?.id?.type
                                              ? buildingMasterMessages.facilityIdRequired
                                              : ''
                                          }
                                        />
                                      }
                                      options={
                                        facilitiesState
                                          ? facilitiesState.map((each: any) => {
                                              return {
                                                key: each.id,
                                                value: (
                                                  <Tooltip placement="topLeft" title={each.name}>
                                                    <div className="deleted-selection-wrap">
                                                      <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                                    </div>
                                                  </Tooltip>
                                                ),
                                              };
                                            })
                                          : []
                                      }
                                      error={(errors?.facilities as any)?.[index]?.id?.type}
                                      placeholder={generalMessages.pleaseSelect}
                                      label={
                                        index === 0 && (
                                          <>
                                            <span>{buildingMasterMessages.facilityNameLabel}</span>
                                          </>
                                        )
                                      }
                                      props={{ ...field }}
                                      isSearch={true}
                                      isElementValue
                                    ></SelectField>
                                  </>
                                );
                              }}
                            />
                          </Col>

                          <Col span={2}>
                            <div
                              className={`${index === 0 && 'first-close'}  close-icon-container`}
                              style={{ marginTop: '5px' }}
                            >
                              <Button type="text" onClick={() => onRemoveFacilityRow(index)}>
                                <CloseOutlined />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}

                    <Row>
                      <Col span={24}>
                        <ButtonWrap
                          className="addValue_btn"
                          text={planMessages.addValue}
                          width={'100%'}
                          height="40px"
                          color={'#49ABB1'}
                          backgroundColor="transparent"
                          iconLeft={
                            <PlusCircleOutlined
                              style={{
                                color: 'inherit',
                              }}
                            />
                          }
                          onClick={() => {
                            handleAddRow('facility');
                          }}
                        ></ButtonWrap>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider style={{ background: '#828282' }} />

                <Row className="edit-building-master__form__item building__container">
                  <Col span={24}>
                    {buildingItemFields.map((field, index: number) => {
                      return (
                        <Row
                          gutter={[20, 10]}
                          className="buildingItem__row"
                          key={field.id}
                          style={{ display: 'flex', marginBottom: 8 }}
                        >
                          <Col span={11}>
                            <Controller
                              name={`building_items.${index}.name` as const}
                              rules={{
                                required: buildingItemFields.length > 1,
                                validate: () => validateBDItemNickName(index),
                              }}
                              control={control}
                              render={({ field }) => (
                                <InputField
                                  help={<ErrorMessage message={renderMessBDItemNick(index)} />}
                                  className={(errors?.building_items as any)?.[index]?.name?.type ? 'border-error' : ''}
                                  label={
                                    index === 0 && (
                                      <>
                                        <span>{buildingMasterMessages.buildingNicknameLabel}</span>
                                      </>
                                    )
                                  }
                                  placeholder={addUserMessages.placeholder}
                                  props={{
                                    ...field,
                                    value: field.value,
                                  }}
                                  onBlur={(e: any) => setValue(field.name, e.target.value.trim())}
                                  autoFocus={false}
                                />
                              )}
                            />
                          </Col>

                          <Col span={11}>
                            <Controller
                              name={`building_items.${index}.id` as const}
                              rules={{
                                required: buildingItemFields.length > 1,

                                validate: () => validateBDItem(index),
                              }}
                              defaultValue=""
                              control={control}
                              render={({ field }) => {
                                return (
                                  <>
                                    <SelectField
                                      help={
                                        <ErrorMessage
                                          message={
                                            (errors?.building_items as any)?.[index]?.id?.type
                                              ? buildingMasterMessages.buildingIdRequired
                                              : ''
                                          }
                                        />
                                      }
                                      options={
                                        buildingItemsState
                                          ? buildingItemsState.map((each: any) => {
                                              return {
                                                key: each.id,
                                                value: (
                                                  <Tooltip placement="topLeft" title={each.name}>
                                                    <div className="deleted-selection-wrap">
                                                      <span className="deleted-selection-wrap__content">{`${each.name}`}</span>
                                                    </div>
                                                  </Tooltip>
                                                ),
                                              };
                                            })
                                          : []
                                      }
                                      error={(errors?.building_items as any)?.[index]?.id?.type}
                                      placeholder={generalMessages.pleaseSelect}
                                      label={
                                        index === 0 && (
                                          <>
                                            <span>{planMessages.labelJobBuildingItem}</span>
                                          </>
                                        )
                                      }
                                      props={field}
                                      isSearch={true}
                                      isElementValue
                                    ></SelectField>
                                  </>
                                );
                              }}
                            />
                          </Col>

                          <Col span={2}>
                            <div
                              className={`${index === 0 && 'first-close'}  close-icon-container`}
                              style={{ marginTop: '5px' }}
                            >
                              <>
                                <Button type="text" onClick={() => onRemoveBuildingRow(index)}>
                                  <CloseOutlined />
                                </Button>
                              </>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}

                    <Row>
                      <Col span={24}>
                        <ButtonWrap
                          className="addValue_btn"
                          text={planMessages.addValue}
                          width={'100%'}
                          height="40px"
                          color={'#49ABB1'}
                          backgroundColor="transparent"
                          iconLeft={
                            <PlusCircleOutlined
                              style={{
                                color: 'inherit',
                              }}
                            />
                          }
                          onClick={() => {
                            handleAddRow('building_item');
                          }}
                        ></ButtonWrap>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider style={{ background: '#828282' }} />

                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <Controller
                      name="coordinates"
                      control={control}
                      rules={{
                        pattern: {
                          value: regex.validateCoordinate,
                          message: buildingMasterMessages.coordinateError,
                        },
                      }}
                      render={({ field }) => (
                        <InputFieldCustom
                          props={field}
                          placeholder={addUserMessages.placeholder}
                          label={buildingMasterMessages.coordinates}
                          msgServer={error['coordinates']}
                          msgValidate={errors?.coordinates?.message}
                          onBlur={(e: any) => setValue('coordinates', e.target.value.trim())}
                          addonAfter={
                            <Button
                              style={{ height: 16, marginTop: 0 }}
                              icon={<AimOutlined />}
                              type="link"
                              onClick={() => setShowMap(true)}
                            />
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item">
                  <Col span={24}>
                    <UploadWrapper
                      label={buildingMasterMessages.document}
                      data={[]}
                      setValues={setValue}
                      multiple={true}
                    />
                  </Col>
                </Row>
                <Row className="edit-building-master__form__item" gutter={[24, 0]}>
                  <Col span={12}>
                    <ButtonWrap
                      type="default"
                      color="#49ABB1"
                      backgroundColor="#1C282F"
                      border="1px solid #247E84"
                      text={buildingMasterMessages.cancel}
                      width="100%"
                      height="56px"
                      onClick={handleCancel}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonWrap
                      type="primary"
                      htmlType="submit"
                      text={buildingMasterMessages.sure}
                      width="100%"
                      height="56px"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Col>
                </Row>
              </Form>
              <Modal
                visible={showMap}
                footer={null}
                onCancel={() => {
                  setShowMap(false);
                  setIsFullScreen(false);
                }}
                width={700}
                title={buildingMasterMessages.map}
                destroyOnClose={true}
                wrapClassName={isFullScreen ? 'map-full' : ''}
              >
                <div className={isFullScreen ? 'map-full__map-full-container' : ''}>
                  <GoogleMap
                    height={isFullScreen ? '100%' : '500px'}
                    width="100%"
                    address={getValues('address_1')}
                    postalCode={getValues('postal_code')}
                    mode="popup"
                    setCoordinate={(val: any) => {
                      setValue('coordinates', val.lat + ',' + val.lng);
                    }}
                    isFullScreen={isFullScreen}
                    setIsFullScreen={() => setIsFullScreen(!isFullScreen)}
                    coordinates={
                      watch('coordinates') && [
                        {
                          lat: Number(watch('coordinates').split(',')[0]),
                          lng: Number(watch('coordinates').split(',')[1]),
                        },
                      ]
                    }
                  />
                </div>
                <div className="popup__form__button">
                  <div className="button-wrapper">
                    <ButtonWrap
                      type="default"
                      color="#49ABB1"
                      backgroundColor="#1C282F"
                      border="1px solid #247E84"
                      text={addUserMessages.cancel}
                      width="100%"
                      height="56px"
                      onClick={() => {
                        setValue('coordinates', prevCoor.current);
                        setShowMap(false);
                        setIsFullScreen(false);
                      }}
                    />
                    <ButtonWrap
                      type="primary"
                      htmlType="submit"
                      text={'確定'}
                      width="100%"
                      height="56px"
                      backgroundColor="#247E84"
                      onClick={() => {
                        prevCoor.current = getValues('coordinates');
                        setShowMap(false);
                        setIsFullScreen(false);
                      }}
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateBuildingMaster;
