import { CloseOutlined, DownloadOutlined, FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import documentApi from '../../../api/documentApi';
import { ImageIcon, VoiceIcon } from '../../../assets/icons';
import { FileTextIcon } from '../../../assets/icons/FileTextIcon';
import { configs } from '../../../configs';
import { buttonMessages, confirmMessages, japanesePlanMessages as planMessages } from '../../../utils/messages';

interface CarouselItemProps {
  file: any;
  deletable?: boolean;
  onDelete?: (id: string) => void;
}

export const CarouselItem: FC<CarouselItemProps> = ({ file, onDelete, deletable }) => {
  const { data, isLoading: isLoadingPreview } = useQuery(
    ['file', file.uid],
    () =>
      documentApi.download(file.type, configs.domain + file.url_preview, file.uid, renderFileName(file?.name), true),
    { enabled: file.url_preview ? true : false }
  );
  const [isDownload, setIsDownload] = useState(false);

  const handleDownload = () => {
    setIsDownload(true);
  };
  const download: any = useQuery(
    ['file__download', file.uid],
    () =>
      documentApi.download(file.type, configs.domain + file.url_download, file.uid, renderFileName(file?.name), false),
    {
      enabled: !!isDownload && !!file.url_download,
    }
  );

  const renderFileName = (fileName: string) => fileName?.substring(0, fileName.lastIndexOf('.'));

  const renderIcon = (fileType: string) => {
    if (file.type.includes('audio')) {
      return <VoiceIcon />;
    } else {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return <ImageIcon />;
      } else {
        return <FileTextIcon />;
      }
    }
  };

  let overallType = file.type === 'image/jpeg' ? 'image' : 'others';
  const setStyle = () => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      return {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url(" ${
          data ? data : file?.originFileObj?.urlImg
        }")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <Spin spinning={isLoadingPreview || download.isLoading}>
        <div className={`file-item ${overallType}`}>
          <div className="file-item_content" style={setStyle()}>
            <span className="upload-icon">{renderIcon(file.type)}</span>
            <div className="file-name-container" style={{ display: 'flex' }}>
              <p className="file-name">{renderFileName(file?.name)}</p>
              <p className="file-ext">.{file?.name.split('.').reverse()[0]}</p>
            </div>
          </div>
          <div className="file-overlay">
            {deletable && (
              <div
                className="delete-icon"
                onClick={(e) => {
                  if (onDelete) {
                    e.stopPropagation();
                    onDelete(file.uid);
                    setIsDownload(false);
                  }
                }}
              >
                <CloseOutlined />
              </div>
            )}
            {file.url_download &&
              (download?.data ? (
                <div className="file-overlay">
                  <a className="file-overlay_content" href={download.data} download={file.name}>
                    <DownloadOutlined />
                    <p>{buttonMessages.download}</p>
                  </a>
                </div>
              ) : (
                <div className="file-overlay" onClick={handleDownload}>
                  <a className="file-overlay_content">
                    <DownloadOutlined />
                    <p>{buttonMessages.download}</p>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </Spin>
    </>
  );
};
