import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { japanesePlanMessages as planMessages } from '../../utils/messages';
import { ROLE } from '../../router/role';
interface HeaderLayoutProps {
  mode?: any;
  onClickMenu?: any;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ mode = 'horizontal', onClickMenu }) => {
  let location = useLocation();
  let menuItems;
  const isStaff = localStorage.getItem('role') === ROLE.STAFF;
  if (isStaff)
    menuItems = [
      {
        key: 'plan',
        label: <Link to="">{planMessages.planTitle}</Link>,
      },
      {
        key: 'user',
        label: <Link to="user">{planMessages.userTitle}</Link>,
      },
      {
        key: 'device',
        label: <Link to="device">{planMessages.deviceTitle}</Link>,
      },
      {
        key: 'master',
        label: <Link to="master">{planMessages.masterTitle}</Link>,
      },
      {
        key: 'analysis',
        label: <Link to="analysis">{planMessages.analysisTitle}</Link>,
      },
    ];
  else
    menuItems = [
      {
        key: 'user',
        label: <Link to="dashboard">{planMessages.userTitle}</Link>,
      },
    ];

  return (
    <Menu
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClickMenu}
      mode={mode}
      className="header__center__menu"
      items={menuItems}
      defaultSelectedKeys={
        isStaff ? (location.pathname === '/' ? ['plan'] : location.pathname.split('/', 2).slice(1)) : ['user']
      }
      selectedKeys={
        isStaff ? (location.pathname === '/' ? ['plan'] : location.pathname.split('/', 2).slice(1)) : ['user']
      }
    />
  );
};

export default HeaderLayout;
