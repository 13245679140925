import { DatePicker, Form } from 'antd';
import React, { FC, useState } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { CalendarOutlined } from '@ant-design/icons';
import './style.scss';
interface IDateTimePicker {
  label: string | any;
  name?: string;
  required?: boolean;
  message?: string;
  dateFormat?: string;
  defaultValue?: string;
  allowClear?: boolean;
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined;
  className?: string;
  placeholder?: string;
  disable?: boolean;
  help?: React.ReactNode;
  props?: any;
  isError?: boolean;
  disabledDate?: any;
}

const DateTimePicker: FC<IDateTimePicker> = ({
  label,
  name,
  required,
  message,
  dateFormat = 'DD/MM/YYYY',
  defaultValue = moment(),
  allowClear,
  picker,
  className,
  placeholder,
  disable = false,
  help,
  props,
  isError,
  disabledDate,
}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  return (
    <Form.Item label={label} name={name} rules={[{ required, message }]} help={help} colon={false}>
      <DatePicker
        {...props}
        defaultValue={moment(defaultValue, dateFormat)}
        format={dateFormat}
        style={
          isError
            ? {
                border: '1px solid #EB5757',
                boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
                width: '100%',
              }
            : { width: '100%' }
        }
        allowClear={allowClear}
        picker={picker}
        locale={locale}
        className={className}
        placeholder={placeholder}
        disabled={disable}
        onOpenChange={(open: any) => {
          if (!open) setIsOpen(open);
        }}
        popupStyle={{ display: isOpen ? '' : 'none' }}
        suffixIcon={
          <CalendarOutlined
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          />
        }
      />
    </Form.Item>
  );
};

export default DateTimePicker;
