import { Form, Input } from 'antd';
import { FC, useEffect, useState } from 'react';
import { ErrorMessage } from '../commons';
import './style.scss';

interface InputRulesProps {
  required?: boolean;
  message?: string;
  [key: string]: any;
}
interface IInputFieldProps {
  label?: React.ReactNode;
  name?: string | any;
  required?: boolean;
  message?: string;
  placeholder?: string;
  props?: any;
  prefix?: any;
  suffix?: any;
  disable?: boolean;
  rules?: InputRulesProps[];
  defaultValue?: string;
  maxLength?: number;
  initialValue?: string;
  className?: string;
  onBlur?: any;
  autoFocus?: boolean;
  msgValidate?: any;
  msgServer?: any;
  addonAfter?: any;
}

const error = {
  border: '1px solid #EB5757',
  boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
};

const InputFieldCustom: FC<IInputFieldProps> = ({
  label,
  name,
  placeholder,
  props,
  prefix,
  suffix,
  disable,
  rules,
  defaultValue,
  maxLength,
  initialValue,
  className,
  onBlur,
  autoFocus,
  msgValidate,
  msgServer,
  addonAfter,
}) => {
  const [msg, setMsg]: any = useState(msgValidate);
  const [messageError, setMessageError]: any = useState(msgServer);

  useEffect(() => {
    setMsg(msgValidate);
  }, [msgValidate]);

  useEffect(() => {
    setMessageError(msgServer);
  }, [msgServer]);

  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      help={<ErrorMessage message={!!messageError ? messageError : msg ?? ''} />}
      className="input-field"
      colon={false}
    >
      <Input
        placeholder={placeholder}
        {...props}
        style={messageError || msg ? error : {}}
        prefix={prefix}
        suffix={suffix}
        disabled={disable}
        defaultValue={defaultValue}
        maxLength={maxLength}
        initialval={initialValue}
        className={className}
        onBlur={onBlur}
        onChange={(e) => {
          props.onChange(e);
          if (!!messageError) {
            setMessageError('');
          }
        }}
        autoFocus={autoFocus ?? undefined}
        addonAfter={addonAfter}
      />
    </Form.Item>
  );
};

export default InputFieldCustom;
