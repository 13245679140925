export const buildingMasterMessages = {
  title: '建物一覧',
  editTitle: '建物情報',
  addTitle: '建物作成',
  addFileBtn: 'ファイル追加',
  uploadBtn: 'ファイル追加',
  submitBtn: '編集',
  cancel: 'キャンセル',
  done: '保存',
  sure: '確定',
  buildingCode: '建物コード',
  name: '建物名',
  nameKatakana: '建物名カナ',
  postalCode: '郵便番号',
  address1: '住所1',
  address2: '住所2',
  coordinates: '座標',
  document: '参考資料',
  requireBuildingCode: '建物コードは必須です。',
  requireName: '建物名は必須です。',
  requireNameKatakana: '建物名カナを入力してください。',
  requirePostalCode: '郵便番号は必須です。',
  requireAddress1: '住所1は必須です。',
  validateName: '施設名の形式が正しくありません。',
  validateNameKatakana: '建物名カナの形式が正しくありません。',
  validatePostalCode: '郵便番号の形式が正しくありません。',
  validateBuildingCode: '建物コードの形式が正しくありません。',
  validateAddress: '住所の形式が正しくありません。',
  masterColumn: '建物名',
  postalCodeColumn: '郵便番号',
  address1Column: '住所1',
  address2Column: '住所2',
  coordinateError: '座標の形式が正しくありません。',
  map: '地図',

  facilityNicknameLabel: '施設名',
  facilityNickNameRequired: '施設名は必須です',
  existFacilityNickname: '施設名が重複しています',

  facilityNameLabel: '施設',
  facilityIdRequired: '施設は必須です',

  buildingNicknameLabel: '設備名',
  buildingNicknameRequired: '設備名は必須です',
  existBuildingNickname: '設備名が重複しています',

  buildingIdRequired: '設備は必須です',

  alreadyAssignInPlan: 'このマスターデータは点検計画の実施に使用されているため、削除できません',
};
