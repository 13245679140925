export const constant = {
  file__accept: ['image/jpeg', 'image/png', 'image/jpg', 'text/plain', 'application/pdf'],
  image__accept: ['png', 'jpg', 'jpeg', 'PNG', 'JPEG', 'JPG'],
  file__decline: '.(JFIF|jfif)$',
  avatarSizeLimit: 5 * 1024 * 1024,
  fileSizeLimit: 20 * 1024 * 1024,
  totalSizeLimit: 50 * 1024 * 1024,
  maxLenghtPostalCode: 8,
  minLenghtPostalCode: 7,
  maxLenghtPhone: 11,
  maxLenghtEmail: 191,
  maxLenghtAddress: 250,
  maxLenghtUsername: 50,
  maxLenghtFullname: 50,
  maxLenghtFullnameKatakana: 50,
  minLenghtPhone: 10,
  maxBuildingCode: 50,
  maxName: 250,
  maxNameKatakana: 50,
  maxLengthName: 250,
  maxLengthNote: 255,
  maxLengthMacAddress: 50,
  tooltipFontSize: 13,
  tooltipFontWeight: 400,
};

export const planConstant = {
  planStatus: {
    DRAFT: 1,
    OPEN: 2,
    ONGOING: 3,
    DONE: 4,
    DELETE: 5,
  },
};

export const mapConstant = {
  defaultLat: 37.449287452579675,
  defaultLng: 138.91529768909515,
  defaultZoom: 5,
  geoURL: 'https://maps.googleapis.com/maps/api/geocode/json'
}
