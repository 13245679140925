import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ButtonWrap } from '../../components/commons';
import { notFoundMessages } from '../../utils/messages';
import './style.scss';

interface IProps {}

const NotFound: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  queryClient.clear();
  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <div className="not-found-page">
      <div className="not-found-page__wraper">
        <div className="not-found-page__text">
          <div className="not-found-page__number">404</div>
          <div className="not-found-page__title">{notFoundMessages.title}</div>
          <div className="not-found-page__content">{notFoundMessages.content}</div>
        </div>
        <div className="not-found-page__button">
          <ButtonWrap type="primary" text={notFoundMessages.home} onClick={handleGoToHome} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
