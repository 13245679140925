import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Space, Col, Checkbox, Form } from 'antd';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { japanesePlanMessages as planMessages } from '../../../../../../utils/messages';
import { ButtonWrap, ErrorMessage, InputField } from '../../../../../commons';
import BtnActionsGroup from '../BtnActionsGroup/BtnActionsGroup';
import '../style.scss';

interface IReportCheckboxType {
  setValue?: any;
  selectedJob?: number;
  data: Array<any>;
  isSubmitted: boolean;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  props?: any;
  name?: string;
  review?: boolean;
}

const ReportCheckboxType: React.FC<IReportCheckboxType> = ({
  setValue,
  selectedJob,
  data,
  isSubmitted,
  setIsSubmitted,
  id,
  props,
  name,
  review = false,
}) => {
  const { clearErrors, setCatchReportValue, handleBack, catchReportValue } = props;
  const form = Form.useFormInstance();
  const refForm: any = useRef();
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleCheckboxChanged = (index: number, isChecked: boolean) => {
    const formListData = form.getFieldValue(`report-checkbox-form-${id}`);
    const currentIndex = formListData.findIndex((item: any) => index == item.Id);
    formListData[currentIndex].Checkbox = isChecked;
    form.setFieldsValue({
      [`report-checkbox-form-${id}`]: formListData,
    });
  };
  useEffect(() => {
    if (data instanceof Array) {
      form.setFieldsValue({
        [`report-checkbox-form-${id}`]: data,
      });
    } else {
      form.setFieldsValue({
        [`report-checkbox-form-${id}`]: [],
      });
    }
  }, []);

  const handleCancell = () => {
    setValue(`works.${selectedJob}.report.option_report`, form.getFieldValue(`report-checkbox-form-${id}`));
    handleBack();
    clearErrors(name);
    form.setFieldsValue({
      [`report-checkbox-form-${id}`]: catchReportValue.current.reportOption,
    });
  };

  useEffect(() => {
    if (!isSubmitted) {
      setValue(`works.${selectedJob}.report.option_report`, []);
    }
  }, [isSubmitted]);

  const handleDone = () => {
    if (form.getFieldValue(`report-checkbox-form-${id}`).length >= 1) {
      refForm.current.submit();
    } else {
      setIsValid(false);
      setIsSubmitted(false);
    }
  };

  const handleCheck = (key: any) => {
    if (data instanceof Array) {
      return data[key]?.isChecked === true ? true : false;
    }
  };

  const onFinish = (values: any) => {
    setIsSubmitted(true);
    setIsValid(true);
    clearErrors(name);
    setValue(`works.${selectedJob}.report.option_report`, form.getFieldValue(`report-checkbox-form-${id}`));
    setCatchReportValue(form.getFieldValue(`report-checkbox-form-${id}`));
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsSubmitted(false);
  };

  const handleOnChange = (e: any, name: any) => {
    let { value } = e.target;
    form.setFieldsValue({ [name]: value.trim() });
  };

  return (
    <>
      <Form
        onSubmitCapture={(e) => e.preventDefault()}
        ref={refForm}
        name={`checkbox-form-${id}`}
        key={id}
        labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="on">
        <Form.List name={`report-checkbox-form-${id}`} initialValue={data instanceof Array ? data : []}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field) => {
                  return (
                    <Form.Item key={field.key} noStyle>
                      <Row style={{ height: !review ? '70px' : '50px' }}>
                        <Col span={24}>
                          <div className="bonus-checkbox-form">
                            <Space direction="vertical" style={{ marginTop: '3px' }}>
                              <Form.Item {...field} name={[field.name, 'Checkbox']}>
                                <Checkbox
                                  defaultChecked={handleCheck(field.key)}
                                  onChange={(event) => handleCheckboxChanged(field.key, event.target.checked)}
                                  disabled={true}
                                />
                              </Form.Item>
                            </Space>

                            <InputField
                              {...field}
                              rules={[
                                {
                                  required: true,
                                  message: planMessages.checkboxInputReportRequired,
                                },
                              ]}
                              name={[field.name, 'Input']}
                              placeholder={planMessages.placeholderCheckBoxInputReport}
                              initialValue=""
                              disable={isSubmitted}
                              props={{ onPressEnter: () => add({ Input: '' }) }}
                            />

                            <div
                              className={`close-icon-container ${isSubmitted && 'd-none'}`}
                              style={{ marginTop: '4px' }}>
                              <Button type="text" disabled={isSubmitted} onClick={() => remove(field.name)}>
                                <CloseOutlined />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  );
                })}

                {!isSubmitted && (
                  <Form.Item>
                    <Form.Item noStyle>
                      <Row>
                        <Col span={24}>
                          <ButtonWrap
                            className="addValueBtn"
                            text={planMessages.addValue}
                            width={'100%'}
                            height="56px"
                            color={isSubmitted ? '#247e84' : '#49ABB1'}
                            backgroundColor="transparent"
                            disabled={isSubmitted}
                            iconLeft={
                              <PlusCircleOutlined
                                style={{
                                  color: 'inherit',
                                }}
                              />
                            }
                            onClick={() => {
                              const id = fields.length > 0 ? fields[fields.length - 1].key + 1 : 0;
                              add({
                                Input: '',
                              });
                            }}></ButtonWrap>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.List>
        {!isValid && <ErrorMessage message={planMessages.checkBoxEmptyMessage} />}

        {!isSubmitted && <BtnActionsGroup onCancel={handleCancell} onDone={handleDone} />}
      </Form>
    </>
  );
};

export default ReportCheckboxType;
