import React, { FC, useCallback, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { japanesePlanMessages as planMessages } from '../../../../utils/messages';
import ContentCheckList from '../../../../components/Form/CheckListForm/RightForm/Content';
import ResultTab from '../Support/ResultTab/ResultTab';

const { TabPane } = Tabs;
interface IWorkFinishProps {
  className: string;
  data: any;
  index: number;
  props: any;
  review: boolean;
}

const WorkDetail: FC<IWorkFinishProps> = ({ className, data, index, review, props }) => {
  const renderWorkContent = useCallback(() => {
    if (!review) {
      return (
        <ContentCheckList
          data={data}
          className={className}
          index={index}
          id={data.id}
          key={data.id}
          props={props}></ContentCheckList>
      );
    }
    return (
      <div className={className}>
        <Tabs moreIcon={<></>} defaultActiveKey="1" className="tabs-container">
          <TabPane tab={<p className="check-tab">{planMessages.labelContent}</p>} key="1">
            <ContentCheckList
              review={review}
              data={data}
              className={className}
              index={index}
              id={data.id}
              key={data.id}
              props={props}></ContentCheckList>
          </TabPane>
          <TabPane tab={<p className="check-tab">{planMessages.labelResult}</p>} key="2">
            <div className="result-container">
              <ResultTab
                props={{
                  inspections: data.inspections,
                  id: props?.control?._formValues?.works?.[index].id,
                  hasNewComment: props?.control?._formValues?.works?.[index].has_new_comment,
                }}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }, [className, data, index, review, props]);

  return <>{renderWorkContent()}</>;
};

export default WorkDetail;
