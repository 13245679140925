import React, { FC, useState } from 'react';
import planApi, { PrefixUrlComments } from '../../../../api/planApi';
import NoteForm from '../../NoteForm';
import { useQuery } from 'react-query';
import { Spin } from 'antd';
import { japanesePlanMessages as planMessages } from '../../../../utils/messages';

interface IPlanCommentFormProps {
  id: string;
  commentVisible: boolean;
  setCommentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  idPlan: any;
  setHasNewCommentFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteWorkForm: FC<IPlanCommentFormProps> = ({
  id,
  commentVisible,
  setCommentVisible,
  setHasNewCommentFlag,
  idPlan,
}) => {
  const { isLoading, isError, data, error }: any = useQuery(['work-comments', id], () =>
    planApi.getAllWorkComments(id)
  );

  if (!isLoading) {
    setHasNewCommentFlag(false);
  }

  return (
    <>
      <NoteForm
        id={id}
        visible={commentVisible}
        title={planMessages.commentTitle}
        data={data?.data}
        PrefixUrlComment={PrefixUrlComments.addCommentWork(id)}
        isLoading={isLoading}
        setCommentVisible={setCommentVisible}
        purpose="work-comment"
        idPlan={idPlan}
      />
    </>
  );
};

export default NoteWorkForm;
