import { EditOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import buildingItemApi from '../../../api/buidingItemApi';
import DocumentApi from '../../../api/documentApi';
import CarouselCustom from '../../../components/CarouselCustom';
import { ButtonWrap, ErrorMessage, TextAreaField, TitleContent } from '../../../components/commons';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';
import RequiredLabel from '../../../components/commons/RequiredLabel';
import InputFieldCustom from '../../../components/InputFieldCustom';
import UploadWrapper from '../../../components/Upload';
import { appRoutes } from '../../../router/routes';
import { constant } from '../../../utils/constants';
import { deviceMasterMessages, generalMessages, masterMessages } from '../../../utils/messages';
import { typeFileMsg } from '../../../utils/messages/typeFile';
import { regex } from '../../../utils/regex';
import './style.scss';

interface EditDeviceMasterProps {}
const EditDeviceMaster: FC<EditDeviceMasterProps> = (props) => {
  const queryClient = useQueryClient();
  const location: any = useLocation();
  const navigate = useNavigate();
  const [err, setError]: any = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, data }: any = useQuery(['building-item_detail', id], () => buildingItemApi.get(id), {
    onError: (error: any) => {
      if (error && error.response.status === 404) {
        navigate(appRoutes.NOT_FOUND);
      }
    },
  });
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }
  const [resetState, setState] = useState(false);
  const [display, setDisplay] = useState('none');
  const [edit, setEdit] = useState('flex');
  const [disable, setDisable] = useState(location?.state?.view ?? true);
  const mutation = useMutation(buildingItemApi.edit, {
    onSuccess: async (data: any) => {
      if (data.success) {
        await Promise.all([
          queryClient.invalidateQueries(['building-item_detail', id]),
          queryClient.invalidateQueries(['building-item', 1]),
        ]);
        if (!location.state?.view) {
          navigate(`${appRoutes.MASTER.DEVICE.ROOT}`);
          notification.success({
            message: generalMessages.successMsg,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'building-item' && query.queryKey[1] !== 1,
          });
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'plan',
          });
          return;
        }
        setDisplay('none');
        setDisable(true);
        setEdit('flex');
        setState(true);
        setLoading(false);
        notification.success({
          message: generalMessages.successMsg,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'building-item' && query.queryKey[1] !== 1,
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'plan',
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      let obsParrent: any = {};
      if (error.response.data.errors !== null) {
        (Object.keys(error.response.data.errors) || []).forEach((item: string) => {
          obsParrent = { ...obsParrent, [item]: error.response.data.errors[item][0].toString() };
        });
        setError(obsParrent);
      }
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const handleEdit = () => {
    setDisplay('flex');
    setDisable(false);
    setEdit('none');
    if (errors) setState(false);
  };
  const handleCancel = () => {
    if (!location.state?.view) navigate(`${appRoutes.MASTER.DEVICE.ROOT}`);
    else {
      reset(data?.data);
      setDisplay('none');
      setDisable(true);
      setEdit('flex');
      setState(true);
      setError({});
    }
  };
  const onSubmit = (dataForm: any) => {
    setError({});
    setLoading(true);
    const formData = new FormData();
    formData.append('name', dataForm.name);
    formData.set('note', dataForm.note.toString());
    dataForm.files?.map((file: any) => {
      if (Object.keys(file).includes('urlImg')) formData.append('files[]', file.originFileObj);
    });
    if (dataForm.files?.length === 0) {
      data?.data?.documents?.map((file: any) => formData.append('fileRemoves[]', file.uuid));
    }
    dataForm.removeFiles?.map((remove_file: any) => {
      formData.append('fileRemoves[]', remove_file);
    });
    if (id) mutation.mutate({ id, data: formData });
  };
  useQuery(
    'file',
    () => {
      data?.data?.filesName?.map((file_name: string) => DocumentApi.get(file_name));
    },
    {
      enabled: !!data?.data?.filesName,
    }
  );
  const files = data?.data?.documents?.map((i: any) => i.base64);
  const documents = data?.data?.documents;
  useEffect(() => {
    if (!location.state?.view) handleEdit();
  }, []);
  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <section className="edit-building-master">
          <div className="edit-building-master__container">
            <div className="edit-building-master__title">
              <TitleContent
                leftIcon={true}
                title={deviceMasterMessages.editTitle}
                handleBack={() => navigate(`${appRoutes.MASTER.DEVICE.ROOT}`)}
              />
            </div>
            <div className="edit-building-master__form__container">
              <div className="edit-building-master__form mini">
                <Form className="edit-building-master__form__wrapper">
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="name"
                        rules={{
                          required: deviceMasterMessages.requireDeviceCode,
                          validate: (value: string) => {
                            if (!!value && regex.validateNameDevice.test(value?.trim()) == false) {
                              return deviceMasterMessages.validateName;
                            }
                          },
                        }}
                        defaultValue={data?.data?.name}
                        control={control}
                        render={({ field }) => (
                          <InputFieldCustom
                            props={field}
                            maxLength={constant.maxLengthName}
                            label={
                              <>
                                {deviceMasterMessages.deviceCode}
                                <RequiredLabel />
                              </>
                            }
                            placeholder={!disable ? deviceMasterMessages.placeholder : ''}
                            disable={disable}
                            msgServer={!resetState && err['name']}
                            msgValidate={!resetState && errors?.name?.message}
                            onBlur={(e: any) => setValue('name', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <Controller
                        name="note"
                        defaultValue={data?.data?.note}
                        control={control}
                        render={({ field }) => (
                          <TextAreaField
                            help={<ErrorMessage message={errors.note?.message ?? err['note']} />}
                            label={deviceMasterMessages.note}
                            placeholder={!disable ? deviceMasterMessages.placeholder : ''}
                            props={field}
                            disable={disable}
                            state={(!resetState && errors.note) || err['note'] ? typeFileMsg.error : ''}
                            onBlur={(e: any) => setValue('note', e.target.value.trim())}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      {!disable ? (
                        <UploadWrapper
                          label={deviceMasterMessages.document}
                          data={documents}
                          setValues={setValue}
                          multiple={true}
                        />
                      ) : (
                        <Form.Item
                          label={deviceMasterMessages.document}
                          className={files?.length <= 0 ? 'no-document' : ''}
                          colon={false}>
                          {files?.length <= 0 && <span>{masterMessages.noDocumentMsg}</span>}
                          <CarouselCustom data={documents} slidesQuantity={4} />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row className="edit-building-master__form__item">
                    <Col span={24}>
                      <div style={{ display: edit }} className="edit-btn">
                        <ButtonWrap
                          iconLeft={<EditOutlined />}
                          text={deviceMasterMessages.submitBtn}
                          backgroundColor="#1C282F"
                          onClick={handleEdit}
                        />
                      </div>

                      <div className="btn-wrapper" style={{ display: display }}>
                        <ButtonWrap
                          type="default"
                          color="#49ABB1"
                          backgroundColor="#1C282F"
                          border="1px solid #49ABB1"
                          text={deviceMasterMessages.cancel}
                          width="100%"
                          height="56px"
                          onClick={handleCancel}
                        />
                        <ButtonWrap
                          type="primary"
                          htmlType="submit"
                          text={deviceMasterMessages.done}
                          width="100%"
                          height="56px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditDeviceMaster;
