import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Empty, Form, Input, List, notification, Spin, Col, Row } from 'antd';
import React, { FC, useState } from 'react';
import { ButtonWrap, ErrorMessage, InputField, TextAreaField } from '../../commons';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import planApi from '../../../api/planApi';
import { japanesePlanMessages as planMessages } from '../../../utils/messages';
import { generalMessages } from '../../../utils/messages/general';
import EmptyWrap from '../../commons/EmptyWrap';
import CommentItem from './CommentItem';
import './styles.scss';

interface INoteFormProps {
  title: string;
  data: string[];
  visible?: boolean;
  setCommentVisible?: any;
  PrefixUrlComment?: any;
  // id?: string | undefined;
  id?: any;
  isLoading?: boolean;
  purpose: string;
  isFetching?: boolean;
  idPlan?: any;
}

const NoteForm: FC<INoteFormProps> = ({
  title,
  data,
  visible = false,
  setCommentVisible,
  id,
  PrefixUrlComment,
  isLoading,
  isFetching,
  purpose,
  idPlan,
}) => {
  const queryClient = useQueryClient();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ defaultValues: { content: '' } });

  const mutation = useMutation(planApi.addComment, {
    onSuccess: (data: any) => {
      if (data.success) {
        if (purpose.includes('work')) {
          queryClient.invalidateQueries('work-comments').then(() => {
            setIsLoadingSubmit(false);
            notification.success({
              message: generalMessages.successMsg,
            });
            setValue('content', '');
          });
        } else {
          queryClient.invalidateQueries('plan-comments').then(() => {
            setIsLoadingSubmit(false);
            notification.success({
              message: generalMessages.successMsg,
            });
            setValue('content', '');
          });
        }
      } else
        notification.error({
          message: data.message,
        });
    },
    onSettled: () => {},
    onError: (data: any) => {
      setIsLoadingSubmit(false);
      notification.error({
        message: data.response.data.message,
      });
    },
  });

  const onSubmit = (data: any) => {
    setIsLoadingSubmit(true);
    const formData: any = new FormData();
    if (PrefixUrlComment.includes('works')) {
      formData.append('comment', data.content);
    } else {
      formData.append('content', data.content);
    }

    if (id) {
      mutation.mutate({ prefix: PrefixUrlComment, data: formData });
    }
  };

  return (
    <Drawer
      height={'60%'}
      footer={
        <>
          {!isLoadingSubmit && !isLoading && !isFetching && (
            <Form className="plan_comment">
              <Row gutter={16}>
                <Col span={21}>
                  <Controller
                    name="content"
                    rules={{
                      required: planMessages.commentRequiredText,
                    }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextAreaField
                          label=""
                          help={<ErrorMessage message={errors?.content?.message} />}
                          className={errors.content ? 'border-error comment-textarea' : 'comment-textarea'}
                          placeholder={planMessages.commentPlaceHolder}
                          rows={1}
                          props={field}
                          onBlur={(e: any) => setValue('content', e.target.value.trim())}
                        />
                      );
                    }}
                  />
                </Col>
                <Col span={3}>
                  <ButtonWrap
                    className="common-btn add-cmt-btn"
                    type="primary"
                    text={planMessages.commentBtn}
                    height="56px"
                    color="white"
                    onClick={handleSubmit(onSubmit)}
                    htmlType="submit"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </>
      }
      footerStyle={{ borderTop: 'none' }}
      title={
        <span className="comment-title" style={{ color: '#fff' }}>
          {title}
        </span>
      }
      visible={visible}
      placement="bottom"
      closable={false}
      headerStyle={{ textAlign: 'center', borderBottom: 'none', background: 'rgba(56, 64, 69, 1)' }}
      extra={<CloseOutlined onClick={() => setCommentVisible(false)} />}
      drawerStyle={{ background: 'rgba(56, 64, 69, 1)' }}
      onClose={() => {
        setCommentVisible(false);
      }}>
      {isLoading || isLoadingSubmit || isFetching ? (
        <Spin tip={planMessages.loading}></Spin>
      ) : (
        <>
          {data.length === 0 && !isFetching ? (
            <EmptyWrap />
          ) : (
            <List
              dataSource={data}
              itemLayout="horizontal"
              renderItem={(item: any) => {
                return (
                  <CommentItem
                    purpose={purpose}
                    item={item}
                    commentContent={purpose == 'plan-comment' ? item?.content : item.comment}
                  />
                );
              }}
              className="comment-list"
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default NoteForm;
