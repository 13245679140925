import { Contract, DetailsResponse, ListResponse, User } from '../models';
import { generalMessages } from '../utils/messages';
import axiosClient from './axiosClient';

const staff = 'staff';
const userApi = {
  getAll(): Promise<ListResponse<User>> {
    const url = staff;
    return axiosClient.get(url);
  },
  get(id: string | undefined): Promise<DetailsResponse<User>> {
    const url = `${staff}/${id}`;
    return axiosClient.get(url);
  },
  getPage(
    pageParams: number,
    search: string | undefined,
    sort: string | undefined,
    type: string | undefined
  ): Promise<DetailsResponse<User>> {
    const url = `${staff}?page_size=${generalMessages.page_size}&page=${pageParams}${
      search ? `&search=${search}` : ''
    }${sort && type ? '&' + type + '=' + sort : ''}`;
    return axiosClient.get(url);
  },
  getContracts(): Promise<Contract[]> {
    const url = `${staff}/contracts`;
    return axiosClient.get(url);
  },
  add(data: any): Promise<DetailsResponse<User>> {
    const url = staff;
    return axiosClient.post(url, data);
  },
  edit({ id, data }: { id: string | undefined; data: any }): Promise<DetailsResponse<User>> {
    const url = `${staff}/${id}`;
    return axiosClient.put(url, data);
  },
  delete(id: string): Promise<DetailsResponse<User>> {
    const url = `${staff}/${id}`;
    return axiosClient.delete(url);
  },
};

export default userApi;
