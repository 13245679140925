import { Modal } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import ButtonWrap from '../ButtonWrap';
import './style.scss';

interface PopupProps {
  unVisible: any;
  title: string;
  actions: any;
  btn1?: string;
  btn2?: string;
  width?: string;
  text?: string;
}
const Popup: FC<PopupProps> = ({ unVisible, title, actions, btn1, btn2, width, text }) => {
  const [isVisible, setIsVisible] = useState(true);
  const myRef = useRef<HTMLButtonElement>(null);
  const handleCancel = () => {
    setIsVisible(false);
    unVisible();
  };
  useEffect(() => {
    if (myRef && myRef.current) {
      const current = myRef.current;
      current.focus();
    }
  }, [myRef]);

  return (
    <>
      <Modal
        title={title}
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
        width={width}
        className="popup-container">
        <h1>{text}</h1>
        <div className="button__wrap">
          <ButtonWrap
            text={btn1}
            onClick={actions}
            width={'100%'}
            height={'40px'}
            backgroundColor={'#247E84'}
            border={'1px solid #247E84 !important'}
            color={'#ffffff'}
          />
          <ButtonWrap
            text={btn2}
            onClick={handleCancel}
            width={'100%'}
            height={'40px'}
            backgroundColor={'#F2F2F2'}
            border={'1px solid #247E84'}
            color={'#49ABB1'}
            autoFocus={true}
            htmlType="button"
            props={{ ref: myRef }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Popup;
