import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import background from '../../assets/images/background-1.png';
import FooterLayout from '../Footer';
import HeaderLayout from '../Header';
import './style.scss';

interface IProps {
  header?: boolean;
}

const MainLayout: FC<IProps> = ({ header }) => {
  return (
    <>
      <div className="main">
        <div className="main__content" style={{ backgroundImage: `url(${background})` }}>
          {header && <HeaderLayout />}
          <div className="main__component">
            <Outlet />
          </div>
          <div className="main__footer">
            <FooterLayout />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
