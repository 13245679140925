import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterTable from '..';
import { deviceMasterMessages, generalMessages, masterMessages } from '../../../utils/messages';
import { ColumnsType } from 'antd/lib/table';
import { notification, Space, Tooltip } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import buildingItemApi from '../../../api/buidingItemApi';
import { BuildingItem } from '../../../models/buildingItem';
import Popup from '../../../components/commons/Popup';
import { appRoutes } from '../../../router/routes';
import HandleError from '../../../components/commons/HandleError';
import Loading from '../../../components/commons/Loading';

interface MasterDeviceProps {}

const MasterDevice: FC<MasterDeviceProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iD, setId] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [sort, setSort]: any = useState({});
  const [filter, setFilter]: any = useState();
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isFetching, isError, data }: any = useQuery(
    ['building-item', currentPage, filter, sort],
    () => buildingItemApi.getPage(currentPage, filter, type ? sort[type] : undefined, type),
    { staleTime: filter ? 0 : Infinity }
  );
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }

  const mutation = useMutation(buildingItemApi.delete, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['building-item', currentPage]).then(() => {
          const dat: any = queryClient.getQueryData(['building-item', currentPage]);
          if (dat?.data?.length === 0) {
            setCurrentPage((preState: any) => preState - 1);
          }
          if (!isFetching) setLoading(false);
          setVisible(false);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'building-item' && query.queryKey[1] !== currentPage,
        });
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      if (error && error.response.status === 404) {
        queryClient.clear();
      }
    },
  });
  const handleOpen = () => {
    navigate(`${appRoutes.MASTER.DEVICE.ADD}`);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  const handleEdit = (e: any, id: string) => {
    e.stopPropagation();
    navigate(`${appRoutes.MASTER.DEVICE.ROOT}/details/${id}`);
  };

  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
    queryClient.invalidateQueries(['building-item']);
  };

  const columns: ColumnsType<BuildingItem> = [
    {
      title: (
        <div className="equipment-management__table__title__wrapper">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_name: 'asc' })}
              style={{ color: sort.sort_by_name == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_name: 'desc' })}
              style={{ color: sort.sort_by_name == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{deviceMasterMessages.deviceCode}</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'deviceCode',
      width: '30%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <div className="equipment-management__table__container">
          <Tooltip title={text} placement="topLeft">
            <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {text}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: <span>{deviceMasterMessages.note}</span>,
      dataIndex: 'note',
      key: 'note',
      width: '60%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <div className="note" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {text}
        </div>
      ),
    },
    {
      title: <></>,
      key: 'action',
      align: 'right',
      width: '10%',
      render: (_: any, record: BuildingItem) => (
        <Space className="action">
          <div className="btn-action" onClick={(e) => handleEdit(e, record.id)}>
            <EditOutlined />
          </div>
          <div
            className="btn-action"
            onClick={(e) => {
              e.stopPropagation();
              setId(record.id);
              setVisible(true);
            }}>
            <CloseOutlined />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <>
      {loading || isFetching ? (
        <Loading />
      ) : (
        <div className="master-table">
          <MasterTable
            route={`${appRoutes.MASTER.DEVICE.ROOT}/details/`}
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            handleFilter={handleFilter}
            title={deviceMasterMessages.title}
            handleOpen={handleOpen}
            columns={columns}
            data={data?.data}
            total={data?.total}
            pageSize={generalMessages.page_size}
            currentPage={currentPage}
            setCurrent={(page: number) => setCurrentPage(page)}
          />
        </div>
      )}

      {visible && (
        <Popup
          unVisible={() => setVisible(false)}
          title={masterMessages.titleConfirm}
          actions={() => {
            setLoading(true);
            if (iD) mutation.mutate(iD);
            setVisible(false);
          }}
          width="415px"
          text={masterMessages.textConfirm}
          btn1={masterMessages.yes}
          btn2={masterMessages.no}
        />
      )}
    </>
  );
};

export default MasterDevice;
