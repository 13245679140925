import { FC } from 'react';
import iconListUser from '../../assets/images/iconuserlist.png';
import iconAddUser from '../../assets/images/iconadduser.png';
import { userMgmtMessages } from '../../utils/messages';
import { useNavigate } from 'react-router-dom';
import TitleContent from '../../components/commons/TitleContent';
import { CardWrap } from '../../components/commons';
import { ROLE } from '../../router/role';
import { Col, Row } from 'antd';
import { appRoutes } from '../../router/routes';
import './style.scss';

interface UserActionProps {}

const UserAction: FC<UserActionProps> = (props) => {
  const navigate = useNavigate();

  const handleAddUser = () => {
    if (localStorage.getItem('role') === ROLE.STAFF) navigate(`${appRoutes.USER.ADD}`);
    else navigate(`${appRoutes.ADMIN.ADD}`);
  };
  const handleListUser = () => {
    if (localStorage.getItem('role') === ROLE.STAFF) navigate(`${appRoutes.USER.LIST}`);
    else navigate(`${appRoutes.ADMIN.LIST}`);
  };

  return (
    <>
      <div className="user__action__container">
        <div className="user__action__title">
          <TitleContent title={userMgmtMessages.titleUserAction} leftIcon={false} />
        </div>
        <div className="user__action">
          <Row className="user__action__item" gutter={[30, 30]}>
            <Col xs={24} lg={12}>
              <CardWrap
                title={userMgmtMessages.addUserTitle}
                className="box box__add"
                width={'450px'}
                height={'190px'}
                widthImg={'58.67px'}
                heightImg={'64.22px'}
                img={iconAddUser}
                handleAction={handleAddUser}
              />
            </Col>
            <Col xs={24} lg={12}>
              <CardWrap
                title={userMgmtMessages.userManagementTitle}
                className="box box__list"
                width={'450px'}
                height={'190px'}
                img={iconListUser}
                widthImg={'58.75px'}
                heightImg={'62.19px'}
                handleAction={handleListUser}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default UserAction;
