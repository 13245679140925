import { Col, Input, notification, Row, Select, Tag, Tooltip } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TitleContent } from '../../../components/commons';
import GoogleMap from '../../../components/commons/GoogleMap';
import Loading from '../../../components/commons/Loading';
import { appRoutes } from '../../../router/routes';
import { generalMessages, japanesePlanMessages as planMessages } from '../../../utils/messages';
import _, { flatten, max, mean, min, uniqWith, isEqual } from 'lodash'
import './style.scss'
import buildingApi from '../../../api/buildingApi';
import planApi from '../../../api/planApi';
import listApi from '../../../api/listApi';
import { findIndex } from 'lodash'
interface MapProps {

}
const BuildingMap: FC<MapProps> = () => {
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [buildings, setBuildings] = useState<any>([])
  const { isLoading, isFetching, isError, data }: any = useQuery(['plan_suggestions'], () => planApi.getSuggestions());
  const fac_buildingItem: any = useQuery('list-fac-buildingItems', listApi.getFacBuildingItem);
  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }

  const searched = useRef(false)

  useEffect(() => {
    if (data) {
      if (searchParams.get('search')) {
        setBuildings(handleFilter(data.data.filter((el: any) => el.name.toLowerCase().indexOf(searchParams.get('search')?.toLocaleLowerCase()) > -1 && !el.deleted_at).map((el: any) => {
          return el
        })))
      } else {
        setBuildings(handleFilter(data.data.filter((el: any) => !el.deleted_at)))
      }

    }
  }, [data])
  const facility = useMemo(() => {
    return fac_buildingItem?.data?.data?.facility || []
  }, [fac_buildingItem])
  const buildingItem = useMemo(() => {
    return fac_buildingItem?.data?.data?.buildingItem || []
  }, [fac_buildingItem])
  const handleSelectFacility = (value: any) => {
    searchParams.set('facility', value || '')
    setSearchParams(searchParams)
    setBuildings(handleFilter(checkName(searchParams.get('search'))))
  }
  const handleSelectBuilingItem = (value: any) => {
    searchParams.set('building-item', value || '')
    setSearchParams(searchParams)
    setBuildings(handleFilter(checkName(searchParams.get('search'))))
  }
  const handleSearch = async (value: any) => {
    searchParams.set('search', value)
    searchParams.delete('facility')
    searchParams.delete('building-item')
    setSearchParams(searchParams)
    setBuildings(checkName(value))
  }
  const checkName = (value: any) => {
    if (value) {
      return data.data.filter((el: any) => el.name.toLowerCase().indexOf(value.toLowerCase()) > -1 && !el.deleted_at)
    } else {
      return data.data.filter((el: any) => !el.deleted_at).map((el: any) => {
        return el
      })
    }
  }
  const handleFilter = (building: any) => {
    searched.current = false
    if (!Number(searchParams.get('facility')) && !Number(searchParams.get('building-item'))) {
      return building.map((el: any) => { return { ...el, match: undefined } })
    } else {
      if (!Number(searchParams.get('facility'))) {
        return building.filter((el: any) =>
          findIndex(el.item_of_buildings,
            {
              'building_item_id': Number(searchParams.get('building-item')),
              'building_item_delete': null,
              'item_of_building_delete': null
            }) > -1
        ).map((el: any) => {
          return {
            ...el,
            match: findIndex(el.item_of_buildings, (el: any) => {
              return el.isPlan === true && el.building_item_id === Number(searchParams.get('building-item'))
            }) > -1
          }
        })
      }
      if (!Number(searchParams.get('building-item'))) {
        return building.filter((el: any) =>
          findIndex(el.building_facilities, {
            'facility_id': Number(searchParams.get('facility')),
            'building_facility_delete': null,
            'facility_delete': null
          }) > -1
        ).map((el: any) => {
          return {
            ...el,
            match: findIndex(el.building_facilities, (el: any) => {
              return el.isPlan === true && el.facility_id === Number(searchParams.get('facility'))
            }) > -1
          }
        })
      }
      return building.filter((el: any) =>
        findIndex(el.building_facilities, {
          'facility_id': Number(searchParams.get('facility')),
          'building_facility_delete': null,
          'facility_delete': null
        }) > -1 && findIndex(el.item_of_buildings,
          {
            'building_item_id': Number(searchParams.get('building-item')),
            'building_item_delete': null,
            'item_of_building_delete': null
          }) > -1
      ).map((el: any) => {
        return {
          ...el,
          match: findIndex(el.item_of_buildings, (el: any) => {
            return el.isPlan === true && el.building_item_id === Number(searchParams.get('building-item'))
          }) > -1 && findIndex(el.building_facilities, (el: any) => {
            return el.isPlan === true && el.facility_id === Number(searchParams.get('facility'))
          }) > -1
        }
      })
    }
  }
  const handleZoom = (difference: number) => {
    switch (true) {
      case difference >= 9:
        return 3
      case difference >= 7:
        return 5
      case difference >= 4:
        return 6
      case difference >= 3:
        return 8
      case difference >= 1:
        return 9
      case difference >= 0.2:
        return 10
      case difference >= 0.1:
        return 12
      default:
        return 13
    }
  }
  const handleScale = () => {
    const maxLat = Number(max(buildings.map((el: any) => el.lat)))
    const minLat = Number(min(buildings.map((el: any) => el.lat)))
    const maxLng = Number(max(buildings.map((el: any) => el.lng)))
    const minLng = Number(min(buildings.map((el: any) => el.lng)))
    let avgLat = mean([maxLat, minLat])
    let avgLng = mean([maxLng, minLng])
    let zoom = handleZoom(max([maxLng - minLng, maxLat - minLat]) || 0)
    searchParams.set('lat', String(avgLat))
    searchParams.set('lng', String(avgLng))
    searchParams.set('zoom', String(zoom))
    setSearchParams(searchParams)
  }
  useEffect(() => {
    if (searched.current && buildings.length) {
      handleScale()
    }
  }, [buildings, searchParams.get('search')])
  return (
    <>
      {isFetching || isLoading ? (<Loading />) : (
        <section className='building-map-master'>
          <div className='building-map-master__container'>
            <div className='building-map-master__title'>
              <TitleContent
                leftIcon={true}
                title={planMessages.titleShowMap}
                handleBack={() => navigate(`/${appRoutes.PLAN.ROOT}`)}
              />
            </div>
            <div className='building-map-master__container__filter'>
              <Row gutter={16}>
                <Col xs={24} lg={10}>
                  {planMessages.labelNameBuilding}
                  <Input.Search
                    placeholder={planMessages.searchBuildingPlaceholder}
                    style={{ width: '100%', marginTop: 6 }}
                    onSearch={handleSearch}
                    defaultValue={searchParams.get('search') || undefined}
                    onChange={() => searched.current = true}
                  />
                </Col>
                <Col xs={24} lg={7}>
                  {planMessages.labelFacility}
                  <Select
                    allowClear
                    onChange={handleSelectFacility}
                    placeholder={planMessages.selectFilterplaceholder}
                    value={Number(Number(searchParams.get('facility'))) || null}
                    showSearch={true}
                    filterOption={(input, option: any) => {
                      return (option!.children?.props?.title as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }}
                    className='building-map-master__container__filter__selector'>
                    {facility.map((el: any) => <Select.Option value={el.id} key={el.id}><Tooltip title={el.name} placement='top'><div className='label-select'><span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{el.name}</span> {el.deleted_at !== null && <span className="label-select__active">{planMessages.deleted}</span>}</div></Tooltip></Select.Option>)}
                  </Select>
                </Col>
                <Col xs={24} lg={7}>
                  {planMessages.labelJobBuildingItem}
                  <Select
                    allowClear
                    onChange={handleSelectBuilingItem}
                    placeholder={planMessages.selectFilterplaceholder}
                    value={Number(Number(searchParams.get('building-item'))) || null}
                    showSearch={true}
                    filterOption={(input, option: any) => {
                      return (option!.children?.props?.title as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }}
                    className='building-map-master__container__filter__selector'>
                    {buildingItem.map((el: any) => <Select.Option value={el.id} key={el.id}><Tooltip title={el.name} placement='top'><div className='label-select'><span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{el.name}</span> {el.deleted_at !== null && <span className="label-select__active">{planMessages.deleted}</span>}</div></Tooltip></Select.Option>)}
                  </Select>
                </Col>
              </Row>
            </div>
            <div className={isFullScreen ? 'building-map-master__container__map-full' : 'building-map-master__container__map'}>
              <GoogleMap
                width='100%'
                height={isFullScreen ? '100%' : '500px'}
                setIsFullScreen={() => setIsFullScreen(!isFullScreen)}
                lat={Number(searchParams.get('lat')!) || undefined}
                lng={Number(searchParams.get('lng')!) || undefined}
                zoom={Number(searchParams.get('zoom')!) || undefined}
                coordinates={buildings}
                isFullScreen={isFullScreen}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BuildingMap;
