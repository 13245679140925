import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC } from 'react';
import { typeFileMsg } from '../../../utils/messages/typeFile';
import './style.scss';
interface ITextAreaFieldProps {
  label: React.ReactNode;
  name?: string;
  required?: boolean;
  message?: string;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  state?: string;
  props?: any;
  help?: React.ReactNode;
  disable?: boolean;
  className?: string;
  onBlur?: any;
}
const error = {
  border: '1px solid #EB5757',
  boxShadow: '0px 0px 15px rgba(232, 78, 78, 0.35)',
};
const TextAreaField: FC<ITextAreaFieldProps> = ({
  label,
  name,
  required,
  message,
  placeholder,
  rows,
  maxLength,
  props,
  state,
  help,
  disable,
  className,
  onBlur,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message }]}
      className="textarea-form"
      help={help}
      colon={false}>
      <TextArea
        rows={rows}
        style={state === typeFileMsg.error ? error : {}}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disable}
        {...props}
        className={className}
        onBlur={onBlur}
      />
    </Form.Item>
  );
};

export default TextAreaField;
