import { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import TitleContent from '../../components/commons/TitleContent';
import { equipmentMessages, generalMessages } from '../../utils/messages';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Space, Tooltip } from 'antd';
import { Equipment } from '../../models';
import TableWrap from '../../components/TableWrap';
import EquipmentDetail from './EquipmentDetails';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import deviceApi from '../../api/deviceApi';
import AddEquipment from './AddEquipment';
import PaginationWrapper from '../../components/commons/Pagination';
import Popup from '../../components/commons/Popup';
import { useNavigate } from 'react-router-dom';
import HandleError from '../../components/commons/HandleError';
import { configs } from '../../configs';
import Loading from '../../components/commons/Loading';
import './style.scss';
import SearchField from '../../components/SearchField';

interface EquipmentManagementProps {}
interface DataType {
  id: number;
  macAddress: string;
  username: string;
}

const EquipmentManagement: FC<EquipmentManagementProps> = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId]: any = useState();
  const [open, setOpen] = useState(false);
  const [sort, setSort]: any = useState({});
  const [filter, setFilter]: any = useState();
  const [value, setValue]: any = useState();
  let type = Object.keys(sort).find((key) => sort[key] !== undefined);
  const { isFetching, isError, data }: any = useQuery(
    ['device', currentPage, filter, sort],
    () => deviceApi.getPage(currentPage, filter, type ? sort[type] : undefined, type),
    configs.staleTime
  );

  if (isError || data?.success === false) {
    notification.error({
      message: generalMessages.error,
      description: generalMessages.serverErr,
    });
  }

  const mutation = useMutation(deviceApi.delete, {
    onSuccess: (data: any) => {
      if (data.success) {
        queryClient.invalidateQueries(['device', currentPage]).then(() => {
          const dat: any = queryClient.getQueryData(['device', currentPage]);
          if (dat?.data?.length === 0) {
            setCurrentPage((preState: any) => preState - 1);
          }
          if (!isFetching) setLoading(false);
          setIsDelete(false);
          notification.success({
            message: generalMessages.successMsg,
          });
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'device' && query.queryKey[1] !== currentPage,
        });
        queryClient.invalidateQueries(['list-staffs']);
      }
    },
    onError: (error: any) => {
      setLoading(false);
      HandleError(error);
      if (error && error.response.status === 404) {
        queryClient.clear();
      }
    },
  });

  const handleSort = (sort: any) => {
    setCurrentPage(1);
    setSort(sort);
    type = Object.keys(sort).find((key) => sort[key] !== undefined);
    queryClient.invalidateQueries(['device']);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    setFilter(value);
  };

  const handleEdit = (record: any) => {
    setId(record?.id);
    setVisible(true);
  };

  const handleClickRow = (equipment: Equipment) => {
    setId(equipment?.id);
    setVisible(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <div className="equipment-management__table__title__wrapper">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_username: 'asc' })}
              style={{ color: sort.sort_by_username == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_username: 'desc' })}
              style={{ color: sort.sort_by_username == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{equipmentMessages.username}</span>
        </div>
      ),
      key: 'username',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (data: any) => {
        return (
          <div className="equipment-management__table__container">
            <Tooltip title={data.username + ' - ' + data.email} placement="topLeft">
              <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {data.username + ' - ' + data.email}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: (
        <div className="equipment-management__table__title">
          <div className="equipment-management__table__title__sort">
            <CaretUpOutlined
              onClick={() => handleSort({ sort_by_device_id: 'asc' })}
              style={{ color: sort.sort_by_device_id == 'asc' ? '#49ABB1' : '#BFBFBF' }}
            />
            <CaretDownOutlined
              onClick={() => handleSort({ sort_by_device_id: 'desc' })}
              style={{ color: sort.sort_by_device_id == 'desc' ? '#49ABB1' : '#BFBFBF' }}
            />
          </div>
          <span>{equipmentMessages.macAddress}</span>
        </div>
      ),
      dataIndex: 'device_id',
      key: 'macAddress',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text: string) => (
        <Tooltip title={text} placement="topLeft">
          <div className="text" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <></>,
      key: 'action',
      align: 'right',
      render: (_: any, record: Equipment) => {
        return (
          <Space className="action">
            <div
              className="btn-action"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record);
              }}>
              <EditOutlined />
            </div>
            <div
              className="btn-action"
              onClick={(e) => {
                e.stopPropagation();
                setId(record.id);
                setIsDelete(true);
              }}>
              <CloseOutlined />
            </div>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {isFetching || loading ? (
        <Loading />
      ) : (
        <section className="equipment-management">
          <div className="equipment-management__container">
            <div className="equipment-management__title">
              <TitleContent
                leftIcon={false}
                title={equipmentMessages.title}
                rightIcon={{
                  front: (
                    <>
                      <div className="holder__icon" onClick={() => handleOpen()}>
                        <PlusCircleOutlined />
                      </div>
                    </>
                  ),
                }}
                side={1}
                handleBack={() => navigate('/')}
              />
            </div>
            <div className={data?.data ? 'equipment-management__table' : 'no-data'}>
              <SearchField
                handleFilter={handleFilter}
                value={value?.trim()}
                onChange={(e: any) => {
                  setValue(e.target.value);
                }}
              />
              <TableWrap columns={columns} data={data?.data} onClickRow={handleClickRow} />
            </div>
            {data?.total > generalMessages.page_size && (
              <div className="equipment-management__pagination">
                <PaginationWrapper
                  current={currentPage}
                  total={data?.total}
                  pageSize={generalMessages.page_size}
                  setCurrent={(page: number) => setCurrentPage(page)}
                />
              </div>
            )}
          </div>
        </section>
      )}

      <Modal
        title={equipmentMessages.addEquipmentTitle}
        visible={open}
        footer={null}
        onCancel={() => setOpen(false)}
        destroyOnClose
        width={'636px'}>
        <AddEquipment
          isOpen={() => {
            setOpen(false);
          }}
        />
      </Modal>
      {isDelete && (
        <Popup
          unVisible={() => setIsDelete(false)}
          title={equipmentMessages.titleConfirm}
          actions={() => {
            setLoading(true);
            mutation.mutate(id);
            setIsDelete(false);
          }}
          width="415px"
          text={equipmentMessages.textConfirm}
          btn1={equipmentMessages.yes}
          btn2={equipmentMessages.no}
        />
      )}
      <Modal
        title={equipmentMessages.equipmentDetailsTitle}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={'636px'}>
        <EquipmentDetail key={`${id}`} isVisible={() => setVisible(false)} id={id} />
      </Modal>
    </>
  );
};

export default EquipmentManagement;
