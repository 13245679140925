import { Table } from 'antd';
import { FC } from 'react';
import { Equipment, Plan, User } from '../../models';
import { Building } from '../../models/building';
import { BuildingItem } from '../../models/buildingItem';
import { Facility } from '../../models/facility';
import { IEvent } from '../../pages/PlanManagement/types/types';
import { planConstant } from '../../utils/constants';
import EmptyWrap from '../commons/EmptyWrap';
import './style.scss';
interface TableWrapProps<T> {
  rowSelection?: any;
  columns: any[];
  data?: T[];
  onClickRow?: any;
  loading?: boolean;
  setRowStyle?: any;
}

const TableWrap: FC<TableWrapProps<User | Equipment | Building | BuildingItem | Facility | IEvent | Plan>> = ({
  rowSelection,
  columns,
  data = [],
  onClickRow,
  loading,
}) => {
  return (
    <Table
      className="table-wrap"
      locale={{ emptyText: <EmptyWrap /> }}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={loading}
      rowKey={(record) => record.id}
      onRow={(record: any, rowIndex) => {
        return {
          className: record?.isHightLight
            ? 'red-column'
            : record.status === planConstant.planStatus.DELETE
            ? 'delete-row'
            : '',
          onClick: (event) => {
            var isCollapsed: any = window.getSelection()?.toString();
            if (!isCollapsed) {
              onClickRow(record);
            }
          },
        };
      }}
    />
  );
};

export default TableWrap;
